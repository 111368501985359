import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Button, Form, Modal, Stack } from 'react-bootstrap';
import { GoSearch } from 'react-icons/go';
import { MdErrorOutline } from 'react-icons/md'
import { facmanGetUnassignedMeters, facmanGetAssignedMeters, facilityManagaerAddNewMeter, facilityManagaerUpdateMeter,PUT_CH_ADD_NEW_METER_SUCCESS, PUT_CH_UPDATE_METER_SUCCESS } from '../../Redux/Actions/Actions';
import { useSelector, useDispatch } from 'react-redux';
import NavBarTop from "../Reusable_Components/NavBarTop";
import LeftNavBar from "../Reusable_Components/LeftNavBar";
import 'react-datepicker/dist/react-datepicker.css';
import { set } from 'date-fns';
import { Alert } from 'react-bootstrap';
import Loader from "../Reusable_Components/loader";
import AlertPopUp from '../Reusable_Components/Alert';
import Pagination from '../Reusable_Components/Pagination';
import { RECORDS_PER_PAGE, RECORDS_PER_PAGE_METER_MASTER } from '../../Config';
import { timeElapsed, validateValue, formatNumericInput, handleKeyDownForMeterReadings } from "../Reusable_Components/ReusableFunctions";
import { formatDateView } from "../Reusable_Components/ReusableFunctions";
import Footer from "../Login/Footer";

const MeterMaster = () => {
    const [searchText, setSearchText] = useState('');
    const [selectMeter, setSelectMeter] = useState('all');
    const [meterType, setMeterType] = useState('assigned');
    
    const dispatch = useDispatch();
    const allUnassignedMeters = useSelector(state => state.allUnassignedMeters?.data);
    const assignedMeters = useSelector(state => state.assignedMeters?.data);
    const error = useSelector(state => state.error); 
    const facilitiesList = useSelector(state => state.facilitiesList)
    const isAddedMeter = useSelector(state => state.addedMeter);
    const addMeterError = useSelector(state => state.addMeterError)
    const updateMeterSuccess = useSelector(state => state.updateMeterSuccess)
    const contractApiLoader = useSelector(state => state.contractApiLoader)

   
    const [modalMeters, setModalMeters] = useState([])
    const [filteredMeters, setFilteredMeters] = useState(null);
    
    const [showAddNewMeterPopUP, setShowAddNewMeterPopUp] = useState(false);
    const [meterSuccessPopUp,setMeterSuccessPopUp] = useState(false);
    const [meterErroePopUp,setMeterErrorPopUp] = useState(false);
    const [lenghtError,setLengthError] = useState(false)
    const [meterNumberError, setMeterNumberError] = useState(false);
    const [uniqueMeterNumberError, setUniqueMeterNumberError] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(true);
    const [modalMeterType, setModalMeterType] = useState("electricity");
    const [modelMeterNumber, setModalMeterNumber ] = useState('');
    const [intialEBReading, setIntialEBReading ] = useState('');
    const [intialDGReading, setIntialDGReading ] = useState('');
    const [intialReading, setIntialReading ] = useState('');
    const [assignedNameSort, setAssignedNameSort] = useState(false);
    const [assignedMeterNumberSort, setAssignedMeterNumberSort] = useState(false);
    const [unassignedMeterNumberSort, setUnassignedMeterNumberSort] = useState(false);
    const [companyNameSort, setCompanyNameSort] = useState(false);
    const [contractNameSort, setContractNameSort] = useState(false);
    const [assignedDateSort, setAssignedDateSort] = useState(null);
    const [initialReadingSort, setInitialReadingSort] = useState(null);
    const [unassignedDateSort, setUnassignedDateSort] = useState(null);
    const [finalReadingSort, setFinalReadingSort] = useState(null);
    const [createdAtSort, setCreatedAtSort] = useState(null);
    const [updatedAtSort, setUpdatedAtSort] = useState(null);
    const [statusAtSort, setStatusAtSort] = useState(null);

    const [selectedDate, setSelectedDate] = useState(null);
    const [errorValue, setErrorValue] = useState(null);
    const [loading, setLoading] = useState(false);
    //for alert pop up
    const [alertShow,setAlertShow] = useState(false)
    const [alertType,setAlertType] = useState('')
    const [alertMessage,setAlertMessage] = useState('')
    const [alertValue,setAlertValue] = useState('')
    const [alertButtons,setAlertButtons] = useState([])
    const [alertAction,setAlertAction] = useState([])

    const [showCloseConfirmationModal, setShowCloseConfirmationModal] = useState(false)
    const handleCloseConfirmation = () => setShowCloseConfirmationModal(false);
    const handleProceed = () => {
        handleCloseConfirmation();
        handleAddNewMeterClose(); 
        setSelectedMeterToEdit(null)
        setEditedMeterStatus(null)
        setIsEditClicked(false)        
    };

    useEffect(() => {
        dispatch(facmanGetUnassignedMeters(
            {
                search_text: searchText,
                items_per_page: RECORDS_PER_PAGE_METER_MASTER, 
                page_number:  1,
                filter_condition:{
                    facility_id: localStorage.getItem("selected_facility_id"),
                    is_in_use: 0,
                    // is_working: 1
                }
            }
        ));
        
        dispatch(facmanGetAssignedMeters({ 
            search_text: searchText,
             items_per_page: RECORDS_PER_PAGE_METER_MASTER, 
             page_number:  1,
             filter_condition:{
                facility_id: localStorage.getItem("selected_facility_id")
             }
            }
            ))
    }, [dispatch]);

    const totalRecordsAssigned = useSelector(state => state.assignedMeters?.info?.total_no_of_records);
    const totalRecordsUnassigned = useSelector(state => state.allUnassignedMeters?.info?.total_no_of_records);

    const currentPaginationPageAssigned = useSelector(state => state.assignedMeters?.info?.page_number);
    const currentPaginationPageUnassigned = useSelector(state => state.allUnassignedMeters?.info?.page_number);

    useEffect(() => {                   
        if (meterType === 'assigned') {
            setFilteredMeters(assignedMeters);
        } else {
            setFilteredMeters(allUnassignedMeters);
        }
    }, [assignedMeters, allUnassignedMeters,meterType]);

    useEffect(() => {  
        setErrorValue(error?.response?.data.message);
        if(error?.response?.data.message || error?.data?.data[1]?.message){
            setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error')
            setAlertValue(error?.data?.data[1] ? error?.data?.data[1].message : error?.response?.data.message)
            setAlertButtons([{name:'Close',action: ()=>{
                setAlertShow(false)
                dispatch({
                    type: PUT_CH_UPDATE_METER_SUCCESS,
                    payload: null
                })
            }}])
        }
        setLoading(false);
    }, [error])

    useEffect(() => {
     setLoading(true);
    },[]);
    
    useEffect(() => {
        if(filteredMeters){
            setLoading(false);
        }
    },[filteredMeters])
    
    useEffect(() => {
        if(updateMeterSuccess==="success"){
            setShowAddNewMeterPopUp(false);
            setAlertShow(true)
            setAlertType('success')
            setAlertMessage('Success')
            setAlertValue(<>
                <p>Meter <b>{modelMeterNumber}</b> Updated Successfully</p>
            </>)
            setAlertButtons([{name:'Close',action: ()=>{
                setAlertShow(false)
                setSelectedMeterToEdit(null)
                setEditedMeterStatus(null)
                setIsEditClicked(false)
                handleAddNewMeterClose()
                dispatch({
                    type: PUT_CH_UPDATE_METER_SUCCESS,
                    payload: null
                })
                handleApply()
            }}])
        }
    },[updateMeterSuccess])

    
    useEffect(() => {
        if (isAddedMeter === "success") {
            setShowAddNewMeterPopUp(false);
            setMeterSuccessPopUp(true)
        }
        setLoading(false);
      }, [isAddedMeter])

    useEffect(() => {
        if(modalMeterType === "electricity"){
           setIntialReading('')
        }else if(modalMeterType === 'water'){
            setIntialEBReading('')
            setIntialDGReading('')
        }
    },[modalMeterType])

    useEffect(()=>{
        if (addMeterError) {
            setMeterErrorPopUp(true)
        }
        setLoading(false);
       
    },[addMeterError])
  
    

function formatToIndianRupee(number) {
    if(number){
        
        const parts = number?.toString().split(".");
        const numStr = parts[0];
        const decimalPart = parts.length > 1 ? "." + parts[1] : "";
    
        if (numStr?.length <= 3) return numStr + decimalPart;
        let lastThree = numStr.substring(numStr.length - 3);
        const otherNumbers = numStr.substring(0, numStr.length - 3);
        if (otherNumbers !== '') {
            lastThree = ',' + lastThree;
        }
        const output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree + decimalPart;
        return output;
    }
    return number
}
  
    const onChangeMeterType = (type) => {
        setMeterType(type);
            
    };
    const handleSearchSubmit = (searchValue) => {
        if (validateValue(searchValue)) {
            setSearchText(searchValue);
        }
    };
    
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    
    const onChangeMeter = (value) => {
        setSelectMeter(value);
        
    };

    const sortAssignedName = () => {
        let sortedMeters = [...filteredMeters];
        sortedMeters.sort((a, b) => {
            const nameA = a.assigned_name ? a.assigned_name.toLowerCase() : '' ;
            const nameB = b.assigned_name ? b.assigned_name.toLowerCase() : '';
            return nameA.localeCompare(nameB);
        });
        if (assignedNameSort) {
            setAssignedNameSort(!assignedNameSort);
            sortedMeters.reverse();
        } else {
            setAssignedNameSort(!assignedNameSort);
        }
        setFilteredMeters(sortedMeters);
    };
    
    const sortAssignedMeterNumber = () => {
        let sortedMeters = [...filteredMeters];
        sortedMeters.sort((a, b) => {
            const meterA = a.meter_number ? a.meter_number.toLowerCase() : '';
            const meterB = b.meter_number ? b.meter_number.toLowerCase() : '';
            return meterA.localeCompare(meterB);
        });
        if (assignedMeterNumberSort) {
            setAssignedMeterNumberSort(!assignedMeterNumberSort);
            sortedMeters.reverse();
        } else {
            setAssignedMeterNumberSort(!assignedMeterNumberSort);
        }
        setFilteredMeters(sortedMeters);
    };
    
    const sortUnassignedMeterNumber = () => {
        let sortedMeters = [...filteredMeters];
        sortedMeters.sort((a,b) => {
            const meterA = a.meter_number ? a.meter_number.toLowerCase() : '';
            const meterB = b.meter_number ? b.meter_number.toLowerCase() : '';
            return meterA.localeCompare(meterB);
        });
        if (unassignedMeterNumberSort) {
            setUnassignedMeterNumberSort(!unassignedMeterNumberSort);
            sortedMeters.reverse();
        } else {
            setUnassignedMeterNumberSort(!unassignedMeterNumberSort);
        }
        setFilteredMeters(sortedMeters);
    };

    const sortCompanyName = () => {
        let sortedMeters = [...filteredMeters];
        sortedMeters.sort((a,b) => {
            const companyA = a.company_name ? a.company_name.toLowerCase() : ''	;
            const companyB = b.company_name ? b.company_name.toLowerCase() : '';
            return companyA.localeCompare(companyB);
        }
        );  
        if (companyNameSort) {
            setCompanyNameSort(!companyNameSort);
            sortedMeters.reverse();
        }
        else {
            setCompanyNameSort(!companyNameSort);
        }
        setFilteredMeters(sortedMeters);
    };
    const sortContractName = () => {
        let sortedMeters = [...filteredMeters];
        sortedMeters.sort((a, b) => {
            const contractA = a.contract_name ? a.contract_name.toLowerCase() : '';
            const contractB = b.contract_name ? b.contract_name.toLowerCase() : '';
            return contractA.localeCompare(contractB);
        });
        
        if (contractNameSort) {
            setContractNameSort(!contractNameSort);
            sortedMeters.reverse();
        } else {
            setContractNameSort(!contractNameSort);
        }
    
        setFilteredMeters(sortedMeters);
    };
    const sortAssignedDate = () => {
        let sortedMeters = [...filteredMeters];
        sortedMeters.sort((a,b) => {
            const dateA = a.assigned_date ? a.assigned_date.toLowerCase() : '';
            const dateB = b.assigned_date ? b.assigned_date.toLowerCase() : '';
            return dateA.localeCompare(dateB);
        }
        );  
        if (assignedDateSort) {
            setAssignedDateSort(!assignedDateSort);
            sortedMeters.reverse();
        }
        else {
            setAssignedDateSort(!assignedDateSort);
        }
        setFilteredMeters(sortedMeters);
    };
    const sortInitialReading = () => {
        if (!Array.isArray(filteredMeters)) {
            console.error('filteredMeters is not an array');
            return;
        }
        let sortedMeters = [...filteredMeters];
        sortedMeters.sort((a, b) => {
            const readingA = a.initial_reading !== null ? parseInt(a.initial_reading, 10) : Number.NEGATIVE_INFINITY;
            const readingB = b.initial_reading !== null ? parseInt(b.initial_reading, 10) : Number.NEGATIVE_INFINITY;
            return readingA - readingB;
        });
        if (initialReadingSort) {
            setInitialReadingSort(!initialReadingSort);
            sortedMeters.reverse();
        } else {
            setInitialReadingSort(!initialReadingSort);
        }
        setFilteredMeters(sortedMeters);
    };
   const sortUnassignedDate = () => {
        let sortedMeters = [...filteredMeters];
        sortedMeters.sort((a,b) => {
            const dateA = a.unassigned_date ? a.unassigned_date.toLowerCase() : '';
            const dateB = b.unassigned_date ? b.unassigned_date.toLowerCase() : '';
            return dateA.localeCompare(dateB);
        }
        );  
        if (unassignedDateSort) {
            setUnassignedDateSort(!unassignedDateSort);
            sortedMeters.reverse();
        }
        else {
            setUnassignedDateSort(!unassignedDateSort);
        }
        setFilteredMeters(sortedMeters);
    };
    const sortFinalReading = () => {
        if (!Array.isArray(filteredMeters)) {
            console.error('filteredMeters is not an array');
            return;
        }
        let sortedMeters = [...filteredMeters];
        sortedMeters.sort((a, b) => {
            const readingA = a.final_reading !== null ? parseInt(a.final_reading, 10) : Number.NEGATIVE_INFINITY;
            const readingB = b.final_reading !== null ? parseInt(b.final_reading, 10) : Number.NEGATIVE_INFINITY;
            return readingA - readingB;
        });
        if (finalReadingSort) {
            setFinalReadingSort(!finalReadingSort);
            sortedMeters.reverse();
        } else {
            setFinalReadingSort(!finalReadingSort);
        }
        setFilteredMeters(sortedMeters);
    };

    const sortCreatedAt = () => {
        let sortedMeters = [...filteredMeters];
        sortedMeters.sort((a,b) => {
            const dateA = a.created_at ? a.created_at.toLowerCase() : '';
            const dateB = b.created_at ? b.created_at.toLowerCase() : '';
            return dateA.localeCompare(dateB);
        }
        );  
        if (createdAtSort) {
            setCreatedAtSort(!createdAtSort);
            sortedMeters.reverse();
        }
        else {
            setCreatedAtSort(!createdAtSort);
        }
        setFilteredMeters(sortedMeters);
    };

    const sortUpdatedAt = () => {
        let sortedMeters = [...filteredMeters];
        sortedMeters.sort((a,b) => {
            const dateA = a.updated_at ? a.updated_at.toLowerCase() : '';
            const dateB = b.updated_at ? b.updated_at.toLowerCase() : '';
            return dateA.localeCompare(dateB);
        }
        );  
        if (updatedAtSort) {
            setUpdatedAtSort(!updatedAtSort);
            sortedMeters.reverse();
        }
        else {
            setUpdatedAtSort(!updatedAtSort);
        }
        setFilteredMeters(sortedMeters);
    };

    const sortStatus = () => {
    let sortedMeters = [...filteredMeters];
    sortedMeters.sort((a, b) => {
        const statusA = a.is_working ? a.is_working.toString() : '';
        const statusB = b.is_working ? b.is_working.toString() : '';
        return statusA.localeCompare(statusB);
    });
    if (statusAtSort) {
        setStatusAtSort(!statusAtSort);
        sortedMeters.reverse();
    } else {
        setStatusAtSort(!statusAtSort);
    }
    setFilteredMeters(sortedMeters);
};
    
  
    const handleClear = () => {
        setLoading(true)
        setSelectedDate(null);
        setSearchText('');
        setSelectMeter('all');
        // setFilteredMeters(meterType === 'assigned' ? assignedMeters : allUnassignedMeters);
        
        dispatch(facmanGetUnassignedMeters({
            items_per_page: RECORDS_PER_PAGE_METER_MASTER, 
            page_number:  1,
            filter_condition:{
                facility_id: localStorage.getItem("selected_facility_id"),
                is_in_use: 0,
                // is_working: 1
            }
        }
        ));
        dispatch(facmanGetAssignedMeters(
            { 
                 items_per_page: RECORDS_PER_PAGE_METER_MASTER, 
                 page_number:  1,
                 filter_condition:{
                    facility_id: localStorage.getItem("selected_facility_id"),
                 }
            }
        ))
    }

 
    const modelMeterTypeSelect = (e) => {
        setModalMeterType(e.target.value);
        if (modalMeterType === 'electricity' || 'water') {
            setModalMeterNumber('');
        }
      };

      const handleIntialEBReading = (e) => {
        const input = e.target.value;
        const formattedInput = formatNumericInput(input, 3);
        setIntialEBReading(formattedInput);
    };

    const handleIntialDGReading = (e) => {
        const input = e.target.value;
        const formattedInput = formatNumericInput(input, 3);
        setIntialDGReading(formattedInput);
    };  

    const handleIntialReading = (e) => {
        const input = e.target.value;
        const formattedInput = formatNumericInput(input, 3);
        setIntialReading(formattedInput);
    }; 

    const handleModalMeterNumber = (e) => {
        const inputValue = e.target.value;
        const regex =   /^[A-Za-z0-9-/_]+$/;
        if(inputValue.length<3 || inputValue.length>200){
            setLengthError(true)
        }
        else{
            setLengthError(false)
    }
    if (regex.test(inputValue) || inputValue === '') {
        setModalMeterNumber(inputValue);
            if(isMeterNumberUnique(inputValue)) {
            setUniqueMeterNumberError(false);
                
        } else {
            setUniqueMeterNumberError(true);
        }
        setMeterNumberError(false);
    } else {
        setMeterNumberError(true);
    }
};

      
    const handleAddNewMeterShow = () => {
        setShowAddNewMeterPopUp(true);
        dispatch({
                                        type: PUT_CH_ADD_NEW_METER_SUCCESS,
                                        payload: null
                                    })
    }

    const [selectedMeterToEdit, setSelectedMeterToEdit] = useState(null);
    const [editedMeterStatus, setEditedMeterStatus] = useState(null);
    const [isEditClicked, setIsEditClicked] = useState(false);

    const handleAddNewMeterShowEdit = (item) => {
        dispatch({
            type: PUT_CH_ADD_NEW_METER_SUCCESS,
            payload: null
        })
        setShowAddNewMeterPopUp(true);
        setSelectedMeterToEdit(item);
        setModalMeterType(item.meter_type);
        setModalMeterNumber(item.meter_number);
        setEditedMeterStatus(item.is_working);
        if(item.meter_type === 'electricity'){
            setIntialEBReading(item.initial_reading);
            setIntialDGReading(item.initial_dg_reading);
        }
        else{
            setIntialReading(item.initial_reading);
        }
        

    }

    const handleEditClick = () =>{
        setIsEditClicked(true);
    }

    const handleUpdateMeter = () => {
        const data = {
            "meter_id": selectedMeterToEdit.meter_id,
            "facility_id": localStorage.getItem("selected_facility_id"),
            "meter_number": modelMeterNumber,
            "meter_type": modalMeterType,
            "is_working": editedMeterStatus,
            "initial_reading": intialReading || intialEBReading,
            "initial_dg_reading": intialDGReading || null
        }
        dispatch(facilityManagaerUpdateMeter(data))
        // setLoading(true);
    }

    const handleAddNewMeterClose = () => {
        setShowAddNewMeterPopUp(false)
        setLengthError(false)
        setMeterNumberError(false)
        setUniqueMeterNumberError(false)
        setModalMeterType('electricity')
        setModalMeterNumber('')
        setIntialDGReading('')
        setIntialEBReading('')
        setIntialReading('')
        // handleApply()

    };

    

    useEffect(()=>{
        setModalMeters(assignedMeters)
        const requiredFieldsFilled = modalMeterType !== '' || modelMeterNumber !== '' ||
        intialEBReading !== "" || intialDGReading !=="" || intialReading !== "" 
    setSaveDisabled(!requiredFieldsFilled);
    }, [])
    
    const handleNewMetersave = () => {
        const data = {
            "facility_id": localStorage.getItem("selected_facility_id"),
            "meter_number": modelMeterNumber ,
            "meter_type": modalMeterType ,
            "initial_reading": intialReading || intialEBReading,
            "initial_dg_reading": intialDGReading || null
        }
        dispatch(facilityManagaerAddNewMeter(data))
        setLoading(true);
    }

    const isMeterNumberUnique = (meter_number) => {
        return !modalMeters?.some(meters => meters?.meter_number?.toLowerCase() === meter_number?.toLowerCase());
        }

    function formatDateToYYYYMMDD(dateTime) {
        const date = new Date(dateTime);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based in JavaScript
        const day = String(date.getDate()).padStart(2, '0');
        
        return `${year}-${month}-${day}`;
        }

    const handledPaginationNextClicked = () => {
        setLoading(true)
        let filterValue = {
            facility_id: localStorage.getItem("selected_facility_id")
         }
        
        if(selectMeter !== 'all'){
            filterValue['meter_type'] = selectMeter
        }
        if(meterType === 'assigned'){
            if(selectedDate){
                filterValue['assigned_date'] = formatDateToYYYYMMDD(selectedDate)
            }
            dispatch(facmanGetAssignedMeters(
                { 
                    search_text: searchText,
                     items_per_page: RECORDS_PER_PAGE_METER_MASTER, 
                     page_number:  currentPaginationPageAssigned + 1,
                     filter_condition:filterValue
                }
                ))
        }
        else{
            if(selectedDate){
                filterValue['unassigned_date'] = formatDateToYYYYMMDD(selectedDate)
            }
            filterValue['is_in_use'] = 0;
            // filterValue['is_working'] = 1;
            dispatch(facmanGetUnassignedMeters({
                search_text: searchText,
                items_per_page: RECORDS_PER_PAGE_METER_MASTER, 
                page_number:  currentPaginationPageUnassigned + 1,
                filter_condition:filterValue
            }
            ));
        
        }

    }

    const handledPaginationPrevClicked = () => {
        setLoading(true)
        let filterValue = {
            facility_id: localStorage.getItem("selected_facility_id")
         }
         
        if(selectMeter !== 'all'){
            filterValue['meter_type'] = selectMeter
        }
        if(meterType === 'assigned'){
            if(selectedDate){
                filterValue['assigned_date'] = formatDateToYYYYMMDD(selectedDate)
            }
            dispatch(facmanGetAssignedMeters(
                { 
                    search_text: searchText,
                     items_per_page: RECORDS_PER_PAGE_METER_MASTER, 
                     page_number:  currentPaginationPageAssigned - 1,
                     filter_condition:filterValue
                }
            ))
        }
        else{
            if(selectedDate){
                filterValue['unassigned_date'] = formatDateToYYYYMMDD(selectedDate)
            }
            filterValue['is_in_use'] = 0;
            // filterValue['is_working'] = 1;
            dispatch(facmanGetUnassignedMeters({
                search_text: searchText,
                items_per_page: RECORDS_PER_PAGE_METER_MASTER, 
                page_number:  currentPaginationPageUnassigned - 1,
                filter_condition:filterValue
            }
            ));
        }
    }

    const handleApply = () => {
        setLoading(true)
        let filterValueAssignes = {
            facility_id: localStorage.getItem("selected_facility_id")
        }
        let filterValueUnassigned = {
            facility_id: localStorage.getItem("selected_facility_id")
        }
        if(selectMeter !== 'all'){
            filterValueAssignes['meter_type'] = selectMeter;
            filterValueUnassigned['meter_type'] = selectMeter;
        }
        if(selectedDate){
            filterValueAssignes['assigned_date'] = formatDateToYYYYMMDD(selectedDate);
            filterValueUnassigned['unassigned_date'] = formatDateToYYYYMMDD(selectedDate);
        }

        dispatch(facmanGetAssignedMeters(
            { 
                search_text: searchText,
                    items_per_page: RECORDS_PER_PAGE_METER_MASTER, 
                    page_number:  1,
                    filter_condition:filterValueAssignes
            }
        ))

        filterValueUnassigned['is_in_use'] = 0;
        // filterValueUnassigned['is_working'] = 1;
        dispatch(facmanGetUnassignedMeters({
            search_text: searchText,
            items_per_page: RECORDS_PER_PAGE_METER_MASTER, 
            page_number:  1,
            filter_condition:filterValueUnassigned
        }
        ))
        
    }


    return (
        <>
        <>
            <NavBarTop />
            <LeftNavBar />
        </>
        <div className='facility_container'>
            <AlertPopUp alertShow={alertShow} alertType={alertType} alertMsg={alertMessage} alertValue={alertValue} alertButtons={alertButtons} />
            <div className='d-flex justify-content-between align-items-center flex-wrap'>
                <div className='d-flex align-items-center gap-3 mb-3'>
                    <h3 className="invoice-heading">Meter Master</h3>
                    {/* <div className="d-flex fin_man_meter_label pt-2">
                        <p className="fin_man_meter_label pt-2"><span>{filteredMeters && filteredMeters.length}</span> Total</p>
                    </div> */}
                </div>
                <div className='d-flex align-items-center gap-3 mb-3'>
                    <button className='invoice_btn' style={{ color: "white" }} onClick={handleAddNewMeterShow}>
                        <span className='generate_invoice'><img src='./images/icons/AddIcon.svg' style={{ marginRight: "10px" }} alt="+" />Add New Meter</span>
                        <span className='generate'><img src={'./images/icons/AddIcon.svg'} style={{ marginRight: "10px" }} alt="+" />Add</span></button>
                </div>
            </div>
            <div className='d-flex align-items-center gap-3 mb-3 flex-wrap justify-content-start' style={{flexDirection:"row"}}>
                
                <div className='d-flex align-items-center gap-2'>
                    
                    <select className='fin_man_meter_dropdown'
                    onChange={(e)=>{
                        onChangeMeter(e.target.value);
                        setSelectMeter(e.target.value);

                    }} value={selectMeter}>
                        <option value="all">Meter Type</option>
                        <option value="electricity">Electricty</option>
                        <option value="water">Water</option>
                    </select>
                </div>
                <div className="d-flex align-items-center pt-2">
                    <label className='fin_man_meter_label'>
                        {meterType === 'assigned' ? 'Assigned Date:' : 'Unassigned Date:'}&nbsp;&nbsp;&nbsp;
                    </label>
                    <div className="fac-man-date-picker-container">
                        <input 
                            type="date" 
                            className="fac-man-date-picker" 
                            dateFormat ="YYYY-MM-DD"
                            max={new Date().toISOString().split('T')[0]}
                            value={selectedDate ? selectedDate.toISOString().split('T')[0] : ''}
                            onChange={(e) => handleDateChange(e.target.value ? new Date(e.target.value) : null)}
                            onKeyDown={(e) => e.preventDefault()}
                        />
                                             
                    </div>
                </div>
                <div className='d-flex fin_man_meter_search_bar_container align-items-center justify-content-start'>
                    <input type='search' placeholder='Search' onChange = {(e)=> handleSearchSubmit(e.target.value)} value={searchText} className='fin_man_meter_search_bar' />
                    <GoSearch className='fin_man_meter_search_bar_icon' />
                </div>
                <div className='add-facility-button' onClick={handleApply}>
                    <span >Apply</span>
                </div>
                <div className='add-facility-button' onClick={handleClear}>
                    <span >Clear</span>
                </div>
            </div>
            <Stack direction="horizontal" className="mt-1 invoice-type-div" gap={6}>
                <div className="invoice-type-card" onClick={() => onChangeMeterType('assigned')}>
                    <p className={meterType === 'assigned' ? "selected-invoice-type C-pointer" : "C-pointer"}>Assigned Meters</p>
                </div>
                <div className="invoice-type-card" onClick={() => onChangeMeterType('unassigned')}>
                    <p className={meterType === 'unassigned' ? "selected-invoice-type C-pointer" : "C-pointer"}>Unassigned Meters</p>
                </div>
            </Stack>

            <div className='table_container_facility_manager'>
                <table>
                    <thead>
                        <tr className='fin_man_table_head commercial-head-table text-center'>
                            {meterType === 'assigned' ? (
                                <>
                                    <th className='C-pointer' onClick={()=>sortAssignedName()}>Assigned Name<img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th className='C-pointer' onClick={()=>sortAssignedMeterNumber()}>Meter Number<img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th className='C-pointer'onClick={()=>sortCompanyName()}>Company Name<img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th className='C-pointer' onClick={()=>sortContractName()}>Contract Name<img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th className='C-pointer'>Meter Type</th>
                                    <th className='C-pointer'onClick={()=>sortAssignedDate()}>Assigned Date<img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th className='C-pointer'onClick={()=>sortInitialReading()}>Initial Reading<img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th className='C-pointer'onClick={()=>sortCreatedAt()}>Created At<img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th className='C-pointer'onClick={()=>sortUpdatedAt()}>Last Updated<img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th className='C-pointer'>Action</th>


                                </>
                            ) : (
                                <>
                                    <th className='C-pointer' onClick={()=>sortUnassignedMeterNumber()}>Meter Number<img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th className='C-pointer' >Meter Type</th>
                                    <th className='C-pointer' onClick={()=>sortUnassignedDate()}>Unassigned Date<img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th className='C-pointer' onClick={()=>sortFinalReading()}>Final Reading<img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th className='C-pointer'onClick={()=>sortCreatedAt()}>Created At<img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th className='C-pointer'onClick={()=>sortUpdatedAt()}>Last Updated<img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th className='C-pointer'onClick={()=>sortStatus()}>Status<img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th className='C-pointer'>Action</th>

                                </>
                            )}
                        </tr>
                    </thead>
                    <tbody className='fac_man_table_body'>
                        {meterType === 'assigned' ? (
                            filteredMeters && filteredMeters.length > 0 ? (
                                filteredMeters.map((each, index) => (
                                    <>
                                    {
                                        each.meter_type === 'electricity' ? 
                                        <>
                                    <tr key={index} className={`table_row ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}>
                                        <td className='table_text_left'>{each.assigned_name}</td>
                                        <td className='table_text_left'>{each.meter_number}</td>
                                        <td className='table_text_left'>{each.company_name}</td>
                                        <td className='table_text_left'>{each.contract_name}</td>
                                        <td className='table_text_left'>{ each.meter_type[0].toUpperCase() + each.meter_type.slice(1)}</td>
                                        <td className='table_text_right'>{formatDateView( each.assigned_date)}</td>
                                        <td className='table_text_right'>{formatToIndianRupee(each?.initial_reading?each?.initial_reading:0)} {each.meter_type === 'electricity' ? 'kWh' : 'kL'}</td>
                                        <td className='table_text_right'>{timeElapsed(each.created_at)}</td>
                                        <td className='table_text_right'>{timeElapsed(each.updated_at)}</td>
                                            <td className='table_text_center' 
                                        // onClick={() => handleShowModal(facility.facility_id)} 
                                        onClick={()=>handleAddNewMeterShowEdit(each)}
                                        style={{ cursor: "pointer", justifyContent: "center", display:"flex" }}>
                                                        {/* <div> */}
                                                            <img src="./images/icons/ViewViewIconEye.svg" alt="view-icon" />
                                                            {/* </div> */}
                                                    </td>


                                    </tr>
                                    {/* <tr key={index} className={`table_row ${index+1 % 2 === 0 ? 'even-row' : 'odd-row'}`}>
                                        <td className='table_text_left'>{each.assigned_name}</td>
                                        <td className='table_text_left'>{each.meter_number}</td>
                                        <td className='table_text_left'>{each.company_name}</td>
                                        <td className='table_text_left'>{each.contract_name}</td>
                                        <td className='table_text_left'>{"Diesel"}</td>
                                        <td className='table_text_right'>{formatDateView (each.assigned_date)}</td>
                                        <td className='table_text_right'>{formatToIndianRupee(each?.initial_dg_reading?each?.initial_dg_reading:0)} {each.meter_type === 'electricity' ? 'kWh' : 'kL'}</td>
                                        <td className='table_text_right'>{timeElapsed(each.created_at)}</td>
                                        <td className='table_text_right'>{timeElapsed(each.updated_at)}</td>
                                        <td className='table_text_left'>
                                            </td>
                                       
                                            <td className='table_text_center' 
                                                    onClick={()=>handleAddNewMeterShowEdit(each)}
                                                    style={{ cursor: "pointer", justifyContent: "center", display:"flex" }}>
                                                            <img src="./images/icons/ViewViewIconEye.svg" alt="view-icon" />
                                            </td>

                                    </tr> */}
                                    </>
                                    :
                                    <tr key={index} className={`table_row ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}>
                                        <td className='table_text_left'>{each.assigned_name}</td>
                                        <td className='table_text_left'>{each.meter_number}</td>
                                        <td className='table_text_left'>{each.company_name}</td>
                                        <td className='table_text_left'>{each.contract_name}</td>
                                        <td className='table_text_left'>{ each.meter_type[0].toUpperCase() + each.meter_type.slice(1)}</td>
                                        <td className='table_text_right'>{formatDateView(each.assigned_date)}</td>
                                        <td className='table_text_right'>{formatToIndianRupee(each?.initial_reading?each?.initial_reading:0)} {each.meter_type === 'electricity' ? ' kWh' : ' kL'}</td>
                                        <td className='table_text_right'>{timeElapsed(each.created_at)}</td>
                                        <td className='table_text_right'>{timeElapsed(each.updated_at)}</td>
                                            <td className='table_text_center' 
                                        // onClick={() => handleShowModal(facility.facility_id)} 
                                        onClick={()=>handleAddNewMeterShowEdit(each)}
                                        style={{ cursor: "pointer", justifyContent: "center", display:"flex" }}>
                                                        {/* <div> */}
                                                            <img src="./images/icons/ViewViewIconEye.svg" alt="view-icon" />
                                                            {/* </div> */}
                                                    </td>

                                    </tr>

                                    }
                                    </>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan='10' style={{ textAlign: "center" }}>No Assigned meters available</td>
                                </tr>
                            )
                        ) : (
                            filteredMeters && filteredMeters?.length > 0 ? (
                                filteredMeters.map((each, index) => (
                                    <tr key={index} className={`table_row ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}>
                                        <td className='table_text_left'>{each.meter_number}</td>
                                        <td className='table_text_left'>{each.meter_type[0].toUpperCase() + each.meter_type.slice(1)}</td>
                                        <td className='table_text_right'>{formatDateView(each.unassigned_date)}</td>
                                        <td className='table_text_right'>{formatToIndianRupee(each?.initial_reading?each?.initial_reading:0)} {each.meter_type === 'electricity' ? 'kWh' : 'kL'}</td>
                                        <td className='table_text_right'>{timeElapsed(each.created_at)}</td>
                                        <td className='table_text_right'>{timeElapsed(each.updated_at)}</td>
                                        <td className='table_text_left'>{each.is_working ? "Active":"In-Active"}</td>
                                        <td className='table_text_center' 
                                        // onClick={() => handleShowModal(facility.facility_id)} 
                                        onClick={()=>handleAddNewMeterShowEdit(each)}
                                        style={{ cursor: "pointer", justifyContent: "center", display:"flex" }}>
                                                        {/* <div> */}
                                                            <img src="./images/icons/ViewViewIconEye.svg" alt="view-icon" />
                                                            {/* </div> */}
                                                    </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan='10' style={{ textAlign: "center" }}>No Unassigned meters available</td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            </div>
                <Pagination 
                handledPaginationNextClicked={handledPaginationNextClicked}
                handledPaginationPrevClicked={handledPaginationPrevClicked}
                isApplyClicked
                totalRecords = {meterType === "assigned" ? totalRecordsAssigned : totalRecordsUnassigned}
                recordsPerPage={RECORDS_PER_PAGE_METER_MASTER}
                currentPaginationPage={meterType === "assigned" ? currentPaginationPageAssigned : currentPaginationPageUnassigned}
                />
            <Modal keyboard={false} backdrop="static" show={showAddNewMeterPopUP} centered onHide={handleAddNewMeterClose} size='lg'>
                    <Modal.Header >
                        <Modal.Title>{selectedMeterToEdit?'Meter Details':'Add New Meter'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div className='d-flex flex-column gap-3'>
                            <div className='d-flex justify-content-between gap-3'>
                                <div className='d-flex align-items-center justify-content-between' style={{ width: "50%" }}>
                                    <label className='fin_man_meter_modal_label'>Location:<span className='text-danger'>*</span></label>
                                    <div className='fin_man_meter_input_fields'>
                                        <input type='text' 
                                        className='form-control add-facility-input-fields' 
                                        value={localStorage.getItem("selected_city_name")} 
                                        style={{ paddingLeft: "14px",border:"0.5px solid #9D9D9C" }} 
                                        disabled={true} 
                                        />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                    <label className='fin_man_meter_modal_label'>Facility:<span className='text-danger'>*</span></label>
                                    <div className='fin_man_meter_input_fields'>
                                        <input type='text' 
                                        className='form-control add-facility-input-fields' 
                                        value ={localStorage.getItem("selected_facility_name")} 
                                        style={{ paddingLeft: "14px",border:"0.5px solid #9D9D9C" }} 
                                        disabled={true} 
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between gap-3'>
                                <div className='d-flex align-items-center justify-content-between' style={{ width: "50%" }}>
                                    <label className='fin_man_meter_modal_label'>Meter Type:<span style ={{color:'red'}}>*</span></label>
                                    <select className='fin_man_model_meter_dropdown'
                                     value={modalMeterType}onChange={modelMeterTypeSelect}
                                     disabled={ selectedMeterToEdit ? true : false}
                                     >
                                        <option value='electricity'>Electricity</option>
                                        <option value='water'>Water</option>
                                    </select>
                                </div>                        
                                <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                    <label className='fin_man_meter_modal_label'>Meter Number:<span style ={{color:'red'}}>*</span></label>
                                    <div className='fin_man_meter_input_fields'>
                                        <input type='text'
                                        className={`form-control add-facility-input-fields ${meterNumberError ? 'is-invalid' : ''}`}
                                        value={modelMeterNumber} 
                                        onChange={handleModalMeterNumber} 
                                        style={{ paddingLeft: "14px", border:"0.5px solid #9D9D9C" }}
                                        required
                                     disabled={ selectedMeterToEdit ? true : false}
                                        />
                                        {meterNumberError && <div className="invalid-input">Only a-z,A-Z,0-9,-,_,/ are allowed.</div>}
                                        {uniqueMeterNumberError && <div className="invalid-input">Meter number should be unique!</div>}
                                        {lenghtError && <div className="invalid-input">Must be between 3 to 200 characters</div>}
                                    </div>
                                </div>
                            </div>
                            {modalMeterType === 'electricity'&&
                              <>
                            <div className='d-flex justify-content-between gap-3'>
                                <div className='d-flex align-items-center justify-content-between' style={{ width: "50%" }}>
                                    <label className='fin_man_meter_modal_label'>Intial EB reading:<span style ={{color:'red'}}>*</span></label>
                                    <div className='fin_man_meter_input_fields'>
                                        <input type='text' 
                                        className='form-control add-facility-input-fields' 
                                        value={intialEBReading} onChange={handleIntialEBReading}
                                        onKeyDown={handleKeyDownForMeterReadings} 
                                        style={{ paddingLeft: "14px", border:"0.5px solid #9D9D9C" }} 
                                        disabled={ (selectedMeterToEdit && !isEditClicked)}
                                        
                                        />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                    <label className='fin_man_meter_modal_label'>Units:<span style ={{color:'red'}}>*</span></label>
                                    <div className='fin_man_meter_input_fields'>
                                        <input type='text' className='form-control add-facility-input-fields' value={"kWh"} onChange={handleIntialDGReading} style={{ paddingLeft: "14px", border:"0.5px solid #9D9D9C" }} disabled={true} />
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between gap-3'>
                                <div className='d-flex align-items-center justify-content-between' style={{ width: "50%" }}>
                                    <label className='fin_man_meter_modal_label'>Intial DG reading:<span style ={{color:'red'}}>*</span></label>
                                    <div className='fin_man_meter_input_fields'>
                                        <input type='text' 
                                        className='form-control add-facility-input-fields' 
                                        value={intialDGReading} onChange={handleIntialDGReading} onKeyDown={handleKeyDownForMeterReadings}
                                        style={{ paddingLeft: "14px", border:"0.5px solid #9D9D9C" }} 
                                        disabled={ (selectedMeterToEdit && !isEditClicked)}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                    <label className='fin_man_meter_modal_label'>Units:<span style ={{color:'red'}}>*</span></label>
                                    <div className='fin_man_meter_input_fields'>
                                        <input type='text' className='form-control add-facility-input-fields' value={"kWh"} style={{ paddingLeft: "14px", border:"0.5px solid #9D9D9C" }} disabled={true} />
                                    </div>
                                </div>
                            </div>
                            </>
                            }
                            {modalMeterType === 'water'&&
                                <div className='d-flex justify-content-between gap-3'>
                                <div className='d-flex align-items-center justify-content-between' style={{ width: "50%" }}>
                                    <label className='fin_man_meter_modal_label'>Initial reading:<span style ={{color:'red'}}>*</span></label>
                                    <div className='fin_man_meter_input_fields'>
                                        <input type='text' 
                                        className='form-control add-facility-input-fields' 
                                        value={intialReading} onChange={handleIntialReading} onKeyDown={handleKeyDownForMeterReadings}
                                        style={{ paddingLeft: "14px", border:"0.5px solid #9D9D9C" }} 
                                        disabled={ (selectedMeterToEdit && !isEditClicked)}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                    <label className='fin_man_meter_modal_label'>Units:<span style ={{color:'red'}}>*</span></label>
                                    <div className='fin_man_meter_input_fields'>
                                        <input type='text' className='form-control add-facility-input-fields' value={"kL"} style={{ paddingLeft: "14px", border:"0.5px solid #9D9D9C" }} disabled={true} />
                                    </div>
                                </div>
                            </div>
                            }
                            {
                                selectedMeterToEdit && !(selectedMeterToEdit.contract_id) &&
                                <div className='d-flex justify-content-between gap-3'>
                                    <div className='d-flex align-items-center justify-content-between' style={{ width: "49%" }}>
                                        <label className='fin_man_meter_modal_label'>Status<span style ={{color:'red'}}>*</span></label>
                                        <div className='fin_man_meter_input_fields'>
                                            <select className='fin_man_model_meter_dropdown' value={editedMeterStatus}
                                            onChange={(e)=> setEditedMeterStatus(e.target.value)}
                                            disabled={(selectedMeterToEdit && !isEditClicked)}
                                            >
                                                <option value='1'>Active</option>
                                                <option value='0'>In-Active</option>
                                            </select>
                                        </div>
                                    </div>
                                    
                                </div>
                            }

                     </div>    
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            selectedMeterToEdit ?
                            <>
                            <Button className='btn btn-light px-5' style={{ border: "1px solid #878787", backgroundColor: "#FFFFFF" }} onClick={() => setShowCloseConfirmationModal(true)}>
                                Cancel
                            </Button>
                            {
                                isEditClicked ?
                                <Button className='btn btn-secondary px-4' 
                                        // onClick={handleNewMetersave}  
                                        onClick={handleUpdateMeter}
                                        style={{ border: "1px solid #878787" }} 
                                        >
                                            Update
                                    </Button>
                            :
                                    <Button className='btn btn-secondary px-4' 
                                        // onClick={handleNewMetersave}  
                                        onClick={handleEditClick}
                                        style={{ border: "1px solid #878787" }} 
                                        >
                                            Edit
                                    </Button>
                            }
                            </>
                            :
                            <>
                            <Button className='btn btn-light px-5' style={{ border: "1px solid #878787", backgroundColor: "#FFFFFF" }} onClick={() => setShowCloseConfirmationModal(true)}>
                                Cancel
                            </Button>
                            <Button className='btn btn-secondary px-4' onClick={handleNewMetersave}  style={{ border: "1px solid #878787" }}disabled={saveDisabled || modalMeterType === '' ||
                                    modelMeterNumber ==="" || ( intialEBReading === "" || intialDGReading ==="") && intialReading === "" ||
                                    lenghtError || uniqueMeterNumberError }>
                                    Save Meter
                            </Button>
                            </>
                            
                        }
                        
                    </Modal.Footer>
                </Modal>
                <Modal  keyboard={false} backdrop="static" show={showCloseConfirmationModal} centered onHide={handleCloseConfirmation}>
                    <Modal.Body className="text-center">
                    <h5>Are you sure you want to Cancel? Your progress will not be saved.</h5>
                    </Modal.Body>
                    <Modal.Footer className="footer">
                    <Button variant="secondary" className="confirmation" onClick={handleCloseConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="secondary" className="confirmation-left" onClick={handleProceed}>
                        Proceed
                    </Button>
                    </Modal.Footer>
                </Modal>
           
            <Modal keyboard={false} backdrop="static" show ={meterSuccessPopUp} onHide={()=>{
                    setMeterSuccessPopUp(false)
                    }} centered className="popper-modal">
                        <Modal.Body className="text-center">
                            <img src= "./images/icons/popper.svg" alt="Party Popper" />
                            <h5>Success!</h5>
                                <p className="mb-3">{modalMeterType==='electricity'?'An':'A'} <b>{modalMeterType}</b> meter with number <b>{modelMeterNumber}</b> has been added.</p>
                        </Modal.Body>
                        <Modal.Footer className="footer d-flex justify-content-center">
                        <Button variant ="secondary" className="secondary" onClick = {()=>{
                            setMeterSuccessPopUp(false)
                            // window.location.reload()
                            setShowAddNewMeterPopUp(false)
                            setLengthError(false)
                            setMeterNumberError(false)
                            setUniqueMeterNumberError(false)
                            setModalMeterType('electricity')
                            setModalMeterNumber('')
                            setIntialDGReading('')
                            setIntialEBReading('')
                            setIntialReading('')
                            handleApply()
                        }} >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                {loading && <Loader/>}
                {contractApiLoader && <Loader/>}
                 <Modal keyboard={false} backdrop="static" show={meterErroePopUp} onHide={() => {
                    setMeterErrorPopUp(false)
                }} centered className="popper-modal">
                    <Modal.Body className="text-center">
                        <MdErrorOutline style={{ fontWeight: "10px", fontSize: "60px", color: "#Cc0202", opacity: 0.8 }} />
                        <h5>Error!</h5>
                        <p className="mb-3">{addMeterError?.response?.data.message}</p>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center" >
                        <Button variant="secondary" className="secondary" onClick={() => {
                           setMeterErrorPopUp(false)
                        }} >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
        </div>
        <Footer/>
        </>
    );
};

export default MeterMaster;
