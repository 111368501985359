import { Button, Table } from "react-bootstrap";
import React from "react";
import Modal from 'react-bootstrap/Modal';
import { FiAlertCircle } from "react-icons/fi";

// have to give the props as alertShow={true/false}, 
// alertType={error/success}, 
// alertMessage={Error,Success},
// alertValue={value},
// alertButtons={[{name:"Button Name", action:()=>{}} ,{name:"Button Name", action:()=>{}} ]}

const AlertPopUp = (props) => {
    const {alertShow, alertType, alertMsg, alertValue, alertButtons} = props;

    return (
        <Modal 
            backdrop="static" 
            keyboard={false}  // Prevents modal from closing on "Esc" key press
            show={alertShow} 
            centered 
            className="popper-modal"
        >
            <Modal.Body className="text-center mt-4">
                {alertType !== 'question' &&
                    <>
                        {alertType === 'success' ? (
                            <img src="./images/icons/popper.svg" alt="Success" />
                        ) : (
                            <FiAlertCircle className="alert-error-icon" />
                        )}
                        <h5>{alertMsg}!</h5>
                        <p className="mb-1">{alertValue}</p>
                    </>
                }
                {alertType === 'question' &&
                    <>
                        <h5>{alertValue}</h5>
                    </>
                }
            </Modal.Body>
            <Modal.Footer className="footer d-flex justify-content-center">
                {alertButtons?.map((button, index) => (
                    <Button 
                        key={index}  
                        style={{width: `${(100 / alertButtons?.length) - 5}%`}} 
                        variant={button.variant ? button.variant : 'secondary'} 
                        className={`${button.class ? button.class : 'secondary'} ${button.variant === 'outline-secondary' ? 'no-hover-outline' : ''}`} 
                        onClick={button.action}
                    >
                        {button.name}
                    </Button>
                ))}
            </Modal.Footer>
        </Modal>
    );
}

export default AlertPopUp;
