import React, { useEffect } from 'react'

export default function Radio({ object, handleFormData, formUse, data }) {

    useEffect(() => {
        formUse && handleFormData && handleFormData(object.content, "")
    }, [])

    return (
        <div className='d-flex flex-column gap-2' style={{ padding: "8px" }}>
            <label id={object.content}>{object.content}</label>
            {object.options.map((option) => {
                return <div className='d-flex gap-3 align-items-center'>
                    <input type='radio' id={option.label} name={object.content}
                        checked={data && data[object.content] === option.label}
                        value={option.label}
                        disabled={data && data}
                        onChange={(e) => handleFormData && handleFormData(object.content, e.target.value)} /><label htmlFor={option.label}>{option.label}</label>
                </div>
            })}
        </div>
    )
}
