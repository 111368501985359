import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import NavBarTop from '../Reusable_Components/NavBarTop'
import LeftNavBar from '../Reusable_Components/LeftNavBar'
import Footer from '../Login/Footer'

export default function TechnicianDashboard() {

    const [timeOfDay, setTimeOfDay] = useState('');

    useEffect(() => {
        const currentTime = new Date().getHours();
        if (currentTime < 12) {
            setTimeOfDay('Good morning');
        } else if (currentTime >= 12 && currentTime < 18) {
            setTimeOfDay('Good afternoon');
        } else {
            setTimeOfDay('Good evening');
        }
    }, []);

    return (
        <>
            <>
                <NavBarTop />
                <LeftNavBar />
            </>

            <div className='technician-dashboard'>
                <h1 className='technician-header'>Welcome Technician</h1>
                <h5 className='mb-3'>{timeOfDay}</h5>
                <div className='technician-notification-container'>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex align-items-center gap-2'>
                            <div className='water-notification-icon'>
                                <img src='./images/icons/waterDrops.svg' />
                            </div>
                            <span className='fs-6'>Notification</span>
                        </div>
                        <img src='./images/icons/Close.svg' height={15} width={15} alt='close' />
                    </div>
                    <div className='notification-content'>We are writing to formally notify you that the work permission
                        request for ACLC 1, Unit 11 has been duly reviewed and accepted.
                        The necessary approvals have been granted, allowing the specified
                        work to proceed in the designated space.</div>
                </div>
                <div className='technician-dashboard-cards'>
                    <Link to={'/technicianChecklist'} className='dashboard-card'>
                        <img src='./images/icons/Checklist.svg' alt='checklist' />
                        <span>Checklists (Work In Progress)</span>
                    </Link>
                    <Link to={'/electricityMeterReadings'} className='dashboard-card'>
                        <img src='./images/icons/Electricity.svg' alt='electricity' />
                        <span>Electricity Readings</span>
                    </Link>
                    <Link to={'/waterMeterReadings'} className='dashboard-card'>
                        <img src='./images/icons/Water.svg' alt='water' />
                        <span>Water Readings</span>
                    </Link>
                </div>
            </div>
            <Footer />
        </>
    )
}
