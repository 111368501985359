import React, { useEffect } from 'react'

export default function Toggle({ object, handleFormData, formUse, data }) {

    useEffect(() => {
        formUse && handleFormData && handleFormData(object.content, false)
    }, [])

    return (
        <div className='d-flex gap-3 align-items-center justify-content-between' style={{ padding: "8px" }}>
            <label>{object.content}</label>
            <div className="form-switch d-flex">
                <input className="form-check-input" name={object.content}
                    checked={data && data[object.content]}
                    disabled={data && data}
                    style={{ width: "40px", height: "20px" }} type="checkbox" id="paymentTerms"
                    onChange={(e) => handleFormData && handleFormData(object.content, e.target.checked)} />
            </div>
        </div>
    )
}
