import React from 'react'
import { useDrag } from 'react-dnd';
import { MdDragIndicator } from "react-icons/md";

export default function Panels({ element }) {

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'FORM_ELEMENT',
    item: element,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const opacity = isDragging ? 0.4 : 1;
  return (
    <div ref={drag} style={{ opacity }} className='form-elements'>
      <MdDragIndicator /> {JSON.parse(element.json_data).label}
    </div>
  )
}
