import React from 'react'
import { SET_PAGINATION_COUNT_DECREASE, SET_PAGINATION_COUNT_INCREASE, handlePaginationLoaderTrue } from '../../Redux/Actions/Actions'
import { useSelector,useDispatch } from 'react-redux'

export default function Pagination({disabled, handledPaginationNextClicked, handledPaginationPrevClicked, isApplyClicked, totalRecords, recordsPerPage, currentPaginationPage}) {

    // const paginationCount = useSelector(state => state.paginationCount)
    // const nextPage = useSelector(state => state.nextPage)


    const dispatch = useDispatch()

    // const [currentPage, setCurrentPage] = React.useState(1);


    return (
        <div className='d-flex align-items-center justify-content-end mt-3 p-3'  style={{ height: "5vh"}}>
            <div className='d-flex align-items-center justify-content-end' >
                <span style={{marginRight: "20px"}}>Showing {(currentPaginationPage-1)*recordsPerPage +1} - {currentPaginationPage*recordsPerPage < totalRecords? currentPaginationPage*recordsPerPage : totalRecords} of {totalRecords}</span>
                {<button className="btn btn-secondary" onClick={() => 
                // dispatch({
                //     type: SET_PAGINATION_COUNT_DECREASE,
                //     payload: 1
                // })
                {
                    handlePaginationLoaderTrue()
                    handledPaginationPrevClicked()
                }
            }
                style={{borderRadius:"4px 0px 0px 4px"}}
                disabled={currentPaginationPage <= 1}
                >Prev</button>}
                <span 
                className='btn'
                style={{width:"50px", display:"flex", justifyContent:"center",
                alignItems:"center",borderRadius:"0px", height:"40px", marginTop:"2px", marginBottom:"2px"}}>
                    {currentPaginationPage} 
                    </span>
                <button className="btn btn-secondary" disabled={disabled || recordsPerPage*currentPaginationPage >= totalRecords} onClick={() => 
                // dispatch({
                //     type: SET_PAGINATION_COUNT_INCREASE,
                //     payload: 1
                // })
                {
                handlePaginationLoaderTrue()
                handledPaginationNextClicked()
                }
            }
                style={{borderRadius:"0px 4px 4px 0px"}}
                >Next</button>
            </div>
            
        </div>
    )
}
