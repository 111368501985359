import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Element from './Element';
import { FaRegClone } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { MdDelete, MdErrorOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { FETCHING_ERROR, getAllTemplates } from '../../Redux/Actions/Actions';
import FormLoader from '../Reusable_Components/FromLoader';
import { Button, Modal } from 'react-bootstrap';
import { sorting_lists, timeElapsed } from '../Reusable_Components/ReusableFunctions';
import AlertPopUp from '../Reusable_Components/Alert';

export default function TemplatesList() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const templatesList = useSelector((state) => state.templatesList)
    const loading = useSelector((state) => state.loading)
    const fetchingError = useSelector((state) => state.fetchingError)

    const [templates, setTemplates] = useState([])
    const [preview, setPreview] = useState(false);
    const [previewObject, setPreviewObject] = useState([])
    const [currentTime, setCurrentTime] = useState(new Date())
    const [footerValues, setFooterValues] = useState({})

    useEffect(() => {
        // let templatesList = JSON.parse(localStorage.getItem("Templates"))
        // templatesList = templatesList?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
        // setTemplates(templatesList ? templatesList : [])
        dispatch(getAllTemplates())
    }, [])

    useMemo(() => {
        return sorting_lists(templatesList)
    }, [templatesList]);

    // const handleDeleteElement = (id) => {
    //     const templates = JSON.parse(localStorage.getItem("Templates"))
    //     const filteredFields = templates.filter((template) => template.id !== id)
    //     setTemplates(filteredFields)
    //     localStorage.setItem("Templates", JSON.stringify(filteredFields))
    //     // setShowModal(false)
    // }

    return (
        <div>
            {preview ? <div className='d-flex flex-column p-3' style={{ width: "210mm", backgroundColor: "white" }}>
                {previewObject?.map((previewObj, index) => {
                    return <div key={previewObj.id} id={previewObj.id + " " + index} className='d-flex'>
                        {previewObj.items.map((item, index) => {
                            return <Element preview={true} key={index} object={item} />
                        })}
                    </div>
                })}
                {/* <div className='d-flex justify-content-between flex-wrap mt-3 p-2' style={{ fontSize: "10px", borderTop: "1px solid gray" }}>
                    <span><b>Created at :</b> {footerValues?.createdAt?.split("T")[0] + "  " + new Date(footerValues?.createdAt).toLocaleTimeString()}</span>
                    <span><b>Last Updated at :</b> {footerValues?.updatedAt?.split("T")[0] + "  " + new Date(footerValues?.createdAt).toLocaleTimeString()}</span>
                    <span><b>Created by :</b> {footerValues?.createdBy}</span>
                    <span><b>Last updated by :</b> {footerValues?.updatedBy}</span>
                </div> */}
                <div className='d-flex justify-content-end w-100'>
                    <button className='btn btn-secondary' onClick={() => {
                        setPreview(false)
                        setPreviewObject([])
                    }}>Back</button>
                </div>
            </div> : <>
                <div className='d-flex justify-content-between'>
                    <h3>Templates</h3>
                    <Link to={'/form/templates'}><button className='btn btn-secondary'>Create new template</button></Link>
                </div>
                <table style={{ width: "100%" }} className='mt-4'>
                    <thead>
                        <tr className='fin_man_table_head text-center'>
                            <th>Template Name</th>
                            <th>Description</th>
                            <th>Created by</th>
                            <th>Created</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody className='fin_man_table_body'>
                        {templatesList && templatesList?.length > 0 ? templatesList.map((template) => {
                            return <tr key={template.template_id}>
                                <td>{template?.template_name}</td>
                                <td>{template?.description}</td>
                                <td>{template?.created_by}</td>
                                <td>{timeElapsed(template?.created_at)}</td>
                                <td className='text-center' role='button'>
                                    <img src="./images/icons/View.png" alt="search-icon" title='view' onClick={() => {
                                        setPreview(true)
                                        setPreviewObject(JSON.parse(template?.json_data))
                                        // setFooterValues({
                                        //     createdAt: template?.createdAt,
                                        //     updatedAt: template?.createdAt,
                                        //     createdBy: "Dinesh",
                                        //     updatedBy: "Dinesh"
                                        // })
                                    }} />
                                    <FaRegClone style={{ marginLeft: "5px" }} title='clone' onClick={() => {
                                        let json_data = JSON.parse(template?.json_data)
                                        navigate('/form/templateClone', { state: { data: json_data } })
                                    }
                                    } />
                                    {/* <MdDelete onClick={() => handleDeleteElement(template.id)}/> */}
                                </td>
                            </tr>
                        }) : <tr>No templates found</tr>}
                    </tbody>
                </table>
            </>}
            {fetchingError && <AlertPopUp
                alertShow={fetchingError}
                alertType="error"
                alertMsg="Error"
                alertValue={fetchingError}
                alertButtons={[{ name: "Close", action: () => { dispatch({ type: FETCHING_ERROR, payload: null }) } }, {
                    name: "Retry", action: () => {
                        dispatch(getAllTemplates())
                        dispatch({
                            type: FETCHING_ERROR,
                            payload: null
                        })
                    }
                }]}
            />}
            {loading && <FormLoader />}
        </div>
    )
}
