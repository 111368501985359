import React, { useEffect, useState } from 'react'
import { GoSearch } from "react-icons/go";
import { MdCurrencyRupee } from "react-icons/md";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Fade, Table}  from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


export default function FinanceManagerUsers() {
    const invoiceTableDummyData = {
        bill_from:"Indoport",
        bill_to : "Amazon",
        invoiceNumber : "#72334",
        issuedOn : "2024-02-06",
        dueDate : "2024-04-06",
        gstIN : "7812372",
        cinNo : "27263",
        data : {
            "space" : {
                servive : "Space License Fee 162121121",
                sac : "996761",
                bill_from : "28.02.2023",
                bill_to : "28.03.2023",
                rpu : 53.43,
                area: "25,430.00",
                basic_amount : "39323.00",
                cgst_rate : 9.00,
                cgst_amount : "3587.00",
                sgst_rate : 9.00,
                sgst_amount : "3587.00",
                gross : "46991.13"
            },   "Energy" : {
                servive : "Energy meter",
                sac : "996761",
                bill_from : "28.02.2023",
                bill_to : "28.03.2023",
                rpu : 53.43,
                area: "34,430.00",
                basic_amount : "49323.00",
                cgst_rate : 9.00,
                cgst_amount : "3587.00",
                sgst_rate : 9.00,
                sgst_amount : "3587.00",
                gross : "46991.12"
            },  "Water" : {
                servive : "Water Meter",
                sac : "996761",
                bill_from : "28.02.2023",
                bill_to : "28.03.2023",
                rpu : 53.43,
                area: "25,430.00",
                basic_amount : "39323.00",
                cgst_rate : 9.00,
                cgst_amount : "3587.00",
                sgst_rate : 9.00,
                sgst_amount : "3587.00",
                gross : "46991.12"
            }
        }
}

    const data = [{
        id: 1,
        user : " ABC Electronics",
        spaceName: "Unit 11",
        contractNo: "#0200021",
        email: "abc@gmail.com",
        mobileNumber: "9898767876",
    },
    {
        id: 1,
        user : " ABC Electronics",
        spaceName: "Unit 11",
        contractNo: "#0200021",
        email: "abc@gmail.com",
        mobileNumber: "9898767876",
    },
    {
        id: 1,
        user : " ABC Electronics",
        spaceName: "Unit 11",
        contractNo: "#0200021",
        email: "abc@gmail.com",
        mobileNumber: "9898767876",
    },
    {
        id: 1,
        user : " ABC Electronics",
        spaceName: "Unit 11",
        contractNo: "#0200021",
        email: "abc@gmail.com",
        mobileNumber: "9898767876",
    },
    {
        id: 1,
        user : " ABC Electronics",
        spaceName: "Unit 11",
        contractNo: "#0200021",
        email: "abc@gmail.com",
        mobileNumber: "9898767876",
    },
    {
        id: 1,
        user : " ABC Electronics",
        spaceName: "Unit 11",
        contractNo: "#0200021",
        email: "abc@gmail.com",
        mobileNumber: "9898767876",
    },
    {
        id: 1,
        user : " ABC Electronics",
        spaceName: "Unit 11",
        contractNo: "#0200021",
        email: "abc@gmail.com",
        mobileNumber: "9898767876",
    },
    {
        id: 1,
        user : " ABC Electronics",
        spaceName: "Unit 11",
        contractNo: "#0200021",
        email: "abc@gmail.com",
        mobileNumber: "9898767876",
    },
    {
        id: 1,
        user : " ABC Electronics",
        spaceName: "Unit 11",
        contractNo: "#0200021",
        email: "abc@gmail.com",
        mobileNumber: "9898767876",
    },
    {
        id: 1,
        user : " ABC Electronics",
        spaceName: "Unit 11",
        contractNo: "#0200021",
        email: "abc@gmail.com",
        mobileNumber: "9898767876",
    },
    {
        id: 1,
        user : " ABC Electronics",
        spaceName: "Unit 11",
        contractNo: "#0200021",
        email: "abc@gmail.com",
        mobileNumber: "9898767876",
    },
    {
        id: 1,
        user : " ABC Electronics",
        spaceName: "Unit 11",
        contractNo: "#0200021",
        email: "abc@gmail.com",
        mobileNumber: "9898767876",
    },
    {
        id: 1,
        user : " ABC Electronics",
        spaceName: "Unit 11",
        contractNo: "#0200021",
        email: "abc@gmail.com",
        mobileNumber: "9898767876",
    },
    {
        id: 1,
        user : " ABC Electronics",
        spaceName: "Unit 11",
        contractNo: "#0200021",
        email: "abc@gmail.com",
        mobileNumber: "9898767876",
    },
    {
        id: 1,
        user : " ABC Electronics",
        spaceName: "Unit 11",
        contractNo: "#0200021",
        email: "abc@gmail.com",
        mobileNumber: "9898767876",
    },
    {
        id: 1,
        user : " ABC Electronics",
        spaceName: "Unit 11",
        contractNo: "#0200021",
        email: "abc@gmail.com",
        mobileNumber: "9898767876",
    },
    {
        id: 1,
        user : " ABC Electronics",
        spaceName: "Unit 11",
        contractNo: "#0200021",
        email: "abc@gmail.com",
        mobileNumber: "9898767876",
    },
    {
        id: 1,
        user : " ABC Electronics",
        spaceName: "Unit 11",
        contractNo: "#0200021",
        email: "abc@gmail.com",
        mobileNumber: "9898767876",
    },
    {
        id: 1,
        user : " ABC Electronics",
        spaceName: "Unit 11",
        contractNo: "#0200021",
        email: "abc@gmail.com",
        mobileNumber: "9898767876",
    },
    {
        id: 1,
        user : " ABC Electronics",
        spaceName: "Unit 11",
        contractNo: "#0200021",
        email: "abc@gmail.com",
        mobileNumber: "9898767876",
    }
    ]

    const [meterReports, setMeterReports] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [modalData,setModalData] = useState({})
    

    const fetchReports = () => {
        setMeterReports(data)
    }

    useEffect(() => {
        fetchReports()
    }, [])

    return (
        <>
            <div className='meter_reports_container'>
                <div className='d-flex justify-content-between align-items-center flex-wrap'>
                    <div className='d-flex align-items-center gap-3 mb-3'>
                        <h1>Users</h1>
                    </div>
                    <div className='d-flex align-items-center gap-3 mb-3'>
                        <div className='fin_man_meter_search_bar_container'>
                            <input type='search' placeholder='Search' className='fin_man_meter_search_bar' />
                            <GoSearch className='fin_man_meter_search_bar_icon'/>
                        </div>
                    </div>
                </div>
                
                <div className='table_container'>
                    <table>
                        <thead>
                            <tr className='fin_man_table_head'>
                                <th>Users</th>
                                <th>Space Name</th>
                                <th>Contract No.</th>
                                <th>Email</th>
                                <th>Mobile Number</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody className='fin_man_table_body'>
                            {meterReports?.map((each) => {
                                return <tr key={each?.id} className='table_row'>
                                    <td className='text-left'>{each?.user}</td>
                                    <td>{each?.spaceName}</td>
                                    <td>{each.contractNo}</td>
                                    <td>{each?.email}</td>
                                    <td className='text-end'>{each?.mobileNumber}</td>
                                    <td className='text-decoration-underline text-center' role='button'  onClick={() => {
                                        setShowModal(true);
                                        setModalData(each)
                                    }}><img src="./images/icons/View.png" alt="search-icon" /></td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                <Modal keyboard={false} backdrop="static" style={{ padding: "20px" }} show={showModal} size='lg' centered onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            View Details
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='modal_popup' style={{ margin: "10px" }}>
                        <h2>{modalData.user}</h2>
                        <Tabs
                            defaultActiveKey="userDetails"
                            transition={Fade}
                            id="noanim-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="userDetails" title={<span className="tab-title">User Details</span>} style={{ marginLeft: "20px" }}>
                                <div className='detailsList'>
                                    <p className='detailsItem'>Company Name: </p>
                                    <p className='detailsItem'>ABC Electronics </p>
                                </div>
                                <div className='detailsList'>
                                    <p className='detailsItem'>PAN Number </p>
                                    <p className='detailsItem'>COZPD6545K </p>
                                </div>
                                <div className='detailsList'>
                                    <p className='detailsItem'>GST Number </p>
                                    <p className='detailsItem'>224356738767GH </p>
                                </div>
                                <div className='detailsList'>
                                    <p className='detailsItem'>CIN </p>
                                    <p className='detailsItem'>224356738767GH</p>
                                </div>
                                <div className='detailsList'>
                                    <p className='detailsItem'>Billing Address </p>
                                    <p className='detailsItem'>Madhapur, Hyderabad, Telangana, 500024, India </p>
                                </div>
                            </Tab>
                            <Tab eventKey="contactDetails" title={<span className="tab-title">Contact Details</span>} style={{ marginLeft: "20px" }}>
                            <div className='detailsList'>
                                    <p className='detailsItem'>Company Name:</p>
                                    <p className='detailsItem'>ABC Electronics </p>
                                </div>
                                <div className='detailsList'>
                                    <p className='detailsItem'>Department:</p>
                                    <p className='detailsItem'>Finance </p>
                                </div>
                                <div className='detailsList'>
                                    <p className='detailsItem'>Contact Name:</p>
                                    <p className='detailsItem'>Vishal Kumar </p>
                                </div>
                                <div className='detailsList'>
                                    <p className='detailsItem'>Phone Number:</p>
                                    <p className='detailsItem'>9123412312</p>
                                </div>
                                <div className='detailsList'>
                                    <p className='detailsItem'>Email:</p>
                                    <p className='detailsItem'>Amazonfin@gmail.com</p>
                                </div>
                            </Tab>
                            <Tab eventKey="spaceDetails" title={<span className="tab-title">Space Details</span>} style={{ marginLeft: "20px" }}>
                                Space Details
                            </Tab>
                            <Tab eventKey="contractDetails" title={<span className="tab-title">Contract Details</span>} style={{ marginLeft: "20px" }}>
                                Contract Details
                            </Tab>
                        </Tabs>
                    </Modal.Body>
                </Modal>


            </div>
        </>
    )
}
