import { set } from 'date-fns';
import React, { useState, useEffect,  } from 'react';
import { Modal } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import {CREATE_SCHEDULE_EVENT_SUCCESS, getAssetCategories,getAssetsByCategory, scheduleNewEvent, SET_ERROR_FALSE } from "../../Redux/Actions/Actions";
import { useSelector } from 'react-redux';
import SearchableDropdown from './searchableDropdown';
import { useDispatch } from "react-redux";
import Loader from '../Reusable_Components/loader';
import AlertPopUp from "../Reusable_Components/Alert";

const ScheduleEvent = ({ show, onHide, scheduleType, daysOfTheWeek }) => {
    const dispatch = useDispatch();
    const contractApiLoader = useSelector(state => state.contractApiLoader);
    const createNewEvent = useSelector(state => state.createNewEvent);
    const error = useSelector(state => state.error);
    const today = new Date().toISOString().split('T')[0];

    const [selectedfacility, setSelectedFacility] = useState('');
    const [selectedScheduleType, setSelectedScheduleType] = useState(scheduleType[0]);
    const [selectedScheduleDay, setSelectedScheduleDay] = useState(daysOfTheWeek[1]);
    const [selectedEvent, setSelectedEvent] = useState(''); 
    const [selectedAssetCategory, setSelectedAssetCategory] = useState('');
    const [selectedAsset, setSelectedAsset] = useState('');
    const [selectedAssignee, setSelectedAssignee] = useState('');
    const [selectedSupervisor, setSelectedSupervisor] = useState('');
    const [selectedSop, setSelectedSop] = useState('');
    const [selectedChecklist, setSelectedChecklist] = useState('');
    const [eventDescription, setEventDescription] = useState('');
  
    const [filteredAssets, setFilteredAssets] = useState([]);
    const [isSingleAsset, setIsSingleAsset] = useState(true); //settinng single asst as defalt
    const [allAssets, setAllAssets] = useState(false);

    const [oneTimeEvent, setOneTimeEvent] = useState(true); //setting one time event as default
    const [recurringEvent, setRecurringEvent] = useState(false); //setting recurring event as default

    const assetCategory = useSelector(state => state.assetCategory);
    const eventCategory = useSelector(state => state.eventCategory); //storing all event categories from api
    const assetByCategory = useSelector(state => state.assetByCategory);
    
    const usersList = useSelector(state => state.usersList);// for assignee.. original list 
    const facmanList = useSelector(state => state.facmanList);

    const [selectedCatergoryId, setSelectedCategoryId] = useState(null);
    const [eventCategoryList, setEventCategoryList] = useState([]);

    const [calendarDay, setCalendarDay] = useState(false);
    const [selectedDay, setSelectedDay] = useState('');
    
    const [weekDay, setweekDay] = useState(false);

    const [setDate, setSetDate] = useState(false);
    const [recurringBeginDate, setRecurringBeginDate] = useState('');
    const [recurringEndDate, setRecurringEndDate] = useState('');
    const [selectedWeekOption, setSelectedWeekOption] = useState('');

    const [repeatUntilCancelled, setRepeatUntilCancelled] = useState(true);

    const [oneTimeEventDate, setOneTimeEventDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0]);
    
    const [alertShow, setAlertShow] = useState(false)
    const [alertType, setAlertType] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertValue, setAlertValue] = useState('')
    const [alertButtons, setAlertButtons] = useState([])
    
    const handleClose = () => {
        setSelectedFacility('');
        setEventDescription('');
        setSelectedEvent('');
        setSelectedAssetCategory('');
        setSelectedAsset('');
        setSelectedAssignee('');
        setSelectedSupervisor('');
        setIsSingleAsset(true);
        setAllAssets(false);
        // setSelectedSop('');
        // setSelectedChecklist('');
        setOneTimeEvent(true);
        setRecurringEvent(false);
        setOneTimeEventDate(new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0]);
        setCalendarDay(false);
        setSelectedDay('');
        setweekDay(false);
        setRecurringBeginDate('');
        setRecurringEndDate('');

        setAlertShow(false);
        setAlertType('');
        setAlertMessage('');
        setAlertValue('');
        setAlertButtons([]);
        onHide();
    };
    
    useEffect(() => {
        // setErrorValue(error?.response?.data.message);
        if (error?.response?.data.message) {
            setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error')
            setAlertValue(error?.response?.data.message)
            setAlertButtons([{
                name: 'Close', action: () => {
                    dispatch({
                        type: SET_ERROR_FALSE,
                        payload: null
                    })
                    setAlertShow(false)
                }
            }])
        }
        // setIsLoading(false);
    }, [error])

    useEffect(() => {

        if(createNewEvent) {
            onHide();//
            // handleBack(); //
            setAlertShow(true)
            setAlertType('success')
            setAlertMessage('Success')
            setAlertValue('Event Scheduled Successfully')
            setAlertButtons([{
                name: 'Close', action: () => {
                    dispatch({
                        type: CREATE_SCHEDULE_EVENT_SUCCESS,
                        payload: null
                    })
                    setAlertShow(false)
                }
            }])
        }
    }, [createNewEvent])
    
    const facilitiesListForNav = useSelector(state => state.facilitiesListForNav);
    const [facilityList, setFacilityList] = useState([]);
    const [selectedFacilityId, setSelectedFacilityId] = useState(null);
    useEffect(() => {
        if (facilitiesListForNav) {
            const options = facilitiesListForNav.map((facility) => `${facility.name}, ${facility.city}`);
            setFacilityList(options);
            console.log("facilityList", options);   
        }
    }, [facilitiesListForNav]);

    useEffect(() => {
        if (eventCategory) {
            const categoryList = eventCategory.map((category) => 
            category.category_name); 
            setEventCategoryList(categoryList);
            console.log("eventCategoryList", categoryList);
        }
    }, [eventCategory]);
        
    const [supervisorList, setSupervisorList] = useState([]);
    useEffect(() => {
        if (facmanList) {
            const dataList = facmanList.map((facman) =>
            facman.username);
            setSupervisorList(dataList);
        }
    }, [facmanList]);

    const [assigneeList, setAssigneeList] = useState([]);
    useEffect(() => {
        if (usersList) {
            const newList = usersList.map((user) =>
            user.username);
            setAssigneeList(newList);
        }
    }, [usersList]);
    
    const [assetsList, setAssetsList] = useState([]);
    const [assetId, setAssetId] = useState(null);
    useEffect(()    => {
        if (assetByCategory) {
            const assetList = assetByCategory.map((asset) =>
            asset.asset_name);
            setAssetsList(assetList);
    }
    }, [assetByCategory]);

    // const assigneeOptions = usersList ? usersList.map(user =>  user.username) : [];
    // const supervisor = facmanList ? facmanList.map(facman => facman.username) : [];
    
    const newSopList = {
        "AC-1" : {
            "sop": "SOP-AC-1",
            "checklist": "Checklist-AC-1"
        },
    }
    

    const handleToggleChange = () => {
        setIsSingleAsset(!isSingleAsset);
        setAllAssets(!allAssets);
    };

    const OccurenceToggle = () => {
        setOneTimeEvent(!oneTimeEvent);
        setRecurringEvent(!recurringEvent);
    };
    
    const calendarDayToggle = () => {
        setCalendarDay(!calendarDay);
    };
    const weekDayToggle = () => {
        setweekDay(!weekDay);
    };



    const handleCreate = () => {
        const data = {
            event_name: selectedEvent,
            event_entity_category: `${selectedAssetCategory},${assetId}`,
            // event_entity_category: selectedAssetCategory,

            description: eventDescription,
            status: "active",
            reminder_type: "email",
            reminder_before_hrs:  "48",  
            assignee: selectedAssignee,
            supervisor: selectedSupervisor,
            facility_name: selectedfacility,
            facility_id: selectedFacilityId,

            "recurrence": {
            "is_one_time": oneTimeEvent,
            "begin_date" : oneTimeEventDate,// to b changed for recurring event
            "end_date" : oneTimeEventDate,// to b changed for recurring event
            "no_of_occurrences":1,
            } ,

            "category": {
                event_category_id : selectedCatergoryId,
            }
        }
        dispatch(scheduleNewEvent(data));
    }



    return (
        <>
        <AlertPopUp alertShow={alertShow} alertType={alertType} alertMsg={alertMessage} alertValue={alertValue} alertButtons={alertButtons} />
            {contractApiLoader && <Loader />}
            <Modal
                keyboard={false}
                backdrop="static"
                show={show}
                className="schedule-mail-popup-modal"
                style={{
                    width: '565px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    display: 'block'
                }}
                centered
                onHide={handleClose}
            >
                <div className="schedule-mail-popup">
                    <div className="header d-flex justify-content-between">
                        <div>
                            <h1>Schedule Event</h1>
                        </div>
                        <IoMdClose
                            className="schedule-mail-pop-close"onClick={handleClose}
                        />
                    </div>
                    <div className="schedule-mail-body">
                        <div className="schedule-mail-details-con">
                            <div>
                                <SearchableDropdown
                                    className = "searchable-dropdown"
                                    options = {facilityList? facilityList:[]} //facilityList is the array of facilities
                                    selectedOption = {selectedfacility}
                                    noOptionsAvailable = "Facility unavailable"
                                    onChange = {(e) => {
                                        setSelectedFacility(e.value)
                                        const selectedFacility = facilitiesListForNav.find((facility) => `${facility.name}, ${facility.city}` === e.value); 
                                        setSelectedFacilityId(selectedFacility.facility_id);
                                        console.log("selectedFacility", selectedFacility, selectedFacility.facility_id);
                                    }}
                                    name = "Facility"
                                    placeholder = "Select Facility"
                                    isDisabled = {false}
                                />
                            </div>
                            <div>
                                <SearchableDropdown
                                        className = "searchable-dropdown"
                                        options = {eventCategoryList? eventCategoryList:[]} 
                                        selectedOption = {selectedEvent}
                                        noOptionsAvailable = "Category unavailable"
                                        onChange = {(e) => {
                                            setSelectedEvent(e.value)
                                            const selectedCatergory = eventCategory.find((category) => category.category_name === e.value);  
                                            setSelectedCategoryId(selectedCatergory.event_category_id);
                                            console.log("selectedCatergory", selectedCatergory, selectedCatergory.event_category_id);
                                        }}
                                        name = "Event"
                                        placeholder = "Select Event"
                                        isDisabled = {false}
                                />
                            </div>
                            <div className="mt-3" style={{ display: 'flex', justifyContent:'space-between' }}>
                                    <div>
                                        <label className="switch">
                                            <input type="checkbox" checked={isSingleAsset} onClick={()=>{handleToggleChange()}} />
                                            <span className="slider round"></span>
                                        </label>
                                        <span className='mx-2'>Single Asset</span>
                                    </div>
                                
                                    <div>
                                        <label className="switch">
                                            <input type="checkbox" checked={allAssets} onClick={()=>{handleToggleChange()}}/>
                                            <span className="slider round"></span>
                                        </label>
                                        <span className='mx-2'>All Assets</span>
                                    </div>                              
                            </div>

                            <div className="mt-3">
                                    <SearchableDropdown
                                        className = "searchable-dropdown"
                                        options = {assetCategory? assetCategory:[]} //assetCategory is the array of asset categories
                                        selectedOption = {selectedAssetCategory}
                                        noOptionsAvailable = "Category unavailable"
                                        onChange = {(e) => {
                                            setSelectedAssetCategory(e.value)  //note here we have to give e.value instead of e.target.value
                                            dispatch(getAssetsByCategory(e.value))
                                        }}
                                        name = "Asset Category"
                                        placeholder = "Select Asset Category"
                                        isDisabled = {false}
                                />
                            </div>
                            
                        
                            {isSingleAsset && 
                                <div className='mt-3'>
                                    <SearchableDropdown
                                        className = "searchable-dropdown"
                                        options = {assetsList} 
                                        selectedOption = {selectedAsset}
                                        noOptionsAvailable = "Asset unavailable"
                                        onChange = {(e) => {
                                            const asset =  assetByCategory.find((asset) => asset.asset_name === e.value);
                                            setSelectedAsset(e.value);
                                            setAssetId(asset.asset_id);  
                                            // setSelectedSop(newSopList[e.value].sop);
                                            // setSelectedChecklist(newSopList[e.value].checklist);
                                        }}
                                        name = "Asset"
                                        placeholder = "Select Asset"
                                        isDisabled = {!selectedAssetCategory}
                                    />
                                </div>
                            }                     
                            <div className="mt-3">
                                <p>Description</p>
                                <textarea
                                    id="textarea-input"
                                    placeholder="Enter text..."
                                    style={{
                                        width: '100%',padding: '8px',borderRadius: '5px', border: '1px solid #D9D9D9'
                                    }}
                                    value={eventDescription}
                                    maxLength={200}
                                    onChange={(e) => setEventDescription(e.target.value)}
                                ></textarea>
                                 <p style={{ fontSize: '12px', color: '#888' }}>
                                    {eventDescription.length}/200 characters
                                </p>
                            </div>
                    
                            <div className="mt-3">
                                <SearchableDropdown
                                    className = "searchable-dropdown"
                                    options = {assigneeList} 
                                    selectedOption = {selectedAssignee}
                                    noOptionsAvailable = "Assignee unavailable"
                                    onChange = {(e) => {
                                        console.log("Assignee selected:", e.value);
                                        setSelectedAssignee(e.value)
                                    }}
                                    name = "Assignee"
                                    placeholder = "Select Assignee"
                                    isDisabled = {false}
                                />
                            </div>
                            <div className="mt-3">
                                <SearchableDropdown
                                    className = "searchable-dropdown"
                                    options = {supervisorList}
                                    selectedOption = {selectedSupervisor}
                                    noOptionsAvailable = "Supervisor unavailable"
                                    onChange = {(e) => {
                                        setSelectedSupervisor(e.value)
                                    }
                                    }
                                    name = "Supervisor"
                                    placeholder = "Select Supervisor"
                                    isDisabled = {false}
                                />
                            </div>
                            {isSingleAsset && <div className="mt-3">
                                <p>SOP</p>
                                <input
                                    value={selectedSop}
                                    disabled //convert this to searchable dropdown along with auto filled data
                                />
                            </div>}
                            {isSingleAsset && <div className="mt-3">
                                <p>Checklist</p>
                                <input
                                    value={selectedChecklist} //convert this to searchable dropdown along with auto filled data
                                    disabled
                                />
                            </div>}
    {/* ************************************************************************************** */}
                            <div className = "mt-3"> 
                                <div style={{ display: 'flex'}}>
                                    <img src="images/icons/schedule.svg" alt="Schedule Icon" style={{ marginRight: '8px' }} />
                                    <p style = {{marginTop:"10px"}}>Schedule On</p>
                                </div>
                                <div>
                                    <div>
                                            <label className="switch">
                                                <input type="checkbox" checked={oneTimeEvent}  onClick={() => OccurenceToggle(true)}/>
                                                <span className="slider round"></span>
                                            </label>
                                            <span className='mx-2'>One Time Event</span>
                                    </div>
                                    {oneTimeEvent &&
                                        <div className="d-flex justify-content-between schedule-mail-inputs mt-3">
                                            <div>
                                                <p>Date</p>
                                                <input type="date" min={today} value={oneTimeEventDate} onChange={(e) => {
                                                    setOneTimeEventDate(e.target.value)
                                                }} style={{ width: '210px', height:'45px' , marginTop:'-10px'}} />
                                            </div>
                                            <div>
                                                <p>Time</p>
                                                <input type="time" style={{ width: '210px', height:'45px', marginTop:'-10px' }} />
                                            </div>
                                        </div>}
                                </div>
                                {/* recurring event div */}
                                <div>
                                    <div className='mt-3'>
                                            <label className="switch">
                                                <input type="checkbox" checked={recurringEvent}  onClick={() => OccurenceToggle(false)}/>
                                                <span className="slider round"></span>
                                            </label>
                                            <span className='mx-2'>Recurring Event</span>
                                    </div>
                                </div>
                               
                                {recurringEvent && 
                                    <div className = 'recurring'>
                                        <div className='mt-3'>
                                                <label className="switch">
                                                    <input type="checkbox"  checked={calendarDay} onClick={()=>calendarDayToggle()}/>
                                                    <span className="slider round"></span>
                                                </label>
                                                <span className='mx-2'>Calendar Day based</span>
                                        </div>
                                        {calendarDay && (
                                            <div className='d-flex align-items-center ms-4 mt-3'>
                                                <select 
                                                    style={{
                                                        padding: '5px',borderRadius: '4px',
                                                        border: '1px solid #D9D9D9',marginRight: '10px'
                                                    }}
                                                    onChange={(e) => setSelectedDay(e.target.value)}
                                                >
                                                    <option value="Sunday">Sunday</option>
                                                    <option value="Monday">Monday</option>
                                                    <option value="Tuesday">Tuesday</option>
                                                    <option value="Wednesday">Wednesday</option>
                                                    <option value="Thursday">Thursday</option>
                                                    <option value="Friday">Friday</option>
                                                    <option value="Saturday">Saturday</option>
                                                </select>
                                                <span>of every month</span>
                                            </div>
                                        )}
                                        
                                        {/* <div className='mt-3'>  
                                                <label className="switch">
                                                    <input type="checkbox"  checked={weekDay} onClick={()=>weekDayToggle()}/>
                                                    <span className="slider round"></span>
                                                </label>
                                                <span className='mx-2'>Week Day based</span>
                                        </div>
                                        { weekDay &&
                                            <div className="schedule-type-day mt-3 ms-4">
                                                {daysOfTheWeek.map((each, index) => (
                                                    <p
                                                        key={index}
                                                        className={`${selectedScheduleDay === each ? 'selected C-pointer' : 'C-pointer'}`}
                                                        onClick={() => setSelectedScheduleDay(each)}
                                                    >
                                                        {each.slice(0,1)}
                                                    </p>
                                                ))}
                                            </div>} */}
                                            <div className='mt-3'>  
                                                    <label className="switch">
                                                        <input type="checkbox" checked={weekDay} onClick={() => weekDayToggle()} />
                                                        <span className="slider round"></span>
                                                    </label>
                                                {weekDay ? (
                                                    <div className="mt-2">
                                                        <select 
                                                            style={{
                                                                padding: '5px', 
                                                                borderRadius: '4px', 
                                                                border: '1px solid #D9D9D9',
                                                                marginBottom: '10px'
                                                            }}
                                                            onChange={(e) => setSelectedWeekOption(e.target.value)}
                                                        >
                                                            <option value="every-week">Every week</option>
                                                            {daysOfTheWeek.map((each, index) => (
                                                                <option key={index} value={each}>
                                                                    {each}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                ) : (
                                                    <span className='mx-2'>Week Day based</span>  
                                                )}
                                            </div>
                                            {weekDay && (
                                                <div className="schedule-type-day mt-3 ms-4">
                                                    <div className="week-days mt-2">
                                                        {daysOfTheWeek.map((each, index) => (
                                                            <p
                                                                key={index}
                                                                className={`${selectedScheduleDay === each ? 'selected C-pointer' : 'C-pointer'}`}
                                                                onClick={() => setSelectedScheduleDay(each)}
                                                            >
                                                                {each.slice(0, 1)} 
                                                            </p>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}

                                            <div className='mt-3'>
                                                <label className="switch">
                                                    <input type="checkbox" />
                                                    <span className="slider round"></span>
                                                </label>
                                                <span className='mx-2'>Set Date</span>
                                            </div>
                                            <div className="d-flex justify-content-between schedule-mail-inputs mt-2 ms-4">
                                                <div>
                                                    <p>Begin Date</p>
                                                    <input type="date" min={today} value={recurringBeginDate} onChange={(e) => {
                                                        setRecurringBeginDate(e.target.value)}}
                                                     style={{ width: '180px', height:'45px' , marginTop:'-10px'}} />
                                                </div>
                                                <div>
                                                    <p>End Date</p>
                                                    <input type="date" min={today}
                                                     value={recurringEndDate} 
                                                     onChange={(e) => {
                                                        if (new Date(e.target.value) <= new Date(recurringBeginDate)) {
                                                            AlertPopUp("End date must be greater than begin date");
                                                        } else {
                                                            setRecurringEndDate(e.target.value);
                                                        }
                                                    }}
                                                     style={{ width: '180px', height:'45px' , marginTop:'-10px'}} />
                                                </div>
                                            </div>
                                            
                                            <div className='mt-3'>
                                                <label className="switch">
                                                    <input type="checkbox" />
                                                    <span className="slider round"></span>
                                                </label>
                                                <span className='mx-2'>Repeat Until Cancelled</span>
                                            </div>

                                    </div>
                                }
                                
                            
                                <div className="d-flex justify-content-between mt-3">
                                    <div className="d-flex gap-2">
                                        <img className="alarm" src="./images/icons/Alarm.png" alt="upload" />
                                        <p>Set Reminder</p>
                                    </div>
                                    <div>
                                        <label className="switch">
                                            <input type="checkbox" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <button className="schedule-create-button"
                                            onClick={handleCreate}
                                    >Schedule Event</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
             {/* <div className="schedule-type-con mt-3">
                                    {scheduleType.map((each, index) => (
                                        <div key={index}>
                                            <p
                                                className={`${selectedScheduleType === each ? 'selected C-pointer' : 'C-pointer'}`}
                                                onClick={() => setSelectedScheduleType(each)}
                                            >
                                                {each}
                                            </p>
                                        </div>
                                    ))}
                                </div> */}
        </>
    );
};

export default ScheduleEvent;
