import React, { useState, useEffect, useMemo } from 'react'
import NavBarTop from '../Reusable_Components/NavBarTop'
import LeftNavBar from '../Reusable_Components/LeftNavBar'
import { useDispatch, useSelector } from 'react-redux'
import { GET_ALL_PARTNER_COMPANIES_FAILURE, getAllPartnerCompanies } from '../../Redux/Actions/Actions'
import FormLoader from '../Reusable_Components/FromLoader'
import { FaRegEdit } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'
import { MdErrorOutline } from 'react-icons/md'
import { IoAddOutline } from "react-icons/io5";
import AddPartnerCompany from './AddPartnerCompany'
import PartnerCompaniesEdit from './PartnerCompaniesEdit'
import { GoSearch } from 'react-icons/go'
import { timeElapsed, validateValue } from '../Reusable_Components/ReusableFunctions'
import Footer from '../Login/Footer'

export default function PartnerCompanies() {

    const dispatch = useDispatch()

    const partnerCompaniesList = useSelector(state => state.partnerCompaniesList)
    const partnerCompaniesListError = useSelector(state => state.partnerCompaniesListError)
    const loading = useSelector(state => state.loading)
    const searchTerm = useSelector(state => state.searchTerm);
    const [page, setPage] = useState(1)
    const [companyId, setCompanyId] = useState(null)
    const [navigate, setNavigate] = useState(false)
    const [searchText, setSearchText] = useState("")


    useEffect(() => {
        dispatch(getAllPartnerCompanies())
    }, [navigate])

    const filteredCompaniesList = useMemo(() => {
        let filter = partnerCompaniesList?.filter(company => {
            return company.company_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                company.pan_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
                company.gst_number.toLowerCase().includes(searchTerm.toLowerCase());
        });
        return filter?.sort((a, b) => new Date(b.created_at) - new Date(a.updated_at));
    }, [searchTerm, partnerCompaniesList]);


    const searchFunc = (value) => {
        if (validateValue(value)) {
            setSearchText(value);
            dispatch({
                type: "SEARCH_TERM",
                payload: value
            })
        }
    }

    return (
        <>
            <>
                <NavBarTop />
                <LeftNavBar />
            </>
            {page === 1 && <div className='partner-companies-container'>
                <div className='commercial-head-header-container' style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                    <h3>Our Companies</h3>
                    <div className='d-flex gap-2 flex-wrap'>
                        <div className='our_companies_search_bar_container'>
                            <input type='search' placeholder='Search' value={searchText} className='fin_man_meter_search_bar'
                                onChange={(e) => searchFunc(e.target.value)} />
                            <GoSearch className='fin_man_meter_search_bar_icon' />
                        </div>
                        <div className='add-facility-button-our-companies' onClick={() => setPage(2)}>
                            <IoAddOutline style={{ height: "20px", width: "20px" }} />
                            <span>New Company</span>
                        </div>
                    </div>
                </div>
                <div className='our_companies_table_container mt-3'>
                    <table>
                        <thead>
                            <tr className='fin_man_table_head'>
                                <th className='C-pointer'>Name</th>
                                <th className='C-pointer'>CIN</th>
                                <th className='C-pointer'>GST Number</th>
                                <th>PAN Number</th>
                                <th>Created</th>
                                <th>Updated</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody className='fin_man_table_body'>
                            {filteredCompaniesList && filteredCompaniesList.length > 0 ? filteredCompaniesList.map((company, index) => {
                                return (
                                    <tr key={company.partner_company_id} className={`table_row ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}>
                                        <td>{company.company_name}</td>
                                        <td>{company.cin}</td>
                                        <td>{company.gst_number}</td>
                                        <td>{company.pan_number}</td>
                                        <td title={company.created_at}>{timeElapsed(company.created_at)}</td>
                                        <td title={company.updated_at}>{timeElapsed(company.updated_at)}</td>
                                        <td style={{ textAlign: "center" }}><FaRegEdit title='edit' style={{ height: "15px", width: "15px" }} onClick={() => {
                                            setPage(3)
                                            setCompanyId(company.partner_company_id)
                                        }} /></td>
                                    </tr>
                                )
                            }) : <tr>
                                <td colSpan="8" className='text-center'>No records found</td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
                {loading && <FormLoader />}
                {partnerCompaniesListError && <Modal keyboard={false} backdrop="static" show={partnerCompaniesListError} onHide={() => {
                    dispatch({
                        type: GET_ALL_PARTNER_COMPANIES_FAILURE,
                        payload: null
                    })
                }} centered className="popper-modal">
                    <Modal.Body className="text-center">
                        <MdErrorOutline color='red' style={{ height: "60px", width: "60px", fontWeight: "10px" }} />
                        <h5>Error!</h5>
                        <p className="mb-3">{partnerCompaniesListError}</p>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center" >
                        <Button variant="secondary" className="secondary" onClick={() => {
                            dispatch({
                                type: GET_ALL_PARTNER_COMPANIES_FAILURE,
                                payload: null
                            })
                        }} >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>}
            </div>}
            {page === 2 && <AddPartnerCompany setPage={setPage} setNavigate={setNavigate} navigat={navigate} />}
            {page === 3 && <PartnerCompaniesEdit setPage={setPage} companyId={companyId} setNavigate={setNavigate} navigate={navigate} />}
            <Footer />
        </>
    )
}
