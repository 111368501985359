import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { UPLOAD_TERMS_AND_CONDITIONS_FAILURE, UPLOAD_TERMS_AND_CONDITIONS_SUCCESS, uploadTermsAndConditions } from '../../Redux/Actions/Actions';
import FormLoader from '../Reusable_Components/FromLoader';
import { Button, Modal } from 'react-bootstrap';
import UploadComponent from '../Reusable_Components/UploadComponent';

export default function TermsAndConditions() {

    const dispatch = useDispatch();

    const loading = useSelector(state => state.loading)
    const uploadTermsAndConditionsSuccess = useSelector(state => state.uploadTermsAndConditionsSuccess)
    const uploadTermsAndConditionsError = useSelector(state => state.uploadTermsAndConditionsError)

    const [fileData, setFileData] = useState([])
    const [selectedService, setSelectedService] = useState('slf');
    const [biilingPeriodFilter, setBillingPeriodFilter] = useState(new Date())

    const selectFile = (files) => {
        const processedFiles1 = [];
        files.forEach((file) => {
            const reader1 = new FileReader();
            reader1.onloadend = function () {
                const base64FileContent = reader1.result.split(",")[1];
                const processedFile1 = {
                    file_content: base64FileContent,
                    file_name: file.file.name,
                    file_type: "pdf",
                    object_type: "terms_documents",
                    flag: null,
                    document_type: "tandc",
                    user_id: localStorage.getItem("username")
                };
                processedFiles1.push(processedFile1);
                if (processedFiles1.length === files.length) {
                    setFileData(processedFiles1);
                }
            };
            reader1.readAsDataURL(file.file);
        });
    }

    const uploadData = (index) => {
        dispatch(uploadTermsAndConditions({ "files": [fileData[index]] }))
    }

    return (
        <div className='py-2' style={{ width: "100%" }}>
            <h4>Terms and Conditions</h4>
            <div className='d-flex align-items-center w-100'>
                <div className='d-flex flex-column gap-3 w-100'>
                    <label>Upload the Terms and Conditions PDF</label>
                    <div>
                        <UploadComponent
                            onFilesSelected={selectFile}
                            uploadActionClicked={uploadData}
                            viewMode={uploadTermsAndConditionsSuccess ? true : false}
                            maxNumberOfFiles={1}
                            uploadedFileData={uploadTermsAndConditionsSuccess && uploadTermsAndConditionsSuccess ? [{ file_name: uploadTermsAndConditionsSuccess?.split("/")[2]?.split("-")[0], file_type: uploadTermsAndConditionsSuccess?.split("/")[2]?.split(".")[1], s3_key: uploadTermsAndConditionsSuccess }] : []}
                            idType={"termsAndConditions"}
                            acceptedFileTypes={".pdf, .jpeg, .jpg, .png"}
                        />
                    </div>
                </div>
            </div>
            {loading && <FormLoader />}
            {/* {uploadTermsAndConditionsSuccess && <Modal keyboard={false} backdrop="static" show={uploadTermsAndConditionsSuccess} onHide={() => {
                dispatch({
                    type: UPLOAD_TERMS_AND_CONDITIONS_SUCCESS,
                    payload: null
                })
            }} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <img src="./images/icons/popper.svg" alt="Party Popper" />
                    <h5>Success!</h5>
                    <p className="mb-3">Uploaded document successfully</p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center" >
                    <Button variant="secondary" className="secondary" onClick={() => {
                        dispatch({
                            type: UPLOAD_TERMS_AND_CONDITIONS_SUCCESS,
                            payload: null
                        })
                    }} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>} */}
            {uploadTermsAndConditionsError && <Modal keyboard={false} backdrop="static" show={uploadTermsAndConditionsError} onHide={() => {
                dispatch({
                    type: UPLOAD_TERMS_AND_CONDITIONS_FAILURE,
                    payload: null
                })
            }} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <img src="./images/icons/alert.svg" alt="Alert" />
                    <h5>Error!</h5>
                    <p className="mb-3">{uploadTermsAndConditionsError}</p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center" >
                    <Button variant="secondary" className="secondary" onClick={() => {
                        dispatch({
                            type: UPLOAD_TERMS_AND_CONDITIONS_FAILURE,
                            payload: null
                        })
                    }} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>}
        </div>
    )
}
