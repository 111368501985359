import React from 'react'

export default function Upload(props) {
  return (
    <div className='d-flex flex-column'>
        <label>Upload files</label>
        <input type='file'  multiple onChange={(e) => console.log(e.target.files)}/>
    </div>
  )
}
