import React, {useState, useEffect} from "react";
import PrivacyPolicy from './privacypolicy';
import TermsAndConditions from './termsandconditions';
import { APP_BASE_MOD } from '../../Config';
import { base_url } from '../../utilities';
 

const Footer = () => {

  const [selectedEnvBase, setSelectedEnvBase] = useState()



  const getCurrentEnvironment = () => {
    const currentUrl = base_url;
    for (const ev in APP_BASE_MOD) {
      if (currentUrl.includes(ev)) {
        setSelectedEnvBase(APP_BASE_MOD[ev]);
      }
    }
  };

  useEffect(() => {
    getCurrentEnvironment();
  }, []);

    return (
      <footer
      className={(selectedEnvBase?.name === "Dev") ? 'footerbottomDev': ((selectedEnvBase?.name === "QA") ? 'footerbottomQa'
        : (selectedEnvBase?.name === 'Uat')? 'footerbottomUat':'footerbottom')}
      >
            <div className="foter">
                <div className="foter-left">
                    <a href="/privacypolicy" target="_blank" rel="noopener noreferrer">PrivacyPolicy</a>
                    <a href="/termsandconditions" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
                </div>
                <div className="foter-right">
                    <p>&copy; 2024, Turnkey Insights (I) Pvt. Ltd. </p>
                </div>
            </div>
        </footer>
);
};
  export default Footer;