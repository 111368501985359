import React, { useEffect, useState, useRef } from "react";
import { GoSearch } from "react-icons/go";
import axios from "../../axios-interceptor"
import { FaSearch } from "react-icons/fa";
import Spinner from 'react-bootstrap/Spinner';
import { Stack } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
    commercialHeadAddNewContract, commercialHeadUpdateDraftContract,
    commercialHeadAddNewContractDelete, commercialHeadSubmitContract,
    commercialHeadGetAllMeters, commercialHeadGetDepartments, commercialHeadGetUtilityRates,
    commercialEditCompleteContract, commercialHeadUploadDocument, commercialHeadGetBillingEntities, commercialHeadGetDocuments,
    handleDocumentLoaderTrue, handleDocumentLoaderFalse, handlePaginationLoaderTrue,
    commercialHeadGetContractsForDropdown, clearCommercialHeadDocumentContent,
    PUT_CH_ADD_NEW_CONTRACT_SUCCESS,
    UPDATE_COMPLETE_CONTRACT_SUCCESS,
    UPDATE_CH_DRAFT_CONTRACT_SUCCESS,
    COMMERCIAL_HEAD_GET_DOCUMENTS_SUCCESS,
    PUT_CH_SUBMIT_CONTRACT_SUCCESS,
    SET_ERROR_FALSE
} from '../../Redux/Actions/Actions'
import { commercialHeadGetContracts } from "../../Redux/Actions/Actions";
import { commercialHeadGetDraftContracts } from "../../Redux/Actions/Actions";
import Loader from "../Reusable_Components/loader";
import { IoMdInformationCircleOutline } from "react-icons/io";
import Alert from 'react-bootstrap/Alert';
import { base_url } from "../../utilities";
import UploadComponent from '../Reusable_Components/UploadComponent';

import { MdInfoOutline } from "react-icons/md";
import NavBarTop from "../Reusable_Components/NavBarTop";
import LeftNavBar from "../Reusable_Components/LeftNavBar";
import AlertPopUp from "../Reusable_Components/Alert";
import { GrView } from "react-icons/gr";
import Pagination from "../Reusable_Components/Pagination";
import { RECORDS_PER_PAGE } from "../../Config";
import { set } from "date-fns";
import {
    timeElapsed, validateValue, formatNumericInput, formatNumericParseIntInput,
    validEmailCheck, formatNumericparseFloatInput, processAndValidateEmail,
    licenseFeeDateValidationByStartDateEndDate,
    eleminateSpecialCharactersAtStartOfInputField, eleminateSpacesAtStartOfInputField, handleCinInputChange, validateGstNumber,
    checkUniqueness, handleKeyPressForGst, handleKeyDownForMeterReadings
} from "../Reusable_Components/ReusableFunctions";

import { MdErrorOutline } from 'react-icons/md';
import Footer from "../Login/Footer";


export default function CommercialHeadUsers() {
    const [cinNumber, setCinNumber] = useState("");
    const [gracePeriod, setGracePeriod] = useState(0);
    const documentLoaderTrigger = useSelector(state => state.documentLoaderTrigger);
    const todayDate = new Date();
    let currentDay = todayDate.getDate();
    let currentMonth = todayDate.getMonth() + 1;
    let currentYear = todayDate.getFullYear();
    const finalTodayDate = new Date(currentYear + "-" + currentMonth + "-" + currentDay)
    const draftcontractUpdated = useSelector((state) => state.updateDraftContract);
    const commercialHeadUploadDocumentList = useSelector((state) => state.commercialHeadUploadDocumentList)
    const commercialHeadGetDocumentsForContract = useSelector((state) => state.commercialHeadGetDocumentsForContract)
    const contractsFromDropdown = useSelector((state) => state.contractsForDropdown)
    const [documentList, setDocumentList] = useState([])
    const paginationLoader = useSelector((state) => state.paginationLoader)
    const contractApiLoader = useSelector((state) => state.contractApiLoader)
    const [contractIdforReference, setcontractIdforReference] = useState('')
    const [billingEntity, setBillingEntity] = useState('')
    const [contractNameforReference, setContractNameforReference] = useState('')

    useEffect(() => {
        let documentListTemp = [...documentList];
        commercialHeadUploadDocumentList?.forEach(item => {
            if (!documentListTemp.includes(item)) {
                documentListTemp.push(item);
            }
        });
        setDocumentList([...documentListTemp]);
    }, [commercialHeadUploadDocumentList])

    useEffect(() => {
        if (contractsFromDropdown !== null && contractsFromDropdown.length > 0) {
            const contractNameArray = []
            const contractNumberArray = []
            contractsFromDropdown.forEach(contract => {
                contractNameArray.push(contract.contract_name)
                contractNumberArray.push(contract.contract_number)
            })
            setContractNames(contractNameArray)
            setContractNumbers(contractNumberArray)
        }
    }, [contractsFromDropdown])

    const steps = [
        {
            name: "1. Basic Information",
            description: "Company Details"
        },
        {
            name: "2. Address Information",
            description: "Billing Address and Contacts"
        },
        {
            name: "3. Contract Information",
            description: "Contract Details"
        },
        {
            name: "4. Space License Fee Details",
            description: "Fee Details"
        },
    ]
    const allContracts = useSelector((state) => state.contracts?.data);
    const alldraftContracts = useSelector((state) => state.draftContracts?.data)

    const totalRecordsAc = useSelector((state) => state.contracts?.info?.total_no_of_records);
    const totalRecordsDc = useSelector((state) => state.draftContracts?.info?.total_no_of_records);

    const currentPaginationPageAc = useSelector((state) => state.contracts?.info?.page_number);
    const currentPaginationPageDc = useSelector((state) => state.draftContracts?.info?.page_number);


    const allMeters = useSelector((state) => state.allMeters)
    const allDepartments = useSelector((state) => state.allDepartments)
    const utilityRates = useSelector((state) => state.utilityRates)
    const meterTypesList = allMeters?.reduce((acc, curr) => {
        if (curr.meter_type && !acc.includes(curr.meter_type)) {
            acc.push(curr.meter_type);
        }
        return acc;
    }, []);


    const [isContractNumberValid, setIsContractNumberValid] = useState([]);
    const [duplicateContractNumberError, setDuplicateContractNumberError] = useState(false);
    const [duplicateContractNameError, setDuplicateContractNameError] = useState(false);
    const [selectedContractName, setSelectedContractName] = useState('');
    const [selectedContractNumber, setSelectedContractNumber] = useState('');



    const [contractsList, setContractsList] = useState([])
    const [tableLoader, setTableLoader] = useState(true)
    const [gstLoader, setGstLoader] = useState(false);
    const newContractId = useSelector((state) => state.createNewContract);
    const [selectedContract, setSelectedContract] = useState(false)
    const [popOverPosition, setPopOverPosition] = useState('bottom')
    const [contractType, setContractType] = useState("all");
    const [searchText, setSearchText] = useState("")
    const [filteredContracts, setFilteredContracts] = useState([]);
    const [viewClicked, setViewClicked] = useState(false)
    const [companyNameFilter, setCompanyNameFilter] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const isSubmittedContract = useSelector((state) => state.submittedContract);
    const isSubmittedCompleteContract = useSelector((state) => state.submittedCompleteContract);
    const [showSuccess, setShowSuccess] = useState(false);
    const [contractStartDate, setContractStartDate] = useState('0000-00-00');
    const [contractEndDate, setContractEndDate] = useState('0000-00-00');
    const [alertShow, setAlertShow] = useState(false)
    const [alertType, setAlertType] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertValue, setAlertValue] = useState('')
    const [alertButtons, setAlertButtons] = useState([])
    const [saveasDraftClicked, setSaveasDraftClicked] = useState(false)

    const error = useSelector((state) => state.error);
    const [errorValue, setErrorValue] = useState(null)
    const [finalReadingError, setFinalReadingError] = useState(false)
    const [finalDgReadingError, setFinalDgReadingError] = useState(false)
    const [facilityName, setFacilityName] = useState(localStorage.getItem("selected_facility_name"))

    const [showCloseConfirmationModal, setShowCloseConfirmationModal] = useState(false);
    const handleShowCloseConfirmationModal = () => setShowCloseConfirmationModal(true);
    const handleCloseCloseConfirmationModal = () => setShowCloseConfirmationModal(false);
    const [fileData, setFileData] = useState(null);
    const [termsAndConditions, setTermsAndConditions] = useState(null);

    const [slfTableError, setSlfTableError] = useState()
    const [dateError, setDateError] = useState('')
    const [endDateError, setEndDateError] = useState('')

    const handleCloseProceed = () => {
        dispatch(clearCommercialHeadDocumentContent())
        setShowCloseConfirmationModal(false);
        closeClicked();
    };


    // ========================error useeffect =================
    useEffect(() => {
        setErrorValue(error?.response?.data.message);
        if (error?.response?.data.message) {
            setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error')
            setAlertValue(error?.response?.data.message)
            setAlertButtons([{
                name: 'Close', action: () => {
                    dispatch({
                        type: SET_ERROR_FALSE,
                        payload: null
                    })
                    setAlertShow(false)
                }
            }])
        }
        setIsLoading(false);
    }, [error])


    const handleSelectTermsAndCondition = (files) => {
        setTermsAndConditions(null)
        let processedFiles1 = [];
        files.forEach((file) => {
            const reader1 = new FileReader();
            reader1.onloadend = function () {
                const base64FileContent = reader1.result.split(",")[1];
                const processedFile1 = {
                    file_content: base64FileContent,
                    file_name: file?.file?.name,
                    file_type: "pdf",
                    object_type: "terms_documents",
                    flag: null,
                    document_type: "tandc",
                    user_id: localStorage.getItem("username"),
                    contract_name: contractName,
                };
                processedFiles1 = [...processedFiles1, processedFile1];
                if (processedFiles1.length === files.length) {
                    setFileData(processedFiles1);
                }
            };
            reader1.readAsDataURL(file.file);
        });
    }
    const handleTermsAndConditionsUpload = (index) => {
        const data = { "files": [fileData[index]] }
        dispatch(handleDocumentLoaderTrue())
        axios.post(base_url + "documents", data)
            .then((response) => {
                if (response.status === 200) {
                    setTermsAndConditions(response.data.data[0].key)
                    dispatch(handleDocumentLoaderFalse())
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        if (draftcontractUpdated === 'success' && saveasDraftClicked) {
            setAlertShow(true)
            setAlertType('question')
            setAlertMessage('')
            setAlertValue(<>
                {
                    selectedDraftContract ?
                        <p className="mb-3">Draft Contract <b>{selectedDraftContract.contract_name}</b> has been successfully updated.</p>
                        :

                        <p className="mb-3">Draft Contract <b>{contractNameforReference}</b> has been successfully created.</p>
                }
            </>
            )


            setAlertButtons([
                {
                    name: 'View Contract', variant: 'outline-secondary', action: () => {
                        setAlertShow(false)
                        dispatch({
                            type: UPDATE_CH_DRAFT_CONTRACT_SUCCESS,
                            payload: null,
                        })
                        setSaveasDraftClicked(false)
                        setViewClicked(true)
                        viewSelectedContract({
                            'draft_id': contractIdforReference
                        })
                        // handleCloseModal()
                    }
                },
                {
                    name: 'Close', action: () => {
                        dispatch({
                            type: UPDATE_CH_DRAFT_CONTRACT_SUCCESS,
                            payload: null,
                        })
                        dispatch({
                            type: PUT_CH_ADD_NEW_CONTRACT_SUCCESS,
                            payload: null,
                        })
                        setAlertShow(false)
                        setSaveasDraftClicked(false)
                        handleCloseModal()
                    }
                },

            ])

        }
        setIsLoading(false)

    }, [draftcontractUpdated])


    useEffect(() => {
        if (newContractId?.length !== 0 && saveasDraftClicked) {
            setAlertShow(true)
            setAlertType('question')
            setAlertMessage('')
            setAlertValue(<>
                <p className="mb-3">Draft Contract <b>{contractName}</b> has been successfully created.</p>
            </>)
            setAlertButtons([
                {
                    name: 'View Contract', variant: 'outline-secondary', action: () => {
                        setAlertShow(false)
                        setViewClicked(true)
                        viewSelectedContract({
                            'draft_id': newContractId
                        })
                        setSaveasDraftClicked(false)
                        dispatch({
                            type: PUT_CH_ADD_NEW_CONTRACT_SUCCESS,
                            payload: null,
                        })
                    }
                },
                {
                    name: 'Close', action: () => {
                        setAlertShow(false)
                        handleCloseModal()
                        dispatch({
                            type: PUT_CH_ADD_NEW_CONTRACT_SUCCESS,
                            payload: null,
                        })
                    }
                },
            ])
        }
    }, [newContractId])


    useEffect(() => {
        if (isSubmittedContract === 'success') {
            setAlertShow(true)
            setAlertType('question')
            setAlertMessage('')
            setAlertValue(<>
                {
                    selectedDraftContract ?
                        <p className="mb-3">Contract <b>{selectedDraftContract.contract_name}</b> has been successfully updated.</p>
                        :

                        <p className="mb-3">Contract <b>{contractName}</b> has been successfully created.</p>
                }
            </>)
            setAlertButtons([
                {
                    name: 'View Contract', variant: 'outline-secondary', action: () => {
                        setAlertShow(false)
                        dispatch({
                            type: UPDATE_COMPLETE_CONTRACT_SUCCESS,
                            payload: null,
                        })
                        setViewClicked(true)
                        viewSelectedContract({
                            'contract_id': contractIdforReference
                        })
                    }
                },
                {
                    name: 'Close', action: () => {
                        setAlertShow(false)
                        handleCloseModal()
                        dispatch({
                            type: UPDATE_COMPLETE_CONTRACT_SUCCESS,
                            payload: null,
                        })
                    }
                },
            ])
        }
        setIsLoading(false)
    }, [isSubmittedContract])

    useEffect(() => {
        if (isSubmittedCompleteContract?.status === 'success') {
            setAlertShow(true)
            setAlertType('question')
            setAlertMessage('')
            setAlertValue(<>
                <p className="mb-3">Contract <b>{contractNameforReference}</b> has been successfully submitted.</p>
            </>)
            setAlertButtons([
                {
                    name: 'View Contract', variant: 'outline-secondary', action: () => {
                        setAlertShow(false)
                        dispatch({
                            type: PUT_CH_SUBMIT_CONTRACT_SUCCESS,
                            payload: null,
                        })
                        setViewClicked(true)
                        viewSelectedContract({
                            'contract_id': isSubmittedCompleteContract?.data?.contract_id
                        })
                    }
                },
                {
                    name: 'Close', action: () => {
                        setAlertShow(false)
                        handleCloseModal()
                        dispatch({
                            type: PUT_CH_SUBMIT_CONTRACT_SUCCESS,
                            payload: null,
                        })
                        dispatch({
                            type: PUT_CH_ADD_NEW_CONTRACT_SUCCESS,
                            payload: null,
                        })
                    }
                },

            ])
        }
        setIsLoading(false)
    }, [isSubmittedCompleteContract])
    // ===============================
    const [licenseTableBodyRows, setLicenseTableBodyRows] = useState(
        [{
            serial_number: 1,
            area_in_sqft: null,
            cost_per_unit: null,
            rate_start_date: contractStartDate,
            rate_end_date: contractEndDate,
            license_fee: ""
        }])

    const handleAddLicenseFeeRow = () => {
        let date = ""
        if (licenseTableBodyRows.length >= 1) {
            if (licenseTableBodyRows[licenseTableBodyRows.length - 1]?.rate_end_date) {
                date = new Date(licenseTableBodyRows[licenseTableBodyRows.length - 1]?.rate_end_date);
                date.setDate(date.getDate() + 1);
                date = date.toISOString('yyyy-mm-dd').slice(0, 10);
            }

        }

        let newRow = [...licenseTableBodyRows, {
            serial_number: licenseTableBodyRows.length + 1,
            area_in_sqft: null,
            cost_per_unit: null,
            rate_start_date: date,
            rate_end_date: contractEndDate,
            license_fee: ""
        }];
        setLicenseTableBodyRows([...newRow])
        setSlfTableError(licenseFeeDateValidationByStartDateEndDate(newRow, contractStartDate, contractEndDate))

    }

    const handleRemoveLicenseFeeRow = (index) => {
        let newList = [...licenseTableBodyRows]
        newList.splice(index, 1);
        // if(newList.length === 1){
        //     newList[0].rate_end_date = contractEndDate
        // }
        setLicenseTableBodyRows([...newList])
        setSlfTableError(licenseFeeDateValidationByStartDateEndDate(newList, contractStartDate, contractEndDate))

    }

    const dispatch = useDispatch();
    const [contractNumbers, setContractNumbers] = useState([]); // List of contract numbers
    const [contractNames, setContractNames] = useState([]); // List of contract names

    const billingEntities = useSelector((state) => state.billingEntities);
    useEffect(() => {
        dispatch(commercialHeadGetContracts({ facility_id: localStorage.getItem("selected_facility_id"), serach_text: searchText, items_per_page: RECORDS_PER_PAGE, page_number: 1 }));
        dispatch(commercialHeadGetDraftContracts({ facility_id: localStorage.getItem("selected_facility_id"), serach_text: searchText, items_per_page: RECORDS_PER_PAGE, page_number: 1 }));
        dispatch({
            type: PUT_CH_ADD_NEW_CONTRACT_SUCCESS,
            payload: false,
        });
        dispatch({
            type: UPDATE_COMPLETE_CONTRACT_SUCCESS,
            payload: false,
        });
        dispatch({
            type: PUT_CH_ADD_NEW_CONTRACT_SUCCESS,
            payload: false,
        });
        dispatch({
            type: UPDATE_CH_DRAFT_CONTRACT_SUCCESS,
            payload: false,
        });
        dispatch(commercialHeadGetAllMeters({
            request_type: "dropdown",
            filter_condition: {
                facility_id: localStorage.getItem("selected_facility_id"),
                is_in_use: 0,
                is_working: 1
            }
        }));
        dispatch(commercialHeadGetDepartments());
        dispatch(commercialHeadGetUtilityRates(localStorage.getItem("selected_facility_id")));
        dispatch(commercialHeadGetBillingEntities());
        dispatch(commercialHeadGetContractsForDropdown());
    }, [dispatch]);

    const handleContractNumberChange = (event) => {
        let input = event.target.value;
        input = input.replace(/[^a-zA-Z0-9./\\\-_]+/g, '');;
        const lengthValid = input.length === 0 || input.length >= 3 && input.length <= 200;

        // const isReplicaValid = input === selectedContractNumber;

        setContractNumber(input); // Changed from value to input
        setIsContractNumberValid(lengthValid);
        const notUnique = checkUniqueness(contractNumbers, selectedContractNumber, input)
        // const isDuplicateNumber = contractNumbers.some((contract_number) => contract_number === input);
        setDuplicateContractNumberError(notUnique);
    };

    const handleContractNameChange = (event) => {
        let value = event.target.value;
        value = value.trim();
        if (validateValue(value.charAt(0))) {
            setContractName(value);
            const lengthValid = value.length >= 3 && value.length <= 200;
            setIsContractNameValid(lengthValid);
            const notUnique = checkUniqueness(contractNames, selectedContractName, value)
            // const isReplicaValid = value.toLowerCase() === selectedContractName.toLowerCase();
            // const isDuplicateName = contractNames.some((contract_name) => contract_name.toLowerCase() === value.toLowerCase());
            setDuplicateContractNameError(notUnique);
        } else {
            event.target.value = contractName;
        }
    };

    const searchFunc = (value) => {
        if (validateValue(value)) {
            setSearchText(value);
            setIsLoading(true)
            dispatch(commercialHeadGetContracts({ facility_id: localStorage.getItem("selected_facility_id"), serach_text: value, items_per_page: RECORDS_PER_PAGE, page_number: 1 }));
            dispatch(commercialHeadGetDraftContracts({ facility_id: localStorage.getItem("selected_facility_id"), serach_text: value, items_per_page: RECORDS_PER_PAGE, page_number: 1 }));
        }

    };
    const backPagination = (value) => {
        if (validateValue(value)) {
            setSearchText(value);
            setIsLoading(true)
            dispatch(commercialHeadGetContracts({ facility_id: localStorage.getItem("selected_facility_id"), serach_text: value, items_per_page: RECORDS_PER_PAGE, page_number: currentPaginationPageAc }));
            dispatch(commercialHeadGetDraftContracts({ facility_id: localStorage.getItem("selected_facility_id"), serach_text: value, items_per_page: RECORDS_PER_PAGE, page_number: currentPaginationPageDc }));
        }

    };


    useEffect(() => {
        if (contractType === 'all') {
            setFilteredContracts(allContracts);
            setContractsList(allContracts)
        }
        else {
            setFilteredContracts(alldraftContracts);
            setContractsList(alldraftContracts)
        }
        if (allContracts !== null && alldraftContracts !== null) {
            const myTimeout = setTimeout(() => {
                setTableLoader(false)
            }, 500);
        }
        setIsLoading(false)

    }, [contractType, allContracts, alldraftContracts])

    const GstLoader = () => {
        return <Spinner style={{ height: "18px", width: "18px" }} animation="border" variant="light" />
    }


    const LoaderSpinner = () => {
        return <Spinner animation="border" variant="secondary" />
    }

    const [showDraftModal, setShowDraftModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const sameAsRegisteredAddress = () => {
        setBillingStreetAddress(registeredStreetAddress)
        setBillingStreetAddressLane2(registeredStreetAddressLane2)
        setBillingCity(registeredCity)
        setBillingStateProvince(registeredStateProvince)
        setBillingPostalCode(registeredPostalCode)
    }

    const sameAsBillingAddress = () => {
        setShippingStreetAddress(billingStreetAddress)
        setShippingStreetAddressLane2(billingStreetAddressLane2)
        setShippingCity(billingCity)
        setShippingStateProvince(billingStateProvince)
        setShippingPostalCode(billingPostalCode)
    }
    const handleCloseDraftModal = () => {
        setShowDraftModal(false);
    };
    const handleCloseConfirmationModal = () => {
        setShowConfirmationModal(false);
        setSaveasDraftClicked(false)
    };
    const handleSaveAsDraftConfirmation = () => {
        setShowConfirmationModal(false);
        saveasDraft();
        dispatch(clearCommercialHeadDocumentContent())
        // setTimeout(() => {
        //     window.location.reload();
        //   }, 1000);          
    };
    const handleSaveAsDraftClick = () => {
        setShowConfirmationModal(true);
    };

    const handleCloseModal = () => {
        dispatch({
            type: PUT_CH_ADD_NEW_CONTRACT_SUCCESS,
            payload: null,
        })
        dispatch(clearCommercialHeadDocumentContent())
        setCurrentPage(1)
        backfunction()
        clearValidationErrors()
        // searchFunc(searchText)
        backPagination(searchText)
        dispatch(commercialHeadGetContractsForDropdown());
        setShowDraftModal(false);
        dispatch(commercialHeadGetAllMeters({
            request_type: "dropdown",
            filter_condition: {
                facility_id: localStorage.getItem("selected_facility_id"),
                is_in_use: 0,
                is_working: 1
            }
        }));
    }

    const handleEmailUpdateChange = (e, index) => {

        const input = e.target.value;
        const currentEmail = listOfContactEmails[index] ? listOfContactEmails[index] : '';
        const { processedInput, isValid } = processAndValidateEmail(input, currentEmail);

        const updatedContacts = [...listOfContacts];
        const updatedContactsValidation = [...listOfContactsValidation];
        const isUnique = checkUniqueness(listOfContactEmails, currentEmail, processedInput);

        updatedContacts[index] = { ...updatedContacts[index], email: processedInput };
        updatedContactsValidation[index] = { ...updatedContactsValidation[index], isValidEmail: isValid, isEmailNotDuplicate: !isUnique };

        setListOfContacts(updatedContacts);
        setListOfContactsValidation(updatedContactsValidation);
    };

    const handleSubmit = () => {
        let meterListArray = listOfMeters.map(({ new_meter, ...rest }) => rest); // to remove new_meter key from the objectb when submitting complete contract
        meterListArray = meterListArray.map(item =>
        (
            {
                ...item,
                initial_reading: parseFloat(item.initial_reading),
                initial_dg_reading: parseFloat(item.initial_dg_reading)
            }
        ))
        if (newContractId || selectedDraftContract?.draft_id) {
            const draftId = selectedDraftContract ? selectedDraftContract.draft_id : newContractId
            setContractNameforReference(contractName)
            let objContract = {
                "draft_id": draftId,
                "contract_name": contractName,
                "billing_entity": billingEntity,
                "contract_status": contractStatus,
                "primary_contact_name": contactName,
                "primary_contact_phone_number": phoneNumber,
                "primary_contact_email": email,
                "company_name": companyName,
                "pan_number": panName,
                "gst_number": gstNumber,
                "cin": cinNumber,
                "registered_address": {
                    "street_address_1": registeredStreetAddress,
                    "street_address_2": registeredStreetAddressLane2,
                    "city": registeredCity,
                    "door_number": 'door2',
                    "country": "India",
                    "state": registeredStateProvince,
                    "postal_code": registeredPostalCode.toString(),
                    "address_type": 'Work'
                },
                "billing_address": {
                    "street_address_1": billingStreetAddress,
                    "street_address_2": billingStreetAddressLane2,
                    "door_number": 'door1',
                    "country": "India",
                    "city": billingCity,
                    "state": billingStateProvince,
                    "postal_code": billingPostalCode.toString(),
                },
                "shipping_address": {
                    "street_address_1": shippingStreetAddress,
                    "street_address_2": shippingStreetAddressLane2,
                    "door_number": 'door1',
                    "country": "India",
                    "city": shippingCity,
                    "state": shippingStateProvince,
                    "postal_code": shippingPostalCode.toString(),
                },
                "contacts": listOfContacts,

                // ================
                "contract_number": contractNumber,
                "contract_start_date": contractStartDate,
                "contract_end_date": contractEndDate,
                "contract_billing_type": contractBillingType,
                "notification_before": expireNotification,
                "grace_period": gracePeriod,
                "meters": meterListArray,
                // ===============
                "verification_from_portal": 0, //check again
                // ============

                "hoto_status": hotoStatus,
                "facility_id": newContractId ? localStorage.getItem("selected_facility_id") : selectedDraftContract.facility_id, // check again
                "space_name": spaceName,
                "electricity_load": electricityLoad,
                "dg_rate": dgRate,
                "truck_bays": parseInt(truckBays),
                "two_wheeler_parking_slots": parseInt(twoWheelerParkingSlots),
                "four_wheeler_parking_slots": parseInt(fourWheelerParkingSlots),
                "license_fee": 15000,
                "terms_and_conditions": termsAndConditions,
                // "escalation_rate": escalationRate,
                "escalation_rate": 10,
                // "escalation_period": escalationPeriod,
                "escalation_period": 1,
                "security_deposit": securityDeposit,
                "slf_rates": licenseTableBodyRows
            }
            setcontractIdforReference(draftId)
            dispatch(commercialHeadSubmitContract(objContract))
        }
        else {
            setContractNameforReference(contractName)
            let objContract = {
                "contract_id": selectedDraftContract.contract_id,
                "contract_name": contractName,
                "contract_status": contractStatus,
                "billing_entity": billingEntity,
                "primary_contact_name": contactName,
                "primary_contact_phone_number": phoneNumber,
                "primary_contact_email": email,
                "company_name": companyName,
                "pan_number": panName,
                "gst_number": gstNumber,
                "cin": cinNumber,
                "registered_address": {
                    "street_address_1": registeredStreetAddress,
                    "street_address_2": registeredStreetAddressLane2,
                    "city": registeredCity,
                    "door_number": 'door2',
                    "country": "India",
                    "state": registeredStateProvince,
                    "postal_code": registeredPostalCode.toString(),
                    "address_type": 'Work'
                },
                "billing_address": {
                    "street_address_1": billingStreetAddress,
                    "street_address_2": billingStreetAddressLane2,
                    "door_number": 'door1',
                    "country": "India",
                    "city": billingCity,
                    "state": billingStateProvince,
                    "postal_code": billingPostalCode.toString(),
                },
                "shipping_address": {
                    "street_address_1": shippingStreetAddress,
                    "street_address_2": shippingStreetAddressLane2,
                    "door_number": 'door1',
                    "country": "India",
                    "city": shippingCity,
                    "state": shippingStateProvince,
                    "postal_code": shippingPostalCode.toString(),
                },
                "contacts": listOfContacts,

                // ================
                "contract_number": contractNumber,
                "contract_start_date": contractStartDate,
                "contract_end_date": contractEndDate,
                "contract_billing_type": contractBillingType,
                "notification_before": expireNotification,
                "grace_period": gracePeriod,
                "meters": meterListArray,
                "verification_from_portal": 0, //check again
                "hoto_status": hotoStatus,
                "facility_id": selectedDraftContract.facility_id, // check again
                "space_name": spaceName,
                "electricity_load": electricityLoad,
                "dg_rate": dgRate,
                "truck_bays": parseInt(truckBays),
                "two_wheeler_parking_slots": parseInt(twoWheelerParkingSlots),
                "four_wheeler_parking_slots": parseInt(fourWheelerParkingSlots),
                "terms_and_conditions": termsAndConditions,
                "license_fee": 15000,
                "escalation_rate": 10,
                "escalation_period": 1,
                "security_deposit": securityDeposit,
                "slf_rates": licenseTableBodyRows,
            }
            setcontractIdforReference(selectedDraftContract.contract_id)
            dispatch(commercialEditCompleteContract(objContract))
        }
        setIsLoading(true)
    }

    const saveasDraft = () => {
        const isDuplicateName = contractNames.some((contract_name) => contract_name.toLowerCase() === contractName.trim().toLowerCase());
        const isReplicaValid = contractName.trim().toLowerCase() === selectedContractName.toLowerCase();
        setDuplicateContractNameError(isDuplicateName && !isReplicaValid);
        setContractName(contractName.trim())
        if (!(isDuplicateName && !isReplicaValid)) {
            // setShowDraftModal(true);
            setContractNameforReference(contractName)
            if (contractType === "draft" && selectedDraftContract) {
                let objContract = {
                    "draft_id": selectedDraftContract.draft_id,
                    "terms_and_conditions": termsAndConditions,
                    "contract_name": contractName.trim(),
                    "billing_entity": billingEntity,
                    "contract_status": contractStatus,
                    "primary_contact_name": contactName,
                    "primary_contact_phone_number": phoneNumber,
                    "primary_contact_email": email,
                    "company_name": companyName,
                    "pan_number": panName,
                    "gst_number": gstNumber,
                    "cin": cinNumber,
                    "slf_rates": licenseTableBodyRows,
                    "registered_address": {
                        "street_address_1": registeredStreetAddress,
                        "street_address_2": registeredStreetAddressLane2,
                        "city": registeredCity,
                        "state": registeredStateProvince,
                        "postal_code": registeredPostalCode.toString(),
                    },

                    "billing_address": {
                        "street_address_1": billingStreetAddress,
                        "street_address_2": billingStreetAddressLane2,
                        "city": billingCity,
                        "state": billingStateProvince,
                        "postal_code": billingPostalCode.toString(),
                    },
                    "shipping_address": {
                        "street_address_1": shippingStreetAddress,
                        "street_address_2": shippingStreetAddressLane2,
                        "city": shippingCity,
                        "state": shippingStateProvince,
                        "postal_code": shippingPostalCode.toString(),
                    },

                    // ==================

                    "contacts": listOfContacts,

                    // ================
                    "contract_number": contractNumber,
                    "contract_start_date": contractStartDate,
                    "contract_end_date": contractEndDate,
                    "contract_billing_type": contractBillingType,
                    "notification_before": expireNotification,
                    "grace_period": gracePeriod,
                    "meters": listOfMeters,
                    // ===============
                    "verification_from_portal": false, //check again
                    // ============
                    "hoto_status": hotoStatus,
                    "facility_id": selectedDraftContract.facility_id, // check again
                    "space_name": spaceName,
                    "electricity_load": electricityLoad,
                    "dg_rate": dgRate,
                    "truck_bays": truckBays,
                    "two_wheeler_parking_slots": parseInt(twoWheelerParkingSlots),
                    "four_wheeler_parking_slots": parseInt(fourWheelerParkingSlots),
                    "license_fee": licenseFee,
                    "escalation_rate": escalationRate,
                    "escalation_period": escalationPeriod,
                    "security_deposit": securityDeposit,
                }
                setcontractIdforReference(selectedDraftContract.draft_id)
                dispatch(commercialHeadUpdateDraftContract(objContract))
                // setSelectedDraftContract(null)
            }
            else if (currentPage >= 2 && !selectedDraftContract && newContractId) {
                setContractNameforReference(contractName)
                let objContract = {
                    "draft_id": newContractId,
                    "contract_name": contractName.trim(),
                    "contract_status": contractStatus,
                    "terms_and_conditions": termsAndConditions,
                    "billing_entity": billingEntity,
                    "primary_contact_name": contactName,
                    "primary_contact_phone_number": phoneNumber,
                    "primary_contact_email": email,
                    "company_name": companyName,
                    "pan_number": panName,
                    "gst_number": gstNumber,
                    "cin": cinNumber,
                    "slf_rates": licenseTableBodyRows,
                    "registered_address": {
                        "street_address_1": registeredStreetAddress,
                        "street_address_2": registeredStreetAddressLane2,
                        "city": registeredCity,
                        "state": registeredStateProvince,
                        "postal_code": registeredPostalCode.toString(),
                    },

                    "billing_address": {
                        "street_address_1": billingStreetAddress,
                        "street_address_2": billingStreetAddressLane2,
                        "city": billingCity,
                        "state": billingStateProvince,
                        "postal_code": billingPostalCode.toString(),
                    },
                    "shipping_address": {
                        "street_address_1": shippingStreetAddress,
                        "street_address_2": shippingStreetAddressLane2,
                        "city": shippingCity,
                        "state": shippingStateProvince,
                        "postal_code": shippingPostalCode.toString(),
                    },
                    "contacts": listOfContacts,

                    // ================
                    "contract_number": contractNumber,
                    "contract_start_date": contractStartDate,
                    "contract_end_date": contractEndDate,
                    "contract_billing_type": contractBillingType,
                    "notification_before": expireNotification,
                    "grace_period": gracePeriod,
                    "meters": listOfMeters,
                    // ===============
                    "verification_from_portal": false, //check again
                    // ============

                    "hoto_status": hotoStatus,
                    "facility_id": localStorage.getItem("selected_facility_id"), // check again
                    "space_name": spaceName,
                    "electricity_load": electricityLoad,
                    "dg_rate": dgRate,
                    "truck_bays": truckBays,
                    "two_wheeler_parking_slots": parseInt(twoWheelerParkingSlots),
                    "four_wheeler_parking_slots": parseInt(fourWheelerParkingSlots),
                    "license_fee": licenseFee,
                    "escalation_rate": escalationRate,
                    "escalation_period": escalationPeriod,
                    "security_deposit": securityDeposit,
                }
                setcontractIdforReference(newContractId)
                dispatch(commercialHeadUpdateDraftContract(objContract))
                dispatch(commercialHeadAddNewContractDelete())

            }
            else {
                setContractNameforReference(contractName)
                let objContract = {
                    "contract_name": contractName.trim(),
                    "contract_status": contractStatus,
                    "billing_entity": billingEntity,
                    "terms_and_conditions": termsAndConditions,
                    "primary_contact_name": contactName,
                    "primary_contact_phone_number": phoneNumber,
                    "primary_contact_email": email,
                    "company_name": companyName,
                    "pan_number": panName,
                    "gst_number": gstNumber,
                    "cin": cinNumber,
                    "slf_rates": licenseTableBodyRows,
                    "registered_address": {
                        "street_address_1": registeredStreetAddress,
                        "street_address_2": registeredStreetAddressLane2,
                        "city": registeredCity,
                        "state": registeredStateProvince,
                        "postal_code": registeredPostalCode.toString(),
                    },

                    "billing_address": {
                        "street_address_1": billingStreetAddress,
                        "street_address_2": billingStreetAddressLane2,
                        "city": billingCity,
                        "state": billingStateProvince,
                        "postal_code": billingPostalCode.toString(),
                    },
                    "shipping_address": {
                        "street_address_1": shippingStreetAddress,
                        "street_address_2": shippingStreetAddressLane2,
                        "city": shippingCity,
                        "state": shippingStateProvince,
                        "postal_code": shippingPostalCode.toString(),
                    },
                    "contacts": listOfContacts,
                    // ================
                    "contract_number": contractNumber,
                    "contract_start_date": contractStartDate,
                    "contract_end_date": contractEndDate,
                    "contract_billing_type": contractBillingType,
                    "notification_before": expireNotification,
                    "grace_period": gracePeriod,
                    "meters": listOfMeters,
                    // ===============
                    "verification_from_portal": false, //check again
                    // ====================

                    "hoto_status": hotoStatus,
                    "facility_id": localStorage.getItem("selected_facility_id"), // check again
                    "space_name": spaceName,
                    "electricity_load": electricityLoad,
                    "dg_rate": dgRate,
                    "truck_bays": truckBays,
                    "two_wheeler_parking_slots": parseInt(twoWheelerParkingSlots),
                    "four_wheeler_parking_slots": parseInt(fourWheelerParkingSlots),
                    "license_fee": licenseFee,
                    "escalation_rate": escalationRate,
                    "escalation_period": escalationPeriod,
                    "security_deposit": securityDeposit,
                }
                dispatch(commercialHeadAddNewContract(objContract))
                dispatch(commercialHeadAddNewContractDelete())
                // setCurrentPage(1)

            }
        }
    };

    const saveAndContinue = () => {

        // extractContractNameNumber();

        const isDuplicateName = contractNames.some((contract_name) => contract_name.toLowerCase() === contractName.trim().toLowerCase());
        const isReplicaValid = contractName.trim().toLowerCase() === selectedContractName.toLowerCase();
        setDuplicateContractNameError(isDuplicateName && !isReplicaValid);
        setContractName(contractName.trim())
        if (!(isDuplicateName && !isReplicaValid)) {
            if (contractType === "draft" && selectedDraftContract) { // for editing a draft contract
                setContractNameforReference(contractName)
                let objContract = {
                    "draft_id": selectedDraftContract.draft_id,
                    "contract_name": contractName.trim(),
                    "billing_entity": billingEntity,
                    "contract_status": contractStatus,
                    "primary_contact_name": contactName,
                    "primary_contact_phone_number": phoneNumber,
                    "primary_contact_email": email,
                    "company_name": companyName,
                    "pan_number": panName,
                    "gst_number": gstNumber,
                    "cin": cinNumber,
                    "slf_rates": licenseTableBodyRows,
                    "registered_address": {
                        "street_address_1": registeredStreetAddress,
                        "street_address_2": registeredStreetAddressLane2,
                        "city": registeredCity,
                        "state": registeredStateProvince,
                        "postal_code": registeredPostalCode.toString(),
                    },

                    "billing_address": {
                        "street_address_1": billingStreetAddress,
                        "street_address_2": billingStreetAddressLane2,
                        "city": billingCity,
                        "state": billingStateProvince,
                        "postal_code": billingPostalCode.toString(),
                    },
                    "shipping_address": {
                        "street_address_1": shippingStreetAddress,
                        "street_address_2": shippingStreetAddressLane2,
                        "city": shippingCity,
                        "state": shippingStateProvince,
                        "postal_code": shippingPostalCode.toString(),
                    },
                    "contract_number": contractNumber,
                    "contract_start_date": contractStartDate,
                    "contract_end_date": contractEndDate,
                    "contract_billing_type": contractBillingType,
                    "notification_before": expireNotification,
                    "grace_period": gracePeriod,
                    "meters": listOfMeters,
                    // ===============
                    "verification_from_portal": false, //check again
                    // ============
                    "hoto_status": hotoStatus,
                    "facility_id": selectedDraftContract.facility_id, // check again
                    "space_name": spaceName,
                    "electricity_load": electricityLoad,
                    "dg_rate": dgRate,
                    "truck_bays": truckBays,
                    "two_wheeler_parking_slots": parseInt(twoWheelerParkingSlots),
                    "four_wheeler_parking_slots": parseInt(fourWheelerParkingSlots),
                    "license_fee": licenseFee,
                    "escalation_rate": escalationRate,
                    "escalation_period": escalationPeriod,
                    "security_deposit": securityDeposit,
                    "contacts": listOfContacts
                }
                dispatch(commercialHeadUpdateDraftContract(objContract))

                setCurrentPage(currentPage + 1)

            }
            else if (currentPage >= 2 && !selectedDraftContract && newContractId) {
                //continuing to create a new contract
                setContractNameforReference(contractName)
                let objContract = {
                    "draft_id": newContractId,
                    "contract_name": contractName.trim(),
                    "billing_entity": billingEntity,
                    "contract_status": contractStatus,
                    "primary_contact_name": contactName,
                    "primary_contact_phone_number": phoneNumber,
                    "primary_contact_email": email,
                    "company_name": companyName,
                    "pan_number": panName,
                    "gst_number": gstNumber,
                    "slf_rates": licenseTableBodyRows,
                    "cin": cinNumber,
                    "registered_address": {
                        "street_address_1": registeredStreetAddress,
                        "street_address_2": registeredStreetAddressLane2,
                        "city": registeredCity,
                        "state": registeredStateProvince,
                        "postal_code": registeredPostalCode.toString(),
                    },

                    "billing_address": {
                        "street_address_1": billingStreetAddress,
                        "street_address_2": billingStreetAddressLane2,
                        "city": billingCity,
                        "state": billingStateProvince,
                        "postal_code": billingPostalCode.toString(),
                    },
                    "shipping_address": {
                        "street_address_1": shippingStreetAddress,
                        "street_address_2": shippingStreetAddressLane2,
                        "city": shippingCity,
                        "state": shippingStateProvince,
                        "postal_code": shippingPostalCode.toString(),
                    },
                    "contract_number": contractNumber,
                    "contract_start_date": contractStartDate,
                    "contract_end_date": contractEndDate,
                    "contract_billing_type": contractBillingType,
                    "notification_before": expireNotification,
                    "grace_period": gracePeriod,
                    "meters": listOfMeters,
                    // ===============
                    "verification_from_portal": false, //check again
                    // ============

                    "hoto_status": hotoStatus,
                    "facility_id": localStorage.getItem("selected_facility_id"), // check again
                    "space_name": spaceName,
                    "electricity_load": electricityLoad,
                    "dg_rate": dgRate,
                    "truck_bays": truckBays,
                    "two_wheeler_parking_slots": parseInt(twoWheelerParkingSlots),
                    "four_wheeler_parking_slots": parseInt(fourWheelerParkingSlots),
                    "license_fee": licenseFee,
                    "escalation_rate": escalationRate,
                    "escalation_period": escalationPeriod,
                    "security_deposit": securityDeposit,
                    "contacts": listOfContacts
                }
                dispatch(commercialHeadUpdateDraftContract(objContract))
                setCurrentPage(currentPage + 1)

            }
            else { // for creating a new contract for the first save and continuw
                setContractNameforReference(contractName)
                let objContract = {
                    "contract_name": contractName.trim(),
                    "contract_status": contractStatus,
                    "billing_entity": billingEntity,
                    "primary_contact_name": contactName,
                    "primary_contact_phone_number": phoneNumber,
                    "primary_contact_email": email,
                    "company_name": companyName,
                    "pan_number": panName,
                    "gst_number": gstNumber,
                    "cin": cinNumber,
                    "slf_rates": licenseTableBodyRows,
                    "registered_address": {
                        "street_address_1": registeredStreetAddress,
                        "street_address_2": registeredStreetAddressLane2,
                        "city": registeredCity,
                        "state": registeredStateProvince,
                        "postal_code": registeredPostalCode.toString(),
                    },
                    "billing_address": {
                        "street_address_1": billingStreetAddress,
                        "street_address_2": billingStreetAddressLane2,
                        "city": billingCity,
                        "state": billingStateProvince,
                        "postal_code": billingPostalCode.toString(),
                    },
                    "shipping_address": {
                        "street_address_1": shippingStreetAddress,
                        "street_address_2": shippingStreetAddressLane2,
                        "city": shippingCity,
                        "state": shippingStateProvince,
                        "postal_code": shippingPostalCode.toString(),
                    },
                    "contract_number": contractNumber,
                    "contract_start_date": contractStartDate,
                    "contract_end_date": contractEndDate,
                    "contract_billing_type": contractBillingType,
                    "notification_before": expireNotification,
                    "grace_period": gracePeriod,
                    "meters": listOfMeters,
                    // ===============
                    "verification_from_portal": false, //check again
                    // ====================

                    "hoto_status": hotoStatus,
                    "facility_id": localStorage.getItem("selected_facility_id"), // check again
                    "space_name": spaceName,
                    "electricity_load": electricityLoad,
                    "dg_rate": dgRate,
                    "truck_bays": truckBays,
                    "two_wheeler_parking_slots": parseInt(twoWheelerParkingSlots),
                    "four_wheeler_parking_slots": parseInt(fourWheelerParkingSlots),
                    "license_fee": licenseFee,
                    "escalation_rate": escalationRate,
                    "escalation_period": escalationPeriod,
                    "security_deposit": securityDeposit,
                    "contacts": listOfContacts
                }
                dispatch(commercialHeadAddNewContract(objContract))
                setCurrentPage(currentPage + 1)
            }
        }
    }



    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if (error) {
            if (currentPage) {
                setCurrentPage(currentPage)
            }
            if (currentPage === 5) {
                setCurrentPage(5)
            }
            else {
                setCurrentPage(currentPage - 1);
            }
        }
    }, [error])


    const [listOfContacts, setListOfContacts] = useState([{
        name: "",
        phone_number: "",
        email: "",
        designation: "",
    }])

    const [listOfContactsValidation, setListOfContactsValidation] = useState([{
        isContactNameValid: true,
        isDesignationValid: true,
        isValidPhone: true,
        isValidEmail: true,
        isEmailNotDuplicate: true
    }])

    const handleAddAnotherContactValidation = () => {
        setListOfContactsValidation([...listOfContactsValidation, {
            isContactNameValid: true,
            isDesignationValid: true,
            isValidPhone: true,
            isValidEmail: true,
            isEmailNotDuplicate: true
        }])

    }

    const handleAddAnotherMeterValidation = () => {
        setListOfMetersValidation([...listOfMetersValidtaion, {
            isValidMeterName: true,
            isvalidMeterType: true,
            isValidMeterNumber: true
        }])
    }




    const [listOfMeters, setListOfMeters] = useState([])

    const [listOfMetersValidtaion, setListOfMetersValidation] = useState([])
    const handleAddAnotherContact = () => {
        setListOfContacts([...listOfContacts, {
            name: "",
            phone_number: "",
            email: "",
            designation: "",
        }])

    }

    const handleAddAnotherMeter = () => {
        if (!editCompleteContractClicked) {
            setListOfMeters([...listOfMeters, {
                meter_id: "",
                meter_type: "",
                assigned_name: "",
                meter_number: "",
                new_meter: true,
                initial_reading: 0.0,
                initial_dg_reading: 0.0,
                assigned_date: contractStartDate,
                unassigned_date: contractEndDate,
                assigned_time: "00:00:00",

            }])
        }
        else {
            setListOfMeters([...listOfMeters, {
                contract_id: selectedDraftContract.contract_id,
                meter_id: "",
                meter_type: "",
                new_meter: true,
                assigned_name: "",
                meter_number: "",
                initial_reading: 0.0,
                initial_dg_reading: 0.0,
                assigned_date: new Date().toISOString().slice(0, 10),
                unassigned_date: contractEndDate,
                assigned_time: new Date().toLocaleTimeString('en-GB', { hour12: false }),
            }])
        }

    }

    useEffect(() => {

        const newArray = [...listOfMeters]
        newArray.forEach((meter, index) => {
            meter.assigned_date = contractStartDate
            meter.unassigned_date = contractEndDate
            meter.assigned_time = "00:00:00"
        })
        setListOfMeters([...newArray])

    }, [contractStartDate, contractEndDate])



    const [showDeleteContractContact, setShowDeleteContractContact] = useState(false);
    const [deleteContractContactSelected, setDeleteContractContactSelected] = useState();
    const [deleteContractContactIdSelected, setDeleteContractContactIdSelected] = useState();

    const deleteSpecificContact = (id, contact) => {

        if (editCompleteContractClicked) {
            setShowDeleteContractContact(true)
            setDeleteContractContactSelected(contact)
            setDeleteContractContactIdSelected(id)
        }
        else {
            const newArray = listOfContacts.filter((_, index) => index !== id);
            const newVaildArray = listOfContactsValidation.filter((_, index) => index !== id);
            setListOfContacts([...newArray]);
            setListOfContactsValidation([...newVaildArray])
        }


    }

    const handleConfirmDeleteContractContact = () => {
        setShowDeleteContractContact(false);
        axios.delete(base_url + `customers/contracts/contractContactRef`,
            {
                params: {
                    contact_id: deleteContractContactSelected.contact_id,
                    contract_id: selectedDraftContract.contract_id
                }
            }

        )
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    setErrorValue(response?.data?.data[1].message)
                    setAlertShow(true)
                    setAlertType('error')
                    setAlertMessage('Error')
                    setAlertValue(response?.data?.data[1].message)
                    setAlertButtons([
                        {
                            name: 'Close', action: () => {
                                setAlertShow(false)
                            }
                        },
                    ])
                    setIsLoading(false)
                }
                else {
                    const newArray = listOfContacts.filter((_, index) => index !== deleteContractContactIdSelected);
                    const newVaildArray = listOfContactsValidation.filter((_, index) => index !== deleteContractContactIdSelected);
                    setListOfContacts([...newArray]);
                    setListOfContactsValidation([...newVaildArray])
                }
            })
            .catch((error) => {
                setErrorValue(error?.response?.data.message);
                setAlertShow(true)
                setAlertType('error')
                setAlertMessage('Error')
                setAlertValue(error?.response?.data.message)
                setAlertButtons([{
                    name: 'Close', action: () => {
                        setAlertShow(false)
                    }
                }])
                setIsLoading(false)
            })

    }

    const [showDeleteContractMeter, setShowDeleteContractMeter] = useState(false);
    const [deleteContractMeterSelected, setDeleteContractMeterSelected] = useState();
    const [deleteContractMeterIdSelected, setDeleteContractMeterIdSelected] = useState();
    const [finalMeterReadings, setFinalMeterReadings] = useState(0)
    const [finalDgMeterReadings, setFinalDgMeterReadings] = useState(0)

    const handleConfirmDeleteContractMeter = () => {
        setShowDeleteContractMeter(false);
        setFinalDgReadingError(false);
        setFinalReadingError(false);
        axios.delete(base_url + `customers/contracts/contractMeterRef`,
            {
                params: {
                    "meter_id": deleteContractMeterSelected.meter_id,
                    "contract_id": deleteContractMeterSelected.contract_id,
                    "final_reading": finalMeterReadings,
                    "final_dg_reading": finalDgMeterReadings,
                    "unassigned_date": new Date().toLocaleDateString(),
                    "unassigned_time": new Date().toLocaleTimeString(),
                    "assigned_name": deleteContractMeterSelected.assigned_name,
                    "meter_number": deleteContractMeterSelected.meter_number,
                    "meter_type": deleteContractMeterSelected.meter_type,
                }
            }

        )
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    setErrorValue(response?.data?.data[1].message)
                    setAlertShow(true)
                    setAlertType('error')
                    setAlertMessage('Error')
                    setAlertValue(response?.data?.data[1].message)
                    setAlertButtons([
                        {
                            name: 'Close', action: () => {
                                setAlertShow(false)
                            }
                        },
                    ])
                    setIsLoading(false)
                }
                else {
                    const newArray = listOfMeters.filter((_, index) => index !== deleteContractMeterIdSelected);
                    const newVaildArray = listOfMetersValidtaion.filter((_, index) => index !== deleteContractMeterIdSelected);
                    setListOfMeters(newArray);
                    setListOfMetersValidation(newVaildArray)
                    dispatch(commercialHeadGetAllMeters({
                        request_type: "dropdown",
                        filter_condition: {
                            facility_id: localStorage.getItem("selected_facility_id"),
                            is_in_use: 0,
                            is_working: 1
                        }
                    }));
                    setFinalReadingError(false)
                    setFinalDgReadingError(false)
                }
            })
            .catch((error) => {
                setErrorValue(error?.response?.data.message);
                setAlertShow(true)
                setAlertType('error')
                setAlertMessage('Error')
                setAlertValue(error?.response?.data.message)
                setAlertButtons([{
                    name: 'Close', action: () => {
                        setAlertShow(false)
                    }
                }])
                setIsLoading(false)
            })
        setFinalMeterReadings(0)

    }

    const deleteSpecificMeter = (id, meter) => {
        if (editCompleteContractClicked && !meter.new_meter) {
            setShowDeleteContractMeter(true)
            setDeleteContractMeterSelected(meter)
            setFinalDgMeterReadings(0)
            setFinalMeterReadings(0)
            setDeleteContractMeterIdSelected(id)
        }
        else {
            const newArray = listOfMeters.filter((_, index) => index !== id);
            const newVaildArray = listOfMetersValidtaion.filter((_, index) => index !== id);
            setListOfMeters(newArray);
            setListOfMetersValidation(newVaildArray)
        }

    }

    // const extractContractNameNumber = () => {
    //     const onlyContractNumbersList = allContracts && allContracts?.map((jsonObj) => jsonObj.contract_number);
    //     const onlyDraftContractNumbersList = alldraftContracts && alldraftContracts?.map((jsonObj) => jsonObj.contract_number);
    //     // const newObj = [...onlyContractNumbersList, ...onlyDraftContractNumbersList]

    //     const onlyContractNamesList = allContracts && allContracts?.map((jsonObj) => jsonObj.contract_name);
    //     const onlyDraftContractNamesList = alldraftContracts && alldraftContracts?.map((jsonObj) => jsonObj.contract_name);
    //     // const newObj2 = [...onlyContractNamesList, ...onlyDraftContractNamesList]
    //     // setContractNumbers([...newObj])
    //     // setContractNames([...newObj2])
    // }

    const changePageNext = () => {
        // extractContractNameNumber();

        if (currentPage <= steps.length) {
            setCurrentPage(currentPage + 1)
        }
        else {
            setCurrentPage(1)
            setViewClicked(false)
            // window.location.reload();
            dispatch({
                type: PUT_CH_ADD_NEW_CONTRACT_SUCCESS,
                payload: null,
            })
            backfunction()
            searchFunc(searchText)
        }
    }

    const backfunction = () => {
        dispatch({
            type: COMMERCIAL_HEAD_GET_DOCUMENTS_SUCCESS,
            payload: null,
        })
        dispatch({
            type: PUT_CH_ADD_NEW_CONTRACT_SUCCESS,
            payload: null,
        })
        setEditCompleteContractClicked(false)
        setContractNameforReference('')
        setListOfContactEmails([])
        setBillingEntity('')
        setSelectedDraftContract('')
        setSaveasDraftClicked(false)
        // =======================
        setContractNumber('');
        setContractName('');
        setDuplicateContractNameError(false)
        setSelectedContractName('')
        setSelectedContractNumber('')
        setIsValidEmail(true)
        setContractStatus('pending');
        setContactName('');
        setPhoneNumber('');
        setEmail('');
        setCinNumber('');
        setGstNumber('');
        setCompanyName('');
        setPanName('');
        setRegisteredStreetAddress('');
        setRegisteredStreetAddressLane2('');
        setRegisteredCity('');
        setRegisteredStateProvince('');
        setRegisteredPostalCode('');
        setBillingStreetAddress('');
        setBillingStreetAddressLane2('');
        setBillingCity('');
        setBillingStateProvince('');
        setBillingPostalCode('');
        setShippingStreetAddress('');
        setShippingStreetAddressLane2('');
        setShippingCity('');
        setShippingStateProvince('');
        setShippingPostalCode('');
        setListOfContacts([
            {
                name: "",
                phone_number: "",
                email: "",
                designation: "",
            }
        ])
        setListOfContactsValidation([{
            isContactNameValid: true,
            isDesignationValid: true,
            isValidPhone: true,
            isValidEmail: true,
            isEmailNotDuplicate: true
        }])
        // setContractValue('')
        setContractStartDate('')
        setContractEndDate('')
        setContractBillingType('half yearly')
        setExpireNotification('1 month')
        setGracePeriod(0)
        // setListOfMeters(contractDetails?.meters)
        setCostperUnit('')
        setListOfMeters([])
        setListOfMetersValidation([])
        setSpaceName('')
        setArea(0)
        setElectricityLoad(0)
        setTruckBays(0)
        setTwoWheelerParkingSlots(0)
        setFourWheelerParkingSlots(0)
        setLicenseFee(0)
        setEscalationRate('')
        setEscalationPeriod(0)
        setSecurityDeposit(0)
        setDgRate(0)
        setFinalDgReadingError(false);
        setFinalReadingError(false);
        setHotoStatus('pending')
        setLicenseFee(0)
        setLicenseTableBodyRows([{
            serial_number: 1,
            area_in_sqft: null,
            cost_per_unit: null,
            rate_start_date: "",
            rate_end_date: "",
            license_fee: ""
        }])
        setTermsAndConditions(null)
        setFinalDgReadingError(false)
        setFinalReadingError(false)
    }

    const closeClicked = () => {
        setCurrentPage(1)
        setViewClicked(false)
        dispatch({
            type: PUT_CH_ADD_NEW_CONTRACT_SUCCESS,
            payload: null,
        })
        dispatch(commercialHeadGetContractsForDropdown());
        clearValidationErrors()
        // searchFunc(searchText)
        backPagination(searchText)
        dispatch(commercialHeadGetAllMeters({
            request_type: "dropdown",
            filter_condition: {
                facility_id: localStorage.getItem("selected_facility_id"),
                is_in_use: 0,
                is_working: 1
            }
        }));
        backfunction()
    }



    const changePagePrev = () => {

        if (currentPage === 2) {
            setSelectedDraftContract(null)
            setViewClicked(false)
            clearValidationErrors()
            dispatch(commercialHeadGetContractsForDropdown());

            // window.location.reload();
            backfunction()
            searchFunc(searchText)
        }
        setCurrentPage(currentPage - 1)
        if (!selectedDraftContract) {
            dispatch(commercialHeadGetDocuments({ contract_name: contractName }))
        }
        else {
            dispatch(commercialHeadGetDocuments({ contract_name: selectedDraftContract.contract_name }))
        }
    }

    const typesOfContracts = [
        {
            name: "All Contracts",
            id: "all"
        },
        {
            name: "Draft Contracts",
            id: "draft"
        },

    ]




    const onchangeContractType = (value) => {
        setContractType(value)
        if (value === "all") {
            const copyContracts = allContracts && allContracts
            setFilteredContracts([...copyContracts])
            setContractsList([...copyContracts])

        }
        else {
            const copyContracts = alldraftContracts && alldraftContracts
            setFilteredContracts([...copyContracts])
            setContractsList([...copyContracts])

        }
    }

    const [selectedDraftContract, setSelectedDraftContract] = useState()



    // ================= Create new Contract Page 1 ================================
    const [contractNumber, setContractNumber] = useState('');
    const [contractName, setContractName] = useState('');
    const [contractStatus, setContractStatus] = useState('pending');
    const [contactName, setContactName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [gstNumber, setGstNumber] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [panName, setPanName] = useState('');
    const [registeredStreetAddress, setRegisteredStreetAddress] = useState('');
    const [registeredStreetAddressLane2, setRegisteredStreetAddressLane2] = useState('');
    const [registeredCity, setRegisteredCity] = useState('');
    const [registeredStateProvince, setRegisteredStateProvince] = useState('');
    const [registeredPostalCode, setRegisteredPostalCode] = useState('');

    // =================Create New Contract Page 2 =========================

    const [billingStreetAddress, setBillingStreetAddress] = useState('');
    const [billingStreetAddressLane2, setBillingStreetAddressLane2] = useState('');
    const [billingCity, setBillingCity] = useState('');
    const [billingStateProvince, setBillingStateProvince] = useState('');
    const [billingPostalCode, setBillingPostalCode] = useState('');

    const [shippingStreetAddress, setShippingStreetAddress] = useState('');
    const [shippingStreetAddressLane2, setShippingStreetAddressLane2] = useState('');
    const [shippingCity, setShippingCity] = useState('');
    const [shippingStateProvince, setShippingStateProvince] = useState('');
    const [shippingPostalCode, setShippingPostalCode] = useState('');

    // ==================== Create New Contract Page 3 ==============

    // const [contractValue, setContractValue] = useState('');


    const [contractBillingType, setContractBillingType] = useState('half yearly');
    const [expireNotification, setExpireNotification] = useState('1 month');
    const [costperUnit, setCostperUnit] = useState('');

    // =================== Create New Contract 4 =================

    const [spaceName, setSpaceName] = useState('');
    const [area, setArea] = useState(0);
    const [electricityLoad, setElectricityLoad] = useState(0);
    const [truckBays, setTruckBays] = useState(0);
    const [twoWheelerParkingSlots, setTwoWheelerParkingSlots] = useState(0);
    const [fourWheelerParkingSlots, setFourWheelerParkingSlots] = useState(0);
    const [licenseFee, setLicenseFee] = useState(0);
    const [escalationRate, setEscalationRate] = useState('');
    const [escalationPeriod, setEscalationPeriod] = useState(0);
    const [securityDeposit, setSecurityDeposit] = useState(0);
    const [dgRate, setDgRate] = useState(0);
    const [hotoStatus, setHotoStatus] = useState('pending')
    const [initialDgReading, setInitialDgReading] = useState(0);


    // ============================ end of input fields ==========================================


    const handleCheckboxChange = (index, field) => {
        const updatedContacts = [...listOfContacts];
        updatedContacts[index][field] = !updatedContacts[index][field] ? 1 : 0;
        setListOfContacts(updatedContacts);
    };

    const [isValidEmail, setIsValidEmail] = useState(true);

    const handleEmailChange = (e) => {
        const input = e.target.value;

        const { processedInput, isValid } = processAndValidateEmail(input, email);

        setEmail(processedInput);
        setIsValidEmail(isValid);
    };

    const [isValidPhone, setIsValidPhone] = useState(true);
    const [isContractNameValid, setIsContractNameValid] = useState([]);

    const [isContactNameValid, setIsContactNameValid] = useState(true)
    const [isPincodeValid, setIsPincodeValid] = useState(true)
    const [isShippingPincodeValid, setIsShippingPincodeValid] = useState(true)
    const [validContractNumber, setValidContractNumber] = useState(true)

    const [isValidGstNumber, setIsValidGstNumber] = useState(true);

    const [isSpaceNameValid, setIsSpaceNameValid] = useState(true);

    const [isCinValid, setIsCinValid] = useState(true);

    const handleCinNumberChange = (e) => {
        handleCinInputChange(e, setCinNumber, setIsCinValid);
    };
    const handleGstNumberChange = (e) => {
        const input = e.target.value;
        const { isValid, uppercasedInput } = validateGstNumber(input);
        setGstNumber(uppercasedInput);
        setIsValidGstNumber(isValid);
    };

    const [editCompleteContractClicked, setEditCompleteContractClicked] = useState(false);
    const [viewCompleteContractClicked, setViewCompleteContractClicked] = useState(false)
    const [selectedIdForDocuments, setSelectedIdForDocuments] = useState();

    const editDraftContract = (contract) => {
        // extractContractNameNumber();
        setIsLoading(true)
        if (contract["draft_id"]) {
            axios.get(base_url + "customers/draftContracts?filter_condition=%7B%22draft_id%22%3A%20%22" + contract["draft_id"] + "%22%7D")
                .then((response) => {
                    if (response?.data?.data[0] === 'error') {
                        setErrorValue(response?.data?.data[1].message)
                        setAlertShow(true)
                        setAlertType('error')
                        setAlertMessage('Error')
                        setAlertValue(response?.data?.data[1].message)
                        setAlertButtons([
                            {
                                name: 'Close', action: () => {
                                    setAlertShow(false)
                                }
                            },
                        ])
                        setIsLoading(false)
                    }
                    else {
                        setIsLoading(false)
                        let contractDetailsInitial = response.data.data
                        const contractDetails = contractDetailsInitial[0]
                        dispatch(commercialHeadGetDocuments({ contract_name: contractDetails.contract_name }))
                        setSelectedDraftContract(contractDetails)
                        // =======================
                        setContractNumber(contractDetails?.contract_number);
                        setSelectedContractNumber(contractDetails?.contract_number);
                        setContractName(contractDetails?.contract_name);
                        setSelectedContractName(contractDetails?.contract_name);
                        setContractStatus(contractDetails?.contract_status);
                        setContactName(contractDetails?.primary_contact_name);
                        setPhoneNumber(contractDetails?.primary_contact_phone_number);
                        setEmail(contractDetails?.primary_contact_email);
                        setCinNumber(contractDetails?.cin);
                        setGstNumber(contractDetails?.gst_number);
                        setCompanyName(contractDetails?.company_name);
                        setPanName(contractDetails?.pan_number);
                        setBillingEntity(contractDetails?.billing_entity);
                        let registeredAddressString = contractDetails?.registered_address;
                        registeredAddressString = registeredAddressString.replace(/'/g, '"');
                        const parsedRegisteredAddress = JSON.parse(registeredAddressString);
                        setRegisteredStreetAddress(parsedRegisteredAddress?.street_address_1);
                        setRegisteredStreetAddressLane2(parsedRegisteredAddress?.street_address_2);
                        setRegisteredCity(parsedRegisteredAddress?.city);
                        setRegisteredStateProvince(parsedRegisteredAddress?.state);
                        setRegisteredPostalCode(parsedRegisteredAddress?.postal_code);

                        // ========================= page 3 ===================
                        let billingAddressString = contractDetails?.billing_address;
                        billingAddressString = billingAddressString.replace(/'/g, '"');
                        const parsedBillingAddress = JSON.parse(billingAddressString);
                        setBillingStreetAddress(parsedBillingAddress?.street_address_1);
                        setBillingStreetAddressLane2(parsedBillingAddress?.street_address_2);
                        setBillingCity(parsedBillingAddress?.city);
                        setBillingStateProvince(parsedBillingAddress?.state);
                        setBillingPostalCode(parsedBillingAddress?.postal_code);
                        let shippingAddressString = contractDetails?.shipping_address;
                        shippingAddressString = shippingAddressString.replace(/'/g, '"');
                        const parseShippingaddress = JSON.parse(shippingAddressString);
                        setShippingStreetAddress(parseShippingaddress?.street_address_1);
                        setShippingStreetAddressLane2(parseShippingaddress?.street_address_2);
                        setShippingCity(parseShippingaddress?.city);
                        setShippingStateProvince(parseShippingaddress?.state);
                        setShippingPostalCode(parseShippingaddress?.postal_code);
                        if (contractDetails?.contacts === "None" || !contractDetails?.contacts) {
                            setListOfContacts([{
                                contactName: "",
                                phoneNumber: "",
                                email: "",
                                designation: "",
                            }]);
                        }
                        else {
                            let contactsString = contractDetails?.contacts;
                            contactsString = contactsString.replace(/'/g, '"');
                            contactsString = contactsString.replace(/True/g, 'true').replace(/False/g, 'false');

                            const parsedContacts = JSON.parse(contactsString);
                            setListOfContacts(parsedContacts)
                            const listOfEmailArray = parsedContacts.map((contact) => contact.email)
                            setListOfContactEmails([...listOfEmailArray])

                            const defaultContactsValidation = Array.from({ length: parsedContacts.length }, () => ({
                                isContactNameValid: true,
                                isDesignationValid: true,
                                isValidPhone: true,
                                isValidEmail: true,
                                isEmailNotDuplicate: true
                            }));
                            setListOfContactsValidation([...defaultContactsValidation])
                        }


                        // ====================== page 4 =====================

                        setContractStartDate(contractDetails?.contract_start_date)
                        setContractEndDate(contractDetails?.contract_end_date)
                        setContractBillingType(contractDetails?.contract_billing_type)
                        setExpireNotification(contractDetails?.notification_before)
                        setGracePeriod(contractDetails?.grace_period)
                        // setListOfMeters(contractDetails?.meters)
                        if (contractDetails?.meters === "None" || !contractDetails?.meters) {
                            setListOfMeters([])
                            setListOfMetersValidation([])
                        }
                        else {
                            let listValue = contractDetails?.meters;
                            listValue = listValue.replace(/'/g, '"');
                            listValue = listValue.replace(/True/g, 'true').replace(/False/g, 'false');
                            let parsedMeters = [];
                            try {
                                parsedMeters = JSON.parse(listValue);
                                setListOfMeters(parsedMeters);
                            } catch (error) {
                                console.error("Failed to parse meters:", error);
                                // Handle the error appropriately, maybe setListOfMeters to an empty array or a default value
                                setListOfMeters([]);
                            }

                            const defaultContactsValidation = Array.from({ length: parsedMeters.length }, () => ({
                                isValidMeterName: true,
                                isValidMeterNumber: true,
                                isvalidMeterType: true,
                            }));
                            setListOfMetersValidation([...defaultContactsValidation])
                        }


                        // ======================= page 5 ================
                        setSpaceName(contractDetails?.space_name)
                        setTermsAndConditions(contractDetails?.terms_and_conditions)
                        setElectricityLoad(contractDetails?.electricity_load)
                        setTruckBays(contractDetails?.truck_bays)
                        setTwoWheelerParkingSlots(contractDetails?.two_wheeler_parking_slots)
                        setFourWheelerParkingSlots(contractDetails?.four_wheeler_parking_slots)
                        setLicenseFee(contractDetails?.license_fee)
                        setEscalationRate(contractDetails?.escalation_rate)
                        setEscalationPeriod(contractDetails?.escalation_period)
                        setSecurityDeposit(contractDetails?.security_deposit)
                        setDgRate(contractDetails?.dg_rate)
                        setHotoStatus(contractDetails?.hoto_status)

                        // ============================functionality below =================
                        setCurrentPage(2)

                        // ===================================slf rates data=====================================
                        let listSlfValue = contractDetails?.slf_rates;
                        listSlfValue = listSlfValue.replace(/'/g, '"');

                        const parsedSlfRates = JSON.parse(listSlfValue);

                        setLicenseTableBodyRows([...parsedSlfRates])
                        if (contractDetails?.contract_start_date && contractDetails?.contract_end_date
                            && contractDetails?.contractStartDate !== "0000-00-00" &&
                            contractDetails?.contract_end_date !== "0000-00-00") {
                            setSlfTableError(licenseFeeDateValidationByStartDateEndDate(parsedSlfRates, contractDetails?.contract_start_date, contractDetails?.contract_end_date))
                        }
                    }
                })
                .catch((error) => {
                    setErrorValue(error?.response?.data.message);
                    setAlertShow(true)
                    setAlertType('error')
                    setAlertMessage('Error')
                    setAlertValue(error?.response?.data.message)
                    setAlertButtons([{
                        name: 'Close', action: () => {
                            setAlertShow(false)
                        }
                    }])
                    setIsLoading(false)
                })
        }
        else {
            setEditCompleteContractClicked(true)
            setSelectedIdForDocuments(contract["contract_id"])
            axios.get(base_url + "customers/contracts?filter_condition=%7B%22contract_id%22%3A%20%22" + contract["contract_id"] + "%22%7D")
                .then((response) => {
                    if (response?.data?.data[0] === 'error') {
                        setErrorValue(response?.data?.data[1].message)
                        setAlertShow(true)
                        setAlertType('error')
                        setAlertMessage('Error')
                        setAlertValue(response?.data?.data[1].message)
                        setAlertButtons([
                            {
                                name: 'Close', action: () => {
                                    setAlertShow(false)
                                }
                            },
                        ])
                        setIsLoading(false)
                    }
                    else {
                        setIsLoading(false)

                        let contractDetailsInitial = response.data.data
                        const contractDetails = contractDetailsInitial
                        dispatch(commercialHeadGetDocuments({ contract_name: contractDetails.contract_name }))

                        setSelectedDraftContract(contractDetails)
                        // =======================
                        setContractNumber(contractDetails?.contract_number);
                        setContractName(contractDetails?.contract_name);
                        setContractStatus(contractDetails?.contract_status);
                        setContactName(contractDetails?.primary_contact_name);
                        setPhoneNumber(contractDetails?.primary_contact_phone_number);
                        setEmail(contractDetails?.primary_contact_email);
                        setCinNumber(contractDetails?.cin);
                        setGstNumber(contractDetails?.gst_number);
                        setCompanyName(contractDetails?.company_name);
                        setPanName(contractDetails?.pan_number);

                        setBillingEntity(contractDetails?.billing_entity);
                        let registeredAddressString = contractDetails?.registered_address;
                        // registeredAddressString = registeredAddressString.replace(/'/g, '"');                    
                        const parsedRegisteredAddress = registeredAddressString;
                        setRegisteredStreetAddress(parsedRegisteredAddress?.street_address_1);
                        setRegisteredStreetAddressLane2(parsedRegisteredAddress?.street_address_2);
                        setRegisteredCity(parsedRegisteredAddress?.city);
                        setRegisteredStateProvince(parsedRegisteredAddress?.state);
                        setRegisteredPostalCode(parsedRegisteredAddress?.postal_code);

                        // ========================= page 3 ===================
                        let billingAddressString = contractDetails?.billing_address;
                        // billingAddressString = billingAddressString.replace(/'/g, '"');                  
                        const parsedBillingAddress = billingAddressString;
                        setBillingStreetAddress(parsedBillingAddress?.street_address_1);
                        setBillingStreetAddressLane2(parsedBillingAddress?.street_address_2);
                        setBillingCity(parsedBillingAddress?.city);
                        setBillingStateProvince(parsedBillingAddress?.state);
                        setBillingPostalCode(parsedBillingAddress?.postal_code);
                        let shippingAddressString = contractDetails?.shipping_address;
                        const parseShippingaddress = shippingAddressString;
                        setShippingStreetAddress(parseShippingaddress?.street_address_1);
                        setShippingStreetAddressLane2(parseShippingaddress?.street_address_2);
                        setShippingCity(parseShippingaddress?.city);
                        setShippingStateProvince(parseShippingaddress?.state);
                        setShippingPostalCode(parseShippingaddress?.postal_code);
                        if (contractDetails?.contacts === "None" || !contractDetails?.contacts) {
                            setListOfContacts([{
                                contactName: "",
                                phoneNumber: "",
                                email: "",
                                designation: "",
                            }]);
                        }
                        else {
                            let contactsString = contractDetails?.contacts;
                            // contactsString = contactsString.replace(/'/g, '"');
                            // contactsString = contactsString.replace(/True/g, 'true').replace(/False/g, 'false');

                            const parsedContacts = contactsString;
                            setListOfContacts(parsedContacts)


                            const listOfEmailArray = parsedContacts.map((contact) => contact.email)
                            setListOfContactEmails([...listOfEmailArray])
                            const defaultContactsValidation = Array.from({ length: parsedContacts.length }, () => ({
                                isContactNameValid: true,
                                isDesignationValid: true,
                                isValidPhone: true,
                                isValidEmail: true,
                                isEmailNotDuplicate: true
                            }));
                            setListOfContactsValidation([...defaultContactsValidation])
                        }


                        // ====================== page 4 =====================

                        setContractStartDate(contractDetails?.contract_start_date)
                        setContractEndDate(contractDetails?.contract_end_date)
                        setContractBillingType(contractDetails?.contract_billing_type)
                        setExpireNotification(contractDetails?.notification_before)
                        setGracePeriod(contractDetails?.grace_period)
                        // setListOfMeters(contractDetails?.meters)

                        if (contractDetails?.meters === "None" || !contractDetails?.meters || contractDetails?.meters.length === 0) {
                            setListOfMeters([])
                            setListOfMetersValidation([])
                        }
                        else {
                            let listValue = contractDetails?.meters;
                            // listValue = listValue.replace(/'/g, '"');
                            // listValue = listValue.replace(/True/g, 'true').replace(/False/g, 'false');

                            const parsedMeters = listValue;
                            setListOfMeters(parsedMeters)

                            const defaultContactsValidation = Array.from({ length: parsedMeters.length }, () => ({
                                isValidMeterName: true,
                                isValidMeterNumber: true,
                                isvalidMeterType: true,
                            }));
                            setListOfMetersValidation([...defaultContactsValidation])
                        }
                        // ======================= page 5 ================
                        setSpaceName(contractDetails?.space_name)
                        setTermsAndConditions(contractDetails?.terms_and_conditions)
                        setElectricityLoad(contractDetails?.electricity_load)
                        setTruckBays(contractDetails?.truck_bays)
                        setTwoWheelerParkingSlots(contractDetails?.two_wheeler_parking_slots)
                        setFourWheelerParkingSlots(contractDetails?.four_wheeler_parking_slots)
                        setLicenseFee(contractDetails?.license_fee)
                        setEscalationRate(contractDetails?.escalation_rate)
                        setEscalationPeriod(contractDetails?.escalation_period)
                        setSecurityDeposit(contractDetails?.security_deposit)
                        setDgRate(contractDetails?.dg_rate)

                        setHotoStatus(contractDetails?.hoto_status)
                        let listSlfValue = contractDetails?.slf_rates;
                        setLicenseTableBodyRows([...listSlfValue])
                        setSlfTableError(licenseFeeDateValidationByStartDateEndDate(listSlfValue, contractDetails?.contract_start_date, contractDetails?.contract_end_date))
                        // ============================functionality below =================

                        setCurrentPage(2)

                    }
                })
                .catch((error) => {
                    setErrorValue(error?.response?.data.message);
                    setAlertShow(true)
                    setAlertType('error')
                    setAlertMessage('Error')
                    setAlertValue(error?.response?.data.message)
                    setAlertButtons([{
                        name: 'Close', action: () => {
                            setAlertShow(false)
                        }
                    }])
                    setIsLoading(false)

                });
        }
    }

    const viewSelectedContract = (contract) => {
        setViewClicked(true)
        setIsLoading(true)
        if (contract["draft_id"]) {
            axios.get(base_url + "customers/draftContracts?filter_condition=%7B%22draft_id%22%3A%20%22" + contract["draft_id"] + "%22%7D")
                .then((response) => {
                    if (response?.data?.data[0] === 'error') {
                        setErrorValue(response?.data?.data[1].message)
                        setAlertShow(true)
                        setAlertType('error')
                        setAlertMessage('Error')
                        setAlertValue(response?.data?.data[1].message)
                        setAlertButtons([
                            {
                                name: 'Close', action: () => {
                                    setAlertShow(false)
                                }
                            },
                        ])
                        setIsLoading(false)
                    }
                    else {
                        setIsLoading(false)
                        let contractDetailsInitial = response.data.data
                        const contractDetails = contractDetailsInitial[0]
                        dispatch(commercialHeadGetDocuments({ contract_name: contractDetails.contract_name }))
                        setBillingEntity(contractDetails.billing_entity)
                        setSelectedDraftContract(contractDetails)
                        // =======================
                        setContractNumber(contractDetails?.contract_number);
                        setContractName(contractDetails?.contract_name);
                        setContractStatus(contractDetails?.contract_status);
                        setContactName(contractDetails?.primary_contact_name);
                        setPhoneNumber(contractDetails?.primary_contact_phone_number);
                        setEmail(contractDetails?.primary_contact_email);
                        setCinNumber(contractDetails?.cin);
                        setGstNumber(contractDetails?.gst_number);
                        setCompanyName(contractDetails?.company_name);
                        setPanName(contractDetails?.pan_number);
                        let registeredAddressString = contractDetails?.registered_address;
                        registeredAddressString = registeredAddressString.replace(/'/g, '"');
                        const parsedRegisteredAddress = JSON.parse(registeredAddressString);
                        setRegisteredStreetAddress(parsedRegisteredAddress?.street_address_1);
                        setRegisteredStreetAddressLane2(parsedRegisteredAddress?.street_address_2);
                        setRegisteredCity(parsedRegisteredAddress?.city);
                        setRegisteredStateProvince(parsedRegisteredAddress?.state);
                        setRegisteredPostalCode(parsedRegisteredAddress?.postal_code);

                        // ========================= page 3 ===================
                        let billingAddressString = contractDetails?.billing_address;
                        billingAddressString = billingAddressString.replace(/'/g, '"');
                        const parsedBillingAddress = JSON.parse(billingAddressString);
                        setBillingStreetAddress(parsedBillingAddress?.street_address_1);
                        setBillingStreetAddressLane2(parsedBillingAddress?.street_address_2);
                        setBillingCity(parsedBillingAddress?.city);
                        setBillingStateProvince(parsedBillingAddress?.state);
                        setBillingPostalCode(parsedBillingAddress?.postal_code);
                        let shippingAddressString = contractDetails?.shipping_address;
                        shippingAddressString = shippingAddressString.replace(/'/g, '"');
                        const parseShippingaddress = JSON.parse(shippingAddressString);
                        setShippingStreetAddress(parseShippingaddress?.street_address_1);
                        setShippingStreetAddressLane2(parseShippingaddress?.street_address_2);
                        setShippingCity(parseShippingaddress?.city);
                        setShippingStateProvince(parseShippingaddress?.state);
                        setShippingPostalCode(parseShippingaddress?.postal_code);
                        if (contractDetails?.contacts === "None" || !contractDetails?.contacts) {
                            setListOfContacts([{
                                contactName: "",
                                phoneNumber: "",
                                email: "",
                                designation: "",
                            }]);
                        }
                        else {
                            let contactsString = contractDetails?.contacts;
                            contactsString = contactsString.replace(/'/g, '"');
                            contactsString = contactsString.replace(/True/g, 'true').replace(/False/g, 'false');

                            const parsedContacts = JSON.parse(contactsString);
                            setListOfContacts(parsedContacts)

                            const defaultContactsValidation = Array.from({ length: parsedContacts.length }, () => ({
                                isContactNameValid: true,
                                isDesignationValid: true,
                                isValidPhone: true,
                                isValidEmail: true,
                                isEmailNotDuplicate: true
                            }));
                            setListOfContactsValidation([...defaultContactsValidation])
                        }


                        // ====================== page 4 =====================

                        setContractStartDate(contractDetails?.contract_start_date)
                        setContractEndDate(contractDetails?.contract_end_date)
                        setContractBillingType(contractDetails?.contract_billing_type)
                        setExpireNotification(contractDetails?.notification_before)
                        setGracePeriod(contractDetails?.grace_period)
                        // setListOfMeters(contractDetails?.meters)

                        if (contractDetails?.meters === "None" || !contractDetails?.meters) {
                            setListOfMeters([])
                        }
                        else {
                            let listValue = contractDetails?.meters;
                            listValue = listValue.replace(/'/g, '"');
                            listValue = listValue.replace(/True/g, 'true').replace(/False/g, 'false');

                            const parsedMeters = JSON.parse(listValue);
                            setListOfMeters(parsedMeters)

                            const defaultContactsValidation = Array.from({ length: parsedMeters.length }, () => ({
                                isValidMeterName: true,
                                isValidMeterNumber: true,
                                isvalidMeterType: true,
                            }));
                            setListOfMetersValidation([...defaultContactsValidation])
                        }



                        // ======================= page 5 ================
                        setSpaceName(contractDetails?.space_name)
                        setTermsAndConditions(contractDetails?.terms_and_conditions)
                        setElectricityLoad(contractDetails?.electricity_load)
                        setTruckBays(contractDetails?.truck_bays)
                        setTwoWheelerParkingSlots(contractDetails?.two_wheeler_parking_slots)
                        setFourWheelerParkingSlots(contractDetails?.four_wheeler_parking_slots)
                        setLicenseFee(contractDetails?.license_fee)
                        setEscalationRate(contractDetails?.escalation_rate)
                        setEscalationPeriod(contractDetails?.escalation_period)
                        setSecurityDeposit(contractDetails?.security_deposit)
                        setDgRate(contractDetails?.dg_rate)

                        setHotoStatus(contractDetails?.hoto_status)
                        // ============================functionality below =================

                        // ===================================slf rates data=====================================
                        let listSlfValue = contractDetails?.slf_rates;
                        listSlfValue = listSlfValue.replace(/'/g, '"');

                        const parsedSlfRates = JSON.parse(listSlfValue);
                        setLicenseTableBodyRows([...parsedSlfRates])
                        setSlfTableError(licenseFeeDateValidationByStartDateEndDate(parsedSlfRates, contractDetails?.contract_start_date, contractDetails?.contract_end_date))

                        setCurrentPage(2)

                    }
                })
                .catch((error) => {
                    setErrorValue(error?.response?.data.message);
                    setAlertShow(true)
                    setAlertType('error')
                    setAlertMessage('Error')
                    setAlertValue(error?.response?.data.message)
                    setAlertButtons([{
                        name: 'Close', action: () => {
                            setAlertShow(false)
                        }
                    }])
                    setIsLoading(false)
                });
        }
        else {
            setSelectedIdForDocuments(contract["contract_id"])
            setViewCompleteContractClicked(true)
            axios.get(base_url + "customers/contracts?filter_condition=%7B%22contract_id%22%3A%20%22" + contract["contract_id"] + "%22%7D")
                .then((response) => {
                    if (response?.data?.data[0] === 'error') {
                        setErrorValue(response?.data?.data[1].message)
                        setAlertShow(true)
                        setAlertType('error')
                        setAlertMessage('Error')
                        setAlertValue(response?.data?.data[1].message)
                        setAlertButtons([
                            {
                                name: 'Close', action: () => {
                                    setAlertShow(false)
                                }
                            },
                        ])
                        setIsLoading(false)
                    }
                    else {
                        setIsLoading(false)
                        let contractDetailsInitial = response.data.data
                        const contractDetails = contractDetailsInitial
                        dispatch(commercialHeadGetDocuments({ contract_name: contractDetails.contract_name }))
                        setBillingEntity(contractDetails.billing_entity)
                        setSelectedDraftContract(contractDetails)
                        // =======================
                        setContractNumber(contractDetails?.contract_number);
                        setContractName(contractDetails?.contract_name);
                        setContractStatus(contractDetails?.contract_status);
                        setContactName(contractDetails?.primary_contact_name);
                        setPhoneNumber(contractDetails?.primary_contact_phone_number);
                        setEmail(contractDetails?.primary_contact_email);
                        setCinNumber(contractDetails?.cin);
                        setGstNumber(contractDetails?.gst_number);
                        setCompanyName(contractDetails?.company_name);
                        setPanName(contractDetails?.pan_number);


                        let registeredAddressString = contractDetails?.registered_address;
                        // registeredAddressString = registeredAddressString.replace(/'/g, '"');                    
                        const parsedRegisteredAddress = registeredAddressString;
                        setRegisteredStreetAddress(parsedRegisteredAddress?.street_address_1);
                        setRegisteredStreetAddressLane2(parsedRegisteredAddress?.street_address_2);
                        setRegisteredCity(parsedRegisteredAddress?.city);
                        setRegisteredStateProvince(parsedRegisteredAddress?.state);
                        setRegisteredPostalCode(parsedRegisteredAddress?.postal_code);

                        // ========================= page 3 ===================
                        let billingAddressString = contractDetails?.billing_address;
                        // billingAddressString = billingAddressString.replace(/'/g, '"');                  
                        const parsedBillingAddress = billingAddressString;
                        setBillingStreetAddress(parsedBillingAddress?.street_address_1);
                        setBillingStreetAddressLane2(parsedBillingAddress?.street_address_2);
                        setBillingCity(parsedBillingAddress?.city);
                        setBillingStateProvince(parsedBillingAddress?.state);
                        setBillingPostalCode(parsedBillingAddress?.postal_code);
                        let shippingAddressString = contractDetails?.shipping_address;
                        const parseShippingaddress = shippingAddressString;
                        setShippingStreetAddress(parseShippingaddress?.street_address_1);
                        setShippingStreetAddressLane2(parseShippingaddress?.street_address_2);
                        setShippingCity(parseShippingaddress?.city);
                        setShippingStateProvince(parseShippingaddress?.state);
                        setShippingPostalCode(parseShippingaddress?.postal_code);
                        if (contractDetails?.contacts === "None" || !contractDetails?.contacts) {
                            setListOfContacts([{
                                contactName: "",
                                phoneNumber: "",
                                email: "",
                                designation: "",
                            }]);
                        }
                        else {
                            let contactsString = contractDetails?.contacts;
                            const parsedContacts = contactsString;
                            setListOfContacts(parsedContacts)

                            const defaultContactsValidation = Array.from({ length: parsedContacts.length }, () => ({
                                isContactNameValid: true,
                                isDesignationValid: true,
                                isValidPhone: true,
                                isValidEmail: true,
                                isEmailNotDuplicate: true
                            }));
                            setListOfContactsValidation([...defaultContactsValidation])
                        }


                        // ====================== page 4 =====================

                        setContractStartDate(contractDetails?.contract_start_date)
                        setContractEndDate(contractDetails?.contract_end_date)
                        setContractBillingType(contractDetails?.contract_billing_type)
                        setExpireNotification(contractDetails?.notification_before)
                        setGracePeriod(contractDetails?.grace_period)

                        if (contractDetails?.meters === "None" || !contractDetails?.meters) {
                            setListOfMeters([])
                            setListOfMetersValidation([])
                        }
                        else {
                            let listValue = contractDetails?.meters;
                            const parsedMeters = listValue;
                            setListOfMeters(parsedMeters)

                            const defaultContactsValidation = Array.from({ length: parsedMeters.length }, () => ({
                                isValidMeterName: true,
                                isValidMeterNumber: true,
                                isvalidMeterType: true,
                            }));
                            setListOfMetersValidation([...defaultContactsValidation])
                        }
                        // ======================= page 5 ================
                        setSpaceName(contractDetails?.space_name)
                        setTermsAndConditions(contractDetails?.terms_and_conditions)
                        setElectricityLoad(contractDetails?.electricity_load)
                        setTruckBays(contractDetails?.truck_bays)
                        setTwoWheelerParkingSlots(contractDetails?.two_wheeler_parking_slots)
                        setFourWheelerParkingSlots(contractDetails?.four_wheeler_parking_slots)
                        setLicenseFee(contractDetails?.license_fee)
                        setEscalationRate(contractDetails?.escalation_rate)
                        setEscalationPeriod(contractDetails?.escalation_period)
                        setSecurityDeposit(contractDetails?.security_deposit)
                        setDgRate(contractDetails?.dg_rate)

                        setHotoStatus(contractDetails?.hoto_status)
                        let listSlfValue = contractDetails?.slf_rates;
                        setLicenseTableBodyRows([...listSlfValue])
                        setSlfTableError(licenseFeeDateValidationByStartDateEndDate(listSlfValue, contractDetails?.contract_start_date, contractDetails?.contract_end_date))

                        // ============================functionality below =================

                        setCurrentPage(2)

                    }
                })
                .catch((error) => {
                    setErrorValue(error?.response?.data.message);
                    setAlertShow(true)
                    setAlertType('error')
                    setAlertMessage('Error')
                    setAlertValue(error?.response?.data.message)
                    setAlertButtons([{
                        name: 'Close', action: () => {
                            setAlertShow(false)
                        }
                    }])
                    setIsLoading(false)
                });
        }
    }

    const [listOfContactEmails, setListOfContactEmails] = useState([]);

    const onEmailInputBlur = (e) => {
        const emailArray = []
        listOfContacts.forEach((contact) => {
            if (!emailArray.includes(contact.email) && contact.email !== "") {
                emailArray.push(contact.email)
            }
        })
        setListOfContactEmails([...emailArray])
    }


    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [draftIdDelete, setDraftIdDelete] = useState(null);
    const [draftContractNumberDelete, setDraftContractNumberDelete] = useState(null)
    const handleDeleteClick = (each) => {
        setDraftContractNumberDelete(each.contract_name)
        setDraftIdDelete(each.draft_id);
        setShowDeleteModal(true);
    };
    const handleProceedClick = () => {
        axios
            .delete(base_url + "customers/draftContracts",
                {
                    params: {
                        "draft_id": draftIdDelete,
                    },
                })
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    setErrorValue(response?.data?.data[1].message)
                    setAlertShow(true)
                    setAlertType('error')
                    setAlertMessage('Error')
                    setAlertValue(response?.data?.data[1].message)
                    setAlertButtons([
                        {
                            name: 'Close', action: () => {
                                setAlertShow(false)
                            }
                        },
                    ])
                    setIsLoading(false)
                }
                else {
                    dispatch(commercialHeadGetDraftContracts({ facility_id: localStorage.getItem("selected_facility_id"), serach_text: searchText, items_per_page: RECORDS_PER_PAGE, page_number: currentPaginationPageDc }));
                    dispatch(commercialHeadGetContractsForDropdown());
                }
            })
            .catch((error) => {
                setErrorValue(error?.response?.data.message);
                setAlertShow(true)
                setAlertType('error')
                setAlertMessage('Error')
                setAlertValue(error?.response?.data.message)
                setAlertButtons([{
                    name: 'Close', action: () => {
                        setAlertShow(false)
                    }
                }])
                setIsLoading(false)
            });
        setShowDeleteModal(false);
    };
    const handleCancelClick = () => {
        setShowDeleteModal(false);
    };

    const sortCompanyName = () => {
        let sortedList = filteredContracts.sort((a, b) => {
            const nameA = a.company_name.toLowerCase();
            const nameB = b.company_name.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        if (companyNameFilter) {
            setCompanyNameFilter(!companyNameFilter)
            sortedList.reverse()
        } else {
            setCompanyNameFilter(!companyNameFilter)
        }
        setFilteredContracts([...sortedList])
    }
    const sortContractName = () => {
        let sortedList = filteredContracts.sort((a, b) => {
            const nameA = a.contract_name.toLowerCase();
            const nameB = b.contract_name.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        if (companyNameFilter) {
            setCompanyNameFilter(!companyNameFilter)
            sortedList.reverse()
        } else {
            setCompanyNameFilter(!companyNameFilter)
        }
        setFilteredContracts([...sortedList])
    }

    const sortSpaceName = () => {
        let sortedList = filteredContracts.sort((a, b) => {
            const nameA = a.space_name.toLowerCase();
            const nameB = b.space_name.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        if (companyNameFilter) {
            setCompanyNameFilter(!companyNameFilter)
            sortedList.reverse()
        } else {
            setCompanyNameFilter(!companyNameFilter)
        }
        setFilteredContracts([...sortedList])
    }
    const sortContractNumber = () => {
        let sortedList = filteredContracts.sort((a, b) => {
            const nameA = a.contract_number.toLowerCase();
            const nameB = b.contract_number.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        if (companyNameFilter) {
            setCompanyNameFilter(!companyNameFilter)
            sortedList.reverse()
        } else {
            setCompanyNameFilter(!companyNameFilter)
        }
        setFilteredContracts([...sortedList])
    }

    const sortContractType = () => {
        let sortedList = filteredContracts.sort((a, b) => {
            const nameA = a.contract_billing_type.toLowerCase();
            const nameB = b.contract_billing_type.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        if (companyNameFilter) {
            setCompanyNameFilter(!companyNameFilter)
            sortedList.reverse()
        } else {
            setCompanyNameFilter(!companyNameFilter)
        }
        setFilteredContracts([...sortedList])
    }
    const sortEmail = () => {
        let sortedList = filteredContracts.sort((a, b) => {
            const nameA = a.primary_contact_email.toLowerCase();
            const nameB = b.primary_contact_email.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        if (companyNameFilter) {
            setCompanyNameFilter(!companyNameFilter)
            sortedList.reverse()
        } else {
            setCompanyNameFilter(!companyNameFilter)
        }
        setFilteredContracts([...sortedList])
    }

    const sortStatus = () => {
        let sortedList = filteredContracts.sort((a, b) => {
            const nameA = a.contract_status;
            const nameB = b.contract_status;
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        if (companyNameFilter) {
            setCompanyNameFilter(!companyNameFilter)
            sortedList.reverse()
        } else {
            setCompanyNameFilter(!companyNameFilter)
        }
        setFilteredContracts([...sortedList])
    }

    const sortUpdatedDate = () => {
        let sortedList = filteredContracts.sort((a, b) => {
            const nameA = a.updated_at.split(" ")[0];
            const nameB = b.updated_at.split(" ")[0];
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        if (companyNameFilter) {
            setCompanyNameFilter(!companyNameFilter)
            sortedList.reverse()
        } else {
            setCompanyNameFilter(!companyNameFilter)
        }
        setFilteredContracts([...sortedList])

    }
    function printMousePos(event) {
        const MousePositionY = event.clientY;
        if (MousePositionY < 450) {
            setPopOverPosition('bottom')
        }
        else {
            setPopOverPosition('top')
        }
    }

    const handleGstClick = (gstId) => {
        setGstLoader(true)
        axios.get(base_url + "gstPortal/verifyGstin?gst_number=" + gstId)
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    setErrorValue(response?.data?.data[1].message)
                    setAlertShow(true)
                    setAlertType('error')
                    setAlertMessage('Error')
                    setAlertValue(response?.data?.data[1].message)
                    setAlertButtons([
                        {
                            name: 'Close', action: () => {
                                setAlertShow(false)
                            }
                        },
                    ])
                    setIsLoading(false)
                    setGstLoader(false);
                }
                else {
                    const details = response.data.data
                    setCompanyName(details?.company_name)
                    setPanName(details?.pan_number)
                    setRegisteredStateProvince(details?.registered_address?.state)
                    setRegisteredPostalCode(details?.registered_address?.postal_code)
                    setRegisteredStreetAddress(details?.registered_address?.door_number + details?.registered_address?.street_address_1)
                    setRegisteredStreetAddressLane2(details?.registered_address?.street_address_2)
                    setRegisteredCity(details?.registered_address?.city)

                    setBillingStateProvince(details?.registered_address?.state);
                    // setBillingPostalCode(details?.registered_address?.postal_code);
                    setShippingStateProvince(details?.registered_address?.state);
                    // setShippingPostalCode(details?.registered_address?.postal_code);
                    setGstLoader(false)
                }
            })
            .catch((error) => {
                const errorDetails = JSON.parse(error.response.data.error_logs.status_desc);
                const errorMessage = errorDetails[0].ErrorMessage
                setErrorValue(errorMessage);
                setAlertShow(true);
                setAlertType('error');
                setAlertMessage('Error');
                setAlertValue(errorMessage);
                setAlertButtons([
                    {
                        name: 'Close',
                        action: () => {
                            setAlertShow(false);
                        }
                    }
                ]);
                setIsLoading(false);
                setGstLoader(false);
            });
    }

    const clearValidationErrors = () => {
        // current page 2
        setIsContractNameValid(true)
        setIsCinValid(true)
        setDuplicateContractNameError(false)
        setDuplicateContractNumberError(false)
        setSelectedContractName('')
        setSelectedContractNumber('')
        setIsContactNameValid(true)
        setIsValidPhone(true)
        setIsValidEmail(true)
        setIsPincodeValid(true)
        setIsShippingPincodeValid(true)
        setIsValidGstNumber(true)
        // current page 4
        setIsContractNumberValid(true)
        setIsSpaceNameValid(true)

    }

    const checkSaveandContinueValidation = () => {
        if (currentPage === 2) {
            const isValid = !isContactNameValid || duplicateContractNameError || contractName === '' || duplicateContractNumberError ||
                !isContactNameValid || !isValidEmail || !isValidGstNumber
            return isValid
        }
        if (currentPage === 3) {
            const isValid = !isContactNameValid || duplicateContractNameError || contractName === '' || duplicateContractNumberError ||
                !isContactNameValid || !isValidEmail || !isValidGstNumber || !isPincodeValid || !isShippingPincodeValid
            const isValidContactList = listOfContactsValidation.some(e =>
                e.isContactNameValid === false ||
                e.isDesignationValid === false ||
                e.isValidPhone === false ||
                e.isValidEmail === false
            );
            return (isValid || isValidContactList)
        }
        if (currentPage === 4) {
            const isValid = !isContactNameValid || duplicateContractNameError || contractName === '' || duplicateContractNumberError ||
                !isContactNameValid || !isValidEmail || !isValidGstNumber || dateError || endDateError
            const isValidContactList = listOfContactsValidation.some(e =>
                e.isContactNameValid === false ||
                e.isDesignationValid === false ||
                e.isValidPhone === false ||
                e.isValidEmail === false
            );
            const isValidMeterList = listOfMetersValidtaion.some(e =>
                e.isValidMeterName === false ||
                e.isValidMeterNumber === false ||
                e.isvalidMeterType === false
            );

            return (isValid || isValidContactList || isValidMeterList)
        }
    }
    const checkSaveDraftValidation = () => {
        const isValid = !isContactNameValid || duplicateContractNameError || contractName === '' || duplicateContractNumberError ||
            !isContactNameValid || !isValidEmail || !isValidGstNumber || !billingEntity ||
            !isSpaceNameValid || !isPincodeValid || !validContractNumber || !isShippingPincodeValid


        const isValidContactList = listOfContactsValidation.some(e =>
            e.isContactNameValid === false ||
            e.isDesignationValid === false ||
            e.isValidPhone === false ||
            e.isValidEmail === false
        );
        const isValidMeterList = listOfMetersValidtaion.some(e =>
            e.isValidMeterName === false ||
            e.isValidMeterNumber === false ||
            e.isvalidMeterType === false
        );
        // license fee detials validation are still yet to be implemented
        return (isValid || isValidContactList || isValidMeterList)
    }

    const contactValidationsFunc = () => {
        const isValidContactListValidations = listOfContactsValidation.some(e =>
            e.isContactNameValid === false ||
            e.isDesignationValid === false ||
            e.isValidPhone === false ||
            e.isValidEmail === false
        );
        const isValidContactList = listOfContacts.some(e =>
            !e.name ||
            // !e.phone_number ||
            !e.email
            // ||
            // !e.designation 
        );
        return !isValidContactList && !isValidContactListValidations
    }

    const meterValidationsFunc = () => {
        const isValidMeterListValidations = listOfMetersValidtaion.some(e =>
            e.isValidMeterName === false ||
            e.isValidMeterNumber === false ||
            e.isvalidMeterType === false
        );
        const isValidMeterList = listOfMeters.some(e =>
            !e.assigned_name ||
            !e.meter_number ||
            !e.meter_type
        );
        const isInitialsValid = listOfMeters.some(e => {
            if (e.meter_type === "electricity") {
                return (
                    e.initial_reading === null || e.initial_reading < 0 || e.initial_reading === ''
                    || e.initial_dg_reading < 0 || e.initial_dg_reading === '' || e.initial_dg_reading === null
                )
            } else {
                return (
                    e.initial_reading === null || e.initial_reading < 0 || e.initial_reading === ''
                )
            }
        }
        );
        const lenghtOfMeterList = listOfMeters.length !== 0
        return !isValidMeterList && !isValidMeterListValidations && !isInitialsValid
        //  && lenghtOfMeterList
    }

    const slfValidationsFunc = () => {
        const isLicenseFeeValid = licenseTableBodyRows.some(e =>
            // e.area_in_sqft === '' ||
            // e.cost_per_unit === '' ||
            e.rate_start_date === '' ||
            e.rate_end_date === '' ||
            e.license_fee === '' ||
            Number.isNaN(Number(e.license_fee))
            // ||
            // !e.area_in_sqft ||
            // !e.cost_per_unit
        )
        return isLicenseFeeValid || licenseTableBodyRows.length === 0
    }
    const toTitleCase = (value) => {
        return value.split(' ').map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }).join(' ');
    }

    // =================================File operations ======================
    const [uploadedFile, setUploadedFile] = useState(null);
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setUploadedFile(file)
        const reader = new FileReader();
        reader.onloadend = function () {
            dispatch(commercialHeadUploadDocument(
                {
                    files: [
                        {
                            file_content: reader.result.split(",")[1],
                            object_type: "contracts",
                            file_type: file.type.split("/")[1],
                            file_name: file.name,
                            contract_name: contractName,
                            flag: "duplicate"
                        }
                    ]
                }
            )

            )
        }
        reader.readAsDataURL(file);
    }
    const [uploadedFiles, setUploadedFiles] = useState();
    const [contractDocumentsList, setContractDocumentsList] = useState([]);

    const handleFilesSelected = (files) => {
        if (selectedDraftContract) {
            const processedFiles = [];
            files.forEach((file) => {
                const reader = new FileReader();

                reader.onloadend = function () {
                    const base64FileContent = reader.result.split(",")[1];
                    // const required_id = selectedDraftContract && selectedDraftContract.draft_id
                    const processedFile = {
                        file_content: base64FileContent,
                        object_type: "drafts",
                        file_description: file.description,
                        file_type: file.file.type.split("/")[1],
                        file_name: file.file.name,
                        // draft_id:required_id,
                        contract_name: selectedDraftContract && selectedDraftContract.contract_name,
                        file_size: file.file.size,
                        flag: file.flag,
                        user_id: localStorage.getItem("username"),
                    };
                    processedFiles.push(processedFile);
                    if (processedFiles.length === files.length) {
                        setUploadedFiles(processedFiles);
                    }
                };

                reader.readAsDataURL(file.file);
            });

        }
        else if (!selectedDraftContract && newContractId) {
            const processedFiles = [];
            files.forEach((file) => {
                const reader = new FileReader();

                reader.onloadend = function () {
                    const base64FileContent = reader.result.split(",")[1];
                    const required_id = newContractId
                    const processedFile = {
                        file_content: base64FileContent,
                        object_type: "drafts",
                        file_description: file.description,
                        file_type: file.file.type.split("/")[1],
                        file_name: file.file.name,
                        draft_id: required_id,
                        contract_name: contractName,
                        file_size: file.file.size,
                        flag: null,
                        user_id: localStorage.getItem("username"),
                    };
                    processedFiles.push(processedFile);
                    if (processedFiles.length === files.length) {
                        setUploadedFiles(processedFiles);
                    }
                };

                reader.readAsDataURL(file.file);
            });


        }
        else {
            const processedFiles = [];
            files.forEach((file) => {
                const reader = new FileReader();

                reader.onloadend = function () {
                    const base64FileContent = reader.result.split(",")[1];
                    const processedFile = {
                        file_content: base64FileContent,
                        object_type: "contracts",
                        file_description: file.description,
                        file_type: file.file.type.split("/")[1],
                        file_name: file.file.name,
                        contract_id: selectedDraftContract.contract_id,
                        file_size: file.file.size,
                        flag: null,
                        user_id: localStorage.getItem("username"),
                    };
                    processedFiles.push(processedFile);
                    if (processedFiles.length === files.length) {
                        setUploadedFiles(processedFiles);
                    }
                };

                reader.readAsDataURL(file.file);
            });
        }

    };
    const handleDocumentUpload = (index) => {
        dispatch(handleDocumentLoaderTrue())
        dispatch(commercialHeadUploadDocument(
            {
                files: [uploadedFiles[index]]
            }
        ))
    }
    const [showConfirmDeleteModel, setConfirmDeleteModel] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState();

    const handleCloseConfirmDeleteModal = () => {
        setConfirmDeleteModel(false);
    };

    const confirmDeleteDocument = (index) => {
        setConfirmDeleteModel(true);
        setSelectedIndex(index);
    }

    const handleDocumentDelete = () => {
        setConfirmDeleteModel(false)
        dispatch(handleDocumentLoaderTrue())
        if (documentList.length > selectedIndex) {
            axios.delete(base_url + `documents`,
                {
                    data: {
                        s3_keys: [documentList[selectedIndex]?.key],

                    }
                }
            )
                .then((response) => {
                    if (response?.data?.data[0] === 'error') {
                        setErrorValue(response?.data?.data[1].message)
                        setAlertShow(true)
                        setAlertType('error')
                        setAlertMessage('Error')
                        setAlertValue(response?.data?.data[1].message)
                        setAlertButtons([
                            {
                                name: 'Close', action: () => {
                                    setAlertShow(false)
                                }
                            },
                        ])
                        setIsLoading(false)
                    }
                    else {
                        dispatch(handleDocumentLoaderFalse())
                        let newDocumentList = [...documentList];
                        newDocumentList.splice(selectedIndex, 1);
                        setDocumentList(newDocumentList);
                    }
                })
                .catch((error) => {
                    handleDocumentLoaderFalse()
                    setErrorValue(error?.response?.data.message);
                    setAlertShow(true)
                    setAlertType('error')
                    setAlertMessage('Error')
                    setAlertValue(error?.response?.data.message)
                    setAlertButtons([{
                        name: 'Close', action: () => {
                            setAlertShow(false)
                        }
                    }])
                    setIsLoading(false)
                })
        }
        else {
            setConfirmDeleteModel(false)
        }

    }

    const handleErrorFromDocument = (value) => {
        setErrorValue(value)
    }

    const isSubmitActive = () => {

        if (
            // ================ Page 1 validations =================
            contractName && isContractNameValid
            && contractStatus && billingEntity && billingEntity !== ""
            && contactName && isContactNameValid
            && email && isValidEmail &&
            contractNumber && isContractNumberValid &&
            gstNumber && isValidGstNumber
            && companyName && panName && registeredStreetAddress &&
            registeredStreetAddressLane2 && registeredCity && registeredStateProvince && registeredPostalCode

            // && cinNumber 
            && isCinValid
            // ================== Page 2 validations =================
            && billingStreetAddress && billingStreetAddressLane2 && billingCity
            && billingStateProvince && billingPostalCode && isPincodeValid && shippingStreetAddress && shippingStreetAddressLane2 && shippingCity
            && shippingStateProvince && shippingPostalCode && isShippingPincodeValid &&
            contactValidationsFunc()
            // ===================== Page 3 validations =================
            && contractStartDate && contractStartDate !== '0000-00-00' &&
            contractEndDate && contractEndDate !== '0000-00-00' && contractBillingType && expireNotification &&
            meterValidationsFunc() &&
            // listOfMeters.length > 0 &&
            gracePeriod >= 0 &&
            // grace period missing
            // ======================== Page 4 validations =================
            spaceName && electricityLoad >= 0 && slfTableError?.error === false && fourWheelerParkingSlots != null && twoWheelerParkingSlots != null &&
            dgRate >= 0 && truckBays >= 0 && twoWheelerParkingSlots >= 0 && fourWheelerParkingSlots >= 0 &&
            !slfValidationsFunc() && securityDeposit >= 0 && termsAndConditions && !slfTableError?.error

        ) {
            return true
        }
        else {
            return false
        }
    }

    const checkMeterIntialValidation = () => {
        const isInitialsValid = listOfMeters.some(e => {
            if (e.meter_type === "electricity") {
                return (
                    !e.initial_reading || e.initial_reading < 0 || e.initial_reading === ''
                    || e.initial_dg_reading < 0 || e.initial_dg_reading === '' || e.initial_dg_reading === null
                )
            } else {
                return (
                    !e.initial_reading || e.initial_reading < 0 || e.initial_reading === ''
                )
            }
        }
        );
        return isInitialsValid
    }

    const handledPaginationNextClicked = () => {
        setIsLoading(true)
        if (contractType === "all") {
            handlePaginationLoaderTrue()
            dispatch(commercialHeadGetContracts({ facility_id: localStorage.getItem("selected_facility_id"), serach_text: searchText, items_per_page: RECORDS_PER_PAGE, page_number: currentPaginationPageAc + 1 }))
        }
        else {
            handlePaginationLoaderTrue()
            dispatch(commercialHeadGetDraftContracts({ facility_id: localStorage.getItem("selected_facility_id"), serach_text: searchText, items_per_page: RECORDS_PER_PAGE, page_number: currentPaginationPageDc + 1 }))
        }
    }

    const handledPaginationPrevClicked = () => {
        setIsLoading(true)
        if (contractType === "all") {
            dispatch(commercialHeadGetContracts({ facility_id: localStorage.getItem("selected_facility_id"), serach_text: searchText, items_per_page: RECORDS_PER_PAGE, page_number: currentPaginationPageAc - 1 }))
        }
        else {
            dispatch(commercialHeadGetDraftContracts({ facility_id: localStorage.getItem("selected_facility_id"), serach_text: searchText, items_per_page: RECORDS_PER_PAGE, page_number: currentPaginationPageDc - 1 }))
        }

    }


    const validateErrorMessageForSteps = (index) => {

    }

    const checkmarkVisibilityCalculationPage2 = () => {
        return (contractName && isContractNameValid && contractStatus && contactName && billingEntity && billingEntity !== ""
            && isContactNameValid && email && isValidEmail &&
            email && gstNumber && companyName && panName && registeredStreetAddress &&
            registeredStreetAddressLane2 && contractNumber && isContractNumberValid &&
            registeredCity && registeredStateProvince && registeredPostalCode &&
            isValidEmail && isValidGstNumber
            && isCinValid)
    }

    const checkmarkVisibilityCalculationPage3 = () => {
        return (billingStreetAddress && billingStreetAddressLane2 && billingCity &&
            billingStateProvince && billingPostalCode && isPincodeValid && shippingStreetAddress && shippingStreetAddressLane2 && shippingCity
            && shippingStateProvince && shippingPostalCode && isShippingPincodeValid &&
            contactValidationsFunc())

    }

    const checkmarkVisibilityCalculationPage4 = () => {
        return (contractStartDate && contractStartDate !== '0000-00-00' &&
            contractEndDate && contractEndDate !== '0000-00-00' && contractBillingType && expireNotification &&
            meterValidationsFunc() && gracePeriod >= 0)

    }

    const checkmarkVisibilityCalculationPage5 = () => {

        return (spaceName &&
            electricityLoad >= 0 &&
            truckBays >= 0 && fourWheelerParkingSlots != null && twoWheelerParkingSlots != null &&
            twoWheelerParkingSlots >= 0 && fourWheelerParkingSlots >= 0 &&
            !slfValidationsFunc() &&
            dgRate >= 0 && slfTableError?.error === false &&
            securityDeposit >= 0 && termsAndConditions)
    }

    const missingFieldsCalculatePage2 = () => {
        return `${!contractName ? "Contract Name, " : ""}
                 ${!isContractNameValid ? "Contract Name, " : ""} 
                 ${!contractStatus ? "Contract Status, " : ""} 
                 ${!contactName ? "Contact Name, " : ""} 
                 ${!billingEntity ? "Billing Entity, " : ""} 
                 ${billingEntity === "" ? "Billing Entity, " : ""} 
                 ${!isContactNameValid ? "Contact Name, " : ""}
                 ${!email ? "Email, " : ""} 
                 ${!isValidEmail ? "Email, " : ""} 
                 ${!gstNumber ? "GST Number, " : ""} 
                 ${!companyName ? "Company Name, " : ""} 
                 ${!panName ? "PAN Name, " : ""} 
                 ${!registeredStreetAddress ? "Registered Street Address, " : ""} 
                 ${!registeredStreetAddressLane2 ? "Registered Street Address Lane 2, " : ""} 
                 ${!contractNumber ? "Contract Number, " : ""} 
                 ${!isContractNumberValid ? "Contract Number, " : ""} 
                 ${!registeredCity ? "Registered City, " : ""} 
                 ${!registeredStateProvince ? "Registered State Province, " : ""} 
                 ${!registeredPostalCode ? "Registered Postal Code, " : ""}
                 ${!isValidGstNumber ? "GST Number, " : ""} 
                 ${!isCinValid ? "CIN, " : ""}`
    }

    const missingFieldsCalculatePage3 = () => {
        return `${!billingStreetAddress ? "Billing Street Address, " : ""}
                ${!billingStreetAddressLane2 ? "Billing Street Address Lane 2, " : ""}
                ${!billingCity ? "Billing City, " : ""}
                ${!billingStateProvince ? "Billing State Province, " : ""} 
                ${!billingPostalCode ? "Billing Postal Code, " : ""} 
                ${!isPincodeValid ? "Billing Postal Code, " : ""} 
                ${!shippingStreetAddress ? "Shipping Street Address, " : ""} 
                ${!shippingStreetAddressLane2 ? "Shipping Street Address Lane 2, " : ""} 
                ${!shippingCity ? "Shipping City, " : ""}
                ${!shippingStateProvince ? "Shipping State Province, " : ""} 
                ${!shippingPostalCode ? "Shipping Postal Code, " : ""} 
                ${!isShippingPincodeValid ? "Shipping Postal Code, " : ""}
                ${!contactValidationsFunc() ? "Missing Fields In Contacts List, " : ""}`
    }
    const missingFieldsCalculatePage4 = () => {
        return `${!contractStartDate ? "Contract Start Date, " : ""} 
        ${!(contractStartDate !== '0000-00-00') ? "Contract Start Date, " : ""}
        ${!contractEndDate ? "Contract End Date, " : ""}
        ${!(contractEndDate !== '0000-00-00') ? "Contract End Date, " : ""} 
        ${!contractBillingType ? "Contract Billing Type, " : ""} 
        ${!expireNotification ? "Expire Notification, " : ""}
        ${!meterValidationsFunc() ? "Missing Fields In Meters List, " : ""} 
        ${!(gracePeriod >= 0) ? "Grace Period, " : ""}`

    }

    const missingFieldsCalculatePage5 = () => {
        return `${!spaceName ? "Space Name, " : ""}
        ${!(electricityLoad >= 0) ? "Electricity Load, " : ""}
        ${!(truckBays >= 0) ? "Truck Bays, " : ""} 
        ${!(fourWheelerParkingSlots != null) ? "Four Wheeler Parking Slots, " : ""} 
        ${!(twoWheelerParkingSlots != null) ? "Two Wheeler Parking Slots, " : ""}
        ${!(twoWheelerParkingSlots >= 0) ? "Two Wheeler Parking Slots, " : ""} 
        ${!(fourWheelerParkingSlots >= 0) ? "Four Wheeler Parking Slots, " : ""}
        ${slfValidationsFunc() ? "Missing Fields In SLF Table, " : ""}
        ${!(dgRate >= 0) ? "DG Rate, " : ""} 
        ${!(slfTableError?.error === false) ? "SLF Table Validation Error, " : ""}
        ${!(securityDeposit >= 0) ? "Security Deposit, " : ""}
        ${!termsAndConditions ? "Terms And Conditions " : ""}
        `
    }


    return (
        <>
            <>
                <NavBarTop />
                <LeftNavBar />
            </>
            <Modal keyboard={false} backdrop="static" show={showDraftModal} onHide={handleCloseDraftModal} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <img src="./images/icons/popper.svg" alt="Party Popper" />
                    <h5>Success!</h5>
                    {
                        selectedDraftContract && selectedDraftContract ?
                            <p className="mb-3">Draft contract <b>{selectedDraftContract?.contract_name}</b> has been successfully updated.</p>
                            :
                            <p className="mb-3">Draft contract <b>{contractName}</b> has been successfully created.</p>
                    }
                </Modal.Body>
                <Modal.Footer className="footer">
                    <Button variant="secondary" className="secondary" onClick={handleCloseModal} >
                        Close
                    </Button>
                    <Button variant="secondary" className="secondary-left">
                        View Contract
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal keyboard={false} backdrop="static" show={showConfirmDeleteModel} centered onHide={handleCloseConfirmDeleteModal}>
                <Modal.Body style={{ display: "flex", flexDirection: "column" }}>
                    <p>Are you sure you want to delete this File?</p>
                    <span style={{ fontSize: "small" }}>Deleting this file will permenantly remove the file from the system!</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setConfirmDeleteModel(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDocumentDelete}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
            <AlertPopUp alertShow={alertShow} alertType={alertType} alertMsg={alertMessage} alertValue={alertValue} alertButtons={alertButtons} />
            {currentPage === 1 &&
                <div className='meter_reports_container'>
                    <div className='d-flex justify-content-between align-items-center flex-wrap'>
                        <div className='d-flex align-items-center gap-3 mb-3'>
                            <h3 className="invoice-heading" >Contracts</h3>
                            {/* <p className="fin_man_meter_label pt-2"><span>{contractsList.length}</span> Total</p> */}
                            {/* <div className="d-flex fin_man_meter_label pt-2">
                                <p className="fin_man_meter_label pt-2"><span>{contractsList && filteredContracts?.length}</span> Total</p>

                            </div> */}
                        </div>
                        <div className='d-flex align-items-center gap-3 mb-3'>
                            <div className='fin_man_meter_search_bar_container'>
                                <input type='search' placeholder='Search' value={searchText} className='fin_man_meter_search_bar' onChange={(e) => searchFunc(e.target.value)} />
                                <GoSearch className='fin_man_meter_search_bar_icon' />
                            </div>
                            <button className='invoice_btn' onClick={() => {
                                changePageNext()
                                dispatch({
                                    type: COMMERCIAL_HEAD_GET_DOCUMENTS_SUCCESS,
                                    payload: null,
                                })
                                dispatch({
                                    type: PUT_CH_ADD_NEW_CONTRACT_SUCCESS,
                                    payload: null,
                                })
                            }} style={{ color: "white" }}><span className='generate_invoice'><img src={"./images/icons/newContract.svg"} style={{ marginRight: "10px" }} alt="+" />Add New Contract</span><span className='generate'><img src={"./images/icons/newContract.svg"} style={{ marginRight: "10px" }} alt="+" />Add</span></button>
                        </div>
                    </div>
                    <Stack direction="horizontal" className="mt-1 invoice-type-div" gap={6}>
                        {typesOfContracts.map((i, index) => {
                            return (
                                <div key={index} className="invoice-type-card" onClick={() => { onchangeContractType(i.id) }}>
                                    <p className={i.id === contractType ? "selected-invoice-type C-pointer" : "C-pointer"}>{i.name}</p>
                                </div>
                            )
                        })
                        }
                    </Stack>

                    <div className='table_container_commercial_head'>
                        <table>
                            <thead>
                                <tr className='fin_man_table_head commercial-head-table text-center'>
                                    <th style={{ textWrap: "nowrap" }} className='C-pointer' onClick={() => sortCompanyName()}>
                                        Company Name <img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th style={{ textWrap: "nowrap" }} className='C-pointer' onClick={() => sortContractName()}>Contract Name <img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th style={{ textWrap: "nowrap" }} className='C-pointer' onClick={() => sortSpaceName()}>Space Name <img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th style={{ textWrap: "nowrap" }} className='C-pointer' onClick={() => sortContractNumber()}>Contract No. <img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    {/* <th style={{ textWrap: "nowrap" }} className='C-pointer' onClick={() => sortContractType()}>Contract Type <img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th style={{ textWrap: "nowrap" }} className='C-pointer' onClick={() => sortEmail()}>Email <img src="./images/icons/sort.svg" alt="edit-icon" /></th> */}
                                    <th style={{ textWrap: "nowrap" }} className='C-pointer' onClick={() => sortStatus()}>Status <img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th style={{ textWrap: "nowrap" }} className='C-pointer' onClick={() => sortUpdatedDate()}>Last Updated <img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th style={{ textWrap: "nowrap" }}>Action</th>

                                </tr>
                            </thead>
                            {tableLoader ? <tbody className='fin_man_table_body'>
                                <tr className='text-center'>
                                    <td colSpan='10'><LoaderSpinner /></td>
                                </tr>
                            </tbody>
                                : contractsList === null ?
                                    <tbody className='fin_man_table_body'>
                                        <tr className='text-center'>
                                            <td colSpan='10'>No records found</td>
                                        </tr>
                                    </tbody>
                                    :
                                    <>
                                        {contractsList && contractsList?.length > 0 ? (

                                            <tbody className='fin_man_table_body'>
                                                {filteredContracts.length > 0 ? (
                                                    filteredContracts.map((each, index) => (
                                                        <tr key={index} className={`table_row ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}>
                                                            <td className='table_text_left'>{each.company_name}</td>
                                                            <td className="table_text_left">{each.contract_name}</td>
                                                            <td className='table_text_left'>{each.space_name}</td>
                                                            <td className="table_text_left">{each.contract_number}</td>
                                                            {/* <td className="table_text_left">{toTitleCase(each.contract_billing_type)}</td>
                                                            <td className='table_text_left'>{each.primary_contact_email}</td> */}
                                                            <td className='table_text_left'>{toTitleCase(each.contract_status)}</td>
                                                            <td className='table_text_left' title={each.updated_at}>{timeElapsed(each.updated_at)}</td>
                                                            <td className='text-center' role='button'
                                                                onClick={(event) => {
                                                                    printMousePos(event)
                                                                    setSelectedContract(true)
                                                                }}
                                                                onMouseEnter={(event) => {
                                                                    printMousePos(event)
                                                                }}
                                                            >

                                                                <div className="dropdown" onMouseLeave={() => {
                                                                    setSelectedContract(false)
                                                                }}>
                                                                    <img className='dropbtn' src="./images/icons/horizontalDots.svg" alt="search-icon" />
                                                                    <div className={`dropdown-content ${selectedContract ? '' : 'd-none'}`} style={{ marginLeft: '-37px', marginTop: `${popOverPosition === 'bottom' ? '-10px' : contractType === 'draft' ? '-170px' : '-127px'}` }}>
                                                                        <a onClick={() => { viewSelectedContract(each) }} >
                                                                            <div className="action-items view-icon" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                                                {/* <img src="./images/icons/ViewEye.svg" alt="view-icon" /> */}
                                                                                < GrView style={{ marginTop: "-14px" }} />
                                                                                <p>View</p>
                                                                            </div>
                                                                        </a>
                                                                        <a onClick={() => { editDraftContract(each) }}>
                                                                            <div className="action-items">
                                                                                <img src="./images/icons/Edit.svg" alt="edit-icon" />
                                                                                <p>Edit</p>
                                                                            </div>
                                                                        </a>
                                                                        {contractType === 'draft' &&
                                                                            <a onClick={() => { handleDeleteClick(each) }}>
                                                                                <div className="action-items">
                                                                                    <img src="./images/icons/delete.svg" alt="delete-icon" />
                                                                                    <p>Delete</p>
                                                                                </div>
                                                                            </a>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </td>

                                                        </tr>

                                                    ))
                                                ) : (
                                                    <tr className='text-center'>
                                                        <td colSpan='10'>No records found</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        ) :
                                            <tbody className='fin_man_table_body'>
                                                <tr className='text-center'>
                                                    <td colSpan='10'>No records found</td>
                                                </tr>
                                            </tbody>
                                        }
                                    </>
                            }
                        </table>
                    </div>
                    <Pagination
                        handledPaginationNextClicked={handledPaginationNextClicked}
                        handledPaginationPrevClicked={handledPaginationPrevClicked}
                        isApplyClicked
                        totalRecords={contractType === "all" ? totalRecordsAc : totalRecordsDc}
                        recordsPerPage={RECORDS_PER_PAGE}
                        currentPaginationPage={contractType === "all" ? currentPaginationPageAc : currentPaginationPageDc}
                    />
                    <Modal keyboard={false} backdrop="static" show={showDeleteModal} onHide={handleCancelClick} centered className="popper-modal">
                        <Modal.Body className="text-center"><h5>{`Are you sure you want to delete contract "${draftContractNumberDelete}"?`}</h5></Modal.Body>
                        <Modal.Footer className="footer">
                            <Button variant="secondary" className="confirmation" onClick={handleCancelClick}>Cancel</Button>
                            <Button variant="secondary" className="confirmation-left" onClick={() => handleProceedClick()}>Proceed</Button>
                        </Modal.Footer>
                    </Modal>
                </div>

            }

            {currentPage === 2 ?
                <div className="newContractContainer">
                    <div className="stepsContainer">
                        <div className="stepsTitle">
                            <h6 style={{ padding: "20px 20px 0px 20px", lineHeight: "0px" }}>Contract Creation Steps</h6>
                            <span style={{ fontSize: "10px", padding: "20px", lineHeight: "0px" }}><MdInfoOutline /> Please fill all the fields in every step to create a Contract</span>
                        </div>
                        <div className="listOfCards">
                            {steps && steps.map((item, index) => {
                                return (
                                    <div className={`${currentPage - 1 === index + 1 ? 'stepsCardActive' : 'stepsCard'}`}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <span style={{ fontWeight: "bold", fontSize: "medium" }}>{item.name}</span>
                                            {
                                                index === 0 && contractName && isContractNameValid && contractStatus && contactName && billingEntity && billingEntity !== ""
                                                    && isContactNameValid && email && isValidEmail &&
                                                    email && gstNumber && companyName && panName && registeredStreetAddress &&
                                                    registeredStreetAddressLane2 && contractNumber && isContractNumberValid &&
                                                    registeredCity && registeredStateProvince && registeredPostalCode &&
                                                    isValidEmail && isValidGstNumber
                                                    // && cinNumber 
                                                    && isCinValid
                                                    ?
                                                    <img src={"./images/icons/CirclecircleWithTick.svg"} style={{ height: "16px" }} alt="ok" />
                                                    :
                                                    <>

                                                        {
                                                            index === 0 ?
                                                                <MdErrorOutline title="Please fill all the fields in this step" style={{ color: "orange" }} fontSize={18} />
                                                                :
                                                                null
                                                        }
                                                    </>
                                            }
                                            {
                                                index === 1 && billingStreetAddress && billingStreetAddressLane2 && billingCity &&
                                                    billingStateProvince && billingPostalCode && isPincodeValid && shippingStreetAddress && shippingStreetAddressLane2 && shippingCity
                                                    && shippingStateProvince && shippingPostalCode && isShippingPincodeValid &&
                                                    contactValidationsFunc()
                                                    ?
                                                    <img src={"./images/icons/CirclecircleWithTick.svg"} style={{ height: "16px" }} alt="ok" />
                                                    :
                                                    <>
                                                        {
                                                            index === 1 ?
                                                                <MdErrorOutline title="Please fill all the fields in this step" style={{ color: "orange" }} fontSize={18} />
                                                                :
                                                                null
                                                        }
                                                    </>
                                            }
                                            {
                                                index === 2 && contractStartDate && contractStartDate !== '0000-00-00' &&
                                                    contractEndDate && contractEndDate !== '0000-00-00' && contractBillingType && expireNotification &&
                                                    meterValidationsFunc() && gracePeriod >= 0
                                                    ?
                                                    <img src={"./images/icons/CirclecircleWithTick.svg"} style={{ height: "16px" }} alt="ok" />
                                                    :
                                                    <>
                                                        {
                                                            index === 2 ?
                                                                <MdErrorOutline title="Please fill all the fields in this step" style={{ color: "orange" }} fontSize={18} />
                                                                :
                                                                null
                                                        }
                                                    </>
                                            }
                                            {
                                                index === 3 && spaceName &&
                                                    electricityLoad >= 0 &&
                                                    truckBays >= 0 && fourWheelerParkingSlots != null && twoWheelerParkingSlots != null &&
                                                    twoWheelerParkingSlots >= 0 && fourWheelerParkingSlots >= 0 &&
                                                    !slfValidationsFunc() &&
                                                    dgRate >= 0 && slfTableError?.error === false &&
                                                    securityDeposit >= 0  && termsAndConditions?
                                                    <img src={"./images/icons/CirclecircleWithTick.svg"} style={{ height: "16px" }} alt="ok" />
                                                    :
                                                    <>
                                                        {
                                                            index === 3 ?
                                                                <MdErrorOutline title="Please fill all the fields in this step" style={{ color: "orange" }} fontSize={18} />
                                                                :
                                                                null
                                                        }
                                                    </>

                                            }



                                        </div>

                                        <span style={{ fontSize: "small", marginTop: "10px" }}>{item.description}</span>
                                        {
                                            checkmarkVisibilityCalculationPage2()
                                                ?
                                                null :
                                                <>
                                                    {
                                                        index === 0 ?
                                                            <spam style={{ fontSize: "10px", marginTop: "10px", color: "red" }}>
                                                                Missing Fields: <br />{missingFieldsCalculatePage2()}
                                                            </spam>
                                                            :
                                                            null
                                                    }
                                                </>
                                        }

                                        {
                                            checkmarkVisibilityCalculationPage3()
                                                ?
                                                null :
                                                <>
                                                    {
                                                        index === 1 ?
                                                            <spam style={{ fontSize: "10px", marginTop: "10px", color: "red" }}>
                                                                Missing Fields: <br />{missingFieldsCalculatePage3()}
                                                            </spam>
                                                            :
                                                            null
                                                    }
                                                </>
                                        }

                                        {
                                            checkmarkVisibilityCalculationPage4()
                                                ?
                                                null :
                                                <>
                                                    {
                                                        index === 2 ?
                                                            <spam style={{ fontSize: "10px", marginTop: "10px", color: "red" }}>
                                                                Missing Fields: <br />{missingFieldsCalculatePage4()}
                                                            </spam>
                                                            :
                                                            null
                                                    }
                                                </>
                                        }

                                        {
                                            checkmarkVisibilityCalculationPage5()
                                                ?
                                                null :
                                                <>
                                                    {
                                                        index === 3 ?
                                                            <spam style={{ fontSize: "10px", marginTop: "10px", color: "red" }}>
                                                                Missing Fields: <br />{missingFieldsCalculatePage5()}
                                                            </spam>
                                                            :
                                                            null
                                                    }
                                                </>
                                        }

                                    </div>
                                )
                            })

                            }

                        </div>
                    </div>
                    { }
                    <div className="infoContainer">
                        <div>
                            {steps && steps.map((item, index) => {
                                return (
                                    <>
                                        {currentPage - 1 === index + 1 ?
                                            <div className="infoTitle">
                                                <h6 style={{ padding: "20px", lineHeight: "0px" }}>{item.name}</h6>
                                                <h6 style={{ padding: "20px", lineHeight: "0px" }}>{index + 1} of {steps.length}</h6>
                                            </div>

                                            : null}
                                    </>
                                )
                            })}
                        </div>

                        <div className="infoBody">

                            <div>
                                <h7 style={{ fontWeight: "bold" }}>Contract Details</h7>
                                <div className="infoBodyInputs">
                                    <div className="responsiveInputSection">
                                        <div >
                                            <label className='add-facility-modal-label'>Contract Name <span style={{ color: "red" }}>*</span></label>
                                            <input type='text' value={contractName} disabled={viewClicked || editCompleteContractClicked}
                                                onChange={handleContractNameChange}
                                                className='form-control responsive-add-facility-input-fields' />
                                            {!isContractNameValid && <span className='input-error-text'>Must be min of 3 to max of 200 characters</span>}
                                            {isContractNameValid && duplicateContractNameError && (<span className="input-error-text">Contract Name already exists!</span>)}
                                        </div>
                                        <div>
                                            <label className='add-facility-modal-label'>Contract Number<span style={{ color: "red" }}>*</span></label>
                                            <input disabled={viewClicked || editCompleteContractClicked} type='text' value={contractNumber}
                                                onChange={handleContractNumberChange}
                                                className='form-control responsive-add-facility-input-fields' />
                                            {!isContractNumberValid && <span className="input-error-text">Must be min of 3 to max of 200 characters</span>}
                                            {isContractNumberValid && duplicateContractNumberError && (<span className='input-error-text'> Contract Number already exists!</span>)}
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label' disabled={viewClicked}>Contract Status {<span style={{ color: "red" }}>*</span>}</label>
                                            <select disabled={viewClicked} value={contractStatus} onChange={(e) => setContractStatus(e.target.value)} className='form-select responsive-add-facility-input-fields'>
                                                <option value="inactive">Inactive</option>
                                                <option value="active">Active</option>
                                                <option value="pending">Pending</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="responsiveInputSection">
                                        <div>
                                            <label className='add-facility-modal-label'>Billing Entity {<span style={{ color: "red" }}>*</span>}</label>
                                            <select disabled={viewClicked} className='form-select responsive-add-facility-input-fields' value={billingEntity} onChange={(e) => {
                                                setBillingEntity(e.target.value)
                                            }}>
                                                <option hidden>Select</option>
                                                {
                                                    billingEntities?.map((entity, index) => (
                                                        <option key={entity.id} value={entity.partner_company_id}>{toTitleCase(entity.company_name)}</option>
                                                    ))
                                                }
                                                {/* entity.partner_company_id */}

                                            </select>
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>Facility {<span style={{ color: "red" }}>*</span>}</label>
                                            <input type='text'
                                                value={facilityName} disabled={true}
                                                className='form-control responsive-add-facility-input-fields'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                            <div>
                                <h7 style={{ fontWeight: "bold" }}>Company Details</h7>
                                <div className="infoBodyInputs">
                                    <div className="responsiveInputSection">
                                        <div >
                                            <label className='add-facility-modal-label'>Contact Name {<span style={{ color: "red" }}>*</span>}</label>
                                            <input type='text' value={contactName} disabled={viewClicked}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    const formattedInput = value.replace(/[^A-Za-z\s]/g, '');
                                                    const trimmedInput = eleminateSpacesAtStartOfInputField(formattedInput);
                                                    if (trimmedInput.length !== 0 && (trimmedInput.length < 3 || trimmedInput.length > 200)) {
                                                        setIsContactNameValid(false);
                                                    } else {
                                                        setIsContactNameValid(true);
                                                    }
                                                    setContactName(trimmedInput);
                                                }} className='form-control responsive-add-facility-input-fields' />
                                            {!isContactNameValid && <span className='input-error-text'>Must be min of 3 to max of 200 characters</span>}
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>Phone Number</label>
                                            <input type='text' value={phoneNumber}
                                                disabled={viewClicked}
                                                onChange={(e) => {
                                                    const input = e.target.value;
                                                    const formattedInput = input.replace(/\D/g, '');
                                                    let trimmedInput = formattedInput.slice(0, 15);
                                                    // if (trimmedInput.length === 0 || trimmedInput.length >= 10) {
                                                    //     setIsValidPhone(true);

                                                    // } else {
                                                    //     setIsValidPhone(false);
                                                    // }
                                                    // if(trimmedInput.length === 0){
                                                    //     trimmedInput = ''
                                                    // }
                                                    setPhoneNumber(trimmedInput);
                                                }}
                                                className='form-control responsive-add-facility-input-fields' />
                                            {!isValidPhone && <span className="input-error-text">Invalid Phone Number</span>}

                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>Email {<span style={{ color: "red" }}>*</span>}</label>
                                            <input type='text' disabled={viewClicked} value={email}
                                                onChange={handleEmailChange}
                                                className='form-control responsive-add-facility-input-fields' />
                                            {!isValidEmail && <span className="input-error-text">Invalid email address</span>}
                                        </div>

                                    </div>
                                    <hr></hr>
                                    <div className="responsiveInputSection">
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <label className='add-facility-modal-label'>GST Number {<span style={{ color: "red" }}>*</span>}</label>
                                                <input disabled={viewClicked || editCompleteContractClicked} type='text' value={gstNumber} onChange={handleGstNumberChange} onKeyDown={handleKeyPressForGst} className='form-control ' style={{ width: "90%", border: "0.5px solid #9D9D9C" }} />
                                                {!isValidGstNumber && <span className="input-error-text">Invalid GST number</span>}
                                            </div>
                                            {/* >>> */}
                                            {
                                                !viewClicked ? (
                                                    <div style={{ marginBottom: "0px", display: "flex", alignItems: "start", marginTop: "20px" }}>
                                                        <div
                                                            style={{
                                                                background: "grey",
                                                                padding: "9px 10px",
                                                                marginLeft: "-20px",
                                                                borderRadius: "5px",
                                                                opacity: gstNumber === '' || !isValidGstNumber ? 0.3 : 1,
                                                                pointerEvents: gstNumber === '' || !isValidGstNumber ? 'none' : 'auto'
                                                            }}
                                                            className="C-pointer"
                                                            onClick={() => {
                                                                if (gstNumber !== '' && isValidGstNumber) {
                                                                    setGstLoader(true);
                                                                    // dispatch(GstNumberDetails(gstNumber))
                                                                    handleGstClick(gstNumber);
                                                                }
                                                            }}
                                                        >
                                                            {
                                                                gstLoader && !errorValue ? (
                                                                    <GstLoader />
                                                                ) : (
                                                                    <FaSearch color="white" size={20} style={{ fontWeight: "bolder" }} />
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                        <div>
                                            <label className='add-facility-modal-label'>CIN
                                                {/* {<span style={{ color: "red" }}>*</span>} */}
                                            </label>
                                            <input type='text' disabled={viewClicked} value={cinNumber} onChange={handleCinNumberChange} className='form-control responsive-add-facility-input-fields' />
                                            {!isCinValid && <span className="input-error-text">Invalid CIN</span>}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div>
                                <div className="infoBodyInputs">
                                    <div className="responsiveInputSection">
                                        <div>
                                            <label className='add-facility-modal-label'>Company Name {<span style={{ color: "red" }}>*</span>}</label>
                                            <input type='text' disabled={true} value={companyName} onChange={(e) => setCompanyName(e.target.value)} className='form-control responsive-add-facility-input-fields' />
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>PAN {<span style={{ color: "red" }}>*</span>}</label>
                                            <input type='text' disabled={true} value={panName} onChange={(e) => setPanName(e.target.value)} className='form-control responsive-add-facility-input-fields' />
                                        </div>
                                        <div>
                                            <label className='add-facility-modal-label'>Street Address {<span style={{ color: "red" }}>*</span>}</label>
                                            <input type='text' disabled={true} value={registeredStreetAddress} onChange={(e) => setRegisteredStreetAddress(e.target.value)} className='form-control responsive-add-facility-input-fields' />
                                        </div>
                                    </div>
                                    <div className="responsiveInputSection">

                                        <div >
                                            <label className='add-facility-modal-label'>Street Address Lane 2 {<span style={{ color: "red" }}>*</span>}</label>
                                            <input type='text' disabled={true} value={registeredStreetAddressLane2} onChange={(e) => setRegisteredStreetAddressLane2(e.target.value)} className='form-control responsive-add-facility-input-fields' />
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>City {<span style={{ color: "red" }}>*</span>}</label>
                                            <input type='text' disabled={true} value={registeredCity} onChange={(e) => setRegisteredCity(e.target.value)} className='form-control responsive-add-facility-input-fields' />
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>State {<span style={{ color: "red" }}>*</span>} </label>
                                            <input type='text' disabled={true} value={registeredStateProvince} onChange={(e) => setRegisteredStateProvince(e.target.value)} className='form-control responsive-add-facility-input-fields' />
                                        </div>
                                    </div>
                                    <div className="responsiveInputSection">

                                        <div >
                                            <label className='add-facility-modal-label'>Pincode {<span style={{ color: "red" }}>*</span>}</label>
                                            <input type='text' disabled={true} value={registeredPostalCode} onChange={(e) => setRegisteredPostalCode(e.target.value)} className='form-control responsive-add-facility-input-fields' />
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className="infoFotter">
                            {!viewClicked && currentPage !== 1 ?
                                <>
                                    {currentPage < steps.length + 1 && (
                                        <button className="infoNextBtn" style={{ marginRight: "20px" }} onClick={handleShowCloseConfirmationModal}>Close</button>
                                    )}
                                    {/* <button className="infoNextBtn" style={{ marginRight: "20px" }} onClick={changePagePrev}>Back</button> */}
                                    {
                                        !editCompleteContractClicked ?
                                            <button className="infoNextBtn" onClick={() => {
                                                handleSaveAsDraftClick()
                                                setSaveasDraftClicked(true)
                                            }}
                                                style={checkSaveDraftValidation() ? { opacity: '50%', marginRight: "20px" } : { opacity: '100%', marginRight: "20px" }}
                                                disabled={checkSaveDraftValidation()}
                                            >Save as Draft</button>
                                            : null
                                    }
                                    {
                                        !editCompleteContractClicked ?
                                            <button className="infoNextBtn" onClick={saveAndContinue}
                                                style={checkSaveandContinueValidation() ? { opacity: '50%' } : { opacity: '100%' }}
                                                disabled={checkSaveandContinueValidation()}
                                            >Save & Continue</button> :
                                            <button className="infoNextBtn" style={{ marginRight: "20px" }} onClick={() => changePageNext()} >{"Next"}</button>
                                    }
                                </>
                                :
                                <>
                                    {currentPage < steps.length + 1 ? <button className="infoNextBtn" style={{ marginRight: "20px" }} onClick={() => closeClicked()} >Close</button> : null}
                                    {/* <button className="infoNextBtn" style={{ marginRight: "20px" }} onClick={changePagePrev}>Back</button> */}
                                    <button className="infoNextBtn" style={{ marginRight: "20px" }} onClick={() => changePageNext()} >{currentPage === steps.length ? "Close" : "Next"}</button>
                                </>
                            }
                        </div>
                        <Modal keyboard={false} backdrop="static"
                            show={showCloseConfirmationModal} onHide={handleCloseCloseConfirmationModal}
                            centered className="popper-modal">
                            <Modal.Body className="text-center">
                                <h5>Are you sure you want to CLOSE? Your progress will  not  be saved.</h5>
                            </Modal.Body>
                            <Modal.Footer className="footer">
                                <Button variant="secondary" className="confirmation" onClick={handleCloseCloseConfirmationModal}>  Cancel </Button>
                                <Button variant="secondary" className="confirmation-left" onClick={handleCloseProceed} > Proceed</Button>
                            </Modal.Footer></Modal>
                        {/*modal for confirmation*/}
                        <Modal keyboard={false} backdrop="static" show={showConfirmationModal} onHide={handleCloseConfirmationModal} centered className="popper-modal" >
                            <Modal.Body className="text-center" ><h5>Are you sure you want to save this as Draft?</h5></Modal.Body>
                            <Modal.Footer className="footer">
                                <Button variant="secondary" className="confirmation" onClick={handleCloseConfirmationModal}>Cancel</Button>
                                <Button variant="secondary" className="confirmation-left" onClick={handleSaveAsDraftConfirmation}>Proceed</Button>
                            </Modal.Footer>
                        </Modal>
                        {/* modal for save as draft page 1 */}

                    </div>
                </div>
                :
                null

            }

            {currentPage === 3 ?
                <div className="newContractContainer">
                    <div className="stepsContainer">
                        <div className="stepsTitle">
                            <h6 style={{ padding: "20px 20px 0px 20px", lineHeight: "0px" }}>Contract Creation Steps</h6>
                            <span style={{ fontSize: "10px", padding: "20px", lineHeight: "0px" }}><MdInfoOutline /> Please fill all the fields in every step to create a Contract</span>
                        </div>
                        <div className="listOfCards">
                            {steps && steps.map((item, index) => {
                                return (
                                    <div className={`${currentPage - 1 === index + 1 ? 'stepsCardActive' : 'stepsCard'}`}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <span style={{ fontWeight: "bold", fontSize: "medium" }}>{item.name}</span>
                                            {
                                                index === 0 && contractName && isContractNameValid && contractStatus && contactName && billingEntity && billingEntity !== ""
                                                    && isContactNameValid && email && isValidEmail &&
                                                    email && gstNumber && companyName && panName && registeredStreetAddress &&
                                                    registeredStreetAddressLane2 && contractNumber && isContractNumberValid &&
                                                    registeredCity && registeredStateProvince && registeredPostalCode &&
                                                    isValidEmail && isValidGstNumber
                                                    // && cinNumber 
                                                    && isCinValid
                                                    ?
                                                    <img src={"./images/icons/CirclecircleWithTick.svg"} style={{ height: "16px" }} alt="ok" />
                                                    :
                                                    <>
                                                        {
                                                            index === 0 ?
                                                                <MdErrorOutline title="Please fill all the fields in this step" style={{ color: "orange" }} fontSize={18} />
                                                                :
                                                                null
                                                        }
                                                    </>
                                            }
                                            {
                                                index === 1 && billingStreetAddress && billingStreetAddressLane2 && billingCity &&
                                                    billingStateProvince && billingPostalCode && isPincodeValid && shippingStreetAddress && shippingStreetAddressLane2 && shippingCity
                                                    && shippingStateProvince && shippingPostalCode && isShippingPincodeValid &&
                                                    contactValidationsFunc()
                                                    ?
                                                    <img src={"./images/icons/CirclecircleWithTick.svg"} style={{ height: "16px" }} alt="ok" />
                                                    :
                                                    <>
                                                        {
                                                            index === 1 ?
                                                                <MdErrorOutline title="Please fill all the fields in this step" style={{ color: "orange" }} fontSize={18} />
                                                                :
                                                                null
                                                        }
                                                    </>
                                            }
                                            {
                                                index === 2 && contractStartDate && contractStartDate !== '0000-00-00' &&
                                                    contractEndDate && contractEndDate !== '0000-00-00' && contractBillingType && expireNotification &&
                                                    meterValidationsFunc() && gracePeriod >= 0
                                                    ?
                                                    <img src={"./images/icons/CirclecircleWithTick.svg"} style={{ height: "16px" }} alt="ok" />
                                                    :
                                                    <>
                                                        {
                                                            index === 2 ?
                                                                <MdErrorOutline title="Please fill all the fields in this step" style={{ color: "orange" }} fontSize={18} />
                                                                :
                                                                null
                                                        }
                                                    </>
                                            }
                                            {
                                                index === 3 && spaceName &&
                                                    electricityLoad >= 0 &&
                                                    truckBays >= 0 && fourWheelerParkingSlots != null && twoWheelerParkingSlots != null &&
                                                    twoWheelerParkingSlots >= 0 && fourWheelerParkingSlots >= 0 &&
                                                    !slfValidationsFunc() &&
                                                    dgRate >= 0 && slfTableError?.error === false &&
                                                    securityDeposit >= 0 && termsAndConditions ?
                                                    <img src={"./images/icons/CirclecircleWithTick.svg"} style={{ height: "16px" }} alt="ok" />
                                                    :
                                                    <>
                                                        {
                                                            index === 3 ?
                                                                <MdErrorOutline title="Please fill all the fields in this step" style={{ color: "orange" }} fontSize={18} />
                                                                :
                                                                null
                                                        }
                                                    </>

                                            }
                                        </div>
                                        <span style={{ fontSize: "small", marginTop: "10px" }}>{item.description}</span>
                                        {
                                            checkmarkVisibilityCalculationPage2()
                                                ?
                                                null :
                                                <>
                                                    {
                                                        index === 0 ?
                                                            <spam style={{ fontSize: "10px", marginTop: "10px", color: "red" }}>
                                                                Missing Fields: <br />{missingFieldsCalculatePage2()}
                                                            </spam>
                                                            :
                                                            null
                                                    }
                                                </>
                                        }

                                        {
                                            checkmarkVisibilityCalculationPage3()
                                                ?
                                                null :
                                                <>
                                                    {
                                                        index === 1 ?
                                                            <spam style={{ fontSize: "10px", marginTop: "10px", color: "red" }}>
                                                                Missing Fields: <br />{missingFieldsCalculatePage3()}
                                                            </spam>
                                                            :
                                                            null
                                                    }
                                                </>
                                        }

                                        {
                                            checkmarkVisibilityCalculationPage4()
                                                ?
                                                null :
                                                <>
                                                    {
                                                        index === 2 ?
                                                            <spam style={{ fontSize: "10px", marginTop: "10px", color: "red" }}>
                                                                Missing Fields: <br />{missingFieldsCalculatePage4()}
                                                            </spam>
                                                            :
                                                            null
                                                    }
                                                </>
                                        }

                                        {
                                            checkmarkVisibilityCalculationPage5()
                                                ?
                                                null :
                                                <>
                                                    {
                                                        index === 3 ?
                                                            <spam style={{ fontSize: "10px", marginTop: "10px", color: "red" }}>
                                                                Missing Fields: <br />{missingFieldsCalculatePage5()}
                                                            </spam>
                                                            :
                                                            null
                                                    }
                                                </>
                                        }
                                    </div>
                                )
                            })

                            }

                        </div>
                    </div>

                    <div className="infoContainer">
                        <div>
                            {steps && steps.map((item, index) => {
                                return (
                                    <>
                                        {currentPage - 1 === index + 1 ?
                                            <div className="infoTitle">
                                                <h6 style={{ padding: "20px", lineHeight: "0px" }}>{item.name}</h6>

                                                <h6 style={{ padding: "20px", lineHeight: "0px" }}>{index + 1} of {steps.length}</h6>
                                            </div>

                                            : null}
                                    </>
                                )
                            })}
                        </div>


                        <div className="infoBody">
                            <div>
                                <div style={{ display: "flex", justifyContent: "space-between", }}>
                                    <h7 style={{ fontWeight: "bold" }}>Billing Address</h7>
                                    {
                                        !viewClicked ?
                                            <span style={{ textDecoration: "underline", }} className="C-pointer" onClick={sameAsRegisteredAddress}>Same as GST address</span>
                                            :
                                            null
                                    }
                                </div>
                                <div className="infoBodyInputs">
                                    <div className="responsiveInputSection">
                                        <div>
                                            <label className='add-facility-modal-label'>Street Address {<span style={{ color: "red" }}>*</span>}</label>
                                            <input disabled={viewClicked} type='text' value={billingStreetAddress} onChange={(e) => setBillingStreetAddress(e.target.value)} className='form-control responsive-add-facility-input-fields' />
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>Street Address Lane 2 {<span style={{ color: "red" }}>*</span>}</label>
                                            <input disabled={viewClicked} type='text' value={billingStreetAddressLane2} onChange={(e) => setBillingStreetAddressLane2(e.target.value)} className='form-control responsive-add-facility-input-fields' />
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>City {<span style={{ color: "red" }}>*</span>}</label>
                                            <input disabled={viewClicked} type='text' value={billingCity} onChange={(e) => setBillingCity(e.target.value)} className='form-control responsive-add-facility-input-fields' />
                                        </div>
                                    </div>
                                    <div className="responsiveInputSection">
                                        <div >
                                            <label className='add-facility-modal-label'>State {<span style={{ color: "red" }}>*</span>}</label>
                                            <input disabled={true} type='text' value={billingStateProvince} onChange={(e) => setBillingStateProvince(e.target.value)} className='form-control responsive-add-facility-input-fields' />
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>Pincode {<span style={{ color: "red" }}>*</span>}</label>
                                            <input disabled={viewClicked} type='text' value={billingPostalCode} onChange={(e) => {
                                                const input = e.target.value
                                                const formattedInput = input.replace(/[^0-9]/g, '')
                                                const trimmedInput = formattedInput.trim();
                                                if (trimmedInput === '') {
                                                    setIsPincodeValid(true);
                                                } else if (trimmedInput.length !== 6) {
                                                    setIsPincodeValid(false);
                                                } else {
                                                    setIsPincodeValid(true);
                                                }
                                                setBillingPostalCode(trimmedInput)
                                            }} className='form-control responsive-add-facility-input-fields' />
                                            {!isPincodeValid && <span className="input-error-text">Invalid pincode</span>}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                            <div style={{ display: "flex", justifyContent: "space-between", }}>
                                <h7 style={{ fontWeight: "bold" }}>Shipping Address</h7>
                                {
                                    !viewClicked ?
                                        <span style={{ textDecoration: "underline", }} className="C-pointer" onClick={sameAsBillingAddress}>Same as Billing address</span>
                                        :
                                        null
                                }
                            </div>
                            <div>
                                <div className="infoBodyInputs">
                                    <div className="responsiveInputSection">
                                        <div>
                                            <label className='add-facility-modal-label'>Street Address {<span style={{ color: "red" }}>*</span>}</label>
                                            <input disabled={viewClicked} type='text' value={shippingStreetAddress} onChange={(e) => setShippingStreetAddress(e.target.value)} className='form-control responsive-add-facility-input-fields' />
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>Street Address Lane 2 {<span style={{ color: "red" }}>*</span>}</label>
                                            <input disabled={viewClicked} type='text' value={shippingStreetAddressLane2} onChange={(e) => setShippingStreetAddressLane2(e.target.value)} className='form-control responsive-add-facility-input-fields' />
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>City {<span style={{ color: "red" }}>*</span>}</label>
                                            <input disabled={viewClicked} type='text' value={shippingCity} onChange={(e) => setShippingCity(e.target.value)} className='form-control responsive-add-facility-input-fields' />
                                        </div>
                                    </div>
                                    <div className="responsiveInputSection">
                                        <div >
                                            <label className='add-facility-modal-label'>State {<span style={{ color: "red" }}>*</span>}</label>
                                            <input disabled={true} type='text' value={shippingStateProvince} onChange={(e) => setShippingStateProvince(e.target.value)} className='form-control responsive-add-facility-input-fields' />
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>Pincode {<span style={{ color: "red" }}>*</span>}</label>
                                            <input disabled={viewClicked} type='text' value={shippingPostalCode} onChange={(e) => {
                                                const input = e.target.value
                                                const formattedInput = input.replace(/[^0-9]/g, '')
                                                const trimmedInput = formattedInput.trim();
                                                if (trimmedInput === '') {
                                                    setIsShippingPincodeValid(true);
                                                } else if (trimmedInput.length !== 6) {
                                                    setIsShippingPincodeValid(false);
                                                } else {
                                                    setIsShippingPincodeValid(true);
                                                }
                                                setShippingPostalCode(trimmedInput)
                                            }} className='form-control responsive-add-facility-input-fields' />
                                            {!isShippingPincodeValid && <span className="input-error-text">Invalid pincode</span>}
                                        </div>

                                    </div>
                                </div>
                                <hr></hr>
                                <div style={{ display: "flex", justifyContent: "space-between", }}>
                                    <h7 style={{ fontWeight: "bold" }}>Contact Information</h7>
                                    {!viewClicked ?
                                        <span style={{
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                            opacity: (listOfContactsValidation[listOfContactsValidation.length - 1]?.isValidEmail === true &&
                                                listOfContacts[listOfContacts.length - 1].email !== '') ? '100%' : '50%'
                                        }}
                                            className="C-pointer"
                                            onClick={() => {
                                                if (
                                                    listOfContactsValidation[listOfContactsValidation.length - 1]?.isValidEmail === true &&
                                                    listOfContacts[listOfContacts.length - 1].email !== ''
                                                ) {
                                                    handleAddAnotherContact()
                                                    handleAddAnotherContactValidation()
                                                }

                                            }} > {listOfContactsValidation[listOfContactsValidation.length - 1]?.isValidEmail === true &&
                                                listOfContacts[listOfContacts.length - 1].email !== ''}Add another contact</span>
                                        :
                                        null}
                                </div>

                                {listOfContacts && listOfContacts?.map((contact, index) => (
                                    <div key={index} className="infoBodyInputs">
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "10px" }}>
                                            <p style={{ fontWeight: "bold" }}>Contact {index + 1}</p>
                                            {index !== 0 && !viewClicked ? (
                                                <div>
                                                    <img src="./images/icons/crossWithCircle.svg" className="C-pointer" alt="cross"
                                                        onClick={() => deleteSpecificContact(index, contact)} />
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="responsiveInputSection" style={{ marginTop: "0px" }}>
                                            <div >
                                                <label className='add-facility-modal-label'>Contact Name {<span style={{ color: "red" }}>*</span>}</label>
                                                <input type='text' disabled={viewClicked} className='form-control responsive-add-facility-input-fields' value={contact.name}
                                                    onChange={(e) => {
                                                        const updatedContacts = [...listOfContacts];
                                                        const updatedContactsValidation = [...listOfContactsValidation];
                                                        const rawInput = e.target.value;
                                                        const formattedInput = rawInput.replace(/[^A-Za-z\s]/g, '');
                                                        const processedInput = eleminateSpacesAtStartOfInputField(formattedInput);
                                                        updatedContacts[index].name = processedInput;
                                                        if (processedInput.length === 0 || processedInput.length < 3 || processedInput.length > 200) {
                                                            updatedContactsValidation[index].isContactNameValid = false;
                                                        } else {
                                                            updatedContactsValidation[index].isContactNameValid = true;
                                                        }
                                                        setListOfContacts(updatedContacts);
                                                        setListOfContactsValidation(updatedContactsValidation);
                                                    }} />
                                                {!listOfContactsValidation[index]?.isContactNameValid && <span className="input-error-text">Must be min of 3 to max of 200 characters</span>}
                                            </div>
                                            <div >
                                                <label className='add-facility-modal-label'>Designation </label>
                                                <input disabled={viewClicked} type='text' className='form-control responsive-add-facility-input-fields' value={contact.designation}
                                                    onChange={(e) => {
                                                        const updatedContacts = [...listOfContacts];
                                                        const updatedContactsValidation = [...listOfContactsValidation];
                                                        const input = e.target.value;
                                                        const trimmedInput = eleminateSpacesAtStartOfInputField(input);
                                                        if (trimmedInput.length !== 0 && (trimmedInput.length > 200)) {
                                                            updatedContactsValidation[index].isDesignationValid = false;
                                                        } else {
                                                            updatedContactsValidation[index].isDesignationValid = true;
                                                        }

                                                        const formattedInput = trimmedInput.replace(/[^a-zA-Z ]+/g, '');
                                                        updatedContacts[index].designation = formattedInput;

                                                        setListOfContacts(updatedContacts);
                                                        setListOfContactsValidation(updatedContactsValidation);
                                                    }} />
                                                {!listOfContactsValidation[index]?.isDesignationValid && <span className="input-error-text">Must be max of 200 characters</span>}
                                            </div>
                                            <div >
                                                <label className='add-facility-modal-label'>Phone Number</label>
                                                <input type='text' disabled={viewClicked} className='form-control responsive-add-facility-input-fields' value={contact.phone_number}
                                                    onChange={(e) => {
                                                        const updatedContactsValidation = [...listOfContactsValidation]
                                                        const input = e.target.value;
                                                        const updatedContacts = [...listOfContacts];

                                                        const formattedInput = input.replace(/\D/g, '');
                                                        const trimmedInput = formattedInput.slice(0, 15);
                                                        updatedContactsValidation[index].isValidPhone = trimmedInput.length === 0 || (trimmedInput.length >= 10 && trimmedInput.length <= 15);
                                                        updatedContactsValidation[index].errorMsg = trimmedInput.length < 10 && trimmedInput.length > 0 ? 'Invalid phone number' : '';

                                                        updatedContacts[index].phone_number = trimmedInput
                                                        setListOfContactsValidation(updatedContactsValidation)
                                                        setListOfContacts(updatedContacts);
                                                    }} />
                                                {!listOfContactsValidation[index]?.isValidPhone && <span className="input-error-text">Invalid Phone Number</span>}
                                            </div>
                                        </div>
                                        <div className="responsiveInputSection">


                                            <div >
                                                <label className='add-facility-modal-label'>Email <span style={{ color: "red" }}>*</span></label>
                                                <input type='text' disabled={viewClicked} className='form-control responsive-add-facility-input-fields'
                                                    value={contact.email}

                                                    onChange={(e) => handleEmailUpdateChange(e, index)}
                                                    onBlur={onEmailInputBlur}
                                                />
                                                {!listOfContactsValidation[index]?.isValidEmail && <span className="input-error-text">Invalid email address</span>}
                                                {/* un comment the bellow line for duplicate email validation, tick and disable functionality should be added */}
                                                {/* {!listOfContactsValidation[index]?.isEmailNotDuplicate && <span className="input-error-text">Email already exists</span>} */}
                                            </div>

                                        </div>
                                        <hr></hr>
                                    </div>
                                ))}

                            </div>
                            <div>

                            </div>
                            <Modal keyboard={false} backdrop="static" show={showDeleteContractContact} onHide={() => { setShowDeleteContractContact(false) }} centered className="popper-modal">
                                <Modal.Body className="text-center">
                                    <h5>{`Are you sure you want to delete this contact?`}</h5>
                                </Modal.Body>
                                <Modal.Footer className="footer">
                                    <Button variant="secondary" className="confirmation" onClick={() => { setShowDeleteContractContact(false) }}>Cancel</Button>
                                    <Button variant="secondary" className="confirmation-left" onClick={() => handleConfirmDeleteContractContact()}>Proceed</Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                        <div className="infoFotter">
                            {!viewClicked ?
                                <>
                                    {currentPage < steps.length + 1 && (
                                        <button className="infoNextBtn" style={{ marginRight: "20px" }} onClick={handleShowCloseConfirmationModal}>Close</button>
                                    )}
                                    <button className="infoNextBtn" style={{ marginRight: "20px" }} onClick={changePagePrev}>Back</button>
                                    {
                                        !editCompleteContractClicked ?
                                            <button className="infoNextBtn" onClick={() => {
                                                handleSaveAsDraftClick()
                                                setSaveasDraftClicked(true)
                                            }}
                                                style={checkSaveDraftValidation() ? { opacity: '50%', marginRight: "20px" } : { opacity: '100%', marginRight: "20px" }}
                                                disabled={checkSaveDraftValidation()}
                                            >Save as Draft</button>
                                            : null
                                    }
                                    {
                                        !editCompleteContractClicked ?
                                            <button className="infoNextBtn" onClick={saveAndContinue}
                                                style={checkSaveandContinueValidation() ? { opacity: '50%' } : { opacity: '100%' }}
                                                disabled={checkSaveandContinueValidation()}
                                            >Save & Continue</button> :
                                            <button className="infoNextBtn" style={{ marginRight: "20px" }} onClick={() => changePageNext()} >{"Next"}</button>
                                    }
                                </>
                                :
                                <>
                                    {currentPage < steps.length + 1 ? <button className="infoNextBtn" style={{ marginRight: "20px" }} onClick={() => closeClicked()} >Close</button> : null}
                                    <button className="infoNextBtn" style={{ marginRight: "20px" }} onClick={changePagePrev}>Back</button>
                                    <button className="infoNextBtn" style={{ marginRight: "20px" }} onClick={() => changePageNext()} >{currentPage === steps.length ? "Close" : "Next"}</button>
                                </>
                            }
                        </div>
                        <Modal
                            backdrop="static"
                            show={showCloseConfirmationModal} onHide={handleCloseCloseConfirmationModal}
                            centered className="popper-modal">
                            <Modal.Body className="text-center">
                                <h5>Are you sure you want to CLOSE? Your progress will  not  be saved.</h5>
                            </Modal.Body>
                            <Modal.Footer className="footer">
                                <Button variant="secondary" className="confirmation" onClick={handleCloseCloseConfirmationModal}>  Cancel </Button>
                                <Button variant="secondary" className="confirmation-left" onClick={handleCloseProceed} > Proceed</Button>
                            </Modal.Footer></Modal>
                        {/*modal for confirmation*/}
                        <Modal keyboard={false} backdrop="static" show={showConfirmationModal} onHide={handleCloseConfirmationModal} centered className="popper-modal" >
                            <Modal.Body className="text-center" ><h5>Are you sure you want to save this as Draft?</h5></Modal.Body>
                            <Modal.Footer className="footer">
                                <Button variant="secondary" className="confirmation" onClick={handleCloseConfirmationModal}>Cancel</Button>
                                <Button variant="secondary" className="confirmation-left" onClick={handleSaveAsDraftConfirmation}>Proceed</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div> : null

            }

            {currentPage === 4 ?
                <div className="newContractContainer">
                    <div className="stepsContainer">
                        <div className="stepsTitle">
                            <h6 style={{ padding: "20px 20px 0px 20px", lineHeight: "0px" }}>Contract Creation Steps</h6>
                            <span style={{ fontSize: "10px", padding: "20px", lineHeight: "0px" }}><MdInfoOutline /> Please fill all the fields in every step to create a Contract</span>

                        </div>
                        <div className="listOfCards">
                            {steps && steps.map((item, index) => {
                                return (
                                    <div className={`${currentPage - 1 === index + 1 ? 'stepsCardActive' : 'stepsCard'}`}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <span style={{ fontWeight: "bold", fontSize: "medium" }}>{item.name}</span>

                                            {
                                                index === 0 && contractName && isContractNameValid && contractStatus && contactName && billingEntity && billingEntity !== ""
                                                    && isContactNameValid && email && isValidEmail &&
                                                    email && gstNumber && companyName && panName && registeredStreetAddress &&
                                                    registeredStreetAddressLane2 && contractNumber && isContractNumberValid &&
                                                    registeredCity && registeredStateProvince && registeredPostalCode &&
                                                    isValidEmail && isValidGstNumber
                                                    // && cinNumber 
                                                    && isCinValid
                                                    ?
                                                    <img src={"./images/icons/CirclecircleWithTick.svg"} style={{ height: "16px" }} alt="ok" />
                                                    :
                                                    <>
                                                        {
                                                            index === 0 ?
                                                                <MdErrorOutline title="Please fill all the fields in this step" style={{ color: "orange" }} fontSize={18} />
                                                                :
                                                                null
                                                        }
                                                    </>
                                            }
                                            {
                                                index === 1 && billingStreetAddress && billingStreetAddressLane2 && billingCity &&
                                                    billingStateProvince && billingPostalCode && isPincodeValid && shippingStreetAddress && shippingStreetAddressLane2 && shippingCity
                                                    && shippingStateProvince && shippingPostalCode && isShippingPincodeValid &&
                                                    contactValidationsFunc()
                                                    ?
                                                    <img src={"./images/icons/CirclecircleWithTick.svg"} style={{ height: "16px" }} alt="ok" />
                                                    :
                                                    <>
                                                        {
                                                            index === 1 ?
                                                                <MdErrorOutline title="Please fill all the fields in this step" style={{ color: "orange" }} fontSize={18} />
                                                                :
                                                                null
                                                        }
                                                    </>
                                            }
                                            {
                                                index === 2 && contractStartDate && contractStartDate !== '0000-00-00' &&
                                                    contractEndDate && contractEndDate !== '0000-00-00' && contractBillingType && expireNotification &&
                                                    meterValidationsFunc() && gracePeriod >= 0
                                                    ?
                                                    <img src={"./images/icons/CirclecircleWithTick.svg"} style={{ height: "16px" }} alt="ok" />
                                                    :
                                                    <>
                                                        {
                                                            index === 2 ?
                                                                <MdErrorOutline title="Please fill all the fields in this step" style={{ color: "orange" }} fontSize={18} />
                                                                :
                                                                null
                                                        }
                                                    </>
                                            }
                                            {
                                                index === 3 && spaceName &&
                                                    electricityLoad >= 0 &&
                                                    dgRate >= 0 && slfTableError?.error === false &&
                                                    truckBays >= 0 && fourWheelerParkingSlots != null && twoWheelerParkingSlots != null &&
                                                    twoWheelerParkingSlots >= 0 && fourWheelerParkingSlots >= 0 &&
                                                    !slfValidationsFunc() &&
                                                    securityDeposit >= 0 && termsAndConditions?
                                                    <img src={"./images/icons/CirclecircleWithTick.svg"} style={{ height: "16px" }} alt="ok" />
                                                    :
                                                    <>
                                                        {
                                                            index === 3 ?
                                                                <MdErrorOutline title="Please fill all the fields in this step" style={{ color: "orange" }} fontSize={18} />
                                                                :
                                                                null
                                                        }
                                                    </>
                                            }
                                        </div>
                                        <span style={{ fontSize: "small", marginTop: "10px" }}>{item.description}</span>
                                        {
                                            checkmarkVisibilityCalculationPage2()
                                                ?
                                                null :
                                                <>
                                                    {
                                                        index === 0 ?
                                                            <spam style={{ fontSize: "10px", marginTop: "10px", color: "red" }}>
                                                                Missing Fields: <br />{missingFieldsCalculatePage2()}
                                                            </spam>
                                                            :
                                                            null
                                                    }
                                                </>
                                        }

                                        {
                                            checkmarkVisibilityCalculationPage3()
                                                ?
                                                null :
                                                <>
                                                    {
                                                        index === 1 ?
                                                            <spam style={{ fontSize: "10px", marginTop: "10px", color: "red" }}>
                                                                Missing Fields: <br />{missingFieldsCalculatePage3()}
                                                            </spam>
                                                            :
                                                            null
                                                    }
                                                </>
                                        }

                                        {
                                            checkmarkVisibilityCalculationPage4()
                                                ?
                                                null :
                                                <>
                                                    {
                                                        index === 2 ?
                                                            <spam style={{ fontSize: "10px", marginTop: "10px", color: "red" }}>
                                                                Missing Fields: <br />{missingFieldsCalculatePage4()}
                                                            </spam>
                                                            :
                                                            null
                                                    }
                                                </>
                                        }

                                        {
                                            checkmarkVisibilityCalculationPage5()
                                                ?
                                                null :
                                                <>
                                                    {
                                                        index === 3 ?
                                                            <spam style={{ fontSize: "10px", marginTop: "10px", color: "red" }}>
                                                                Missing Fields: <br />{missingFieldsCalculatePage5()}
                                                            </spam>
                                                            :
                                                            null
                                                    }
                                                </>
                                        }


                                    </div>
                                )
                            })

                            }

                        </div>
                    </div>

                    <div className="infoContainer">
                        <div>
                            {steps && steps.map((item, index) => {
                                return (
                                    <>
                                        {currentPage - 1 === index + 1 ?
                                            <div className="infoTitle">
                                                <h6 style={{ padding: "20px", lineHeight: "0px" }}>{item.name}</h6>
                                                <h6 style={{ padding: "20px", lineHeight: "0px" }}>{index + 1} of {steps.length}</h6>
                                            </div>

                                            : null}
                                    </>
                                )
                            })}
                        </div>

                        <div className="infoBody">
                            <div>
                                <h7 style={{ fontWeight: "bold" }}>Contract Information</h7>
                                <div className="infoBodyInputs">
                                    <div className="responsiveInputSection">

                                        <div >
                                            <label className='add-facility-modal-label'>Contract Start Date {<span style={{ color: "red" }}>*</span>}</label>
                                            <input disabled={viewClicked} type='date'

                                                min={new Date()}
                                                value={contractStartDate}
                                                onChange={(e) => {
                                                    const newStartDate = e.target.value;
                                                    if (newStartDate === "") {
                                                        setContractEndDate("")
                                                    }
                                                    setContractStartDate(newStartDate)
                                                    if (newStartDate && contractEndDate && new Date(newStartDate) >= new Date(contractEndDate)) {
                                                        setDateError('End date should be after the start date.')
                                                    } else {
                                                        setDateError("")
                                                        setEndDateError("")
                                                    }
                                                    let newLicenseTableBodyRows = [...licenseTableBodyRows]
                                                    newLicenseTableBodyRows[0].rate_start_date = e.target.value
                                                    setLicenseTableBodyRows(newLicenseTableBodyRows)
                                                    setSlfTableError(licenseFeeDateValidationByStartDateEndDate(newLicenseTableBodyRows, e.target.value, contractEndDate))
                                                }
                                                }
                                                onKeyDown={(e) => e.preventDefault()}
                                                className='form-control responsive-add-facility-input-fields' />
                                            <p className="input-error-text">{dateError}</p>
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>Contract End Date {<span style={{ color: "red" }}>*</span>}</label>
                                            <input min={contractStartDate}
                                                onKeyDown={(e) => e.preventDefault()}
                                                disabled={viewClicked || contractStartDate === ''} type='date' value={contractEndDate}
                                                onChange={(e) => {
                                                    const newEndDate = e.target.value;

                                                    if (newEndDate === contractStartDate) {
                                                        setEndDateError("End date and Start date should not be same");
                                                    } else {
                                                        setEndDateError("")
                                                        setDateError('')
                                                    }
                                                    setSlfTableError(null);
                                                    setContractEndDate(newEndDate);
                                                    if (newEndDate === "") {
                                                        setLicenseTableBodyRows([{
                                                            serial_number: 1,
                                                            area_in_sqft: null,
                                                            cost_per_unit: null,
                                                            rate_start_date: contractStartDate,
                                                            rate_end_date: contractEndDate,
                                                            license_fee: ""
                                                        }]);
                                                    }

                                                    let newLicenseTableBodyRows = [...licenseTableBodyRows];
                                                    newLicenseTableBodyRows[newLicenseTableBodyRows.length - 1].rate_end_date = newEndDate;
                                                    setLicenseTableBodyRows(newLicenseTableBodyRows);
                                                    setSlfTableError(licenseFeeDateValidationByStartDateEndDate(newLicenseTableBodyRows, contractStartDate, newEndDate));
                                                }} className='form-control responsive-add-facility-input-fields' />
                                            <p className="input-error-text">{endDateError}</p>
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>Contract Type {<span style={{ color: "red" }}>*</span>}</label>
                                            <select disabled={viewClicked} value={contractBillingType} onChange={(e) => setContractBillingType(e.target.value)} className='form-select responsive-add-facility-input-fields'>
                                                <option hidden>Select</option>
                                                <option value="half yearly">Half Yearly</option>
                                                <option value="yearly">Yearly</option>
                                                <option value="quarterly">Quarterly</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="responsiveInputSection">

                                        <div >
                                            <label className='add-facility-modal-label'>Send Expiry Notification Before {<span style={{ color: "red" }}>*</span>}</label>
                                            <select disabled={viewClicked} value={expireNotification} onChange={(e) => setExpireNotification(e.target.value)} className='form-select responsive-add-facility-input-fields'>
                                                <option hidden>Select</option>
                                                <option value="1 month">1 Month</option>
                                                <option value="2 months">2 Months</option>
                                                <option value="3 months">3 Months</option>
                                            </select>
                                        </div>
                                        <div>
                                            <label className='add-facility-modal-label'>Payment Time (Max 90 days) {<span style={{ color: "red" }}>*</span>}</label>
                                            <input type='number'
                                                onWheel={(e) => e.target.blur()}
                                                disabled={viewClicked} className='form-control responsive-add-facility-input-fields'
                                                value={gracePeriod}
                                                onChange={(e) => {
                                                    const input = e.target.value;
                                                    const formattedInput = input.replace(/[^\d]+|(?<=\..*)\./g, '');
                                                    if (formattedInput <= 90) {
                                                        setGracePeriod(parseInt(formattedInput));
                                                    }
                                                }} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                            <div>
                                <div style={{ display: "flex", justifyContent: "space-between", }}>
                                    <h7 style={{ fontWeight: "bold" }}>Meters</h7>

                                    {
                                        allMeters.length > 0 && !viewClicked ?
                                            <span style={{
                                                textDecoration: "underline",
                                                cursor: "pointer",
                                                opacity: (() => {
                                                    const meterA = listOfMeters[listOfMeters.length - 1];
                                                    const meterB = listOfMeters[listOfMeters.length - 2];
                                                    const notNullValidationA = meterA?.assigned_name.length > 0 && meterA?.meter_number.length > 0 && meterA?.meter_type.length > 0;
                                                    const notNullValidationB = meterB?.assigned_name.length > 0 && meterB?.meter_number.length > 0 && meterB?.meter_type.length > 0;
                                                    const meterAValidation = listOfMetersValidtaion[listOfMetersValidtaion.length - 1];
                                                    const meterBValidation = listOfMetersValidtaion[listOfMetersValidtaion.length - 2];
                                                    const validationA = meterAValidation?.isvalidMeterType && meterAValidation?.isValidMeterName && meterAValidation?.isValidMeterNumber;
                                                    const validationB = meterBValidation?.isvalidMeterType && meterBValidation?.isValidMeterName && meterBValidation?.isValidMeterNumber;
                                                    const isValid = (notNullValidationA && notNullValidationB && validationA && validationB) ||
                                                        (meterA === undefined || meterB === undefined) && (notNullValidationA && validationA) ||
                                                        listOfMeters.length === 0;

                                                    return isValid ? '100%' : '50%';
                                                })()
                                            }}
                                                onClick={() => {
                                                    const meterA = listOfMeters[listOfMeters.length - 1]
                                                    const meterB = listOfMeters[listOfMeters.length - 2]
                                                    const notNullValidationA = meterA?.assigned_name.length > 0 && meterA?.meter_number.length > 0 && meterA?.meter_type.length > 0
                                                    const notNullValidationB = meterB?.assigned_name.length > 0 && meterB?.meter_number.length > 0 && meterB?.meter_type.length > 0
                                                    const meterAValidation = listOfMetersValidtaion[listOfMetersValidtaion.length - 1]
                                                    const meterBValidation = listOfMetersValidtaion[listOfMetersValidtaion.length - 2]
                                                    const validationA = meterAValidation?.isvalidMeterType && meterAValidation?.isValidMeterName && meterAValidation?.isValidMeterNumber
                                                    const validationB = meterBValidation?.isvalidMeterType && meterBValidation?.isValidMeterName && meterBValidation?.isValidMeterNumber
                                                    if (notNullValidationA && notNullValidationB && validationA && validationB) {
                                                        handleAddAnotherMeter()
                                                        handleAddAnotherMeterValidation()
                                                    }
                                                    if (meterA === undefined || meterB === undefined) {
                                                        if (notNullValidationA && validationA) {
                                                            handleAddAnotherMeter()
                                                            handleAddAnotherMeterValidation()
                                                        }
                                                    }
                                                    if (listOfMeters.length === 0) {
                                                        handleAddAnotherMeter()
                                                        handleAddAnotherMeterValidation()
                                                    }

                                                }}
                                                className="C-pointer"
                                            >Add {listOfMeters.length === 0 ? 'a' : 'another'} meter</span>
                                            :
                                            null
                                    }

                                </div>
                                {allMeters.length <= 0 && !viewClicked ?
                                    <div style={{ margin: "5px 0px 0px 0px", background: "rgba(255, 0, 0, 0.1)", border: "1.5px solid red", borderRadius: "8px", padding: "10px" }}>
                                        <MdErrorOutline style={{ color: "red", marginRight: "5px" }} fontSize={18} />
                                        <span className='input-error-text'>No meters available in {localStorage.getItem("selected_facility_name")} facility, please contact facility manager</span>
                                    </div>
                                    :
                                    null
                                }
                                <div className="infoBodyInputs">
                                    <Modal keyboard={false} backdrop="static" show={showDeleteContractMeter} onHide={() => { setShowDeleteContractMeter(false) }} centered className="popper-modal">
                                        <Modal.Body className="text-center">
                                            <h5>{`Are you sure you want to delete this meter?`}</h5>
                                        </Modal.Body>
                                        <Modal.Footer className="footer">
                                            <label style={{ fontSize: 'small' }}>Final {deleteContractMeterSelected?.meter_type === 'electricity' ? 'EB' : 'Water'} Meter Readings {deleteContractMeterSelected?.meter_type === 'electricity' ? '(kWh)' : '(kL)'}: <span style={{ color: "red" }}>*</span></label>
                                            <input type="text" className="form-control responsive-add-facility-input-fields" value={finalMeterReadings}
                                                onChange={(e) => {
                                                    const input = e.target.value;
                                                    const formattedInput = formatNumericInput(input, 3);
                                                    setFinalMeterReadings(formattedInput);
                                                    if (parseFloat(formattedInput) < parseFloat(deleteContractMeterSelected?.initial_reading || 0)) {
                                                        setFinalReadingError(true);
                                                    } else {
                                                        setFinalReadingError(false);
                                                    }
                                                }}
                                                onKeyDown={handleKeyDownForMeterReadings}
                                            />
                                            {finalReadingError && <span className='input-error-text'>Final reading should be greater than or equal to initial reading</span>}
                                            {deleteContractMeterSelected?.meter_type === 'electricity' &&
                                                <>
                                                    <label style={{ fontSize: 'small' }}>Final DG Meter Readings {deleteContractMeterSelected?.meter_type === 'electricity' ? '(kWh)' : '(kL)'}: <span style={{ color: "red" }}>*</span> </label>
                                                    <input type="text" className="form-control responsive-add-facility-input-fields" value={finalDgMeterReadings} onChange={(e) => {
                                                        const input = e.target.value
                                                        const parts = input.replace(/[^0-9.]/g, '').split('.');
                                                        const integerPart = parts[0];
                                                        const decimalPart = parts.length > 1 ? '.' + parts[1].substring(0, 2) : '';
                                                        const formattedInput = integerPart + decimalPart;
                                                        setFinalDgMeterReadings(formattedInput)
                                                        if (parseFloat(formattedInput) < parseFloat(deleteContractMeterSelected?.initial_dg_reading)) {
                                                            setFinalDgReadingError(true)
                                                        } else {
                                                            setFinalDgReadingError(false)
                                                        }
                                                    }} />
                                                    {finalDgReadingError && <span className="input-error-text">Final reading should be greater than or equal to initial reading</span>}
                                                </>
                                            }
                                            <Button variant="secondary" className="confirmation" onClick={() => { 
                                                setShowDeleteContractMeter(false) 
                                                setFinalReadingError(false)
                                                setFinalDgReadingError(false)
                                                }}>Cancel</Button>
                                            {
                                                deleteContractMeterSelected?.meter_type === 'electricity' ? <Button variant="secondary" className="confirmation-left" disabled={!finalMeterReadings || finalMeterReadings == 0 || !finalDgMeterReadings || finalDgMeterReadings == 0 || finalDgReadingError || finalReadingError} onClick={() => handleConfirmDeleteContractMeter()}>Proceed</Button>
                                                    : <Button variant="secondary" className="confirmation-left" disabled={!finalMeterReadings || finalMeterReadings == 0 || finalReadingError} onClick={() => handleConfirmDeleteContractMeter()}>Proceed</Button>
                                            }
                                        </Modal.Footer>
                                    </Modal>
                                    {listOfMeters && listOfMeters?.map((meter, index) => {
                                        return (
                                            <div>
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "10px" }}>
                                                    <p style={{ fontWeight: "bold" }}>Meter {index + 1}</p>
                                                    {!viewClicked ?
                                                        <div>
                                                            <img src="./images/icons/crossWithCircle.svg" alt="cross" onClick={() => deleteSpecificMeter(index, meter)} />
                                                        </div>
                                                        : null}
                                                </div>
                                                <div className="responsiveInputSection" style={{ marginTop: "0px" }}>
                                                    <div>
                                                        <label className='add-facility-modal-label'>Meter Type <span style={{ color: "red" }}>*</span></label>
                                                        <select disabled={viewClicked || (editCompleteContractClicked && meter.meter_type && meter.meter_number && !meter.new_meter)} className='form-select responsive-add-facility-input-fields'
                                                            value={meter.meter_type}
                                                            onChange={(e) => {
                                                                const updatedMeter = [...listOfMeters];
                                                                updatedMeter[index].meter_number = ''
                                                                updatedMeter[index].meter_type = e.target.value;
                                                                updatedMeter[index].initial_reading = 0.0;
                                                                updatedMeter[index].initial_dg_reading = 0.0;
                                                                setListOfMeters([...updatedMeter]);


                                                            }}
                                                        >
                                                            <option hidden>Select</option>
                                                            {
                                                                contractType === 'all' && selectedDraftContract ?
                                                                    <>
                                                                        <option value="electricity">Electricity</option>
                                                                        <option value="water">Water</option>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {
                                                                            meterTypesList?.map((type) => (
                                                                                <option value={type}>{toTitleCase(type)}</option>
                                                                            ))
                                                                        }
                                                                    </>
                                                            }

                                                        </select>
                                                    </div>
                                                    <div >
                                                        <label className='add-facility-modal-label'>Assigned Name <span style={{ color: "red" }}>*</span></label>
                                                        <input type='text'
                                                            disabled={viewClicked}
                                                            value={meter.assigned_name}
                                                            onChange={(e) => {
                                                                const input = e.target.value
                                                                const regex = /^[a-zA-Z0-9./\-_s]*$/;
                                                                const updatedMeterValidation = [...listOfMetersValidtaion]
                                                                if (regex.test(input)) {
                                                                    if (input.length !== 0 && input.length < 3 || input.length > 200) {
                                                                        updatedMeterValidation[index].isValidMeterName = false
                                                                    } else {
                                                                        updatedMeterValidation[index].isValidMeterName = true
                                                                    }
                                                                    const updatedMeter = [...listOfMeters];
                                                                    updatedMeter[index].assigned_name = e.target.value;
                                                                    setListOfMetersValidation(updatedMeterValidation)
                                                                    setListOfMeters(updatedMeter);
                                                                }
                                                            }}

                                                            className='form-control responsive-add-facility-input-fields' />
                                                        {!listOfMetersValidtaion[index].isValidMeterName && <p className="input-error-text">Must be min of 3 to max of 200 characters</p>}
                                                    </div>
                                                    <div >
                                                        <label className='add-facility-modal-label'>Meter Number <span style={{ color: "red" }}>*</span></label>
                                                        <select disabled={viewClicked || (editCompleteContractClicked && meter.meter_type && meter.meter_number && !meter.new_meter)} className='form-select responsive-add-facility-input-fields'
                                                            value={meter.meter_id}
                                                            onChange={(e) => {
                                                                const updatedMeter = [...listOfMeters];
                                                                updatedMeter[index].meter_id = e.target.value;
                                                                const currentMeter = allMeters.find(item => item.meter_id === e.target.value)
                                                                updatedMeter[index].initial_reading = currentMeter?.initial_reading;
                                                                updatedMeter[index].initial_dg_reading = currentMeter?.initial_dg_reading ? currentMeter?.initial_dg_reading : 0.0;
                                                                updatedMeter[index].meter_number = currentMeter?.meter_number;
                                                                setListOfMeters(updatedMeter);
                                                            }}
                                                        >
                                                            <option hidden>Select</option>
                                                            {((viewCompleteContractClicked || editCompleteContractClicked) && meter.meter_type && meter.meter_number) && <option value={meter.meter_id}>{toTitleCase(meter.meter_number)}</option>}
                                                            {
                                                                allMeters?.filter(meterCheck => {

                                                                    return meter.meter_type === meterCheck.meter_type &&
                                                                        (!listOfMeters.some(m => m.meter_id === meterCheck.meter_id) ||
                                                                            meter.meter_id === meterCheck.meter_id)
                                                                })
                                                                    .map(meterCheck => (
                                                                        //    return {!meterCheck.is_in_use === 0 &&

                                                                        <option key={meterCheck.meter_id} value={meterCheck.meter_id} disabled={!meterCheck.is_in_use === 0}>{toTitleCase(meterCheck.meter_number)}</option>
                                                                        // }
                                                                    ))

                                                            }
                                                        </select>
                                                        {!listOfMetersValidtaion[index].isValidMeterNumber && <p className="input-error-text">Must be min of 3 to max of 50 characters</p>}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="responsiveInputSection" style={{ marginTop: '15px' }}>
                                                        <div style={{ marginTop: '15px' }}>
                                                            <label className='add-facility-modal-label'>
                                                                {meter.meter_type === "water" ? "Initial Reading" : meter.meter_type === "electricity" ? "Initial EB Reading" : "Initial Reading"}
                                                                {meter.meter_type === "water" ? " (kL)" : meter.meter_type === "electricity" ? " (kWh)" : ""}
                                                                <span style={{ color: "red" }}>*</span>
                                                            </label>
                                                            <input type='text'
                                                                // disabled={viewClicked}
                                                                disabled={viewClicked || (editCompleteContractClicked && meter.meter_type && meter.meter_number && !meter.new_meter)}
                                                                value={meter.initial_reading}
                                                                onChange={(e) => {
                                                                    const updatedMeter = [...listOfMeters];
                                                                    const updatedMeterValidation = [...listOfMetersValidtaion]
                                                                    const input = e.target.value;
                                                                    const formattedInput = formatNumericInput(input, 3);
                                                                    updatedMeter[index].initial_reading = formattedInput;
                                                                    setListOfMeters(updatedMeter);
                                                                    // setListOfMetersValidation(updatedMeterValidation)
                                                                }}
                                                                onKeyDown={handleKeyDownForMeterReadings}
                                                                className='form-control responsive-add-facility-input-fields' />
                                                            {!listOfMetersValidtaion[index].isValidMeterNumber && <p className="input-error-text">Must be min of 3 to max of 50 characters</p>}
                                                        </div>
                                                        {meter.meter_type === "electricity" &&
                                                            <div style={{ marginTop: '15px' }}>
                                                                <label className='add-facility-modal-label'>Initial DG Reading (kWh)<span style={{ color: "red" }}>*</span></label>
                                                                <input type='text'
                                                                    disabled={viewClicked || (editCompleteContractClicked && meter.meter_type && meter.meter_number && !meter.new_meter)}
                                                                    // disabled={viewClicked} 
                                                                    value={meter.initial_dg_reading}
                                                                    className='form-control responsive-add-facility-input-fields'
                                                                    onChange={(e) => {
                                                                        const input = e.target.value;
                                                                        const formattedInput = formatNumericInput(input, 3);
                                                                        const updatedMeter = [...listOfMeters];
                                                                        const updatedMeterValidation = [...listOfMetersValidtaion]
                                                                        updatedMeter[index].initial_dg_reading = formattedInput;
                                                                        setListOfMeters(updatedMeter);
                                                                    }}
                                                                    onKeyDown={handleKeyDownForMeterReadings}
                                                                />
                                                                {!listOfMetersValidtaion[index].isValidMeterNumber && <p className="input-error-text">Must be min of 3 to max of 50 characters</p>}
                                                            </div>
                                                        }
                                                    </div>

                                                </div>

                                                <hr></hr>

                                            </div>
                                        )
                                    })}
                                    {<div className="infoBodyInputs">
                                        {listOfMeters.length === 0 && <p style={{ fontSize: 'large', textAlign: 'center', margin: '3rem' }} ><IoMdInformationCircleOutline style={{ marginTop: '-2px' }} /> {viewClicked ? 'No Meters are added' : "Please add a Meter"}
                                        </p>}
                                    </div>
                                    }

                                </div>
                            </div>
                            <div>

                            </div>
                        </div>
                        <div className="infoFotter">
                            {!viewClicked ?
                                <>
                                    {currentPage < steps.length + 1 && (
                                        <button className="infoNextBtn" style={{ marginRight: "20px" }} onClick={handleShowCloseConfirmationModal}>Close</button>
                                    )}
                                    <button className="infoNextBtn" disabled={(editCompleteContractClicked ? (checkMeterIntialValidation()) : false)}
                                        style={{ marginRight: "20px", opacity: (editCompleteContractClicked ? (checkMeterIntialValidation()) : false) ? '50%' : '100%' }} onClick={changePagePrev}>Back</button>
                                    {
                                        !editCompleteContractClicked ?
                                            <button className="infoNextBtn" onClick={() => {
                                                handleSaveAsDraftClick()
                                                setSaveasDraftClicked(true)
                                            }}
                                                style={checkSaveDraftValidation() ? { opacity: '50%', marginRight: "20px" } : { opacity: '100%', marginRight: "20px" }}
                                                disabled={checkSaveDraftValidation()}
                                            >Save as Draft</button>
                                            : null
                                    }
                                    {
                                        !editCompleteContractClicked ?
                                            <button className="infoNextBtn" onClick={saveAndContinue}
                                                style={checkSaveandContinueValidation() ? { opacity: '50%' } : { opacity: '100%' }}
                                                disabled={checkSaveandContinueValidation()}
                                            >Save & Continue</button> :
                                            <button className="infoNextBtn" disabled={checkSaveDraftValidation() || checkMeterIntialValidation()} style={{ marginRight: "20px", opacity: (checkMeterIntialValidation() || checkSaveDraftValidation()) ? '50%' : '100%' }} onClick={() => changePageNext()} >{"Next"}</button>
                                    }
                                </>
                                :
                                <>
                                    {currentPage < steps.length + 1 ? <button className="infoNextBtn" style={{ marginRight: "20px" }} onClick={() => closeClicked()} >Close</button> : null}
                                    <button className="infoNextBtn" style={{ marginRight: "20px" }} onClick={changePagePrev}>Back</button>
                                    <button className="infoNextBtn" style={{ marginRight: "20px" }} onClick={() => changePageNext()} >{currentPage === steps.length + 1 ? "Close" : "Next"}</button>
                                </>
                            }
                        </div>
                        <Modal
                            backdrop="static"
                            show={showCloseConfirmationModal} onHide={handleCloseCloseConfirmationModal}
                            centered className="popper-modal">
                            <Modal.Body className="text-center">
                                <h5>Are you sure you want to CLOSE? Your progress will  not  be saved.</h5>
                            </Modal.Body>
                            <Modal.Footer className="footer">
                                <Button variant="secondary" className="confirmation" onClick={handleCloseCloseConfirmationModal}>  Cancel </Button>
                                <Button variant="secondary" className="confirmation-left" onClick={handleCloseProceed} > Proceed</Button>
                            </Modal.Footer></Modal>
                        {/*modal for confirmation*/}
                        <Modal keyboard={false} backdrop="static" show={showConfirmationModal} onHide={handleCloseConfirmationModal} centered className="popper-modal" >
                            <Modal.Body className="text-center" ><h5>Are you sure you want to save this as Draft?</h5></Modal.Body>
                            <Modal.Footer className="footer">
                                <Button variant="secondary" className="confirmation" onClick={handleCloseConfirmationModal}>Cancel</Button>
                                <Button variant="secondary" className="confirmation-left" onClick={handleSaveAsDraftConfirmation}>Proceed</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
                :
                null

            }
            {currentPage === 5 ?
                <div className="newContractContainer">
                    <div className="stepsContainer">
                        <div className="stepsTitle">
                            <h6 style={{ padding: "20px 20px 0px 20px", lineHeight: "0px" }}>Contract Creation Steps</h6>
                            <span style={{ fontSize: "10px", padding: "20px", lineHeight: "0px" }}><MdInfoOutline /> Please fill all the fields in every step to create a Contract</span>
                        </div>
                        <div className="listOfCards">
                            {steps && steps.map((item, index) => {
                                return (
                                    <div className={`${currentPage - 1 === index + 1 ? 'stepsCardActive' : 'stepsCard'}`}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <span style={{ fontWeight: "bold", fontSize: "medium" }}>{item.name}</span>
                                            {
                                                index === 0 && contractName && isContractNameValid && contractStatus && contactName && billingEntity && billingEntity !== ""
                                                    && isContactNameValid && email && isValidEmail &&
                                                    email && gstNumber && companyName && panName && registeredStreetAddress &&
                                                    registeredStreetAddressLane2 && contractNumber && isContractNumberValid &&
                                                    registeredCity && registeredStateProvince && registeredPostalCode &&
                                                    isValidEmail && isValidGstNumber
                                                    // && cinNumber 
                                                    && isCinValid
                                                    ?
                                                    <img src={"./images/icons/CirclecircleWithTick.svg"} style={{ height: "16px" }} alt="ok" />
                                                    :
                                                    <>
                                                        {
                                                            index === 0 ?
                                                                <MdErrorOutline title="Please fill all the fields in this step" style={{ color: "orange" }} fontSize={18} />
                                                                :
                                                                null
                                                        }
                                                    </>
                                            }
                                            {
                                                index === 1 && billingStreetAddress && billingStreetAddressLane2 && billingCity &&
                                                    billingStateProvince && billingPostalCode && isPincodeValid && shippingStreetAddress && shippingStreetAddressLane2 && shippingCity
                                                    && shippingStateProvince && shippingPostalCode && isShippingPincodeValid &&
                                                    contactValidationsFunc()
                                                    ?
                                                    <img src={"./images/icons/CirclecircleWithTick.svg"} style={{ height: "16px" }} alt="ok" />
                                                    :
                                                    <>
                                                        {
                                                            index === 1 ?
                                                                <MdErrorOutline title="Please fill all the fields in this step" style={{ color: "orange" }} fontSize={18} />
                                                                :
                                                                null
                                                        }
                                                    </>
                                            }
                                            {
                                                index === 2 && contractStartDate && contractStartDate !== '0000-00-00' &&
                                                    contractEndDate && contractEndDate !== '0000-00-00' && contractBillingType && expireNotification &&
                                                    meterValidationsFunc() && gracePeriod >= 0
                                                    ?
                                                    <img src={"./images/icons/CirclecircleWithTick.svg"} style={{ height: "16px" }} alt="ok" />
                                                    :
                                                    <>
                                                        {
                                                            index === 2 ?
                                                                <MdErrorOutline title="Please fill all the fields in this step" style={{ color: "orange" }} fontSize={18} />
                                                                :
                                                                null
                                                        }
                                                    </>
                                            }
                                            {
                                                index === 3 && spaceName &&
                                                    electricityLoad >= 0 &&
                                                    dgRate >= 0 && slfTableError?.error === false &&
                                                    truckBays >= 0 && fourWheelerParkingSlots != null && twoWheelerParkingSlots != null &&
                                                    twoWheelerParkingSlots >= 0 && fourWheelerParkingSlots >= 0 &&
                                                    !slfValidationsFunc() &&
                                                    securityDeposit >= 0  && termsAndConditions ?
                                                    <img src={"./images/icons/CirclecircleWithTick.svg"} style={{ height: "16px" }} alt="ok" />
                                                    :
                                                    <>
                                                        {
                                                            index === 3 ?
                                                                <MdErrorOutline title="Please fill all the fields in this step" style={{ color: "orange" }} fontSize={18} />
                                                                :
                                                                null
                                                        }
                                                    </>

                                            }
                                        </div>
                                        <span style={{ fontSize: "small", marginTop: "10px" }}>{item.description}</span>
                                        {
                                            checkmarkVisibilityCalculationPage2()
                                                ?
                                                null :
                                                <>
                                                    {
                                                        index === 0 ?
                                                            <spam style={{ fontSize: "10px", marginTop: "10px", color: "red" }}>
                                                                Missing Fields: <br />{missingFieldsCalculatePage2()}
                                                            </spam>
                                                            :
                                                            null
                                                    }
                                                </>
                                        }

                                        {
                                            checkmarkVisibilityCalculationPage3()
                                                ?
                                                null :
                                                <>
                                                    {
                                                        index === 1 ?
                                                            <spam style={{ fontSize: "10px", marginTop: "10px", color: "red" }}>
                                                                Missing Fields: <br />{missingFieldsCalculatePage3()}
                                                            </spam>
                                                            :
                                                            null
                                                    }
                                                </>
                                        }

                                        {
                                            checkmarkVisibilityCalculationPage4()
                                                ?
                                                null :
                                                <>
                                                    {
                                                        index === 2 ?
                                                            <spam style={{ fontSize: "10px", marginTop: "10px", color: "red" }}>
                                                                Missing Fields: <br />{missingFieldsCalculatePage4()}
                                                            </spam>
                                                            :
                                                            null
                                                    }
                                                </>
                                        }

                                        {
                                            checkmarkVisibilityCalculationPage5()
                                                ?
                                                null :
                                                <>
                                                    {
                                                        index === 3 ?
                                                            <spam style={{ fontSize: "10px", marginTop: "10px", color: "red" }}>
                                                                Missing Fields: <br />{missingFieldsCalculatePage5()}
                                                            </spam>
                                                            :
                                                            null
                                                    }
                                                </>
                                        }

                                    </div>
                                )
                            })

                            }

                        </div>
                    </div>

                    <div className="infoContainer">
                        <div>
                            {steps && steps.map((item, index) => {
                                return (
                                    <>
                                        {currentPage - 1 === index + 1 ?
                                            <div className="infoTitle">
                                                <h6 style={{ padding: "20px", lineHeight: "0px" }}>{item.name}</h6>
                                                <h6 style={{ padding: "20px", lineHeight: "0px" }}>{index + 1} of {steps.length}</h6>
                                            </div>

                                            : null}
                                    </>
                                )
                            })}
                        </div>

                        <div className="infoBody">
                            <div>
                                <h7 style={{ fontWeight: "bold" }}>Space</h7>
                                <div className="infoBodyInputs">
                                    <div className="responsiveInputSection">
                                        <div>
                                            <label className='add-facility-modal-label'>Space Name {<span style={{ color: "red" }}>*</span>}</label>
                                            <input disabled={viewClicked} type='text' value={spaceName}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    const trimmedValue = eleminateSpacesAtStartOfInputField(value);
                                                    // const regex = /^[a-zA-Z0-9.,/\-_ #&()~]*$/; 
                                                    const regex = /^[a-zA-Z0-9.,/\-_ !@#$%^&*():;+=<>?{}[\]~]*$/;
                                                    if (regex.test(trimmedValue) || trimmedValue === '') {
                                                        if (trimmedValue.length !== 0 && (trimmedValue.length < 3 || trimmedValue.length > 200)) {
                                                            setIsSpaceNameValid(false);
                                                        } else {
                                                            setIsSpaceNameValid(true);
                                                        }
                                                        setSpaceName(trimmedValue);
                                                    }
                                                }}
                                                className='form-control responsive-add-facility-input-fields' />
                                            {!isSpaceNameValid &&
                                                <span className='input-error-text'>Must be min of 3 to max of 200 characters</span>}
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>Security Deposit {<span style={{ color: "red" }}>*</span>}</label>

                                            <div className='Rupee'>
                                                <input disabled={viewClicked}
                                                    value={securityDeposit}
                                                    onChange={(e) => {
                                                        const input = e.target.value;
                                                        const formattedInput = formatNumericparseFloatInput(input);
                                                        setSecurityDeposit(parseFloat(formattedInput));
                                                    }}
                                                    className='form-control noSpinArrows responsive-add-facility-input-fields'
                                                    type="number"
                                                    onWheel={(e) => e.target.blur()}
                                                    style={{ paddingLeft: "30px" }} />
                                                <img src="./images/icons/rupee.svg" alt='ruppee symbol' className='ruppee' />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="responsiveInputSection">

                                    </div>

                                </div>
                            </div>
                            <hr></hr>
                            <div>
                                <div style={{ display: "flex", justifyContent: "space-between", }}>
                                    <h7 style={{ fontWeight: "bold", marginBottom: "10px" }}>SLF Rates {editCompleteContractClicked && <span style={{ color: "red" }}>*</span>}</h7>
                                </div>

                                {slfTableError && slfTableError?.error &&
                                    <div style={{ margin: "0px 0px 10px 0px", background: "rgba(255, 0, 0, 0.1)", border: "1.5px solid red", borderRadius: "8px", padding: "10px" }}>
                                        <MdErrorOutline style={{ color: "red" }} fontSize={18} /> <span className='input-error-text'>{slfTableError.message}</span>
                                    </div>
                                }
                                {contractStartDate && contractEndDate && contractStartDate !== '0000-00-00' && contractEndDate !== '0000-00-00' ?
                                    <div className="infoBodyInputs">
                                        <div className='license_fee_details_table'>
                                            <table>
                                                <thead className='fin_man_table_head commercial-head-table text-center'>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Begin Date <span style={{ color: "red" }}>*</span></th>
                                                        <th>End Date <span style={{ color: "red" }}>*</span></th>
                                                        <th style={{ textWrap: "nowrap" }}>Area (sq. ft)</th>
                                                        <th style={{ textWrap: "nowrap" }}>Cost / Unit (<span><img src="./images/icons/rupee.svg" alt='ruppee symbol' /></span>)</th>
                                                        <th style={{ textWrap: "nowrap" }}>License Fee (<span><img src="./images/icons/rupee.svg" alt='ruppee symbol' /></span>)<span style={{ color: "red" }}>*</span></th>
                                                    </tr>

                                                </thead>
                                                <tbody className='fin_man_table_body'>
                                                    {licenseTableBodyRows?.map((item, index) => (
                                                        <>
                                                            <tr >
                                                                <td className="table_text_right">{index + 1}</td>
                                                                <td><input type="date" value={item.rate_start_date}
                                                                    min={index === 0 ? contractStartDate : item.rate_start_date}
                                                                    onChange={(e) => {
                                                                        const input = e.target.value;
                                                                        let newObj = licenseTableBodyRows;
                                                                        newObj[index].rate_start_date = input
                                                                        if (input > newObj[index].rate_end_date) {
                                                                            newObj[index].rate_end_date = ""
                                                                        }
                                                                        setLicenseTableBodyRows([...newObj])
                                                                        setSlfTableError(licenseFeeDateValidationByStartDateEndDate(newObj, contractStartDate, contractEndDate))
                                                                    }}
                                                                    onKeyDown={(e) => e.preventDefault()}
                                                                    disabled={viewClicked || true}
                                                                    className='form-control noSpinArrows' /></td>

                                                                <td><input type="date" value={item.rate_end_date}
                                                                    min={item.rate_start_date !== "0000-00-00" ? new Date(new Date(item.rate_start_date).setDate(new Date(item.rate_start_date).getDate() + 1)).toISOString().slice(0, 10) : item.rate_start_date}
                                                                    max={contractEndDate}
                                                                    onChange={(e) => {
                                                                        const input = e.target.value;
                                                                        let newObj = licenseTableBodyRows;
                                                                        if (index < licenseTableBodyRows.length - 1) {
                                                                            let date = new Date(input);
                                                                            date.setDate(date.getDate() + 1);
                                                                            date = date.toISOString('yyyy-mm-dd').slice(0, 10);
                                                                            newObj[index + 1].rate_start_date = date;
                                                                        }
                                                                        newObj[index].rate_end_date = input
                                                                        setLicenseTableBodyRows([...newObj])
                                                                        setSlfTableError(licenseFeeDateValidationByStartDateEndDate(newObj, contractStartDate, contractEndDate))
                                                                    }}
                                                                    onKeyDown={(e) => e.preventDefault()}
                                                                    disabled={viewClicked}
                                                                    className='form-control noSpinArrows' /></td>

                                                                <td><input type="number" value={item.area_in_sqft}
                                                                    onChange={(e) => {
                                                                        const input = e.target.value;
                                                                        const formattedInput = parseInt(input);
                                                                        let newObj = [...licenseTableBodyRows];
                                                                        newObj[index].area_in_sqft = parseInt(formattedInput)
                                                                        setLicenseTableBodyRows([...newObj])
                                                                    }}
                                                                    onWheel={(e) => e.target.blur()}

                                                                    disabled={viewClicked}
                                                                    className='form-control noSpinArrows' /></td>
                                                                <td><input type="number"
                                                                    value={item.cost_per_unit}
                                                                    onChange={(e) => {
                                                                        const input = e.target.value;
                                                                        const formattedInput = formatNumericparseFloatInput(input);
                                                                        const limitedDecimals = formattedInput
                                                                            ? parseFloat(formattedInput).toFixed(2)
                                                                            : '';
                                                                        let newObj = licenseTableBodyRows;
                                                                        newObj[index].cost_per_unit = parseFloat(limitedDecimals)
                                                                        newObj[index].license_fee = parseFloat(item.area_in_sqft * item.cost_per_unit).toFixed(2)
                                                                        setLicenseTableBodyRows([...newObj])
                                                                    }}
                                                                    onWheel={(e) => e.target.blur()}

                                                                    disabled={viewClicked}
                                                                    className='form-control noSpinArrows' /></td>
                                                                <td><input type="number"
                                                                    value={item.license_fee}
                                                                    onChange={(e) => {
                                                                        const input = e.target.value;
                                                                        const formattedInput = formatNumericparseFloatInput(input);
                                                                        let newObj = [...licenseTableBodyRows];
                                                                        newObj[index].license_fee = formattedInput;
                                                                        setLicenseTableBodyRows([...newObj]);
                                                                    }}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    disabled={viewClicked}
                                                                    className='form-control noSpinArrows' /></td>
                                                            </tr>


                                                        </>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        {
                                            !viewClicked ?

                                                <div style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}>
                                                    {
                                                        contractEndDate !== licenseTableBodyRows[licenseTableBodyRows.length - 1].rate_end_date ?
                                                            <img style={{ height: "18px", marginRight: "10px" }}
                                                                onClick={handleAddLicenseFeeRow}
                                                                src="./images/icons/PlusaddItem.svg" alt="add"
                                                                title="Add row below"
                                                            /> : null
                                                    }
                                                    {
                                                        licenseTableBodyRows.length >= 2 ?
                                                            <img style={{ height: "18px" }} src="./images/icons/MinusremoveItem.svg"
                                                                onClick={() => handleRemoveLicenseFeeRow(licenseTableBodyRows.length - 1)}
                                                                alt="remove"
                                                                title="Delete last row"
                                                            /> : null
                                                    }

                                                </div> :
                                                null}
                                    </div>
                                    :
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                        <span className='input-error-text'>Please select the contract start date and end date</span>
                                    </div>
                                }
                            </div>
                            <hr></hr>
                            <div>
                                <h7 style={{ fontWeight: "bold" }}>Miscellaneous</h7>
                                <div className="infoBodyInputs">
                                    <div className="responsiveInputSection">
                                        <div>
                                            <label className='add-facility-modal-label'>Electricity Load (per kW) {<span style={{ color: "red" }}>*</span>}</label>
                                            <input disabled={viewClicked} type='number'
                                                onWheel={(e) => e.target.blur()}
                                                value={electricityLoad}
                                                onChange={(e) => {
                                                    const input = e.target.value;
                                                    const formattedInput = formatNumericparseFloatInput(input);
                                                    setElectricityLoad(parseFloat(formattedInput));
                                                }}
                                                className='form-control responsive-add-facility-input-fields' />
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>Truck Slots {<span style={{ color: "red" }}>*</span>}</label>
                                            <input disabled={viewClicked} type='number' value={truckBays}
                                                onWheel={(e) => e.target.blur()}
                                                onChange={(e) => {
                                                    const input = e.target.value;
                                                    const formattedInput = formatNumericParseIntInput(input);
                                                    setTruckBays(formattedInput);
                                                }}
                                                className='form-control responsive-add-facility-input-fields' />
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>2 Wheeler Parking Slots {<span style={{ color: "red" }}>*</span>}</label>
                                            <input disabled={viewClicked} type='number' value={twoWheelerParkingSlots}
                                                onWheel={(e) => e.target.blur()}
                                                onChange={(e) => {
                                                    const input = e.target.value;
                                                    const formattedInput = formatNumericParseIntInput(input);
                                                    setTwoWheelerParkingSlots(formattedInput);
                                                }}
                                                className='form-control responsive-add-facility-input-fields' />
                                        </div>
                                    </div>
                                </div>
                                <div className="infoBodyInputs">
                                    <div className="responsiveInputSection">
                                        <div >
                                            <label className='add-facility-modal-label'>4 Wheeler Parking Slots {<span style={{ color: "red" }}>*</span>}</label>
                                            <input disabled={viewClicked} type='number' value={fourWheelerParkingSlots}
                                                onWheel={(e) => e.target.blur()}
                                                onChange={(e) => {
                                                    const input = e.target.value;
                                                    const formattedInput = formatNumericParseIntInput(input);
                                                    setFourWheelerParkingSlots(formattedInput);
                                                }}
                                                className='form-control responsive-add-facility-input-fields' />
                                        </div>
                                        <div>
                                            <label className='add-facility-modal-label'>DG Rate (per kW) {<span style={{ color: "red" }}>*</span>}</label>
                                            <div className='Rupee'>
                                                <img src="./images/icons/rupee.svg" alt='rupee symbol' className='ruppee' />
                                                <input value={utilityRates?.find(item => item.utility_type === "dieselgenerator")?.utility_rate} disabled
                                                    className='form-control responsive-add-facility-input-fields'
                                                    style={{ paddingLeft: "30px" }} />
                                            </div></div>
                                        <div >
                                            <label className='add-facility-modal-label'>EB Rate (per kW) {<span style={{ color: "red" }}>*</span>}</label>
                                            <div className='Rupee'>
                                                <img src="./images/icons/rupee.svg" alt='rupee symbol' className='ruppee' />
                                                <input value={utilityRates?.find(item => item.utility_type === "electricity")?.utility_rate} className='form-control responsive-add-facility-input-fields' style={{ paddingLeft: "30px" }} disabled />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="infoBodyInputs">
                                    <div className="responsiveInputSection">
                                        <div >
                                            <label className='add-facility-modal-label'>  Water Rate (per kL) {<span style={{ color: "red" }}>*</span>}</label>
                                            <div className='Rupee'>
                                                <img src="./images/icons/rupee.svg" alt='rupee symbol' className='ruppee' />
                                                <input type='number'
                                                    onWheel={(e) => e.target.blur()}
                                                    className='form-control responsive-add-facility-input-fields'
                                                    value={utilityRates?.find(item => item.utility_type === "water")?.utility_rate} style={{ paddingLeft: "30px" }} disabled />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                            <div>
                                <label >Terms and Conditions {<span style={{ color: "red" }}>*</span>}</label>
                                <UploadComponent
                                    onFilesSelected={handleSelectTermsAndCondition}
                                    idType={"termsandconditions"}
                                    uploadActionClicked={handleTermsAndConditionsUpload}
                                    uploadMethod={"single"}
                                    viewMode={viewClicked}
                                    maxNumberOfFiles={1}
                                    uploadedFileData={termsAndConditions ? [{ file_name: termsAndConditions?.split("/")[2]?.split("-")[0], file_type: termsAndConditions?.split("/")[2]?.split(".")[1], s3_key: termsAndConditions }] : []}
                                    acceptedFileTypes={".pdf"}
                                />
                            </div>
                            <hr></hr>

                            <div className="infoBodyInputs">
                                <div className="responsiveInputSection" >
                                    <div>
                                        <UploadComponent
                                            onFilesSelected={handleFilesSelected}
                                            uploadMethod="single"
                                            viewMode={viewClicked}
                                            uploadedFileData={commercialHeadGetDocumentsForContract}
                                            uploadActionClicked={handleDocumentUpload}
                                            deleteSpecificFileMain={confirmDeleteDocument}
                                            maxNumberOfFiles={5}
                                            handleErrorFromDocument={handleErrorFromDocument}
                                            idType={"contract"}
                                            acceptedFileTypes={".pdf, .jpeg, .jpg, .png"}
                                        />
                                    </div>
                                    <div>
                                        <span style={{ marginRight: "20px", fontSize: "medium", fontWeight: "normal" }}>HOTO Status{<span style={{ color: "red" }}>*</span>}</span>
                                        <select disabled={viewClicked} style={{ padding: "5px", borderRadius: "5px" }}
                                            value={hotoStatus}
                                            onChange={(e) => {
                                                setHotoStatus(e.target.value)
                                            }}
                                        >
                                            <option hidden>Select</option>
                                            <option value='pending'>Pending</option>
                                            <option value='completed'>Completed</option>
                                        </select>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="infoFotter">

                            {/* <button className="infoNextBtn" style={{ marginRight: "20px" }} onClick={() => closeClicked()}>Close</button> */}
                            {!viewClicked && contractType === "all" && currentPage !== 1 && (
                                <button
                                    className="infoNextBtn"
                                    style={{ marginRight: "20px" }}
                                    onClick={handleShowCloseConfirmationModal}
                                >
                                    Close
                                </button>
                            )}
                            {!viewClicked && contractType === "draft" && currentPage !== 1 && (
                                <button
                                    className="infoNextBtn"
                                    style={{ marginRight: "20px" }}
                                    onClick={handleShowCloseConfirmationModal}
                                >
                                    Close
                                </button>
                            )}
                            <button className="infoNextBtn" style={{ marginRight: "20px" }} onClick={changePagePrev}>Back</button>

                            {
                                !viewClicked ?
                                    <>
                                        {
                                            (!contractNumber || !contractName || !contractStatus ||
                                                !contactName || !email || !gstNumber ||
                                                !companyName || !panName ||
                                                !registeredStreetAddress || !registeredStreetAddressLane2 || !registeredCity ||
                                                !registeredStateProvince || !registeredPostalCode || !billingStreetAddress ||
                                                !billingStreetAddressLane2 || !billingCity || !billingStateProvince ||
                                                !billingPostalCode || !shippingStreetAddress ||
                                                !shippingStreetAddressLane2 || !shippingCity || !shippingStateProvince ||
                                                !shippingPostalCode || !contractStartDate ||
                                                !contractEndDate || !contractBillingType || !expireNotification || !spaceName ||
                                                !(electricityLoad >= 0) || !(truckBays >= 0) || !(twoWheelerParkingSlots >= 0) || !(fourWheelerParkingSlots >= 0) ||

                                                slfValidationsFunc() ||
                                                !(securityDeposit >= 0) || !(dgRate >= 0) || checkSaveDraftValidation()) && !editCompleteContractClicked ?
                                                <button className="infoNextBtn" onClick={() => {
                                                    handleSaveAsDraftClick()
                                                    setSaveasDraftClicked(true)
                                                }}
                                                    style={checkSaveDraftValidation() ? { opacity: '50%' } : { opacity: '100%', marginRight: "20px" }}
                                                    disabled={checkSaveDraftValidation()}
                                                >Save as Draft</button>
                                                :
                                                <div style={{ display: "flex", flexDirection: "row" }}>
                                                    {
                                                        !(contractType === "all") || !(selectedDraftContract) ?

                                                            <button className="infoNextBtn" style={{ marginRight: "20px" }} onClick={() => {
                                                                handleSaveAsDraftClick()
                                                                setSaveasDraftClicked(true)
                                                            }}>Save as Draft</button>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        isSubmitActive() ?
                                                            <button className="infoNextBtn" onClick={handleSubmit}> Submit</button>
                                                            :
                                                            <>
                                                                {
                                                                    newContractId || selectedDraftContract?.draft_id ?
                                                                        null
                                                                        :
                                                                        <button disabled style={{ opacity: '50%' }} className="infoNextBtn">Submit</button>
                                                                }

                                                            </>
                                                    }
                                                </div>
                                        }
                                    </>
                                    :
                                    <button className="infoNextBtn" style={{ marginRight: "20px" }} onClick={() => changePageNext()} >{currentPage === steps.length + 1 ? "Close" : "Next"}</button>

                            }

                        </div>
                        <Modal
                            backdrop="static"
                            show={showCloseConfirmationModal} onHide={handleCloseCloseConfirmationModal}
                            centered className="popper-modal">
                            <Modal.Body className="text-center">
                                <h5>Are you sure you want to CLOSE? Your progress will  not  be saved.</h5>
                            </Modal.Body>
                            <Modal.Footer className="footer">
                                <Button variant="secondary" className="confirmation" onClick={handleCloseCloseConfirmationModal}>  Cancel </Button>
                                <Button variant="secondary" className="confirmation-left" onClick={handleCloseProceed} > Proceed</Button>
                            </Modal.Footer></Modal>
                        {/*modal for confirmation*/}
                        <Modal keyboard={false} backdrop="static" show={showConfirmationModal} onHide={handleCloseConfirmationModal} centered className="popper-modal" >
                            <Modal.Body className="text-center" ><h5>Are you sure you want to save this as Draft?</h5></Modal.Body>
                            <Modal.Footer className="footer">
                                <Button variant="secondary" className="confirmation" onClick={handleCloseConfirmationModal}>Cancel</Button>
                                <Button variant="secondary" className="confirmation-left" onClick={handleSaveAsDraftConfirmation}>Proceed</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <Modal keyboard={false} backdrop="static" show={showSuccess} onHide={() => {
                        setShowSuccess(false)
                    }} centered className="popper-modal">
                        <Modal.Body className="text-center">
                            <img src="./images/icons/popper.svg" alt="Party Popper" />
                            <h5>Success!</h5>
                            {
                                selectedDraftContract ?
                                    <p className="mb-3">Contract <b>{selectedDraftContract.contract_name}</b> has been successfully updated.</p>
                                    :

                                    <p className="mb-3">Contract <b>{contractName}</b> has been successfully created.</p>
                            }
                        </Modal.Body>
                        <Modal.Footer className="footer">
                            <Button variant="secondary" className="secondary-success" onClick={() => {
                                setShowSuccess(false)
                                // window.location.reload()
                                setCurrentPage(1)
                                dispatch({
                                    type: PUT_CH_ADD_NEW_CONTRACT_SUCCESS,
                                    payload: null,
                                })
                                searchFunc(searchText)
                            }} >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>


                </div>
                :
                null
            }

            {isLoading ? <Loader /> : null}
            {paginationLoader ? <Loader /> : null}
            {contractApiLoader ? <Loader /> : null}
            <Footer />
        </>
    )
}