/**
 * Dropdown component renders a customizable dropdown menu.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {string} props.selectedOption - The currently selected option.
 * @param {function} props.handleChange - The function to handle the change event.
 * @param {Array} props.options - The array of options to display in the dropdown.
 * @param {string} props.name - The label for the dropdown.
 * @param {string} props.className - Additional class names for the select element.
 *
 * @example
 * // Example usage of Dropdown component
 * const options = [
 *   { name: 'Option 1', backgroundColor: 'red' },
 *   { name: 'Option 2', backgroundColor: 'blue' },
 *   { name: 'Option 3', backgroundColor: 'green' }
 * ];
 * 
 * function handleDropdownChange(event) {
 *   console.log(event.target.value);
 * }
 * 
 * <Dropdown
 *   selectedOption="Option 1"
 *   handleChange={handleDropdownChange}
 *   options={options}
 *   name="Select an Option"
 *   className="custom-dropdown"
 * />
 */


import React from 'react'

export default function Dropdown({ selectedOption, handleChange, options, name, className, defaultOption }) {
    return (
        <>
            <div className='d-flex flex-column'>
                {name && <label>{name}</label>}
                <select className={className} value={selectedOption} style={{ border: "1px solid lightgray", height: "40px" }} onChange={handleChange}>
                    {defaultOption && <option value={defaultOption.toLowerCase()}>{defaultOption}</option>}
                    {options.map((option, index) => {
                        return <option style={{ backgroundColor: option?.backgroundColor }} key={index} value={option?.value}>
                            {option?.name}
                        </option>
                    })}
                </select>
            </div>
        </>
    )
}


