import axios from 'axios';

axios.interceptors.request.use(
(request) => {
      const user_token = localStorage.getItem('access_token');
      if(user_token){
        request.headers['Authorization'] = `Bearer ${user_token}`;
        request.headers['Content-Type'] = 'application/json';
      }
      return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
      return response;
  },
  (error) => {
      if (error.response.status === 401) {
          // Unauthorized, redirect to login page
          window.location.href = "/unauthorized";
      } else if (error.response.status === 403) {
          // Forbidden, redirect to login page
          window.location.href = "/accessDenied";
      } else {
          return Promise.reject(error);
      }
      // return Promise.reject(error);
  }
);


export default axios;
