import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FaRegEdit } from "react-icons/fa";
import { GET_ALL_USERS, GET_ALL_USERS_ERROR, getAllUsers } from '../../Redux/Actions/Actions';
import { useDispatch, useSelector } from 'react-redux';
import FormLoader from '../Reusable_Components/FromLoader';
import { Button, Modal } from 'react-bootstrap';
import { MdErrorOutline } from 'react-icons/md';
import NavBarTop from "../Reusable_Components/NavBarTop";
import LeftNavBar from "../Reusable_Components/LeftNavBar";
import Pagination from '../Reusable_Components/Pagination';
import AddUser from '../AddUser/AddUser';
import EditUser from '../AddUser/EditUser';
import { GoSearch } from 'react-icons/go';
import { timeElapsed, validateValue } from '../Reusable_Components/ReusableFunctions';
import Footer from "../Login/Footer.jsx";

export default function CommercialHeadUsersList() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const usersList = useSelector(state => state.usersList)
    const usersListError = useSelector(state => state.usersListError)
    const loading = useSelector(state => state.loading)
    const searchTerm = useSelector(state => state.searchTerm);
    const [page, setPage] = useState(1)
    const [userData, setUserData] = useState({})
    const [searchText, setSearchText] = useState("")

    const handleSort = (key) => {
        let sortedList = [...filteredUsersList];
        sortedList.sort((a, b) => {
            if (a[key] < b[key]) {
                return -1;
            }
            if (a[key] > b[key]) {
                return 1;
            }
            return 0;
        });
        dispatch({
            type: GET_ALL_USERS,
            payload: sortedList
        })
    }

    const searchFunc = (value) =>{
        if (validateValue(value)) {
            setSearchText(value);
            dispatch({
                type: "SEARCH_TERM",
                payload: value
            })
        }
    }

    const filteredUsersList = useMemo(() => {
        if (!searchTerm) {
            return usersList?.sort((a, b) => new Date(b.user_create_date) - new Date(a.user_create_date));
        }
        let filter = usersList?.filter(user => {
            return user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.phone_number.toLowerCase().includes(searchTerm.toLowerCase());
        });
        return filter?.sort((a, b) => new Date(b.user_create_date) - new Date(a.user_create_date));
    }, [searchTerm, usersList, handleSort]);

    const [group, setGroup] = useState("Commercial Head")

    useEffect(() => {
        dispatch(getAllUsers(group))
    }, [group, page])

    return (
        <>
            <>
                <NavBarTop />
                <LeftNavBar />
            </>

            {page === 1 && <div className='users-list-homepage'>
                {loading && <FormLoader />}
                <div className='commercial-head-header-container' style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <h1>Users</h1>
                    <div className='add-facility-button' onClick={() => setPage(2)}>
                        <img src='./images/icons/addNewUser.png' alt='add new user' />
                        <span>Add New User</span>
                    </div>
                </div>
                <div className='d-flex gap-3 align-items-center flex-wrap mt-3'>
                    <div className='d-flex gap-2 align-items-center'>
                        <label>Select Group</label>
                        <select className='form-select add-facility-input-fields' style={{ height: "45px", border: "1px solid #D3D3D3" }} value={group} onChange={(e) => setGroup(e.target.value)}>
                            <option hidden>Select</option>
                            <option value="Commercial Head">Commercial Head</option>
                            <option value="CEO">CEO</option>
                            <option value="Finance Manager">Finance Manager</option>
                            <option value="Facility Manager">Facility Manager</option>
                            <option value="Technician">Technician</option>
                        </select>
                    </div>
                    <div className='fin_man_meter_search_bar_container'>
                        <input type='search' placeholder='Search' value={searchText} className='fin_man_meter_search_bar' 
                         onChange={(e) => searchFunc(e.target.value)}/>
                        <GoSearch className='fin_man_meter_search_bar_icon' />
                    </div>
                </div>
                <div className='userstable_container mt-2'>
                    <table>
                        <thead>
                            <tr className='fin_man_table_head'>
                                <th className='C-pointer'>Name</th>
                                <th className='C-pointer'>Email</th>
                                <th>Username</th>
                                <th>Phone Number</th>
                                <th>Status</th>
                                <th>Created</th>
                                <th>Updated</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody className='fin_man_table_body'>
                            {filteredUsersList && filteredUsersList.length > 0 ? filteredUsersList.map((user) => {
                                return (
                                    <tr key={user.sub} className='table_row'>
                                        <td>{user.name}</td>
                                        <td>{user.email}</td>
                                        <td>{user.username}</td>
                                        <td className='text-end'>{user.phone_number}</td>
                                        <td>{user?.status ? "Active" : "Inactive"}</td>
                                        <td title={user.user_create_date}>{timeElapsed(user.user_create_date)}</td>
                                        <td title={user.user_last_modified_date}>{timeElapsed(user.user_last_modified_date)}</td>
                                        <td style={{ textAlign: "center" }}><FaRegEdit title='edit' style={{ height: "15px", width: "15px" }} onClick={() => {
                                            setPage(3)
                                            setUserData({ ...user, group })
                                        }} /></td>
                                    </tr>
                                )
                            }) : <tr>
                                <td colSpan="8" className='text-center'>No users found</td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
                {usersListError && <Modal keyboard={false} backdrop="static" show={usersListError} onHide={() => {
                    dispatch({
                        type: GET_ALL_USERS_ERROR,
                        payload: null
                    })
                }} centered className="popper-modal">
                    <Modal.Body className="text-center">
                        <MdErrorOutline style={{ fontWeight: "10px", fontSize: "60px", color: "#Cc0202", opacity: 0.8 }} />
                        <h5>Error!</h5>
                        <p className="mb-3">{usersListError}</p>
                    </Modal.Body>
                    <Modal.Footer className='footer'>
                        <Button variant="secondary" className="secondary-left" onClick={() => {
                            dispatch({
                                type: GET_ALL_USERS_ERROR,
                                payload: null
                            })
                            setGroup("Commercial Head")
                        }} >
                            Close
                        </Button>
                        <Button variant="secondary" className="secondary" onClick={() => {
                            dispatch(getAllUsers(group))
                        }} >
                            Retry
                        </Button>
                    </Modal.Footer>
                </Modal>}
            </div>}
            {page === 2 && <AddUser setPage={setPage} />}
            {page === 3 && <EditUser setPage={setPage} userData={userData} />}
            <Footer/>
        </>
    )
}
