import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { UPDATE_USER_STATUS_FAILURE, UPDATE_USER_STATUS_SUCCESS, updateUserStatus } from '../../Redux/Actions/Actions'
import FormLoader from '../Reusable_Components/FromLoader'
import { Button, Modal } from 'react-bootstrap'
import { MdErrorOutline } from 'react-icons/md'
import NavBarTop from '../Reusable_Components/NavBarTop'
import LeftNavBar from '../Reusable_Components/LeftNavBar'
import Footer from "../Login/Footer.jsx";
import { encrypt } from '../Reusable_Components/ReusableFunctions'

export default function EditUser(props) {

    const dispatch = useDispatch()

    let user = props?.userData

    const updateUserStatusSuccess = useSelector(state => state.updateUserStatusSuccess)
    const updateUserStatusFailure = useSelector(state => state.updateUserStatusFailure)
    const loading = useSelector(state => state.loading)

    const [status, setStatus] = useState(user.status ? "Active" : "Inactive")
    const [updateDisable, setUpdateDisable] = useState(true)

    const handleUpdateStatus = (username) => {
        let userStatus = status === "Active" ? "true" : "false"
        let data = {
            status: userStatus,
            username: username
        }
        dispatch(updateUserStatus(encrypt(data)))
    }

    return (
        <>
            <>
                <NavBarTop />
                <LeftNavBar />
            </>
            <div className="addUserContainer">
                {loading && <FormLoader />}
                <div className="stepsContainer">
                    <div className="stepsTitle">
                        <h6 style={{ padding: "20px", lineHeight: "0px" }}>Edit User</h6>
                    </div>
                    <div className={'stepsCardActive'}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <span style={{ fontWeight: "bold", fontSize: "medium" }}>User Information</span>
                        </div>
                        <span style={{ fontSize: "small", marginTop: "10px" }}>User Details</span>
                    </div>
                </div>
                <div className="infoContainer">
                    <div className="infoTitle">
                        <h6 style={{ padding: "20px", lineHeight: "0px" }}>1. User Information</h6>
                    </div>
                    <div className="infoBody">
                        <div>
                            <h7 style={{ fontWeight: "bold" }}>User Details</h7>
                            <div className='d-flex flex-column gap-3 mt-3'>
                                <div className='d-flex align-items-center gap-2 '>
                                    <label style={{ width: "40%" }}>Full Name<span className='text-danger'>*</span></label>
                                    <input type="text" disabled placeholder="Enter Full Name" value={user.name} className='form-control' />
                                </div>
                                <div className='d-flex align-items-center gap-2 '>
                                    <label style={{ width: "40%" }}>Username<span className='text-danger'>*</span></label>
                                    <input type="text" disabled placeholder="Enter Username" value={user.username} className='form-control' />
                                </div>
                                <div className='d-flex align-items-center gap-2 '>
                                    <label style={{ width: "40%" }}>Email<span className='text-danger'>*</span></label>
                                    <input type="email" disabled placeholder="Enter Email" value={user.email} className='form-control' />
                                </div>
                                <div className='d-flex align-items-center gap-2 '>
                                    <label style={{ width: "40%" }}>Phone Number<span className='text-danger'>*</span></label>
                                    <div className='d-flex align-items-center' style={{ width: "100%" }}>
                                        <input type="text" placeholder="Enter Phone number" value={user.phone_number} className='form-control noSpinArrows' style={{ borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px" }} disabled />
                                    </div>
                                </div>
                                <div className='d-flex align-items-center gap-2 '>
                                    <label style={{ width: "40%" }}>Group<span className='text-danger'>*</span></label>
                                    <select className='form-control' disabled value={user.group} style={{ color: "gray" }}>
                                        <option hidden >Select a Group</option>
                                        <option>Commercial Head</option>
                                        <option>Facility Manager</option>
                                        <option>Finance Manager</option>
                                        <option>Technician</option>
                                        <option>CEO</option>
                                    </select>
                                </div>
                                <div className='d-flex align-items-center gap-2 '>
                                    <label style={{ width: "40%" }}>Status<span className='text-danger'>*</span></label>
                                    <select className='fin_man_meter_dropdown' style={{ color: "gray", width: "100%" }} value={status} onChange={(e) => {
                                        setStatus(e.target.value)
                                        setUpdateDisable(false)
                                    }}
                                        disabled={localStorage.getItem("username") === user.username}
                                    >
                                        <option hidden >Select a Status</option>
                                        <option value={"Active"}>Active</option>
                                        <option value={"Inactive"}>Inactive</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="infoFotter">
                        <div className="d-flex gap-3">
                            <button className="btn btn-secondary" onClick={() => props?.setPage(1)}>Back</button>
                            <button className="btn btn-secondary" disabled={updateDisable || status === (user.status ? "Active" : "Inactive")} onClick={() => handleUpdateStatus(user.username)}>Update</button>
                        </div>
                    </div>
                </div>
                {updateUserStatusSuccess && <Modal keyboard={false} backdrop="static" show={updateUserStatusSuccess} onHide={() => {
                    dispatch({
                        type: UPDATE_USER_STATUS_SUCCESS,
                        payload: null
                    })
                }} centered className="popper-modal">
                    <Modal.Body className="text-center">
                        <img src="./images/icons/popper.svg" alt="Party Popper" />
                        <h5>Success!</h5>
                        <p className="mb-3">{updateUserStatusSuccess}</p>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center" >
                        <Button variant="secondary" className="secondary" onClick={() => {
                            dispatch({
                                type: UPDATE_USER_STATUS_SUCCESS,
                                payload: null
                            })
                            props?.setPage(1)
                        }} >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>}
                {updateUserStatusFailure && <Modal keyboard={false} backdrop="static" show={updateUserStatusFailure} onHide={() => {
                    dispatch({
                        type: UPDATE_USER_STATUS_FAILURE,
                        payload: null
                    })
                }} centered className="popper-modal">
                    <Modal.Body className="text-center">
                        <MdErrorOutline color='red' style={{ height: "60px", width: "60px", fontWeight: "10px" }} />
                        <h5>Error!</h5>
                        <p className="mb-3">{updateUserStatusFailure}</p>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center" >
                        <Button variant="secondary" className="secondary" onClick={() => {
                            dispatch({
                                type: UPDATE_USER_STATUS_FAILURE,
                                payload: null
                            })
                        }} >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>}
            </div>
            <Footer />

        </>
    )
}
