import React, { useState } from 'react'
// import UploadCompoent from '../Reusable_Components/UploadComponent'
import PaymentTerms from '../Admin_Controls/PaymentTerms'
import TermsAndConditions from '../Admin_Controls/TermsAndConditions'
import { MdErrorOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_NEW_SERVICE_FAILURE, ADD_NEW_SERVICE_SUCCESS, addNewService, UPLOAD_PAYMENT_TERMS_SUCCESS, UPLOAD_TERMS_AND_CONDITIONS_SUCCESS } from '../../Redux/Actions/Actions';
import { Button, Modal } from 'react-bootstrap';
import AlertPopUp from '../Reusable_Components/Alert';
import { eleminateSpacesAtStartOfInputField, eleminateSpecialCharactersAtStartOfInputField } from '../Reusable_Components/ReusableFunctions';
import Footer from "../Login/Footer.jsx";

export default function AddService(props) {

    const dispatch = useDispatch();

    const addNewServiceSuccess = useSelector(state => state.addNewService)
    const addNewServiceError = useSelector(state => state.addNewServiceError)
    const uploadTermsAndConditionsSuccess = useSelector(state => state.uploadTermsAndConditionsSuccess)
    const uploadPaymentTermsSuccess = useSelector(state => state.uploadPaymentTermsSuccess)


    const [serviceName, setServiceName] = useState("");
    const [serviceCode, setServiceCode] = useState("");
    const [serviceDescription, setServiceDescription] = useState("");
    const [invoiceName, setInvoiceName] = useState("");
    const [invoiceDescription, setInvoiceDescription] = useState("");
    const [invoiceFrequency, setInvoiceFrequency] = useState("");
    const [generationDate, setGenerationDate] = useState("");
    const [cutOffDate, setCutOffDate] = useState("");
    const [prePaid, setPrePaid] = useState(false);
    const [chargeByConsumption, setChargeByConsumption] = useState(false);
    const [proRateBilling, setProRateBilling] = useState(false);
    const [hsnCode, setHsnCode] = useState("");
    const [cgst, setCgst] = useState("");
    const [sgst, setSgst] = useState("");
    const [igst, setIgst] = useState("");
    const [serviceNameError, setServiceNameError] = useState("");
    const [serviceCodeError, setServiceCodeError] = useState("");
    const [serviceDescriptionError, setServiceDescriptionError] = useState("");
    const [invoiceNameError, setInvoiceNameError] = useState("");
    const [invoiceDescriptionError, setInvoiceDescriptionError] = useState("");
    const [invoiceFrequencyError, setInvoiceFrequencyError] = useState("");
    const [generationDateError, setGenerationDateError] = useState("");
    const [hsnCodeError, setHsnCodeError] = useState("");
    const [cgstError, setCgstError] = useState("");
    const [sgstError, setSgstError] = useState("");
    const [igstError, setIgstError] = useState("");

    const handleServiceNameChange = (e) => {
        let input = e.target.value
        input = eleminateSpacesAtStartOfInputField(input)
        input = eleminateSpecialCharactersAtStartOfInputField(input)
        setServiceName(input)
        if (input === "") {
            setServiceNameError("Service Name is required")
        } else {
            setServiceNameError("")
        }
    }

    const handleServiceCodeChange = (e) => {
        let input = eleminateSpacesAtStartOfInputField(e.target.value)
        input = eleminateSpecialCharactersAtStartOfInputField(input)
        if (input === "") {
            setServiceCodeError("Service Code is required")
            setServiceCode(input)
            setInvoiceName(input)
        } else if (input.length > 20) {
            setServiceCodeError("Service Code should be maximum 20 characters")
        } else if (!/^[a-z]+$/.test(input)) {
            setServiceCodeError("Service Code should contain only small letters")
            setServiceCode(input)
            setInvoiceName(input)
        } else {
            setServiceCodeError("")
            setServiceNameError("")
            setServiceCode(input)
            setInvoiceName(input)
        }
    }

    const handleServiceDescriptionChange = (e) => {
        let input = eleminateSpacesAtStartOfInputField(e.target.value)
        input = eleminateSpecialCharactersAtStartOfInputField(input)
        setServiceDescription(input)
        if (input === "") {
            setServiceDescriptionError("Service Description is required")
        } else {
            setServiceDescriptionError("")
        }
    }

    const handleInvoiceNameChange = (e) => {
        let input = eleminateSpacesAtStartOfInputField(e.target.value)
        input = eleminateSpecialCharactersAtStartOfInputField(input)
        setInvoiceName(input)
        if (input === "") {
            setInvoiceNameError("Invoice Name is required")
        } else {
            setInvoiceNameError("")
        }
    }

    const handleInvoiceDescriptionChange = (e) => {
        let input = eleminateSpacesAtStartOfInputField(e.target.value)
        input = eleminateSpecialCharactersAtStartOfInputField(input)
        setInvoiceDescription(input)
        if (input === "") {
            setInvoiceDescriptionError("Invoice Description is required")
        } else {
            setInvoiceDescriptionError("")
        }
    }

    const handleFrequencyChange = (e) => {
        setInvoiceFrequency(e.target.value)
        if (e.target.value === "") {
            setInvoiceFrequencyError("Frequency is required")
        } else {
            setInvoiceFrequencyError("")
        }
    }

    const handleHsnCodeChange = (e) => {
        setHsnCode(e.target.value)
        if (e.target.value === "") {
            setHsnCodeError("HSN Code is required")
        } else {
            setHsnCodeError("")
        }
    }

    const handleCgstChange = (e) => {
        setCgst(e.target.value)
        setSgst(e.target.value)
        setIgst(e.target.value * 2)
        if (e.target.value === "") {
            setCgstError("CGST is required")
            setSgstError("SGST is required")
            setIgstError("IGST is required")
        } else if (![0, 2.5, 6, 9, 14].includes(parseFloat(e.target.value))) {
            setCgstError("Invalid CGST value")
            setSgstError("Invalid SGST value")
            setIgstError("Invalid IGST value")
        } else {
            setCgstError("")
            setSgstError("")
            setIgstError("")
        }
    }

    const handleIgstChange = (e) => {
        setIgst(e.target.value)
        setCgst(e.target.value / 2)
        setSgst(e.target.value / 2)
        if (e.target.value === "") {
            setCgstError("CGST is required")
            setSgstError("SGST is required")
            setIgstError("IGST is required")
        } else if (![0, 5, 12, 18, 28].includes(parseFloat(e.target.value))) {
            setCgstError("Invalid CGST value")
            setSgstError("Invalid SGST value")
            setIgstError("Invalid IGST value")
        } else {
            setCgstError("")
            setSgstError("")
            setIgstError("")
        }
    }

    const handleInvoiceGenerationDateChange = (e) => {
        setGenerationDate(e.target.value)
        setCutOffDate(e.target.value)
        if (e.target.value === "") {
            setGenerationDateError("Invoice generation day is required")
        } else {
            setGenerationDateError("")
        }
    }

    const handleAddNewService = () => {

        const data = {
            "service_name": serviceName.trim(),
            "service_code": serviceCode.trim(),
            "service_description": serviceDescription.trim(),
            "invoice_name": invoiceName.trim(),
            "invoice_description": invoiceDescription.trim(),
            "invoice_frequency": invoiceFrequency,
            "invoice_generation_day": generationDate,
            "invoice_cutoff_day": cutOffDate,
            "pre_pay_ind": prePaid ? 1 : 0,
            "charge_by_consumption_ind": chargeByConsumption ? 1 : 0,
            "allow_prorate_billing_ind": proRateBilling ? 1 : 0,
            "hsn_code": hsnCode,
            "sgst": parseFloat(sgst),
            "cgst": parseFloat(cgst),
            "igst": parseFloat(igst),
            // "terms_and_conditions": uploadTermsAndConditionsSuccess && uploadTermsAndConditionsSuccess,
            // "payment_terms": uploadPaymentTermsSuccess && uploadPaymentTermsSuccess,
        }

        dispatch(addNewService(data))
    }

    const [showBackConfirmation, setShowBackConfirmation] = useState(false)
    const handleBack = () => setShowBackConfirmation(false);
    const handleProceed = () => {
        setShowBackConfirmation(false)
    }

    return (
        <div className="addUserContainer">
            <div className="stepsContainer">
                <div className="stepsTitle">
                    <h6 style={{ padding: "20px", lineHeight: "0px" }}>Add New Service</h6>
                </div>
                <div className={'stepsCardActive'}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <span style={{ fontWeight: "bold", fontSize: "medium" }}>Service Information</span>
                    </div>
                    <span style={{ fontSize: "small", marginTop: "10px" }}>Service Details</span>
                </div>
                <div style={{ height: "80%" }} className='d-flex flex-column align-items-start gap-2 px-3'>
                    {(serviceName === "" || serviceNameError) && <span className='text-danger'><MdErrorOutline fontSize={20} /> Serivce Name</span>}
                    {(serviceCode === "" || serviceCodeError) && <span className='text-danger'><MdErrorOutline fontSize={20} /> Serivce Code</span>}
                    {(serviceDescription === "" || serviceDescriptionError) && <span className='text-danger'><MdErrorOutline fontSize={20} /> Serivce Description</span>}
                    {(invoiceName === "" || invoiceNameError) && <span className='text-danger'><MdErrorOutline fontSize={20} /> Invoice Name</span>}
                    {(invoiceDescription === "" || invoiceDescriptionError) && <span className='text-danger'><MdErrorOutline fontSize={20} /> Invoice Description</span>}
                    {(invoiceFrequency === "" || invoiceFrequencyError) && <span className='text-danger'><MdErrorOutline fontSize={20} /> Invoice Frequency</span>}
                    {(generationDate === "" || generationDateError) && <span className='text-danger'><MdErrorOutline fontSize={20} /> Invoice Generation Date</span>}
                    {(hsnCode === "" || hsnCodeError) && <span className='text-danger'><MdErrorOutline fontSize={20} /> HSN Code</span>}
                    {(cgst === "" || cgstError) && <span className='text-danger'><MdErrorOutline fontSize={20} /> CGST</span>}
                    {(sgst === "" || sgstError) && <span className='text-danger'><MdErrorOutline fontSize={20} /> SGST</span>}
                    {(igst === "" || igstError) && <span className='text-danger'><MdErrorOutline fontSize={20} /> IGST</span>}
                </div>

            </div>
            <div className="infoContainer">
                <div className="infoTitle">
                    <h6 style={{ padding: "20px", lineHeight: "0px" }}>Service Information</h6>
                </div>
                <div className="infoForm">
                    <div className="infoBody">
                        <h6>Service details</h6>
                        <div className="d-flex gap-5 flex-wrap">
                            <div>
                                <label className='add-facility-modal-label'>Service Name<span style={{ color: "red" }}>*</span></label>
                                <input type='text' className='form-control responsive-add-facility-input-fields' value={serviceName}
                                    onChange={handleServiceNameChange}
                                    placeholder='Eg: Space liscese fee, ELectricity etc'
                                />
                                <span style={{ color: "red", fontSize: "small" }}>{serviceNameError}</span>
                            </div>
                            <div>
                                <label className='add-facility-modal-label'>Service Code (max 20 characters)<span style={{ color: "red" }}>*</span></label>
                                <input type='text' className='form-control responsive-add-facility-input-fields' value={serviceCode}
                                    onChange={handleServiceCodeChange}
                                    placeholder='Eg: slf, electricity,water,misc etc'
                                />
                                <span style={{ color: "red", fontSize: "small" }}>{serviceCodeError}</span>
                            </div>
                        </div>
                        <div className="inputsSection">
                            <div style={{ width: "100%" }}>
                                <label className='add-facility-modal-label'>Service Description<span style={{ color: "red" }}>*</span></label>
                                <textarea className='form-control' value={serviceDescription}
                                    onChange={handleServiceDescriptionChange}
                                    placeholder='Description of the service'
                                    style={{ border: "0.5px solid #9D9D9C" }}
                                />
                                <span style={{ color: "red", fontSize: "small" }}>{serviceDescriptionError}</span>
                            </div>
                        </div>
                        <hr />
                        <h6>Invoice Information</h6>
                        <div className="inputsSection">
                            <div>
                                <label className='add-facility-modal-label'>Service name in Invoice<span style={{ color: "red" }}>*</span></label>
                                <input type='text' className='form-control responsive-add-facility-input-fields' value={invoiceName}
                                    onChange={handleInvoiceNameChange}
                                    placeholder='Eg: slf, electricity etc'
                                />
                                <span style={{ color: "red", fontSize: "small" }}>{invoiceNameError}</span>
                            </div>
                        </div>
                        <div className="inputsSection">
                            <div style={{ width: "100%" }}>
                                <label className='add-facility-modal-label'>Invoice Description<span style={{ color: "red" }}>*</span></label>
                                <textarea className='form-control' value={invoiceDescription}
                                    onChange={handleInvoiceDescriptionChange}
                                    placeholder='Description of the invoice'
                                    style={{ border: "0.5px solid #9D9D9C" }}
                                />
                                <span style={{ color: "red", fontSize: "small" }}>{invoiceDescriptionError}</span>
                            </div>
                        </div>
                        <div className="inputsSection">
                            <div>
                                <label className='add-facility-modal-label'>Frequency<span style={{ color: "red" }}>*</span> </label>
                                <select className='form-select responsive-add-facility-input-fields' style={{ height: "40px", border: "1px solid #D3D3D3" }}
                                    value={invoiceFrequency}
                                    onChange={handleFrequencyChange}
                                >
                                    <option value="" hidden>Select</option>
                                    <option value="monthly">Monthly</option>
                                    <option value="quarterly">Quarterly</option>
                                    <option value="yearly">Yearly</option>
                                </select>
                                <span style={{ color: "red", fontSize: "small" }}>{invoiceFrequencyError}</span>
                            </div>
                            <div>
                                <label className='add-facility-modal-label'>Invoice generation day<span style={{ color: "red" }}>*</span> </label>
                                <select className='form-select responsive-add-facility-input-fields' style={{ height: "40px", border: "1px solid #D3D3D3" }}
                                    onChange={handleInvoiceGenerationDateChange}
                                >
                                    <option value="" hidden>Select</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                    <option value="26">26</option>
                                    <option value="27">27</option>
                                    <option value="28">28</option>
                                </select>
                                <span style={{ color: "red", fontSize: "small" }}>{generationDateError}</span>
                            </div>
                            <div>
                                <label className='add-facility-modal-label'>Invoice Cut-off day<span style={{ color: "red" }}>*</span></label>
                                <input type='text' disabled value={cutOffDate} className='form-control responsive-add-facility-input-fields' />
                            </div>
                        </div>
                        <div className="inputsSection">
                            <div className="checkboxHolder">
                                <input type='checkbox' id='prepaid' className="inputCheckbox" checked={prePaid} onChange={(e) => {
                                    setPrePaid(e.target.checked)
                                    if (e.target.checked) {
                                        setChargeByConsumption(false)
                                    }
                                }} />
                                <label className='add-facility-modal-label' htmlFor='prepaid'>Pre paid</label>
                            </div>
                            <div className="checkboxHolder">
                                <input type='checkbox' id='consumption' className="inputCheckbox" checked={chargeByConsumption} onChange={(e) => {
                                    setChargeByConsumption(e.target.checked)
                                    if (e.target.checked) {
                                        setPrePaid(false)
                                    }
                                }} />
                                <label className='add-facility-modal-label' htmlFor='consumption'>Charge by consumption</label>
                            </div>
                            <div className="checkboxHolder">
                                <input type='checkbox' className="inputCheckbox" id='prorate' value={proRateBilling} onChange={(e) => setProRateBilling(e.target.checked)} />
                                <label className='add-facility-modal-label' htmlFor='prorate' >Pro rate billing</label>
                            </div>
                        </div>
                        <hr />
                        <h6>HSN Code</h6>
                        <div className="d-flex gap-5 flex-wrap">
                            <div>
                                <label className='add-facility-modal-label'>HSN Code<span style={{ color: "red" }}>*</span></label>
                                <input type='number'
                                    onWheel={(e) => e.target.blur()}
                                    className='noSpinArrows form-control responsive-add-facility-input-fields'
                                    value={hsnCode}
                                    onChange={handleHsnCodeChange}
                                    placeholder='Eg: 123456 or 12345678'
                                />
                                <span style={{ color: "red", fontSize: "small" }}>{hsnCodeError}</span>
                            </div>
                        </div>
                        <hr />
                        <h6>Tax rates</h6>
                        <b>Intra State: </b>
                        <div className="d-flex gap-5 flex-wrap" style={{ padding: "0px 0px 10px 0px" }}>
                            <div>
                                <label className='add-facility-modal-label'>CGST (0, 2.5, 6, 9, 14)<span style={{ color: "red" }}>*</span></label>
                                <input type='number'
                                    onWheel={(e) => e.target.blur()}
                                    className='noSpinArrows form-control responsive-add-facility-input-fields'
                                    value={cgst}
                                    onChange={handleCgstChange}
                                />
                                <span style={{ color: "red", fontSize: "small" }}>{cgstError}</span>
                            </div>
                            <div>
                                <label className='add-facility-modal-label'>SGST (0, 2.5, 6, 9, 14)<span style={{ color: "red" }}>*</span></label>
                                <input type='number'
                                    onWheel={(e) => e.target.blur()}
                                    className='noSpinArrows form-control responsive-add-facility-input-fields'
                                    value={sgst}
                                    onChange={(e) => handleCgstChange(e)}
                                />
                                <span style={{ color: "red", fontSize: "small" }}>{sgstError}</span>
                            </div>
                        </div>
                        <b>Inter State: </b>
                        <div className="d-flex gap-5 flex-wrap">
                            <div>
                                <label className='add-facility-modal-label'>IGST (0, 5, 12, 18, 28)<span style={{ color: "red" }}>*</span></label>
                                <input type='number'
                                    onWheel={(e) => e.target.blur()}
                                    className='noSpinArrows form-control responsive-add-facility-input-fields'
                                    value={igst}
                                    onChange={(e) => handleIgstChange(e)}
                                />
                                <span style={{ color: "red", fontSize: "small" }}>{igstError}</span>
                            </div>
                        </div>
                        <hr />
                        {/* <h6>Invoice Terms</h6>
                        <div className='inputSection'>
                            <PaymentTerms edit={true} />

                        </div>
                        <div className='inputSection'>
                            <TermsAndConditions edit={true} />
                        </div> */}
                    </div>
                </div>
                <div className="infoFotter">
                    <div className="d-flex gap-3">
                        <button className="btn btn-secondary" onClick={() => setShowBackConfirmation(true)}>Back</button>
                        <button className="btn btn-secondary" disabled={
                            !serviceName || !serviceCode || !serviceDescription || !invoiceName || !invoiceDescription || !invoiceFrequency || !generationDate || !cutOffDate || !hsnCode || !cgst || !sgst || !igst
                            || serviceNameError || serviceCodeError || serviceDescriptionError || invoiceNameError || invoiceDescriptionError || invoiceFrequencyError || generationDateError || hsnCodeError || cgstError || sgstError || igstError
                        }
                            onClick={handleAddNewService}
                        >Add Service</button>
                    </div>
                </div>
            </div>
            <Modal keyboard={false} backdrop="static" show={showBackConfirmation} onHide={() => setShowBackConfirmation(false)} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <h5>Are you sure you want to go back?</h5>
                    <p className="mb-3">All the data will be lost</p>
                </Modal.Body>
                <Modal.Footer className="footer" >
                    <Button variant="secondary" className="confirmation" onClick={handleBack} >
                        Cancel
                    </Button>
                    <Button variant="secondary" className="confirmation-left" onClick={() => props.setPage(1)} >
                        Proceed
                    </Button>
                </Modal.Footer>
            </Modal>



            {addNewServiceSuccess && <Modal keyboard={false} backdrop="static" show={addNewServiceSuccess} onHide={() => {
                dispatch({
                    type: ADD_NEW_SERVICE_SUCCESS,
                    payload: null
                })
            }} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <img src="./images/icons/popper.svg" alt="Party Popper" />
                    <h5>Success!</h5>
                    <p className="mb-3"><b>{serviceName && serviceName}</b> {addNewServiceSuccess}</p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center" >
                    <Button variant="secondary" className="secondary" onClick={() => {
                        dispatch({
                            type: ADD_NEW_SERVICE_SUCCESS,
                            payload: null
                        })
                        dispatch({
                            type: UPLOAD_TERMS_AND_CONDITIONS_SUCCESS,
                            payload: null
                        })
                        dispatch({
                            type: UPLOAD_PAYMENT_TERMS_SUCCESS,
                            payload: null
                        })
                        props.setReload(true)
                        props.setPage(1)
                    }} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>}
            {addNewServiceError && <AlertPopUp alertShow={addNewServiceError} alertType="error" alertMsg="Error" alertValue={addNewServiceError} alertButtons={[{
                name: "Close", action: () => dispatch({
                    type: ADD_NEW_SERVICE_FAILURE,
                    payload: null
                })
            }]} />}
            <Footer />
        </div>

    )
}
