import React from 'react'

export default function Summary({ item }) {

    return (
        <div className='container'>
            <div className="box" style={{ backgroundColor: "#a6edd0" }}>
                <div className="summary-count" style={{ backgroundColor: "#22d68e" }}>{item?.Safety["Precautions Passed"]}/{item?.Safety["Total Safety Precautions"]}</div>
                <div className="summary-body">
                    <div className='d-flex flex-column align-items-start text-start'>
                        <b>Reamrks :</b>
                        <div>
                            {item?.Safety["Failed Precaution Details"]?.Remarks}
                        </div>
                    </div>
                    <div className='d-flex flex-column align-items-start'>
                        <b>Recommendations :</b>
                        <ul className='text-start'>
                            {item?.Safety?.Recommendations?.map((recommendation) => {
                                return <li>{recommendation}</li>
                            })}
                        </ul>
                    </div>
                </div>
                <div className="summary-name" style={{ backgroundColor: "#22d68e" }}>{Object.keys(item)[0]}</div>
            </div>
            <div className="box" style={{ backgroundColor: "#fc883a" }}>
                <div className="summary-count" style={{ backgroundColor: "#bf5717" }}>{item?.Risk["Checks Passed"]}/{item?.Risk["Total Performance Checks"]}</div>
                <div className="summary-body">
                    <div className='d-flex flex-column align-items-start text-start'>
                        <b>Reamrks :</b>
                        <div>{item?.Risk["Failed Check Details"]?.Remarks}
                        </div>
                    </div>
                    <div className='d-flex flex-column align-items-start'>
                        <b>Recommendations :</b>
                        <ul className='text-start'>
                            {item?.Risk?.Recommendations?.map((recommendation) => {
                                return <li>{recommendation}</li>
                            })}
                        </ul>
                    </div>
                </div>
                <div className="summary-name" style={{ backgroundColor: "#bf5717" }}>{Object.keys(item)[1]}</div>
            </div>
            <div className="box" style={{ backgroundColor: "#e84a4a" }}>
                <div className="summary-count" style={{ backgroundColor: "#b82a2a" }}>{item?.Cost["Materials Used"]}/{item?.Cost["Total Materials Required"]}</div>
                <div className="summary-body">
                    <div className='d-flex flex-column align-items-start'>
                        <b>Reamrks :</b>
                        <div>{item?.Cost?.Remarks}</div>
                    </div>
                    <div className='d-flex flex-column align-items-start'>
                        <b>Recommendations :</b>
                        <ul className='text-start'>
                            {item?.Cost?.Recommendations?.map((recommendation) => {
                                return <li>{recommendation}</li>
                            })}
                        </ul>
                    </div>
                </div>
                <div className="summary-name" style={{ backgroundColor: "#b82a2a" }}>{Object.keys(item)[2]}</div>
            </div>
        </div>
    );
}
