import React, { useEffect, useState, useRef } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaRegCircleCheck } from "react-icons/fa6";
import Loader from "../Reusable_Components/loader";

import { commercialHeadAddNewFacility, handlePaginationLoaderTrue, commercialHeadGetAllFacilitiesForNav, commercialHeadUpdateFacility, SET_ERROR_FALSE } from '../../Redux/Actions/Actions';
import { Alert } from 'react-bootstrap';
import { base_url } from "../../utilities";
import axios from "../../axios-interceptor"
import {
    commercialHeadGetAllFacilities, commercialHeadGetFacilityDocuments, commercialHeadUploadFacilityDocument,
    handleDocumentLoaderTrue, handleDocumentLoaderFalse
} from '../../Redux/Actions/Actions';
import { useDispatch, useSelector } from 'react-redux';
import { GoSearch } from 'react-icons/go';
import UploadComponent from '../Reusable_Components/UploadComponent';
import NavBarTop from "../Reusable_Components/NavBarTop";
import LeftNavBar from "../Reusable_Components/LeftNavBar";
import AlertPopUp from '../Reusable_Components/Alert';
import Pagination from "../Reusable_Components/Pagination";
import { RECORDS_PER_PAGE } from "../../Config";
import { timeElapsed, validateValue, formatNumericInput, formatNumericparseFloatInput, eleminateSpacesAtStartOfInputField, formatNumericInputForMaxValue } from '../Reusable_Components/ReusableFunctions';
import Footer from "../Login/Footer.jsx";

export default function CommercialHeadAddFacility() {

    const commercialHeadUploadFacilityDocumentList = useSelector(state => state.commercialHeadUploadFacilityDocumentList);
    const commercialHeadGetFacilityDocumentsList = useSelector(state => state.commercialHeadGetFacilityDocumentsList);
    const [documentsListArray, setDocumentListArray] = useState([])
    const [facilities, setFacilities] = useState([])
    const [show, setShow] = useState(false);
    const [viewModal, setViewModal] = useState(false)
    const [modalData, setModalData] = useState({})
    const [editFacilityDetails, setEditFacilityDetails] = useState(false)
    const fileInputRef = useRef(null);
    const [uploadError, setUploadError] = useState(false)
    const [files, setFiles] = useState([]);
    const [limit, setLimit] = useState(5);
    const [page, setPage] = useState(1);
    const [facilityName, setFacilityName] = useState('');
    const [facilityArea, setFacilityArea] = useState('');
    const [facilityNameError, setFacilityNameError] = useState(false);
    const [uniqueFacilityNameError, setUniqueFacilityNameError] = useState(false);
    const [electricityRate, setElectricityRate] = useState('');
    const [waterRate, setWaterRate] = useState('');
    const [dgRate, setDgRate] = useState('')
    const [address, setAddress] = useState('');
    const [saveDisabled, setSaveDisabled] = useState(true);
    const [lenghtError, setLengthError] = useState(false)
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorValue, setErrorValue] = useState(null)
    const [isupdated, setIsUpdated] = useState(false)
    const isSubmittedFacility = useSelector(state => state.submittedFacility);
    const isUpdatedFacility = useSelector(state => state.updatedFacility);
    const error = useSelector(state => state.error);
    //for alert pop up
    const [alertShow, setAlertShow] = useState(false)
    const [alertType, setAlertType] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertValue, setAlertValue] = useState('')
    const [alertButtons, setAlertButtons] = useState([])
    const [alertAction, setAlertAction] = useState([])
    const dispatch = useDispatch();
    const states = [
        'Select',
        'Delhi',
        'Andhra Pradesh',
        'Arunachal Pradesh',
        'Assam',
        'Bihar',
        'Chhattisgarh',
        'Goa',
        'Gujarat',
        'Haryana',
        'Himachal Pradesh',
        'Jharkhand',
        'Karnataka',
        'Kerala',
        'Madhya Pradesh',
        'Maharashtra',
        'Manipur',
        'Meghalaya',
        'Mizoram',
        'Nagaland',
        'Odisha',
        'Punjab',
        'Rajasthan',
        'Sikkim',
        'Tamil Nadu',
        'Telangana',
        'Tripura',
        'Uttar Pradesh',
        'Uttarakhand',
        'West Bengal'
    ];
    const cities = {
        'Delhi': ['Select', 'New Delhi'],
        'Andhra Pradesh': ['Select', 'Visakhapatnam', 'Vijayawada', 'Guntur', 'Nellore'],
        'Arunachal Pradesh': ['Select', 'Itanagar', 'Naharlagun'],
        'Assam': ['Select', 'Guwahati', 'Dibrugarh', 'Jorhat', 'Silchar'],
        'Bihar': ['Select', 'Patna', 'Gaya', 'Bhagalpur', 'Muzaffarpur'],
        'Chhattisgarh': ['Select', 'Raipur', 'Bilaspur', 'Durg', 'Bhilai'],
        'Goa': ['Select', 'Panaji', 'Margao', 'Vasco da Gama', 'Mapusa'],
        'Gujarat': ['Select', 'Ahmedabad', 'Surat', 'Vadodara', 'Rajkot'],
        'Haryana': ['Select', 'Chandigarh', 'Gurgaon', 'Faridabad', 'Panipat'],
        'Himachal Pradesh': ['Select', 'Shimla', 'Manali', 'Dharamshala', 'Solan'],
        'Jharkhand': ['Select', 'Ranchi', 'Jamshedpur', 'Dhanbad', 'Bokaro'],
        'Karnataka': ['Select', 'Bangalore', 'Mysore', 'Mangalore', 'Hubli'],
        'Kerala': ['Select', 'Thiruvananthapuram', 'Kochi', 'Kozhikode', 'Thrissur'],
        'Madhya Pradesh': ['Select', 'Bhopal', 'Indore', 'Gwalior', 'Jabalpur'],
        'Maharashtra': ['Select', 'Mumbai', 'Pune', 'Nagpur', 'Nashik'],
        'Manipur': ['Select', 'Imphal', 'Thoubal'],
        'Meghalaya': ['Select', 'Shillong', 'Tura'],
        'Mizoram': ['Select', 'Aizawl', 'Lunglei'],
        'Nagaland': ['Select', 'Kohima', 'Dimapur'],
        'Odisha': ['Select', 'Bhubaneswar', 'Cuttack', 'Rourkela', 'Puri'],
        'Punjab': ['Select', 'Chandigarh', 'Ludhiana', 'Amritsar', 'Jalandhar'],
        'Rajasthan': ['Select', 'Jaipur', 'Udaipur', 'Jodhpur', 'Kota'],
        'Sikkim': ['Select', 'Gangtok', 'Namchi'],
        'Tamil Nadu': ['Select', 'Chennai', 'Coimbatore', 'Madurai', 'Tiruchirappalli'],
        'Telangana': ['Select', 'Hyderabad', 'Warangal', 'Nizamabad', 'Khammam'],
        'Tripura': ['Select', 'Agartala', 'Udaipur'],
        'Uttar Pradesh': ['Select', 'Lucknow', 'Kanpur', 'Agra', 'Varanasi'],
        'Uttarakhand': ['Select', 'Dehradun', 'Haridwar', 'Roorkee'],
        'West Bengal': ['Select', 'Kolkata', 'Howrah', 'Darjeeling', 'Durgapur']
    };
    useEffect(() => {
        if (isSubmittedFacility === "success") {
            setShow(false);
            setShowSuccess(true);
        }
        setIsLoading(false);
    }, [isSubmittedFacility])

    useEffect(() => {
        if (commercialHeadGetFacilityDocumentsList) {
            setDocumentListArray(commercialHeadGetFacilityDocumentsList)
            setIsLoading(false)
        }
    }, [commercialHeadGetFacilityDocumentsList])

    useEffect(() => {
        setErrorValue(error?.response?.data.message);
        if (error?.response?.data.message || error?.data?.data[1]?.message) {
            setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error')
            setAlertValue(error?.data?.data[1] ? error?.data?.data[1].message : error?.response?.data.message)
            setAlertButtons([{ name: 'Close', action: () => { 
                setAlertShow(false) 
                dispatch({
                    type: SET_ERROR_FALSE,
                    payload: null
                })
            } }])
        }
        setIsLoading(false);
    }, [error])

    useEffect(() => {
        if (isUpdatedFacility === "success") {
            setViewModal(false);
            setIsUpdated(true)
            setShowSuccess(true);
        }
        setIsLoading(false);
    }, [isUpdatedFacility])




    const [sortOrder, setSortOrder] = useState('asc');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredList, setFilteredList] = useState(null);
    const [selectedStateFilter, setSelectedStateFilter] = useState('');
    const [selectedCityFilter, setSelectedCityFilter] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [selectedStateList, setSelectedStateList] = useState([]);
    const [selectedCityDropdown, setSelectedCityDropdown] = useState('');

    const facilitiesList = useSelector(state => state.facilitiesList?.data);
    const facilitiesListForNav = useSelector(state => state.facilitiesListForNav)
    const totalRecords = useSelector((state) => state.facilitiesList?.info?.total_no_of_records);
    const currentPaginationPage = useSelector((state) => state.facilitiesList?.info?.page_number);
    const paginationLoader = useSelector(state => state.paginationLoader)

    const [showCloseConfirmationModal, setShowCloseConfirmationModal] = useState(false)
    const handleCloseConfirmation = () => setShowCloseConfirmationModal(false);
    const handleProceed = () => {
        handleCloseConfirmation();
        handleClose(); 
    };

    const [showBackCloseConfirmation, setShowBackCloseConfirmation] = useState(false)
    const handleCloseBackConfirmation = () => setShowBackCloseConfirmation(false);
    const handleBackProceed = () => {
        handleCloseBackConfirmation();
        handleCloseModal();
        // handleCloseConfirmation();
    };

    const handleStateChange = (event) => {
        setSelectedState(event.target.value);
        setSelectedCity('');
    };

    const handleCityChange = (event) => {
        setSelectedCity(event.target.value);
    };

    const handleAddressChange = (event) => {
        const input = event.target.value
        const formattedInput = eleminateSpacesAtStartOfInputField(input)
        setAddress(formattedInput);
    };

    const handleSave = () => {
        const data = {
            "name": facilityName,
            "state": selectedState,
            "city": selectedCity,
            "address": address,
            "total_area": facilityArea,
            "total_area_unit": "sq. ft",
            "water_rate": waterRate,
            "dg_rate": dgRate,
            "electricity_rate": electricityRate,
        }
        dispatch(commercialHeadAddNewFacility(data))
        dispatch(commercialHeadGetAllFacilitiesForNav())
        setIsLoading(true);
        handleClose();
    }

    const handleFacilityAreaChange = (e) => {
        const input = e.target.value;
        const formattedInput = input.replace(/[^0-9]/g, '');
        setFacilityArea(formattedInput)
    };

    const handleElectricityRateChange = (e) => {
        const input = e.target.value;
        const formattedInput = formatNumericInputForMaxValue(input);
        setElectricityRate(formattedInput);
    };
    const handleWaterRateChange = (e) => {
        const input = e.target.value;
        const formattedInput = formatNumericInputForMaxValue(input);
        setWaterRate(formattedInput);
    };

    const handleDgRate = (e) => {
        const input = e.target.value;
        const formattedInput = formatNumericInputForMaxValue(input);
        setDgRate(formattedInput);
    }
    
    const handleFacilityNameChange = (event) => {
        let inputValue = event.target.value;
    
        if (validateValue(inputValue.charAt(0))) {
            const regex = /^[A-Za-z0-9\s-/_]+$/;
            
            if (inputValue.length < 3 || inputValue.length > 200) {
                setLengthError(true);
            } else {
                setLengthError(false);
            }
    
            if (regex.test(inputValue) || inputValue === '') {
                setFacilityName(inputValue);
                if (isFacilityNameUnique(inputValue)) {
                    setUniqueFacilityNameError(false);
                } else {
                    setUniqueFacilityNameError(true);
                }
                setFacilityNameError(false);
            } else {
                setFacilityNameError(true);
            }
        } else {
            event.target.value = facilityName;
        }
    };
    // const handleCLoseBack = () => {
    //   setShowConfirmationModal(true)
    // }

    const handleClose = () => {
        setShow(false)
        setEditFacilityDetails(false)
        setFiles([])
        setLengthError(false)
        setUniqueFacilityNameError(false)
        setFacilityNameError(false)
        setLengthError(false)
        setUploadError(false)
        setFacilityNameError('')
        setElectricityRate('')
        setWaterRate('')
        setDgRate('')
        setAddress('')
        setFacilityName('')
        setFacilityArea('')
        setSelectedCity('')
        setSelectedState('')
        setDocumentListArray([])
    };

    const handleShow = () => setShow(true);

    const handleCloseModal = () => {
        setViewModal(false)
        setEditFacilityDetails(false)
        setUniqueFacilityNameError(false)
        setDocumentList([])
        setLengthError(false)
        setEditFacilityDetails(false)
        setFiles([])
        setLengthError(false)
        setUniqueFacilityNameError(false)
        setFacilityNameError(false)
        setLengthError(false)
        setUploadError(false)
        setElectricityRate('')
        setWaterRate('')
        setAddress('')
        setFacilityName('')
        setFacilityArea('')
        setSelectedCity('')
        setSelectedState('')
        setDocumentListArray([])
        // window.location.reload()

    }
    const [selectedFacilityId, setSelectedFacilityId] = useState();
    const handleShowModal = (id) => {
        dispatch(commercialHeadGetAllFacilitiesForNav())
        setIsLoading(true)
        setSelectedFacilityId(id)
        dispatch(commercialHeadGetFacilityDocuments(id))
        const filteredData = facilitiesList.filter((each) => each.facility_id === id)
        setModalData(filteredData[0])
        setViewModal(true)
        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
    }

    useEffect(() => {
        dispatch(commercialHeadGetAllFacilities({ page: 1, limit: RECORDS_PER_PAGE, search: searchTerm, state: selectedStateFilter, city: selectedCityDropdown }));
        dispatch(commercialHeadGetAllFacilitiesForNav())
        setIsLoading(true);
    }, []);



    useEffect(() => {
        if (facilitiesList) {
            setFilteredList(facilitiesList)

        }
        setIsLoading(false)
    }, [facilitiesList])

    useEffect(() => {
        if (facilitiesListForNav) {
            const stateData = [];
            const citiesData = {};
    
            facilitiesListForNav.forEach(e => {
                if (!stateData.includes(e.state)) {
                    stateData.push(e.state);
                }
                if (!citiesData[e.state]) {
                    citiesData[e.state] = [];
                }
                if (!citiesData[e.state].includes(e.city)) {
                    citiesData[e.state].push(e.city);
                }
            });
            stateData.sort(); //sorting added here
            for (const state in citiesData) {
                citiesData[state].sort();
            }
    
            setSelectedStateList(stateData);
            setSelectedCityFilter(citiesData);
        }
    }, [facilitiesListForNav]);


    useEffect(() => {
        setFacilities(facilitiesList)
        const requiredFieldsFilled = selectedState !== '' || selectedState !== 'Select' ||
            selectedCity !== '' || facilityName !== '' ||
            electricityRate !== '' || waterRate !== '' || dgRate !== "" || address !== '';
        setSaveDisabled(!requiredFieldsFilled);
    }, [selectedState, selectedCity, facilityName, electricityRate, waterRate, dgRate, address])

    function formatToIndianRupee(number) {
        const parts = number?.toString().split(".");
        const numStr = parts[0];
        const decimalPart = parts.length > 1 ? "." + parts[1] : "";

        if (numStr?.length <= 3) return numStr + decimalPart;
        let lastThree = numStr.substring(numStr.length - 3);
        const otherNumbers = numStr.substring(0, numStr.length - 3);
        if (otherNumbers !== '') {
            lastThree = ',' + lastThree;
        }
        const output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree + decimalPart;
        return output;
    }

    const sortState = () => {
        const sortedData = !filteredList ? facilitiesList?.sort((a, b) => {

            if (sortOrder) {
                return a.state.localeCompare(b.state);
            } else {
                return b.state.localeCompare(a.state);
            }
        })
            : filteredList?.sort((a, b) => {
                if (sortOrder) {
                    return a.state.localeCompare(b.state);
                } else {
                    return b.state.localeCompare(a.state);
                }
            })
        setFilteredList([...sortedData]);
        setSortOrder(!sortOrder);
    }


    const sortCity = () => {
        const sortedData = !filteredList ? facilitiesList?.sort((a, b) => {
            if (sortOrder) {
                return a.city.localeCompare(b.city);
            } else {
                return b.city.localeCompare(a.city);
            }
        })
            : filteredList?.sort((a, b) => {
                if (sortOrder) {
                    return a.city.localeCompare(b.city);
                } else {
                    return b.city.localeCompare(a.city);
                }
            })
        setFilteredList([...sortedData]);
        setSortOrder(!sortOrder);

    }
    const sortFacility = () => {
        const sortedData = !filteredList ? facilitiesList?.sort((a, b) => {
            if (sortOrder) {
                return a.name.localeCompare(b.name);
            } else {
                return b.name.localeCompare(a.name);
            }
        })
            : filteredList?.sort((a, b) => {
                if (sortOrder) {
                    return a.name.localeCompare(b.name);
                } else {
                    return b.name.localeCompare(a.name);
                }
            })
        setFilteredList([...sortedData]);
        setSortOrder(!sortOrder);
    }
    const sortArea = () => {
        const sortedData = (filteredList || facilitiesList)?.sort((a, b) => {
            const areaA = parseInt(a.total_area);
            const areaB = parseInt(b.total_area);
            if (sortOrder) {
                return areaA - areaB; // asc  
            } else {
                return areaB - areaA;
            }
        });

        setFilteredList([...sortedData]);
        setSortOrder(!sortOrder);
    }
    const sortlastUpdated = () => {
        const sortedData = (filteredList || facilitiesList)?.sort((a, b) => {
            if (sortOrder) {
                return a.updated_at.localeCompare(b.updated_at);
            } else {
                return b.updated_at.localeCompare(a.updated_at);
            }
        });

        setFilteredList([...sortedData]);
        setSortOrder(!sortOrder);
    }


    const isFacilityNameUnique = (name) => {
        return !facilitiesListForNav.some(facility => facility.name.toLowerCase() === name.toLowerCase());
    }

    const handleSearchSubmit = (searchValue) => {
        if (validateValue(searchValue)) {
            setSearchTerm(searchValue);
        }
    };

    const handleApplyStateFilter = (e) => {
        const selectedState = e.target.value;
        setSelectedStateFilter(selectedState);
    }

    const handleApplyCityFilter = (e) => {
        const selectedCity = e.target.value;
        setSelectedCityDropdown(selectedCity);
    }

    const handleUpdate = () => {
        setIsLoading(true)
        dispatch(commercialHeadUpdateFacility(modalData))
        dispatch(commercialHeadGetAllFacilitiesForNav())
    }

    const handleClearFilters = () => {
        setSelectedStateFilter('')
        setSelectedCityDropdown('')
        setSearchTerm('')
        const page = 1;
        dispatch(commercialHeadGetAllFacilities({ page, limit, search: '', state: '', city: '' }));
        setIsLoading(true);
    }
    const [uploadedFiles, setUploadedFiles] = useState();
    const handleFilesSelected = (files) => {
        const processedFiles = [];
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onloadend = function () {
                const base64FileContent = reader.result.split(",")[1];
                const required_id = selectedFacilityId
                const processedFile = {
                    file_content: base64FileContent,
                    object_type: "facilities",
                    file_description: file.description,
                    file_type: file.file.type.split("/")[1],
                    file_name: file.file.name,
                    facility_id: required_id,
                    file_size: file.file.size,
                    flag: file.flag,
                    user_id: localStorage.getItem('username'),
                };
                processedFiles.push(processedFile);
                if (processedFiles.length === files.length) {
                    setUploadedFiles(processedFiles);
                }
            };
            reader.readAsDataURL(file.file);
        });
    };

    const handleDocumentUpload = (index) => {
        dispatch(handleDocumentLoaderTrue())
        dispatch(commercialHeadUploadFacilityDocument(
            {
                files: [uploadedFiles[index]]
            }
        ))
    }

    const [showConfirmDeleteModel, setConfirmDeleteModel] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState();

    const handleCloseConfirmDeleteModal = () => {
        setConfirmDeleteModel(false);
    };

    const confirmDeleteDocument = (index) => {
        setConfirmDeleteModel(true);
        setSelectedIndex(index);
    }

    const [documentList, setDocumentList] = useState([]);
    useEffect(() => {
        let documentListTemp = [...documentList];
        commercialHeadUploadFacilityDocumentList?.forEach(item => {
            if (!documentListTemp.includes(item)) {
                documentListTemp.push(item);
            }
        });
        setDocumentList([...documentListTemp]);
    }, [commercialHeadUploadFacilityDocumentList])

    const handleDocumentDelete = () => {
        dispatch(handleDocumentLoaderTrue())
        if (documentList.length > selectedIndex) {
            axios.delete(base_url + `documents`,
                {
                    data: {
                        s3_keys: [documentList[selectedIndex]?.key],

                    }
                }
            )
                .then((response) => {
                    if (response?.data?.data[0] === 'error') {
                        setErrorValue(response?.data?.data[1].message)
                        setAlertShow(true)
                        setAlertType('error')
                        setAlertMessage('Error')
                        setAlertValue(response?.data?.data[1].message)
                        setAlertButtons([
                            {
                                name: 'Close', action: () => {
                                    setAlertShow(false)
                                }
                            },
                        ])
                        setIsLoading(false)
                    }
                    else {
                        setConfirmDeleteModel(false)
                        dispatch(handleDocumentLoaderFalse())
                        let newDocumentList = [...documentList];
                        newDocumentList.splice(selectedIndex, 1);
                        setDocumentList(newDocumentList);
                    }
                })
                .catch((error) => {
                    setErrorValue(error?.response?.data.message);
                    setAlertShow(true)
                    setAlertType('error')
                    setAlertMessage('Error')
                    setAlertValue(error?.response?.data.message)
                    setAlertButtons([{
                        name: 'Close', action: () => {
                            setAlertShow(false)
                        }
                    }])
                    setIsLoading(false)
                })

        }
        else {
            setConfirmDeleteModel(false)
        }

    }

    const handleErrorFromDocument = (value) => {
        setErrorValue(value)
    }

    const applyFilter = () => {
        dispatch(commercialHeadGetAllFacilities({ page: 1, limit: RECORDS_PER_PAGE, search: searchTerm, state: selectedStateFilter, city: selectedCityDropdown }));
        setIsLoading(true);
    }

    const handledPaginationNextClicked = () => {
        setIsLoading(true)
        handlePaginationLoaderTrue()
        dispatch(commercialHeadGetAllFacilities({ state: selectedStateFilter, city: selectedCityDropdown, search: searchTerm, limit: RECORDS_PER_PAGE, page: currentPaginationPage + 1 }))
    }

    const handledPaginationPrevClicked = () => {
        setIsLoading(true)
        handlePaginationLoaderTrue()
        dispatch(commercialHeadGetAllFacilities({ state: selectedStateFilter, city: selectedCityDropdown, search: searchTerm, limit: RECORDS_PER_PAGE, page: currentPaginationPage - 1 }))
    }

    return (
        <>
            <>
                <NavBarTop />
                <LeftNavBar />
            </>
            <div className='commercial-head-add-facility-container'>
                <Modal keyboard={false} backdrop="static" show={showConfirmDeleteModel} centered onHide={handleCloseConfirmDeleteModal}>
                    <Modal.Body style={{ display: "flex", flexDirection: "column" }}>
                        <p>Are you sure you want to delete this File?</p>
                        <span style={{ fontSize: "small" }}>Deleting this file will permenantly remove the file from the system!</span>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setConfirmDeleteModel(false)}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={handleDocumentDelete}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
                <AlertPopUp alertShow={alertShow} alertType={alertType} alertMsg={alertMessage} alertValue={alertValue} alertButtons={alertButtons} />
                <div className='commercial-head-header-container'>
                    <h1>Facilities</h1>
                    <div className='add-facility-button' onClick={handleShow}><span>Add Facility</span><img src='./images/icons/AddIcon.svg' /></div>
                </div>
                <div className='d-flex gap-3 flex-wrap'>
                    <select className='commercial-head-dropdown'
                        value={selectedStateFilter}
                        onChange={(e) => { handleApplyStateFilter(e) }}>
                        <option value=''>State</option>
                        {selectedStateList?.map(state => (
                            <option key={state} value={state}>{state}</option>
                        ))}
                    </select>
                    <select className='commercial-head-dropdown'
                        value={selectedCityDropdown}
                        onChange={e => { handleApplyCityFilter(e) }} >
                        <option value=''>City</option>
                        {selectedCityFilter[selectedStateFilter]?.map(city => (
                            <option key={city} value={city}>{city}</option>
                        ))}
                    </select>
                    <div className='fin_man_meter_search_bar_container'>
                        <input type='search' placeholder='Search Facility' className='fin_man_meter_search_bar'
                            value={searchTerm}
                            onChange={(e) => handleSearchSubmit(e.target.value)} />
                        <GoSearch className='fin_man_meter_search_bar_icon' />
                    </div>
                    <button className="add_fac_apply_btn"
                        // disabled={selectedStateFilter === '' && selectedCityFilter === '' && searchTerm === ''} 
                        style={{ color: 'white', height: '20px !important', fontSize: "15px" }} 
                        onClick={() => {
                            applyFilter()
                        }}>Apply
                    </button>
                    <div className='add-facility-button' onClick={handleClearFilters}>
                        <span >Clear</span>
                    </div>
                </div>
                <div className='commercial-head-facilities-table'>
                    <table>
                        <thead>
                            <tr className='fin_man_table_head commercial-head-table text-center'>
                                <th className='C-pointer' onClick={() => sortState()}>State <img src="./images/icons/sort.svg" alt="edit-icon" style={{ height: "18px" }} /></th>
                                <th className='C-pointer' onClick={() => sortCity()}>City<img src="./images/icons/sort.svg" alt="edit-icon" style={{ height: "18px" }} /></th>
                                <th className='C-pointer' onClick={() => sortFacility()}>Facility Name<img src="./images/icons/sort.svg" alt="edit-icon" style={{ height: "18px" }} /></th>
                                <th className='C-pointer' onClick={() => sortArea()}>Area (sq. ft)<img src="./images/icons/sort.svg" alt="edit-icon" style={{ height: "18px" }} /></th>
                                <th className='C-pointer' onClick={() => sortlastUpdated()} >Last Updated<img src="./images/icons/sort.svg" alt="edit-icon" style={{ height: "18px" }} /></th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!filteredList ?
                                <>
                                    {
                                        facilitiesList?.length > 0 ?
                                            facilitiesList?.map((facility, index) => {
                                                return <tr key={index} className={`table_row ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}>
                                                    <td className='table_text_left'>{facility.state}</td>
                                                    <td className='table_text_left'>{facility.city}</td>
                                                    <td className='table_text_left'>{facility.name}</td>
                                                    <td className='table_text_right'>{formatToIndianRupee(facility.total_area)}</td>
                                                    <td className='table_text_right' title={facility.updated_at}>{timeElapsed(facility.updated_at)}</td>
                                                    <td className='table_text_center' onClick={() => handleShowModal(facility.facility_id)} style={{ cursor: "pointer", justifyContent: "center" }}>
                                                        <div><img src="./images/icons/ViewViewIconEye.svg" alt="view-icon" /></div>
                                                    </td>
                                                </tr>
                                            })
                                            :
                                            <tr>
                                                <td colSpan='10' style={{ textAlign: "center" }}>No Records Found</td>
                                            </tr>
                                    }
                                </>
                                :
                                <>
                                    {
                                        filteredList.length > 0 ?
                                            filteredList?.map((facility, index) => {
                                                return <tr key={index} className={`table_row ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}>
                                                    <td>{facility.state}</td>
                                                    <td>{facility.city}</td>
                                                    <td>{facility.name}</td>
                                                    <td>{facility.total_area}</td>
                                                    <td>{timeElapsed(facility.updated_at)}</td>
                                                    <td className='text-center' onClick={() => handleShowModal(facility.facility_id)} style={{ cursor: "pointer", justifyContent: "center" }}>
                                                        <div><img src="./images/icons/ViewViewIconEye.svg" alt="view-icon" /></div>
                                                    </td>
                                                </tr>
                                            })
                                            :
                                            <tr >
                                                <td colSpan='10' style={{ textAlign: "center" }}>No Records Found</td>
                                            </tr>
                                    }

                                </>
                            }
                        </tbody>
                    </table>
                </div>
                <Pagination
                    handledPaginationNextClicked={handledPaginationNextClicked}
                    handledPaginationPrevClicked={handledPaginationPrevClicked}
                    isApplyClicked
                    totalRecords={totalRecords}
                    recordsPerPage={RECORDS_PER_PAGE}
                    currentPaginationPage={currentPaginationPage}
                />
                <Modal keyboard={false} backdrop="static" show={show} centered onHide={handleClose} size='lg'>
                    <Modal.Header >
                        <Modal.Title>Add New Facility</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='d-flex flex-column gap-3'>
                            <div className='d-flex justify-content-between gap-3'>
                                <div className='d-flex align-items-center justify-content-between' style={{ width: "50%" }}>
                                    <label className='add-facility-modal-label'>State <span style={{ color: 'red' }}>*</span>:</label>
                                    <select className='commercial-head-dropdown' value={selectedState} onChange={handleStateChange} required>
                                        {states.map(state => (
                                            <option key={state} value={state}>{state}</option>
                                        ))}
                                    </select>

                                </div>
                                <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                    <label className='add-facility-modal-label'>City <span style={{ color: 'red' }}>*</span>:</label>
                                    <select className='commercial-head-dropdown' value={selectedCity}
                                        onChange={handleCityChange} required disabled={selectedState === '' || selectedState === 'Select'}>
                                        {cities[selectedState]?.map(city => (
                                            <option key={city} value={city}>{city}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between gap-3'>
                                <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                    <label className='add-facility-modal-label'>Facility Name<span style={{ color: 'red' }}>*</span>:</label>
                                    <div className='d-flex flex-column'>
                                        <input type='text'
                                            className={`form-control add-facility-input-fields ${facilityNameError ? 'is-invalid' : ''}`}
                                            value={facilityName}
                                            onChange={handleFacilityNameChange}
                                            required
                                        />

                                        {facilityNameError && <div className="invalid-input">Only a-z,A-Z,0-9,spaces,-,_,/ are allowed.</div>}
                                        {uniqueFacilityNameError && <div className="invalid-input">Facility name should be unique!</div>}
                                        {lenghtError && <div className="invalid-input">Must be between 3 to 200 characters</div>}
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                    <label className='add-facility-modal-label'>Facility Area<span style={{ color: 'red' }}>*</span>:</label>
                                    <div className='facility-area-input'>
                                        <input type='text' value={facilityArea} onChange={handleFacilityAreaChange} className='form-control noSpinArrows add-facility-input-fields' style={{ paddingRight: "50px" }} />
                                        <span className='square-feet'>Sq.Ft</span>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between gap-3'>
                                <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                    <label className='add-facility-modal-label'>EB Rate/Unit <span style={{ color: 'red' }}>*</span>:</label>
                                    <div className='add-facility-electricity-rate' required>
                                        <img src='./images/icons/rupee.svg' />
                                        <input type='text' value={electricityRate} onChange={handleElectricityRateChange} className='form-control noSpinArrows add-facility-input-fields' style={{ paddingLeft: "30px" }} />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                    <label className='add-facility-modal-label'>Water Rate/Unit<span style={{ color: 'red' }}>*</span>:</label>
                                    <div className='add-facility-water-rate' required>
                                        <img src='./images/icons/rupee.svg' />
                                        <input type='text' value={waterRate} onChange={handleWaterRateChange} className='form-control noSpinArrows add-facility-input-fields' style={{ paddingLeft: "30px" }} />
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between gap-3'>
                                <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                    <label className='add-facility-modal-label'>DG Rate/Unit <span style={{ color: 'red' }}>*</span>:</label>
                                    <div className='add-facility-electricity-rate' style={{ marginRight: "8px" }} required>
                                        <img src='./images/icons/rupee.svg' />
                                        <input type='text' value={dgRate} onChange={handleDgRate} className='form-control noSpinArrows add-facility-input-fields' style={{ paddingLeft: "30px" }} />
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between gap-2 align-items-start'>
                                <label className='add-facility-modal-label-address'>Address<span style={{ color: 'red' }}>*</span>:</label>
                                <textarea className='form-control' required style={{ border: "0.5px solid #9D9D9C" }} value={address} onChange={handleAddressChange} />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='btn btn-light px-5' style={{ border: "1px solid #878787", backgroundColor: "#FFFFFF" }} onClick={() => setShowCloseConfirmationModal(true)}>
                            Back
                        </Button>
                        <Button className='btn btn-secondary px-5' onClick={handleSave} disabled={saveDisabled || selectedState === '' || uniqueFacilityNameError ||
                            selectedCity === '' || facilityName === '' || facilityArea === "" || electricityRate === '' || waterRate === '' || dgRate === '' || address === '' || lenghtError}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal keyboard={false} backdrop="static" show={showCloseConfirmationModal} centered onHide={handleCloseConfirmation}>
                    <Modal.Body className="text-center">
                    <h5>Are you sure you want to CLOSE? Your progress will not be saved.</h5>
                    </Modal.Body>
                    <Modal.Footer className="footer">
                    <Button variant="secondary" className="confirmation" onClick={handleCloseConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="secondary" className="confirmation-left" onClick={handleProceed}>
                        Proceed
                    </Button>
                    </Modal.Footer>
                </Modal>
                
                <Modal keyboard={false} backdrop="static" show={viewModal} centered onHide={handleCloseModal} size='lg'>
                    <Modal.Header >
                        <Modal.Title>Facility Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='d-flex flex-column gap-3'>
                            <div className='d-flex justify-content-between gap-3'>
                                <div className='d-flex align-items-center justify-content-between' style={{ width: "50%" }}>
                                    <label className='add-facility-modal-label'>State<span style={{ color: 'red' }}>*</span>:</label>
                                    <select className='commercial-head-dropdown'
                                        value={modalData.state} disabled
                                    >
                                        <option value=''>{modalData.state}</option>

                                    </select>

                                </div>
                                <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                    <label className='add-facility-modal-label'>City<span style={{ color: 'red' }}>*</span>:</label>
                                    <select className='commercial-head-dropdown'
                                        value={modalData.city} disabled
                                    >
                                        <option value=''>{modalData.city}</option>

                                    </select>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between gap-3'>
                                <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                    <label className='add-facility-modal-label'>Facility Name <span style={{ color: 'red' }}>*</span>:</label>
                                    <div className='d-flex flex-column'>
                                        <input type='text'
                                            className={`form-control add-facility-input-fields ${facilityNameError ? 'is-invalid' : ''}`}
                                            onChange={e => {
                                                if (validateValue(e.target.value.charAt(0))) {
                                                const regex = /^[A-Za-z0-9\s-/_]+$/;
                                                const name = facilitiesList.filter(facility => facility.facility_id === modalData.facility_id)[0].name
                                                const inputValue = e.target.value
                                                if (inputValue < 3 || inputValue > 200) {
                                                    setLengthError(true)
                                                }
                                                else {
                                                    setLengthError(false)
                                                }
                                                if (regex.test(inputValue)) {
                                                    setModalData({ ...modalData, name: inputValue })
                                                    if ( (inputValue === name) || (inputValue === '')) {
                                                        
                                                        setUniqueFacilityNameError(false)
                                                    }
                                                    else {
                                                        isFacilityNameUnique(inputValue) ? setUniqueFacilityNameError(false) : setUniqueFacilityNameError(true)
                                                    }
                                                    setFacilityNameError(false);
                                                } else {
                                                    setFacilityNameError(true);
                                                }

                                                setModalData({ ...modalData, name: inputValue })
                                                }}
                                        }
                                            value={modalData.name} disabled={!editFacilityDetails} />

                                        {facilityNameError && <div className="invalid-input">Only a-z,A-Z,0-9,spaces,-,_,/ are allowed.</div>}
                                        {uniqueFacilityNameError && <div className="invalid-input">Facility name should be unique!</div>}
                                        {lenghtError && <div className="invalid-input">Must be between 3 to 200 characters</div>}
                                    </div>
                                </div>

                                <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                    <label className='add-facility-modal-label'>Facility Area<span style={{ color: 'red' }}>*</span>:</label>
                                    <div className='facility-area-input'>
                                        <input type='number' 
                                        // onWheel={(e) => e.target.blur()}
                                        className='form-control noSpinArrows add-facility-input-fields' value={modalData.total_area} disabled={true} style={{ paddingRight: "50px" }} />
                                        <span className='square-feet'>Sq.Ft</span>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between gap-3'>
                                <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                    <label className='add-facility-modal-label'>EB Rate/Unit <span style={{ color: 'red' }}>*</span>:</label>
                                    <div className='add-facility-electricity-rate'>
                                        <img src='./images/icons/rupee.svg' />
                                        <input type='text' className='form-control noSpinArrows add-facility-input-fields'
                                           onChange={e => {
                                            const input = e.target.value;
                                            const formattedInput = formatNumericInputForMaxValue(input);
                                            setModalData({ ...modalData, electricity_rate: formattedInput });
                                        }}
                                            value={modalData.electricity_rate} disabled={!editFacilityDetails} style={{ paddingLeft: "30px" }} />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                    <label className='add-facility-modal-label'>Water Rate/Unit<span style={{ color: 'red' }}>*</span>:</label>
                                    <div className='add-facility-water-rate'>
                                        <img src='./images/icons/rupee.svg' />
                                        <input type='text' className='form-control noSpinArrows add-facility-input-fields'
                                            onChange={e => {
                                                const input = e.target.value;
                                                const formattedInput = formatNumericInputForMaxValue(input);
                                                setModalData({ ...modalData, water_rate: formattedInput });
                                            }}
                                            value={modalData.water_rate} disabled={!editFacilityDetails} style={{ paddingLeft: "30px" }} />
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between gap-3'>
                                <div className='d-flex justify-content-between align-items-center' style={{ width: "50%" }}>
                                    <label className='add-facility-modal-label'>DG Rate/Unit <span style={{ color: 'red' }}>*</span>:</label>
                                    <div className='add-facility-electricity-rate'  style={{ marginRight: "8px"}}>
                                        <img src='./images/icons/rupee.svg' />
                                        <input type='text' className='form-control noSpinArrows add-facility-input-fields'
                                            onChange={e => {
                                                const input = e.target.value;
                                                const formattedInput = formatNumericInputForMaxValue(input);
                                                setModalData({ ...modalData, dg_rate: formattedInput });
                                            }}
                                            value={modalData.dg_rate} disabled={!editFacilityDetails} style={{ paddingLeft: "30px" }} />
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between gap-2 align-items-start'>
                                <label className='add-facility-modal-label-address'>Address<span style={{ color: 'red' }}>*</span>:</label>
                                <textarea className='form-control' style={{ border: "0.5px solid #9D9D9C" }}
                                    onChange={e => {
                                        const newValue = eleminateSpacesAtStartOfInputField(e.target.value);
                                        setModalData({ ...modalData, address: newValue });
                                    }}
                                    value={modalData.address} disabled={!editFacilityDetails} />
                            </div>
                            <div>
                                <UploadComponent
                                    onFilesSelected={handleFilesSelected}
                                    uploadMethod="single"
                                    viewMode={!editFacilityDetails}
                                    uploadedFileData={documentsListArray}
                                    uploadActionClicked={handleDocumentUpload}
                                    deleteSpecificFileMain={confirmDeleteDocument}
                                    maxNumberOfFiles={5}
                                    handleErrorFromDocument={handleErrorFromDocument}
                                    idType={"facility"}
                                    acceptedFileTypes={".pdf, .jpeg, .jpg, .png"}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {editFacilityDetails ? (
                            <>
                                <Button className='btn btn-light px-5' style={{ border: "1px solid #878787", backgroundColor: "#FFFFFF" }} onClick={() => setShowBackCloseConfirmation(true)}>
                                    Back
                                </Button>
                                <Button className='btn btn-secondary px-5' onClick={() => handleUpdate()} disabled={modalData.name === '' || modalData.electricity_rate === '' || modalData.water_rate === '' || modalData.dg_rate === '' || modalData.dg_rate === null || modalData.address === '' || uniqueFacilityNameError || lenghtError || facilityNameError}>
                                    Save
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button className='btn btn-light px-5' style={{ border: "1px solid #878787", backgroundColor: "#FFFFFF" }} onClick={handleCloseModal}>
                                    Back
                                </Button>
                                <Button className='btn btn-secondary px-5' style={{ border: "1px solid #878787" }} onClick={() => setEditFacilityDetails(true)}>
                                    Edit
                                </Button>
                            </>
                        )}
                    </Modal.Footer>
                    <Modal keyboard={false} backdrop="static" show={showBackCloseConfirmation} centered onHide={handleCloseConfirmation}>
                        <Modal.Body className="text-center">
                            <h5>Are you sure you want to CLOSE? Your progress will not be saved.</h5>
                            </Modal.Body>
                            <Modal.Footer className="footer">
                                <Button variant="secondary" className="confirmation" onClick={handleCloseBackConfirmation}>
                                    Cancel
                                </Button>
                                <Button variant="secondary" className="confirmation-left" onClick={handleBackProceed}>
                                    Proceed
                                </Button>
                                </Modal.Footer>
                    </Modal>
                    
                </Modal>
                {(isLoading || paginationLoader) && <Loader />}
                <Modal keyboard={false} backdrop="static" show={showSuccess} onHide={() => {
                    setShowSuccess(false)
                }} centered className="popper-modal">
                    <Modal.Body className="text-center">
                        <img src="./images/icons/popper.svg" alt="Party Popper" />
                        <h5>Success!</h5>
                        {
                            isupdated ?
                                <p className="mb-3">Your facility has been successfully updated.</p> :
                                <p className="mb-3">Your facility has been successfully added.</p>
                        }
                    </Modal.Body>
                    <Modal.Footer className="footer d-flex justify-content-center">
                        <Button variant="secondary" className="secondary" onClick={() => {
                            setShowSuccess(false)
                            setIsUpdated(false)
                            handleClose()
                            applyFilter()
                            dispatch({
                                type: "PUT_CH_ADD_NEW_FACILITY_SUCCESS",
                                payload: null
                            })
                            dispatch({
                                type: "PUT_CH_UPDATE_FACILITY_SUCCESS",
                                payload: null
                            })
                        }} >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            {/* <div className="footerbottom">   
            </div> */}
            <Footer/>
        </>
    )
}
