import React, { useEffect, useState } from 'react'
import { GoSearch } from "react-icons/go";
import { MdCurrencyRupee } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Table } from 'react-bootstrap'
import NavBarTop from '../Reusable_Components/NavBarTop'
import LeftNavBar from '../Reusable_Components/LeftNavBar'
import { useDispatch, useSelector } from 'react-redux';
import { finmanGetMeterReadings, getGstSubmissionLogs, PUT_CH_UPDATE_METER_SUCCESS } from '../../Redux/Actions/Actions';
import { set } from 'date-fns';
import Loader from '../Reusable_Components/loader';
import AlertPopUp from '../Reusable_Components/Alert';
import { validateValue } from "../Reusable_Components/ReusableFunctions";
import { SiVelog } from 'react-icons/si';
import Footer from "../Login/Footer";

export default function GstSubmissionLogs() {

    const gstLogs = useSelector(state => state.gstLogs)
    const contractApiLoader = useSelector(state => state.contractApiLoader)


    function formatDate(date) {
        const d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        return [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-');
    }

    const [currentPage, setCurrentPage] = useState(1)
    const [showModal, setShowModal] = useState(false)
    const [modalData, setModalData] = useState({})
    const [selectMeter, setSelectMeter] = useState("all")
    const [invoiceData, setInvoiceData] = useState()
    const [spaceFee, setSpaceFee] = useState(false)
    const [energyMeter, setEnergyMeter] = useState(true)
    const [waterMeter, setWaterMeter] = useState(true)
    const [miscellaneous, setMiscellaneous] = useState(false)
    const [dueData, setDueDate] = useState()
    const [miscBaseAmount, setMiscBaseAmount] = useState("0")
    const [miscCgstRate, setMiscCgstRate] = useState(0)
    const [miscSgstRate, setMiscSgstRate] = useState(0)
    const [miscDescription, setMiscDescription] = useState("")
    const [sortOrder, setSortOrder] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(() => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        currentDate.setMonth(currentMonth - 1);
        currentDate.setDate(1);
        return formatDate(currentDate);
    });
    const [endDate, setEndDate] = useState(formatDate(new Date()));
    const meterReportsList = useSelector(state => state.meterList)
    const error = useSelector(state => state.error);
    const [isLoading, setIsLoading] = useState(false)
    //for alert pop up
    const [alertShow, setAlertShow] = useState(false)
    const [alertType, setAlertType] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertValue, setAlertValue] = useState('')
    const [alertButtons, setAlertButtons] = useState([])
    const [alertAction, setAlertAction] = useState([])

    const fetchReports = () => {
        // setIsLoading(true)
        const data = {
            start_date: startDate,
            end_date: endDate,
        };
        dispatch(getGstSubmissionLogs(data))
    }

    useEffect(() => {
        // setErrorValue(error?.response?.data.message);
        if (error?.response?.data.message || error?.data?.data[1]?.message) {
            setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error')
            setAlertValue(error?.data?.data[1] ? error?.data?.data[1].message : error?.response?.data.message)
            setAlertButtons([{
                name: 'Close', action: () => {
                    setAlertShow(false)
                    dispatch({
                        type: PUT_CH_UPDATE_METER_SUCCESS,
                        payload: null
                    })
                }
            }])
        }
        setIsLoading(false);
    }, [error])

    useEffect(() => {
        fetchReports()
    }, [])

    useEffect(() => {
        if (gstLogs?.length > 0) {
            setFilteredData([...gstLogs])
        } else {
            setFilteredData([])
        }
    }, [gstLogs])



    const sortContractNumber = () => {
        const sortedData = filteredData.sort((a, b) => {
            if (sortOrder) {
                return a.contract_number.localeCompare(b.contract_number)
            } else {
                return b.contract_number.localeCompare(a.contract_number)
            }
        })
        setFilteredData([...sortedData]);
        setSortOrder(!sortOrder);
    }

    const sortContractName = () => {
        const sortedData = filteredData.sort((a, b) => {
            if (sortOrder) {
                return a.contract_name.localeCompare(b.contract_name)
            } else {
                return b.contract_name.localeCompare(a.contract_name)
            }
        })
        setFilteredData([...sortedData]);
        setSortOrder(!sortOrder);
    }






    return (
        <>
            <>
                <NavBarTop />
                <LeftNavBar />
            </>

            {/* <> */}
            {/* {isLoading && <Loader />} */}
            {contractApiLoader && <Loader />}
            <AlertPopUp alertShow={alertShow} alertType={alertType} alertMsg={alertMessage} alertValue={alertValue} alertButtons={alertButtons} />
            {(currentPage === 1) && <div className='meter_container_reports '>
                <div className='fm-invoice-con'>
                    <div className='fm-invoice-heading-con  mb-1'>
                        <h3 className="invoice-heading">GST Submission Logs</h3>
                    </div>
                </div>
                <div className='fm-invoice-con'>
                    <div className='fin-meter-reports-con'>
                        <div className='d-flex flex-column'>
                            <label>From date</label>
                            <input type='date' onChange={e => {
                                if (endDate < e.target.value) {
                                    const newEndDate = new Date(e.target.value)
                                    newEndDate.setMonth(newEndDate.getMonth() + 1)
                                    const formattedEndDate = newEndDate.toISOString().split('T')[0]
                                    setEndDate(formattedEndDate)
                                }
                                setStartDate(e.target.value)
                            }}
                                max={formatDate(new Date())}
                                onKeyDown={(e) => e.preventDefault()}
                                value={startDate}
                                className='fin_man_date_range' />
                        </div>
                        <div className='d-flex flex-column'>
                            <label>To date</label>
                            <input type='date' value={endDate}
                                onKeyDown={(e) => e.preventDefault()}
                                min={startDate}
                                max={formatDate(new Date())}
                                onChange={e =>
                                    setEndDate(e.target.value)
                                } className='fin_man_date_range' />
                        </div>

                        <button className="invoice_btn" style={{ marginTop: '18px', color: 'white', height: '20px !important' }} onClick={() => {
                            fetchReports()
                        }}>Apply</button>
                        <button className="invoice_btn" style={{ marginTop: '18px', color: 'white', height: '20px !important' }} onClick={() => {
                            const currentDate = new Date();
                            const currentMonth = currentDate.getMonth();
                            currentDate.setMonth(currentMonth - 1);
                            currentDate.setDate(1);
                            const startDate1 = formatDate(currentDate);
                            const endDate1 = formatDate(new Date())
                            setEndDate(endDate1);
                            setStartDate(startDate1)
                            setSelectMeter("all")
                            setSearchTerm('')
                            // setIsLoading(true)
                            const data = {
                                start_date: startDate1,
                                end_date: endDate1,
                            };
                            dispatch(getGstSubmissionLogs(data))
                        }}>Clear</button>
                    </div>
                </div>
                {/* {'submission_id': '55c15278-cdb0-4112-8fc0-42b7945cdf53',
                 'request_type': 'Invoice Submission',
                  'vendor': 'MASTERGST',
                   'created_at': datetime.datetime(2024, 10, 11, 8, 43, 52),
                    'invoice_number': 'SRST2425-001158',
                     'status_desc': 'GSTR request succeeds',
                      'irn': '5ebecc234a10281d78eea5f7425217a9cd4298e38d4a9d870e2a6aa92d73e0ca'} */}
                {selectMeter && <div className='fin_meter_reports_table' style={{ height: '58vh' }}>
                <table>
                    <colgroup>
                        <col style={{ width: 'auto' }} /> {/* Customer GST */}
                        <col style={{ width: 'auto' }} /> {/* Customer Name */}
                        <col style={{ width: 'auto' }} /> {/* Invoice Number */}
                        <col style={{ width: 'auto' }} /> {/* Invoice Total */}
                        <col style={{ width: '40px' }} /> {/* Status Description (reduced width) */}
                        <col style={{ width: 'auto' }} /> {/* IRN */}
                        <col style={{ width: '300px' }} /> {/* Created At (increased width) */}
                    </colgroup>
                    <thead>
                        <tr className='fin_man_table_head commercial-head-table text-center'>
                            <th>Customer GST</th>
                            <th>Customer Name</th>
                            <th>Invoice Number</th>
                            <th>Invoice Total</th>
                            <th>Status Description</th>
                            <th>IRN</th>
                            <th>Created At</th>
                        </tr>
                    </thead>
                    <tbody className='fin_man_table_body'>
                        {filteredData && filteredData?.length !== 0 ? (
                            filteredData?.map((each, index) => {
                                return (
                                    <tr key={index} className='table_row'>
                                        <td>{each?.customer_gst}</td>
                                        <td>{each?.customer_name}</td>
                                        <td>{each?.invoice_number}</td>
                                        <td>{each?.invoice_total}</td>
                                        <td style={{ wordWrap: 'break-word', whiteSpace: 'normal', maxWidth: '320px' }}>{each?.status_desc}</td>
                                        <td style={{ wordWrap: 'break-word', whiteSpace: 'normal', maxWidth: '250px' }}>{each?.irn}</td>
                                        <td>{each?.created_at}</td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan='10' className='text-center'>No Records Found</td>
                            </tr>
                        )}
                    </tbody>
                </table>


                </div>}
            </div>}
            {selectMeter === "all" ? <Modal keyboard={false} backdrop="static" show={showModal} style={{ height: "100vh" }} centered onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Meter Reading Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal_body'>
                    <div className='mb-4'>
                        <dl className="description-list">
                            <div className='d-flex'>
                                <span className='modal_desc'>Contract No.</span>
                                <dd>{modalData.contractNo}</dd>
                            </div>
                            <div className='d-flex'>
                                <span className='modal_desc'>User Name</span>
                                <dd>{modalData.contract}</dd>
                            </div>
                            <div className='d-flex'>
                                <span className='modal_desc'>Space Name</span>
                                <dd>{modalData.spaceName}</dd>
                            </div>
                            <div className='d-flex'>
                                <span className='modal_desc'>Unit Name</span>
                                <dd>11</dd>
                            </div>

                        </dl>
                    </div>
                    <div>
                        <div className='d-flex'>
                            <span className='modal_desc'>Energy Meter No.</span>
                            <dd>{modalData.meterNumber}</dd>
                        </div>
                        <div className='d-flex'>
                            <div style={{ width: "50%" }}>
                                <p>EB Reading</p>
                                <div className='d-flex mb-3 flex-wrap'>
                                    <div className='d-flex flex-column' style={{ width: "50%" }}>
                                        <span>Initial</span>
                                        <span className='modal_readings_box'>{modalData.initialReadings}</span>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <span>Final</span>
                                        <span className='modal_readings_box'>{modalData.finalReadings}</span>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <span className='modal_desc' style={{ width: "60%" }}>Total units</span>
                                    <dd>{modalData.totalUnits}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Rate per unit</span>
                                    <dd>{modalData.ratePerUnit}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Total Amount</span>
                                    <dd>{modalData.totalAmount}</dd>
                                </div>
                            </div>
                            <div style={{ width: "50%" }}>
                                <p>DG Reading</p>
                                <div className='d-flex mb-3 flex-wrap'>
                                    <div className='d-flex flex-column' style={{ width: "50%" }}>
                                        <span>Initial</span>
                                        <span className='modal_readings_box'>{modalData.initialReadings}</span>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <span>Final</span>
                                        <span className='modal_readings_box'>{modalData.finalReadings}</span>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <span className='modal_desc' style={{ width: "60%" }}>Total units</span>
                                    <dd>{modalData.totalUnits}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Rate per unit</span>
                                    <dd>{modalData.ratePerUnit}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Total Amount</span>
                                    <dd>{modalData.totalAmount}</dd>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className='d-flex'>
                            <span className='modal_desc'>Water Meter No.</span>
                            <dd>{modalData.waterMeterNumber}</dd>
                        </div>
                        <div className='d-flex'>
                            <div style={{ width: "50%" }}>
                                <p>EB Reading</p>
                                <div className='d-flex mb-3 flex-wrap'>
                                    <div className='d-flex flex-column' style={{ width: "50%" }}>
                                        <span>Initial</span>
                                        <span className='modal_readings_box'>{modalData.waterInitialReading}</span>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <span>Final</span>
                                        <span className='modal_readings_box'>{modalData.waterFinalReading}</span>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <span className='modal_desc' style={{ width: "60%" }}>Total units</span>
                                    <dd>{modalData.totalWaterUnits}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Rate per unit</span>
                                    <dd>{modalData.waterRatePerUnit}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Total Amount</span>
                                    <dd>{modalData.totalWaterAMount}</dd>
                                </div>
                            </div>
                            <div style={{ width: "50%" }}>
                                <p>DG Reading</p>
                                <div className='d-flex mb-3 flex-wrap'>
                                    <div className='d-flex flex-column' style={{ width: "50%" }}>
                                        <span>Initial</span>
                                        <span className='modal_readings_box'>{modalData.waterInitialReading}</span>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <span>Final</span>
                                        <span className='modal_readings_box'>{modalData.waterFinalReading}</span>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <span className='modal_desc' style={{ width: "60%" }}>Total units</span>
                                    <dd>{modalData.totalWaterUnits}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Rate per unit</span>
                                    <dd>{modalData.waterRatePerUnit}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Total Amount</span>
                                    <dd>{modalData.totalWaterAMount}</dd>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className='col' onClick={() => {
                        setCurrentPage(2)
                        setShowModal(false)
                    }
                    }>
                        Generate Draft Invoice
                    </Button>
                </Modal.Footer>
            </Modal> : selectMeter === "energy" ? <Modal keyboard={false} backdrop="static" show={showModal} style={{ height: "100vh" }} centered onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Energy Reading Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal_body'>
                    <div className='mb-4'>
                        <dl className="description-list">
                            <div className='d-flex'>
                                <span className='modal_desc'>Contract No.</span>
                                <dd>{modalData.contractNo}</dd>
                            </div>
                            <div className='d-flex'>
                                <span className='modal_desc'>User Name</span>
                                <dd>{modalData.contract}</dd>
                            </div>
                            <div className='d-flex'>
                                <span className='modal_desc'>Space Name</span>
                                <dd>{modalData.spaceName}</dd>
                            </div>
                            <div className='d-flex'>
                                <span className='modal_desc'>Unit Name</span>
                                <dd>11</dd>
                            </div>

                        </dl>
                    </div>
                    <div>
                        <div className='d-flex'>
                            <span className='modal_desc'>Meter No.</span>
                            <dd>{modalData.meterNumber}</dd>
                        </div>
                        <div className='d-flex'>
                            <div style={{ width: "50%" }}>
                                <p>EB Reading</p>
                                <div className='d-flex mb-3 flex-wrap'>
                                    <div className='d-flex flex-column' style={{ width: "50%" }}>
                                        <span>Initial</span>
                                        <span className='modal_readings_box'>{modalData.initialReadings}</span>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <span>Final</span>
                                        <span className='modal_readings_box'>{modalData.finalReadings}</span>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <span className='modal_desc' style={{ width: "60%" }}>Total units</span>
                                    <dd>{modalData.totalUnits}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Rate per unit</span>
                                    <dd>{modalData.ratePerUnit}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Total Amount</span>
                                    <dd>{modalData.totalAmount}</dd>
                                </div>
                            </div>
                            <div style={{ width: "50%" }}>
                                <p>DG Reading</p>
                                <div className='d-flex mb-3 flex-wrap'>
                                    <div className='d-flex flex-column' style={{ width: "50%" }}>
                                        <span>Initial</span>
                                        <span className='modal_readings_box'>{modalData.initialReadings}</span>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <span>Final</span>
                                        <span className='modal_readings_box'>{modalData.finalReadings}</span>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <span className='modal_desc' style={{ width: "60%" }}>Total units</span>
                                    <dd>{modalData.totalUnits}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Rate per unit</span>
                                    <dd>{modalData.ratePerUnit}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Total Amount</span>
                                    <dd>{modalData.totalAmount}</dd>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className='col' onClick={() => {
                        setCurrentPage(2)
                        setShowModal(false)
                    }
                    }>
                        Generate Draft Invoice
                    </Button>
                </Modal.Footer>
            </Modal> : <Modal keyboard={false} backdrop="static" show={showModal} style={{ height: "100vh" }} centered onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Water Reading Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal_body'>
                    <div className='mb-4'>
                        <dl className="description-list">
                            <div className='d-flex'>
                                <span className='modal_desc'>Contract No.</span>
                                <dd>{modalData.contractNo}</dd>
                            </div>
                            <div className='d-flex'>
                                <span className='modal_desc'>User Name</span>
                                <dd>{modalData.contract}</dd>
                            </div>
                            <div className='d-flex'>
                                <span className='modal_desc'>Space Name</span>
                                <dd>{modalData.spaceName}</dd>
                            </div>
                            <div className='d-flex'>
                                <span className='modal_desc'>Unit Name</span>
                                <dd>11</dd>
                            </div>

                        </dl>
                    </div>
                    <div>
                        <div className='d-flex'>
                            <span className='modal_desc'>Meter No.</span>
                            <dd>{modalData.waterMeterNumber}</dd>
                        </div>
                        <div className='d-flex'>
                            <div style={{ width: "50%" }}>
                                <p>EB Reading</p>
                                <div className='d-flex mb-3 flex-wrap'>
                                    <div className='d-flex flex-column' style={{ width: "50%" }}>
                                        <span>Initial</span>
                                        <span className='modal_readings_box'>{modalData.waterInitialReading}</span>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <span>Final</span>
                                        <span className='modal_readings_box'>{modalData.waterFinalReading}</span>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <span className='modal_desc' style={{ width: "60%" }}>Total units</span>
                                    <dd>{modalData.totalWaterUnits}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Rate per unit</span>
                                    <dd>{modalData.waterRatePerUnit}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Total Amount</span>
                                    <dd>{modalData.totalWaterAMount}</dd>
                                </div>
                            </div>
                            <div style={{ width: "50%" }}>
                                <p>DG Reading</p>
                                <div className='d-flex mb-3 flex-wrap'>
                                    <div className='d-flex flex-column' style={{ width: "50%" }}>
                                        <span>Initial</span>
                                        <span className='modal_readings_box'>{modalData.waterInitialReading}</span>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <span>Final</span>
                                        <span className='modal_readings_box'>{modalData.waterFinalReading}</span>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <span className='modal_desc' style={{ width: "60%" }}>Total units</span>
                                    <dd>{modalData.totalWaterUnits}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Rate per unit</span>
                                    <dd>{modalData.waterRatePerUnit}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Total Amount</span>
                                    <dd>{modalData.totalWaterAMount}</dd>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className='col' onClick={() => {
                        setCurrentPage(2)
                        setShowModal(false)
                    }
                    }>
                        Generate Draft Invoice
                    </Button>
                </Modal.Footer>
            </Modal>}
            <Footer />
            {/* </> */}
        </>
    )
}
