import React from 'react'
import LeftNavBar from '../Reusable_Components/LeftNavBar'
import NavBarTop from '../Reusable_Components/NavBarTop'
import InvoiceDate from './InvoiceDate'
import { useNavigate } from 'react-router-dom'
import TermsAndConditions from './TermsAndConditions'
import PaymentTerms from './PaymentTerms'
import HSNCode from './HSNCode'
import TaxRates from './TaxRates'
import Footer from "../Login/Footer.jsx";

export default function AdminControls() {

    return (
        <>
            <>
                <LeftNavBar />
                <NavBarTop />
            </>
            <div className="addUserContainer">
                <div className="stepsContainer">
                    <div className="stepsTitle">
                        <h6 style={{ padding: "20px", lineHeight: "0px" }}>Admin Controls</h6>
                    </div>
                    <div className={'stepsCardActive'}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <span style={{ fontWeight: "bold", fontSize: "medium" }}>Invoice Information</span>
                        </div>
                        <span style={{ fontSize: "small", marginTop: "10px" }}>Update Invoice Information</span>
                    </div>
                </div>
                <div className="infoContainer d-flex flex-column justify-content-start">
                    <div className="infoTitle">
                        <h6 style={{ padding: "20px", lineHeight: "0px" }}>Update Information</h6>
                    </div>
                    <div className="infoBody" style={{ height: "80vh" }}>
                        {/* <InvoiceDate /> */}
                        <TermsAndConditions />
                        {/* <PaymentTerms /> */}
                        <HSNCode />
                        <TaxRates />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
