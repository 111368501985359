import { Table } from "@mui/material";
import React, {useEffect,useState} from "react";
import NavBarTop from "../Reusable_Components/NavBarTop";
import LeftNavBar from "../Reusable_Components/LeftNavBar";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Label,
    Tooltip,
    Legend,
    RadialBarChart,
    RadialBar,
    BarChart,
    Bar,
    CartesianGrid,
    PieChart,
  Pie,
  Cell,
  } from "recharts";

const CommercialHeadDashboard = () => {
  const barChartData = []
      //   const barChartData = [
      //   {
      //     "name": "Jan",
      //     "Month Wise Payments": 40,
          
      //   },
      //   {
      //     "name": "Feb",
      //     "Month Wise Payments": 30,
          
      //   },
      //   {
      //     "name": "Mar",
      //     "Month Wise Payments": 40,
          
      //   },
      //   {
      //     "name": "Apr",
      //     "Month Wise Payments": 80,
          
      //   },
      //   {
      //     "name": "May",
      //     "Month Wise Payments": 54,
          
      //   },
      //   {
      //     "name": "Jun",
      //     "Month Wise Payments": 39,
          
      //   },
      //   {
      //     "name": "Jul",
      //     "Month Wise Payments": 29,
          
      //   },
      //   {
      //       "name": "Aug",
      //       "Month Wise Payments": 24,
            
      //     },
      //     {
      //       "name": "Sep",
      //       "Month Wise Payments": 14,
            
      //     },
      //     {
      //       "name": "Oct",
      //       "Month Wise Payments": 11,
            
      //     },
      //     {
      //       "name": "Nov",
      //       "Month Wise Payments": 18,
            
      //     },
      //               {
      //       "name": "dec",
      //       "Month Wise Payments": 3,
            
      //     }
      // ]


    const usersDetailsData = [
        {
            userName : "ABC Electronics",
            assignedFacility : "ALCL 1,unit 1",
            sample : "ALCL 1,unit 1",
            lastModified : new Date('2024-3-1')
        },
        {
            userName : "ABC Electronics",
            assignedFacility : "ALCL 1,unit 1",
            sample : "ALCL 1,unit 1",
            lastModified : new Date('2024-3-5')
        },
        {
            userName : "ABC Electronics",
            assignedFacility : "ALCL 1,unit 1",
            sample : "ALCL 1,unit 1",
            lastModified : new Date('2024-3-5')
        },
        {
            userName : "ABC Electronics",
            assignedFacility : "ALCL 1,unit 1",
            sample : "ALCL 1,unit 1",
            lastModified : new Date('2024-3-5')
        },
        {
            userName : "ABC Electronics",
            assignedFacility : "ALCL 1,unit 1",
            sample : "ALCL 1,unit 1",
            lastModified : new Date('2024-3-5')
        },
        {
            userName : "ABC Electronics",
            assignedFacility : "ALCL 1,unit 1",
            sample : "ALCL 1,unit 1",
            lastModified : new Date('2024-3-5')
        },
        {
            userName : "ABC Electronics",
            assignedFacility : "ALCL 1,unit 1",
            sample : "ALCL 1,unit 1",
            lastModified : new Date('2024-3-5')
        },
        {
            userName : "ABC Electronics",
            assignedFacility : "ALCL 1,unit 1",
            sample : "ALCL 1,unit 1",
            lastModified : new Date('2024-3-5')
        },
        {
            userName : "ABC Electronics",
            assignedFacility : "ALCL 1,unit 1",
            sample : "ALCL 1,unit 1",
            lastModified : new Date('2024-3-5')
        },
        {
            userName : "ABC Electronics",
            assignedFacility : "ALCL 1,unit 1",
            sample : "ALCL 1,unit 1",
            lastModified : new Date('2024-3-5')
        },
        
    ] 

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text x={x} y={y} fill="white"  dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const getPath = (x, y, width, height) => {
        const borderRadius = 10; 
        const barWidth = width / 1; 
        const halfWidth = barWidth / 0.66;
        
        return `M${x + halfWidth},${y + height - borderRadius}
          L${x + halfWidth},${y + borderRadius}
          Q${x + halfWidth},${y},${x + halfWidth + borderRadius},${y}
          L${x + halfWidth + barWidth - borderRadius},${y}
          Q${x + halfWidth + barWidth},${y},${x + halfWidth + barWidth},${y + borderRadius}
          L${x + halfWidth + barWidth},${y + height - borderRadius}
          Q${x + halfWidth + barWidth},${y + height},${x + halfWidth + barWidth - borderRadius},${y + height}
          L${x + halfWidth + borderRadius},${y + height}
          Q${x + halfWidth},${y + height},${x + halfWidth},${y + height - borderRadius}
          Z`;
      };
    const RoundedBar = (props) => {
        const { fill, x, y, width, height } = props;
        const barWidth = width / 4; // Set the width to one-fourth of the available width
      
        return <path d={getPath(x, y, barWidth, height)} stroke="none" fill={fill} />;
      };

      const onMouseEnter = ()=>{
  
      }

      const spacesData = []

    // const spacesData = [
    //     {
    //         "name": "Occupied spaces",
    //         "value": 133,
    //         "color" : '#706F6F'

    //       },
    //       {
    //         "name": "Vacant spaces",
    //         "value": 50,
    //         "color" : '#9D9D9C'
    //       },
    //       {
    //         "name": "Other spaces",
    //         "value": 20,
    //         "color" : '#D9D9D9'
    //       },
    // ]

    const contractsData = []
//     const contractsData = [
//   {
//     "name": "JAN",
//     "Month Wise Contracts": 4000,
//     "amt": 2400
//   },
//   {
//     "name": "FEB",
//     "Month Wise Contracts": 3000,
//     "amt": 2210
//   },
//   {
//     "name": "MAR",
//     "Month Wise Contracts": 2000,
//     "amt": 2290
//   },
//   {
//     "name": "APR",
//     "Month Wise Contracts": 2780,
//     "amt": 2000
//   },
//   {
//     "name": "MAY",
//     "Month Wise Contracts": 1890,
//     "amt": 2181
//   },
//   {
//     "name": "JUN",
//     "Month Wise Contracts": 2390,
//     "amt": 2500
//   },
//   {
//     "name": "JUL",
//     "Month Wise Contracts": 3490,
//     // "amt": 2100
//   },
//   {
//     "name": "AUG",
//     "Month Wise Contracts": 3490,
//     "amt": 2100
//   },
//   {
//     "name": "SEP",
//     "Month Wise Contracts": 490,
//     "amt": 2100
//   },
//   {
//     "name": "OCT",
//     "Month Wise Contracts": 3490,
//     "amt": 2100
//   },
//   {
//     "name": "NOV",
//     "Month Wise Contracts": 90,
//     "amt": 2100
//   },
//   {
//     "name": "DEC",
//     "Month Wise Contracts": 800,
//     "amt": 2100
//   }
// ]


    
    const [invoiceBool,setInvoiceBool]=useState(false);
    const [userDetails,setUserDetails] = useState([])
     const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [isMEdiumScreen, setIsMediumScreen] = useState(false);

    

     useEffect(() => {
      // setUserDetails([usersDetailsData])

        const handleResize = () => {
          setIsSmallScreen(window.innerWidth <= 768); // Adjust the threshold as needed
          setIsMediumScreen(window.innerWidth > 768 && window.innerWidth < 1070)
        };
        
        handleResize(); // Check initial screen size
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);


    const userModifiedDate = (date) => {
        const data  = date.getDay()+'/'+date.getMonth() +'/'+ date.getFullYear() +" "+date.toLocaleTimeString('en-US',{hour:'2-digit',minute:'2-digit'});
        return data
    }
    

    return (
      <>
      <>
        <NavBarTop />
        <LeftNavBar />
      </>
        <div className="fm-dashboard-container">
            <h1 className="fm-dashboard-heading">Dashboard (Work In Progress)</h1>
                        <div className="commercial-dashboard-con">
                <div className="commercial-das-graph-con">
                        <div className="commercial-das-users-con">
                             <div className="commercial-header">
                                <div className="d-flex">
                                <h1>Users</h1>
                                <select className="mx-3">
                                    <option>Month Wise </option>
                                </select>
                            </div>
                            <button>View All &gt;</button>
                        </div>
                        {
                          userDetails.length > 0 ?
                          <div className="commercial-user-table-scrollable">
                        <div  className="commercial-user-table">
                            <Table>
                                <thead className="commercial-das-thead">
                                    <tr className="commercial-das-tr">
                                        <td>User Name</td>
                                        <td>Assigned Facility</td>
                                        <td>Sample Info</td>
                                        <td>Last Modified</td>
                                    </tr>
                                </thead>
                                <tbody  className="commercial-das-tbody">
                                        { usersDetailsData?.map((data,index)=>{
                                         return(
                                            <tr key={index} className="commercial-das-tr">
                                                <td>{data.userName}</td>
                                                <td>{data.assignedFacility}</td>
                                                <td>{data.sample}</td>
                                                <td>{userModifiedDate(data.lastModified)}</td>
                                            </tr>
                                         )
                                         })
                                        }
                                </tbody>
                            </Table>
                        </div>  
                        </div>
                        :
                        <div className="commercial-das-no-user">
                          <p>Add Users to assign space and contract details.</p>
                          {/* <button disabled> <img src='./images/icons/addNewUser.png'/> Add new User</button> */}
                        </div>
                        }                          
                        </div>
                        <div className="commercial-das-contracts-con">
                            <div className="commercial-header">
                                    <div className="d-flex">
                                    <h1>Contracts</h1>
                                    <select className="mx-3">
                                        <option>Month Wise </option>
                                    </select>
                                </div>
                                <button>View All &gt;</button>
                            </div>
                            {
                              contractsData?.length>0 ?
                              <div className="commercial-das-contract-line-chart">
                                <LineChart width={isSmallScreen ? 330: isMEdiumScreen ? 700 : 630} height={isMEdiumScreen ? 200 :150} data={contractsData}
                                    margin={{ top: 5, right: isSmallScreen ?10 :30, left: isSmallScreen? -5 :20, bottom: 5 }}>
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="Month Wise Contracts" stroke="#000" />
                                </LineChart>
                            </div>
                            :
                             <div className="commercial-das-no-user">
                          <p>Add Users to assign space and contract details.</p>
                          {/* <button disabled> <img src='./images/icons/addNewUser.png'/> Add new User</button> */}
                        </div>
                            }
                            
                        </div>                    
                </div>
                <div className="commercial-das-graph-con-2">
                     <div className="commercial-das-spaces-con">
                        <div className="commercial-header">
                                <div className="d-flex">
                                <h1>Spaces</h1>
                                <select className="mx-3">
                                    <option>All spaces</option>
                                </select>
                            </div>
                            <button>View All &gt;</button>
                        </div>
                        <div className="fm-dashboard-graph-card">
                        <div>
                        <div className="fm-dashboard-invoive-graph">
                    {
                        spacesData && spacesData.length>0 ?
                        <PieChart width={isMEdiumScreen ? 160 : 300} height={300} >
                        <Pie
                            data={spacesData}
                            dataKey="value"
                            nameKey="name"
                            cx={isMEdiumScreen ? 70 :isSmallScreen ? 70 : 120}
                            cy={isMEdiumScreen ? 130 : isSmallScreen ? 130 : 130}
                            innerRadius={ isMEdiumScreen ? 30 : isSmallScreen ? 30 : 20}
                            outerRadius={isMEdiumScreen ? 70 : isSmallScreen ? 70 : 70}
                            paddingAngle={0}
                            label={renderCustomizedLabel}
                            labelLine={0}
                        >
                            {spacesData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                        </Pie>
                        <Tooltip
                            formatter={(value) =>
                            `${value}`
                            }
                        />
                        {isSmallScreen ? <Legend
                            align={isSmallScreen ? "center" : "right"}
                            verticalAlign={isSmallScreen ? "bottom" : "middle"} 
                            layout={isSmallScreen ? "centric" : "vertical"}
                            iconSize={10}
                            wrapperStyle={{ bottom : 15 }} // Adjust bottom margin as needed
                            formatter={(value, entry) => (
                            <span style={{ color: 'black' }}>
                                <span>{`${entry.payload.name}`}</span>
                                <span>{` ${entry.payload.value ? entry.payload.value : 0}`}</span>
                            </span>
                            )}
                        /> : <Legend
                        align={isSmallScreen ? "center" : "right"}
                        verticalAlign={isSmallScreen ? "bottom" : "middle"} 
                        layout={isSmallScreen ? "centric" : "vertical"}
                        iconSize={10}
                        wrapperStyle={{ right: invoiceBool ? 20 : -180 }} // Adjust bottom margin as needed
                        formatter={(value, entry) => (
                        <span style={{ color: 'black' }}>
                            <span>{`${entry.payload.name}`}</span>
                            <span>{` ${entry.payload.value ? entry.payload.value : 0}`}</span>
                        </span>
                        )}
                    />}
                    
                        </PieChart>:
                        <div className="commercial-das-no-space">
                                  {/* <button disabled> <img src='./images/icons/Spaces.png'/> Add space</button> */}
                                </div>
                        }
                    </div>
                        

                        
                        </div>
                    </div>
                            
                        </div>
                        <div className="commercial-das-pending-con">
                            <div className="commercial-header">
                                    <div className="d-flex">
                                    <h1>Pending Contracts</h1>
                                    <select className="mx-1">
                                        <option>Month</option>
                                    </select>
                                </div>
                                <button>View All &gt;</button>
                            </div>
                            <div className="fm-dashboard-payments-graph">
                            {
                                barChartData && barChartData.length>0 ?                                
                                <BarChart width={isMEdiumScreen?350:isSmallScreen?350:530} barGap={10} height={180} data={barChartData} margin={{ top: 5, right:isMEdiumScreen?20: 5, bottom: 5, left:isSmallScreen? -15: isMEdiumScreen?-15:5 }} className='responsive-chart'>
                                                <CartesianGrid strokeDasharray="0" />
                                                <XAxis dataKey="name" tick={{ fontSize: 14 }}/>
                                                <YAxis tick={{ fontSize: 14 }}>
                                                    <Label dy={-35}  offset={10} position="insideBottomLeft" angle={-90} fontSize={14} />
                                                </YAxis>
                                                <Tooltip />
                                                <Legend/>
                                                <Bar dataKey="Month Wise Payments" fill="#878787" shape={<RoundedBar/>} onMouseEnter={onMouseEnter} />
                                                </BarChart>:
                               
                                 <div className="commercial-das-no-user-space">
                          <p className="spaces">Once you have a list of Users, their contract details or space specifications will be displayed here. If they are approaching the end of their tenure, this information will be highlighted or indicated for timely consideration.</p>
                          
                        </div>
                                }
                            </div>
                            
                        </div>
                </div>

                <div>    
                    {/*  */}
                </div>
               
            </div>

        </div>
        {/* <div className="footerbottom">   
            </div> */}
      </>
    )
}

export default CommercialHeadDashboard