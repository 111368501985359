import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { countryCodes } from "../../Config"
import { useDispatch, useSelector } from 'react-redux'
import { ADD_USER_FAILURE, ADD_USER_SUCCESS, addUser } from '../../Redux/Actions/Actions'
import { Button, Modal } from 'react-bootstrap'
import FormLoader from '../Reusable_Components/FromLoader'
import { MdErrorOutline } from 'react-icons/md'
import NavBarTop from '../Reusable_Components/NavBarTop'
import LeftNavBar from '../Reusable_Components/LeftNavBar'
import { validEmailCheck, processAndValidateEmail, encrypt, eleminateSpacesAtStartOfInputField } from "../Reusable_Components/ReusableFunctions";
import Footer from "../Login/Footer.jsx";

export default function AddUser(props) {

    const [username, setUsername] = useState('')
    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [group, setGroup] = useState('')
    const [usernameError, setUsernameError] = useState('')
    const [fullNameError, setFullNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [phoneNumberError, setPhoneNumberError] = useState('')
    const [groupError, setGroupError] = useState('')
    const [countryCode, setCountryCode] = useState("+91")
    const [emailVerificationModal, setEmailVerificationModal] = useState(false)
    const [userNameCharError, setuserNameCharError] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);


    const addUserSuccess = useSelector(state => state.addUserSuccess)
    const lodaing = useSelector(state => state.loading)
    const addUserError = useSelector(state => state.addUserError)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleFullName = (e) => {
        const cleanedValue = eleminateSpacesAtStartOfInputField(e.target.value);
        setFullName(cleanedValue);
        if (cleanedValue === '') {
            setFullNameError('Full Name is required');
        } else if (cleanedValue.length < 3) {
            setFullNameError('Full Name must be at least 3 characters long');
        } else if (!/^[a-zA-Z\s.]+$/.test(cleanedValue)) {
            setFullNameError('Full Name cannot contain numbers or special characters');
        } else {
            setFullNameError('');
        }
    }

    const handleUsername = (e) => {
        setUsername(e.target.value)
        if (e.target.value === '') {
            setUsernameError('Username is required')
        } else if (e.target.value.length < 3) {
            setUsernameError('Username must be at least 3 characters long');
        } else if (/\s/.test(e.target.value)) {
            setUsernameError('Username cannot contain spaces');
        } else if (/[A-Z]/.test(e.target.value)) {
            setUsernameError('Username must be in lowercase');
        } else {
            setUsernameError('')
        }

        if (/^[A-Za-z0-9\s-/_]+$/.test(e.target.value)) {
            setuserNameCharError(false)
        } else {
            setuserNameCharError(true)
        }

        // if (/\s/.test(e.target.value)) {
        //     setUsernameError('Username cannot contain spaces');
        // } else {
        //     setUsernameError('');
        // }
    }

    const handleEmail = (e) => {
        const input = e.target.value.toLowerCase();
        const { processedInput, isValid } = processAndValidateEmail(input, email);
        setEmail(processedInput);
        setIsValidEmail(isValid);
    };

    const handlePhoneNumber = (e) => {
        if (e.target.value.length <= 10) {
            setPhoneNumber(e.target.value)
            if (e.target.value === '') {
                setPhoneNumberError('Phone number is required')
            } else {
                setPhoneNumberError('')
            }
            if (e.target.value.length !== 10) {
                setPhoneNumberError('Phone number must be 10 digits');
            } else {
                setPhoneNumberError('');
            }
        }
    }

    const handleGroup = (e) => {
        setGroup(e.target.value)
        if (e.target.value === '') {
            setGroupError('Group is required')
        } else {
            setGroupError('')
        }
    }

    const handleAddUser = () => {
        let user_details = {
            username: username,
            name: fullName,
            email: email,
            phone_number: countryCode + phoneNumber,
            group: group,
        }
        dispatch(addUser(encrypt(user_details)))
    }

    const [showBackConfirmation, setShowBackConfirmation] = useState(false);
    const handleBackConfirmation = () => setShowBackConfirmation(false); //???
    const handleProceed = () => {
        setShowBackConfirmation(false);
        
    }



    return (
        <>
            <>
                <NavBarTop />
                <LeftNavBar />
            </>
            <div className="addUserContainer">
                <div className="stepsContainer">
                    <div className="stepsTitle">
                        <h6 style={{ padding: "20px", lineHeight: "0px" }}>Add New User</h6>
                    </div>
                    <div className={'stepsCardActive'}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <span style={{ fontWeight: "bold", fontSize: "medium" }}>User Information</span>
                        </div>
                        <span style={{ fontSize: "small", marginTop: "10px" }}>User Details</span>
                    </div>
                </div>
                <div className="infoContainer">
                    <div className="infoTitle">
                        <h6 style={{ padding: "20px", lineHeight: "0px" }}>1. User Information</h6>
                    </div>
                    <div className="infoBody">
                        <div>
                            <h6 style={{ fontWeight: "bold" }}>User Details</h6>
                            <div className='d-flex flex-column gap-4 mt-3'>
                                <div className='d-flex align-items-center gap-2 '>
                                    <label style={{ width: "40%" }}>Full Name <span className='text-danger'>*</span></label>
                                    <div style={{ width: "60%" }}>
                                        <input type="text" placeholder="Enter Full Name" value={fullName} className='form-control' onChange={handleFullName} />
                                        <span className='text-danger'>{fullNameError}</span>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center gap-2 '>
                                    <label style={{ width: "40%" }}>Username <span className='text-danger'>*</span></label>
                                    <div style={{ width: "60%" }}>
                                        <input type="text" placeholder="Enter Username" value={username} className='form-control' onChange={handleUsername} />
                                        <span className='text-danger'>{usernameError}</span>
                                        {userNameCharError && <div className="text-danger">Only a-z,A-Z,0-9,-,_,/ are allowed.</div>}
                                    </div>
                                </div>
                                <div className='d-flex align-items-center gap-2 '>
                                    <label style={{ width: "40%" }}>Email <span className='text-danger'>*</span></label>
                                    <div style={{ width: "60%" }}>
                                        <input type="email" placeholder="Enter Email" value={email} className='form-control' onChange={handleEmail} />
                                        {/* <span className='text-danger'>{emailError}</span> */}
                                        {!isValidEmail && <span className="input-error-text">Invalid email address</span>}
                                    </div>
                                </div>
                                <div className='d-flex align-items-center gap-2'>
                                    <label style={{ width: "40%" }}>Select Country<span className='text-danger'>*</span></label>
                                    <div style={{ width: "60%" }}>
                                        <select className="form-control" style={{ color: "gray" }} value={countryCode} onChange={(e) => setCountryCode(e.target.value)}>
                                            <option hidden>Select Country</option>
                                            {countryCodes.map((country, index) => (
                                                <option key={index} value={country.code}>{country.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center gap-2 '>
                                    <label style={{ width: "40%" }}>Phone Number <span className='text-danger'>*</span></label>
                                    <div style={{ width: "60%" }}>
                                        <div className='d-flex align-items-center'>
                                            <span className='p-2' style={{ border: "1px solid lightgray", borderRadius: "5px 0px 0px 5px", backgroundColor: "lightgray" }}>{countryCode}</span><input type="number" placeholder="Enter Phone number" value={phoneNumber} className='form-control noSpinArrows' style={{ borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px" }} maxLength={10} onChange={handlePhoneNumber} />

                                        </div>
                                        <span className='text-danger'>{phoneNumberError}</span>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center gap-2 '>
                                    <label style={{ width: "40%" }}>Group <span className='text-danger'>*</span></label>
                                    <div style={{ width: "60%" }}>
                                        <select className='fin_man_meter_dropdown' style={{ color: "gray", width: "100%" }} value={group} onChange={handleGroup}>
                                            <option hidden >Select a Group</option>
                                            <option>Commercial Head</option>
                                            <option>Facility Manager</option>
                                            <option>Finance Manager</option>
                                            <option>Technician</option>
                                            <option>CEO</option>
                                        </select>
                                        <span className='text-danger'>{groupError}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="infoFotter">
                        <div className="d-flex gap-3">
                            <button className="btn btn-secondary" onClick={() => setShowBackConfirmation(true)}>Back</button>
                            <button className="btn btn-secondary"
                                onClick={() => setEmailVerificationModal(true)}
                                disabled={
                                    username === '' || fullName === '' || email === '' || phoneNumber === '' || group === '' || !isValidEmail
                                    || fullNameError !== '' || usernameError !== '' || emailError !== '' || phoneNumberError !== '' || groupError !== '' || userNameCharError
                                }>Add User</button>
                        </div>
                    </div>
                </div>
                <Modal keyboard={false} backdrop="static" show={showBackConfirmation} onHide={handleBackConfirmation} centered className="popper-modal">
                    <Modal.Body className="text-center">
                        <h5>Are you sure you want to go back?</h5>
                        <p className="mb-3">Any changes made will be lost</p>
                    </Modal.Body>
                    <Modal.Footer className="footer" >
                        <Button variant="secondary" className="confirmation" onClick={handleBackConfirmation} >Cancel</Button>
                        <Button variant="secondary" className="confirmation-left" onClick={() => props?.setPage(1)} >
                            Proceed
                        </Button>
                    </Modal.Footer>
                </Modal>
                {emailVerificationModal && <Modal keyboard={false} backdrop="static" show={emailVerificationModal} onHide={() => setEmailVerificationModal(false)} centered className="popper-modal">
                    <Modal.Body className="text-center">
                        <h5>Verify Email</h5>
                        <p className="mb-3">Please double check your eamil and enter a valid email address </p>
                        <input type="email" placeholder="Enter Email" value={email} className='form-control' onChange={handleEmail} />
                    </Modal.Body>
                    <Modal.Footer className="footer" >
                        <Button variant="secondary" className="confirmation" onClick={() => setEmailVerificationModal(false)} >Cancel</Button>
                        <Button variant="secondary" className="confirmation-left" onClick={() => {
                            setEmailVerificationModal(false)
                            handleAddUser()
                        }} >
                            Proceed
                        </Button>
                    </Modal.Footer>
                </Modal>}
                {addUserSuccess && <Modal keyboard={false} backdrop="static" show={addUserSuccess} onHide={() => {
                    dispatch({
                        type: ADD_USER_SUCCESS,
                        payload: null
                    })
                }} centered className="popper-modal">
                    <Modal.Body className="text-center">
                        <img src="./images/icons/popper.svg" alt="Party Popper" />
                        <h5>Success!</h5>
                        <p className="mb-3">{addUserSuccess}</p>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center" >
                        <Button variant="secondary" className="secondary" onClick={() => {
                            dispatch({
                                type: ADD_USER_SUCCESS,
                                payload: null
                            })
                            props.setPage(1)
                        }} >
                            Proceed
                        </Button>
                    </Modal.Footer>
                </Modal>}
                {addUserError && <Modal keyboard={false} backdrop="static" show={addUserError} onHide={() => {
                    dispatch({
                        type: ADD_USER_FAILURE,
                        payload: null
                    })
                }} centered className="popper-modal">
                    <Modal.Body className="text-center">
                        <MdErrorOutline style={{ fontWeight: "10px", fontSize: "60px", color: "#Cc0202", opacity: 0.8 }} />
                        <h5>Error!</h5>
                        <p className="mb-3">{addUserError}</p>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center" >
                        <Button variant="secondary" className="secondary" onClick={() => {
                            dispatch({
                                type: ADD_USER_FAILURE,
                                payload: null
                            })
                        }} >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>}
                {lodaing && <FormLoader />}
            </div>
            <Footer />
        </>
    )
}
