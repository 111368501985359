/**
 * Search Component
 * 
 * This is a reusable search bar component that can be used across different parts of the application.
 * It includes an input field for search queries and a search icon.
 * 
 * @component
 * @param {Object} props - The props object.
 * @param {string} props.value - The current value of the search input.
 * @param {function} props.handleSearch - The function to handle the search input change event.
 * 
 * @example
 * // Example usage of the Search component
 * <Search value={searchValue} handleSearch={handleSearchFunction} />
 */


import React from 'react'
import { GoSearch } from 'react-icons/go'

export default function Search({ value, handleSearch, placeholder = "Search" }) {
    return (
        <div>
            <div className='our_companies_search_bar_container'>
                <input type='search' placeholder={placeholder} value={value} className='fin_man_meter_search_bar' onChange={handleSearch} />
                <GoSearch className='fin_man_meter_search_bar_icon' />
            </div>
        </div>
    )
}



