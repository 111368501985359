import React, { useEffect, useState } from 'react'
import NavBarTop from '../Reusable_Components/NavBarTop'
import LeftNavBar from '../Reusable_Components/LeftNavBar'
import { GoSearch } from 'react-icons/go'
import AddService from './AddService'
import { useDispatch, useSelector } from 'react-redux'
import { GET_ALL_SERVICES_FAILURE, getAllServices } from '../../Redux/Actions/Actions'
import { timeElapsed } from '../Reusable_Components/ReusableFunctions'
import Loader from '../Reusable_Components/loader'
import EditService from './EditService'
import AlertPopUp from '../Reusable_Components/Alert'
import Footer from "../Login/Footer.jsx";

export default function Services() {

    const dispatch = useDispatch()

    const servicesList = useSelector(state => state.servicesList)
    const getAllServicesError = useSelector(state => state.getAllServicesError)
    const loading = useSelector(state => state.loading)

    const [page, setPage] = useState(1)
    const [reload, setReload] = useState(false)
    const [serviceId, setServiceId] = useState(null)

    useEffect(() => {
        dispatch(getAllServices())
    }, [reload])

    return (
        <>
            <NavBarTop />
            <LeftNavBar />
            {page === 1 && <div className="meter_reports_container" >
                {loading && <Loader />}
                <div className='d-flex justify-content-between align-items-center flex-wrap'>
                    <h1 className='invoice-heading'>Services</h1>
                    <div className='d-flex align-items-center flex-wrap gap-3 mb-3'>
                        <button className='add-facility-button-our-companies' 
                        style={{ color: "white", border: "none" }} onClick={() => setPage(2)}>
                            <span className='generate_invoice'>
                                <img src={"./images/icons/newContract.svg"} style={{ marginRight: "10px" }} alt="+" />
                                Add New Service</span>
                                <span className='generate'><img src={"./images/icons/newContract.svg"} 
                                style={{ marginRight: "10px" }} alt="+" />Add</span>
                                </button>
                    </div>
                </div>
                <div className='table_container_commercial_head'>
                    <table>
                        <thead>
                            <tr className='fin_man_table_head'>
                                <th style={{ textWrap: "nowrap" }} className='C-pointer' >Service Name</th>
                                <th style={{ textWrap: "nowrap" }} className='C-pointer' >Service Code</th>
                                <th style={{ textWrap: "nowrap" }} className='C-pointer' >Service Description</th>
                                <th style={{ textWrap: "nowrap" }} className='C-pointer' >Created</th>
                                <th style={{ textWrap: "nowrap" }} className='C-pointer' >Updated </th>
                                <th style={{ textWrap: "nowrap" }} className='C-pointer' >Created by</th>
                                <th style={{ textWrap: "nowrap" }} className='C-pointer' >Updated by</th>
                                <th style={{ textWrap: "nowrap" }}>Action</th>
                            </tr>
                        </thead>
                        <tbody className='fin_man_table_body'>
                            {servicesList && servicesList.length > 0 ? servicesList.map((service, index) => {
                                return (
                                    <tr className={`table_row ${index % 2 === 0 ? 'even-row' : 'odd-row'}`} key={index}>
                                        <td>{service?.service_name}</td>
                                        <td>{service?.service_code}</td>
                                        <td>{service?.service_description}</td>
                                        <td>{timeElapsed(service?.created_at)}</td>
                                        <td>{timeElapsed(service?.updated_at)}</td>
                                        <td>{service?.created_by}</td>
                                        <td>{service?.updated_by}</td>
                                        <td className='text-center'>
                                            <img src="./images/icons/Edit.svg" alt="edit-icon" onClick={() => {
                                                setPage(3)
                                                setServiceId(service?.service_type_id)
                                            }} />
                                        </td>
                                    </tr>
                                )
                            }) :
                                <tr style={{textAlign:"center"}}>
                                    <td colSpan={8}>No Services Found</td>
                                </tr>}
                        </tbody>
                    </table>
                </div>
                {getAllServicesError && <AlertPopUp alertShow={getAllServicesError} alertType="error" alertMsg="Error" alertValue={getAllServicesError} alertButtons={[{
                    name: "Close", action: () => dispatch({
                        type: GET_ALL_SERVICES_FAILURE,
                        payload: null
                    })
                }]} />}
            </div>}
            {page === 2 && <AddService setPage={setPage} setReload={setReload} />}
            {page === 3 && <EditService setPage={setPage} setReload={setReload} serviceId={serviceId} />}
            <Footer />
        </>
    )
}
