import React, { useEffect, useState } from 'react'

export default function FinanceManagerDayCalendar({day,dayCalendarTasks}) {

    const [time, setTime] = useState([])

    useEffect(() => {
        var startTime = 6; // 6am
        var endTime = 24; // 12 AM (midnight)
        const taskTimes = []

        const arr = dayCalendarTasks

        // Loop through each hour from 6am to 12am
        for (var i = startTime; i <= endTime; i++) {
            // Convert hour to 12-hour format
            var hour = i % 12;
            if (hour === 0) {
                hour = 12; // 12am (midnight) should be represented as 12
            }

            // Determine if it'sam or pm
            var period = (i < 12 || i === 24) ? 'am' : 'pm';

            // Format hour with leading zero if necessary
            var formattedHour = hour < 10 ? '0' + hour : hour;

            // Output time in HH:MMam/pm format
            taskTimes.push({ time: formattedHour + ':00' + period })
        }

        const newTimes = taskTimes.map(timeSlot => {
            const hour = parseInt(timeSlot.time.match(/\d+/)[0]); // Extract hour from time
            const ampm = timeSlot.time.slice(-2); // Extract AM/PM from time
        
            const matchedTasks = arr ? arr.filter(task => {
                const taskHour = parseInt(task.time.match(/\d+/)[0]); // Extract hour from task time
                const taskAmpm = task.time.slice(-2); // Extract AM/PM from task time
                return taskHour === hour && taskAmpm === ampm;
            }).map(task => {
                return {task:task.taskname,time:task.time}
            }) : [] ;
        
            return { ...timeSlot, task: matchedTasks };
        });

        setTime(newTimes)
    }, [day])


    return (
        <div className='fin_man_day_calendar_container'>
                <div className='d-flex day_calendar_top_header'>
                    <div className='time'></div>
                    <div className={'task d-flex justify-content-center fs-5'}>{day === 1 ? "Monday" : day === 2 ? "Tuesday" : day === 3 ? "Wednesday" : day === 4 ? "Thursday" : day === 5 ? "Friday" : day === 6 ? "Saturday" : "Sunday"}</div>
                </div>
                {time.map((each) => {
                    return <div className='d-flex'>
                        <div className='time'>{each.time}</div>
                        <div className={(each.task.length > 0) ? 'task' : "task-add"}>
                            {(each.task.length > 0) ? each.task.map((item) => {
                                return <div className='task-details' title={item} >{item.task} , {item.time}</div>
                            }) : <button className='button_add_schedule'>Add Schedule</button>}
                        </div>
                    </div>
                })}
        </div>
    )
}