import React from 'react'

export default function FormLoader() {
  return (
    <div className="loader-overlay">
      <div className="loader-spinner"></div>
      <div className="loader-text">Please wait...</div>
    </div>
  )
}
