import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Element from './Element'
import { MdErrorOutline, MdOutlineDelete } from "react-icons/md";
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_FIELD, DELETE_FIELD_ERROR, FETCHING_ERROR, SHOW_MODAL, deleteField, getAllFields } from '../../Redux/Actions/Actions';
import FormLoader from '../Reusable_Components/FromLoader';
import { sorting_lists, timeElapsed } from '../Reusable_Components/ReusableFunctions';
import AlertPopUp from '../Reusable_Components/Alert';

export default function FieldsList() {

    const dispatch = useDispatch()
    const fieldsList = useSelector((state) => state.fieldsList)
    const loader = useSelector((state) => state.loading)
    const modal = useSelector((state) => state.showModal)
    const deleteFieldSuccess = useSelector((state) => state.deleteField)
    const fetchingError = useSelector((state) => state.fetchingError)
    const deleteFieldError = useSelector((state) => state.deleteFieldError)

    const [fields, setFields] = useState(useSelector((state) => state.fieldsList))
    const [preview, setPreview] = useState(false)
    const [previewObject, setPreviewObject] = useState({})
    const [showModal, setShowModal] = useState(false);
    const [deleteId, setDeleteId] = useState("")

    useEffect(() => {
        dispatch(getAllFields())
    }, [])

    const handleCloseModal = () => {
        dispatch({
            type: SHOW_MODAL,
            payload: false
        })
    };

    useMemo(() => {
        return sorting_lists(fieldsList)
    }, [fieldsList]);

    const handleDeleteElement = () => {
        // const fields = JSON.parse(localStorage.getItem("Fields"))
        // const filteredFields = fields.filter((field) => field.items.id !== deleteId)
        // setFields(filteredFields)
        // localStorage.setItem("Fields", JSON.stringify(filteredFields))
        // setShowModal(false)
        dispatch(deleteField(deleteId))
    }

    const componentToRender = () => {
        return <div style={{ padding: "20px", width: "210mm", backgroundColor: "white" }}>
            <div style={{ overflow: "auto", width: "100%" }} className='d-flex flex-column gap-3'>
                <div className='d-flex align-items-center gap-3 container'>
                    <Element object={previewObject} />
                    <div className='d-flex justify-content-end' onClick={() => {
                        setPreview(false)
                        setPreviewObject({})
                    }}>
                        <button className='btn btn-secondary'>Back</button>
                    </div>
                </div>

            </div>

        </div>
    }

    const handleDeleteElementModal = (id) => {
        setDeleteId(id)
        dispatch({
            type: SHOW_MODAL,
            payload: true
        })
    }

    return (
        <>
            {loader && <FormLoader />}
            {preview ? <div>{componentToRender()}</div> : <div>
                <div className='d-flex justify-content-between'>
                    <h3>Fields</h3>
                    <Link to={'/form/fields'}><button className='btn btn-secondary mx-4'>Create new field</button></Link>
                </div>
                <table style={{ width: "100%" }} className='mt-4'>
                    <thead>
                        <tr className='fin_man_table_head text-center' style={{ textWrap: "nowrap" }}>
                            <th>Field Name</th>
                            <th>Field Type</th>
                            <th>Description</th>
                            <th>Created by</th>
                            <th>Created</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody className='fin_man_table_body'>
                        {fieldsList?.length > 0 ?
                            fieldsList?.map((field) => {
                                if (field?.field_type !== "Table") {
                                    return <tr key={field?.field_id}>
                                        <td>{field?.field_name}</td>
                                        <td>{field?.field_type}</td>
                                        <td title={field?.description}>{field?.description?.length > 50 ? field?.description?.substring(0, 50) + '...' : field.description}</td>
                                        <td>{field?.created_by}</td>
                                        <td>{timeElapsed(field?.created_at)}</td>
                                        <td className='text-center' role='button'>
                                            <img src="./images/icons/View.png" alt="search-icon" onClick={() => {
                                                setPreview(true)
                                                setPreviewObject(JSON.parse(field?.json_data))
                                            }} />
                                            <MdOutlineDelete height={30} width={30} onClick={() => handleDeleteElementModal(field?.field_id)} />
                                        </td>
                                    </tr>
                                }
                            })
                            : <tr>
                                <td>No records found</td>
                            </tr>}

                    </tbody>
                </table>
            </div>}
            {modal && <Modal keyboard={false} backdrop="static" show={modal} centered onHide={handleCloseModal}>
                <Modal.Body>Are you sure you want to delete this Field?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteElement}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>}
            {deleteFieldSuccess && <AlertPopUp
                alertShow={deleteFieldSuccess}
                alertType="success"
                alertMsg="Success"
                alertValue={deleteFieldSuccess}
                alertButtons={[{ name: "Close", action: () => { dispatch({ type: DELETE_FIELD, payload: null }) } }]}
            />}
            {fetchingError && <AlertPopUp
                alertShow={fetchingError}
                alertType="error"
                alertMsg="Error"
                alertValue={fetchingError}
                alertButtons={[{ name: "Close", action: () => { dispatch({ type: FETCHING_ERROR, payload: null }) } }, {
                    name: "Retry", action: () => {
                        dispatch(getAllFields())
                        dispatch({
                            type: FETCHING_ERROR,
                            payload: null
                        })
                    }
                }]}
            />}
            {deleteFieldError && <AlertPopUp
                alertShow={deleteFieldError}
                alertType="error"
                alertMsg="Error"
                alertValue={deleteFieldError}
                alertButtons={[{
                    name: "Close", action: () => {
                        dispatch({
                            type: DELETE_FIELD_ERROR,
                            payload: null
                        })
                        dispatch({
                            type: SHOW_MODAL,
                            payload: false
                        })
                    }
                }, {
                    name: "Retry", action: () => {
                        handleDeleteElement()
                        dispatch({
                            type: DELETE_FIELD_ERROR,
                            payload: null
                        })
                    }
                }]}
            />}
        </>
    )
}