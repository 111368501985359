import React, { useEffect, useState } from 'react';
import UploadComponent from './UploadComponent';
import { commercialHeadUploadDocument,
} from '../../Redux/Actions/Actions';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { base_url } from "../../utilities";


export default function FileUpload() {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadedFileData, setUploadedFileData] = useState([{}]);
    const [contractDocumentsList, setContractDocumentsList] = useState([]);
    useEffect(() => {
        const id_value = "Indo-Ama"
        axios
        .get("https://pzkmr3y6xf.execute-api.us-east-2.amazonaws.com/dev/api/documents/listDocumentsByID?filter_condition=%7B%22id_type%22%3A%20%22contract_id%22%2C%22id_value%22%3A%220aa0160f-f111-4912-abc0-c70134ddcbe3%22%7D")
        .then((response) => {
          const listOfDocuments = response.data.data;
          setContractDocumentsList([...listOfDocuments]);
        })
        .catch((error) => {
          console.log(error)
        });
    }, []);

   
    const dispatch = useDispatch();
    
    const handleFilesSelected = (files) => {
        setUploadedFiles(files);
        const processedFiles = [];
    
    files.forEach((file) => {
        const reader = new FileReader();
        
        reader.onloadend = function() {
        const base64FileContent = reader.result.split(",")[1];
        const processedFile = {
            file_content: base64FileContent,
            object_type: "contracts",
            file_description:file.description,
            file_type: file.file.type.split("/")[1],
            file_name: file.file.name,
            contract_name:"Amazon-ACLC9",
            file_size: file.file.size,
            flag:"duplicate",
            user_id:"123",
        };
        processedFiles.push(processedFile);

        // Check if all files are processed
        if (processedFiles.length === files.length) {
            setUploadedFiles(processedFiles);
            // Dispatch the action or perform any other actions with processedFiles
        }
        };

        reader.readAsDataURL(file.file);
    });
        
    };

    const handleUploadFilesSubmit = () => {
        dispatch(commercialHeadUploadDocument({ files: uploadedFiles }));
    }
    const viewClicked = false;

    
  return (
    <div className='meter_reports_container'>
      {/* <h4>Upload Files</h4> */}
      <UploadComponent onFilesSelected={handleFilesSelected} uploadMethod="single" 
      viewMode={viewClicked} uploadedFileData={contractDocumentsList} />
      <button onClick={handleUploadFilesSubmit} disabled={viewClicked}>Submit</button>
    </div>
  );
}
