import React from "react";
import axios from "axios";
import {base_url} from "../../utilities" 

const ImageUpload = (selectedContractId, listOfMeterValues,listOfDgMeterValues,dgReadingToggle,contractName,setErrorPopUp,setDocumentsUploadData,errorPopUpMessage,setErrorValue,setIsLoading,setAlertShow,setAlertType,setAlertMessage,setAlertValue,setAlertButtons) => {
    setIsLoading(true)
    const URL = base_url + 'documents'
    const data = []
    listOfMeterValues.forEach(e=>{
        data.push({
             "file_content" : e.meter_image,
                "object_type": "meter_readings",
                "file_type": "png",
                "user_id":localStorage.getItem('username'),
                "contract_name": contractName,
                "file_name": e.assigned_name,
                "contract_id": selectedContractId,
                "meter_id": e.meter_id,
                "facility_id": localStorage.getItem('selected_facility_id'),
                "flag": null // duplicate or overwrite or null
        })
    })
    if(dgReadingToggle){
        listOfDgMeterValues?.forEach(e=>{
            data.push({
                "file_content" : e.meter_image,
                "object_type": "meter_readings",
                "user_id":localStorage.getItem('username'),
                "file_type": "png",
                "file_name": e.assigned_name,
                "contract_name": contractName,
                "contract_id": selectedContractId,
                "meter_id": e.meter_id,
                "facility_id": localStorage.getItem('selected_facility_id'),
                "flag": "duplicate" // duplicate or overwrite or null
            })
        })
    }	
    const apiData = {
        "files" : [...data]
    }
    if(errorPopUpMessage==='' || errorPopUpMessage===null){
    axios.post(URL,apiData)
    .then(response=>{
        if(response?.data?.data[0]==='error'){
            setErrorValue(response?.data?.data[1].message)
            setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error')
            setAlertValue(response?.data?.data[1].message)
            setAlertButtons([
                {name:'Close',action: ()=>{
                setAlertShow(false)
                // window.location.reload()
                }},
            ])
            setIsLoading(false)
        }else{
        setDocumentsUploadData(response.data.data)
        }
    })
    .catch(error=>{
        if(error?.response?.data.message.startsWith('Object already exists in S3 bucket')){
            setIsLoading(false)
            setErrorPopUp(true)
        }
        else{
            setErrorValue(error?.response?.data.message);
            setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error')
            setAlertValue(error?.response?.data.message)
            setAlertButtons([{name:'Close',action: ()=>{
                setAlertShow(false)
                // window.location.reload()
            }}])
            setIsLoading(false)
            }
        })  
    }
    else{
        data.forEach(e=>{
                e.flag = errorPopUpMessage
            }
            )
            const apiData2 = {
                "files" : [...data]
            }
            axios.post(URL,apiData2)
            .then(response=>{
                if(response?.data?.data[0]==='error'){
                    setErrorValue(response?.data?.data[1].message)
                    setAlertShow(true)
                    setAlertType('error')
                    setAlertMessage('Error')
                    setAlertValue(response?.data?.data[1].message)
                    setAlertButtons([
                        {name:'Close',action: ()=>{
                        setAlertShow(false)
                        // window.location.reload()
                        }},
                    ])
                    setIsLoading(false)
                }
                else{
                    setDocumentsUploadData(response.data.data)
                }
            })
            .catch(error=>{
                setErrorValue(error?.response?.data.message);
                setAlertShow(true)
                setAlertType('error')
                setAlertMessage('Error')
                setAlertValue(error?.response?.data.message)
                setAlertButtons([{name:'Close',action: ()=>{
                    setAlertShow(false)
                    // window.location.reload()
                }}])
                setIsLoading(false)
            })
    }

}

export default ImageUpload;

