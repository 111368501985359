import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker';
import { GET_TAX_RATES_FAILURE, GET_TAX_RATES_SUCCESS, UPDATE_TAX_RATES_FAILURE, UPDATE_TAX_RATES_SUCCESS, getTaxRates, updateTaxRates } from '../../Redux/Actions/Actions';
import { useDispatch, useSelector } from 'react-redux';
import FormLoader from '../Reusable_Components/FromLoader';
import { Button, Modal } from 'react-bootstrap';
import { MdErrorOutline } from 'react-icons/md';

export default function TaxRates() {

    const dispatch = useDispatch();

    const loading = useSelector(state => state.loading);
    const taxRates = useSelector(state => state.taxRates);
    const taxRatesError = useSelector(state => state.taxRatesError);
    const updateTaxRatesSuccess = useSelector(state => state.updateTaxRatesSuccess);
    const updateTaxRatesError = useSelector(state => state.updateTaxRatesError);

    const [selectedService, setSelectedService] = useState('slf');
    const [biilingPeriodFilter, setBillingPeriodFilter] = useState(new Date())

    useEffect(() => {
        const month = biilingPeriodFilter.getMonth() + 1;
        const year = biilingPeriodFilter.getFullYear();
        dispatch(getTaxRates(selectedService, month, year))
    }, [selectedService, biilingPeriodFilter])

    const handleUpdateTaxRates = () => {
        if (!taxRates.cgst || !taxRates.sgst || !taxRates.igst) {
            dispatch({
                type: UPDATE_TAX_RATES_FAILURE,
                payload: "Please fill all the fields"
            })
            return;
        } else if (taxRates.cgst < 0 || taxRates.sgst < 0 || taxRates.igst < 0) {
            dispatch({
                type: UPDATE_TAX_RATES_FAILURE,
                payload: "Please enter valid tax rates"
            })
            return;

        } else {
            const month = biilingPeriodFilter.getMonth() + 1;
            const year = biilingPeriodFilter.getFullYear();
            const tax_data = {
                service_name: selectedService,
                effective_from_month: month,
                effective_from_year: year,
                cgst: taxRates.cgst,
                sgst: taxRates.sgst,
                igst: taxRates.igst
            }
            dispatch(updateTaxRates(tax_data))
        }
    }


    return (
        <div className='py-2' style={{ borderBottom: "1px solid lightgray" }}>
            <h4>Tax Rates</h4>
            <div className='d-flex'>
                <div className='d-flex flex-column gap-3'>
                    <span>Update the Tax Rates for a service</span>
                    <div className='d-flex align-items-center gap-3 flex-wrap ' style={{ width: "100%" }}>
                        <div className='d-flex flex-column'>
                            <label>Service</label>
                            <div>
                                <select value={selectedService} className='form-select responsive-add-facility-input-fields' onChange={(e) => setSelectedService(e.target.value)}>
                                    <option hidden>Select Service</option>
                                    <option value={"slf"}>SLF</option>
                                    <option value={"electricity"}>Electricity</option>
                                    <option value={"water"}>Water</option>
                                    <option value={"fms"}>FMS</option>
                                    <option value={"misc"}>Misc</option>
                                </select>
                            </div>
                        </div>
                        <div className="d-flex flex-column">
                            <label style={{ width: "50%", marginBottom: "5px" }}>Period :&nbsp;</label>
                            <DatePicker
                                className="admin-controls-date-picker form-control responsive-add-facility-input-fields"
                                placeholderText="Select Period"
                                selected={biilingPeriodFilter}
                                style={{ width: "1rem", height: "2.5rem !important" }}
                                onChange={(date) => setBillingPeriodFilter(date)}
                                showMonthYearPicker
                                dateFormat="MMM yyyy"
                            // customInput={<input value={biilingPeriodFilter ? changeDateFormat() : ''} />}
                            />

                        </div>
                    </div>
                    <h6>Intra State: </h6>
                    <div className='d-flex align-items-center gap-3 flex-wrap ' style={{ width: "100%" }}>
                        <div className='d-flex flex-column'>
                            <label style={{ width: "50%" }}>CGST</label>
                            <input type='number'
                            onWheel={(e) => e.target.blur()}
                                placeholder='Enter CGST rate'
                                className='noSpinArrows form-control responsive-add-facility-input-fields'
                                value={taxRates && taxRates.cgst}
                                onChange={(e) => {
                                    dispatch({
                                        type: GET_TAX_RATES_SUCCESS,
                                        payload: { ...taxRates, cgst: e.target.value }
                                    })
                                }}
                            />
                        </div>
                        <div className='d-flex flex-column'>
                            <label style={{ width: "50%" }}>SGST</label>
                            <input type='number'
                            onWheel={(e) => e.target.blur()}
                                placeholder='Enter SGST rate'
                                className='noSpinArrows form-control responsive-add-facility-input-fields'
                                value={taxRates && taxRates.sgst}
                                onChange={(e) => {
                                    dispatch({
                                        type: GET_TAX_RATES_SUCCESS,
                                        payload: { ...taxRates, sgst: e.target.value }
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <h6>Inter State: </h6>
                    <div className='d-flex align-items-center gap-3 flex-wrap ' style={{ width: "100%" }}>
                        <label style={{ width: "50%" }}>IGST</label>
                        <input type='number'
                        onWheel={(e) => e.target.blur()}
                            placeholder='Enter IGST rate'
                            className='noSpinArrows form-control responsive-add-facility-input-fields'
                            value={taxRates && taxRates.igst}
                            onChange={(e) => {
                                dispatch({
                                    type: GET_TAX_RATES_SUCCESS,
                                    payload: { ...taxRates, igst: e.target.value }
                                })
                            }}
                        />
                    </div>
                </div>

            </div>
            <div className='d-flex justify-content-end' style={{ height: "100%" }}>
                <button className='invoice_btn' style={{ color: "white" }} onClick={handleUpdateTaxRates}>Update</button>
            </div>
            {loading && <FormLoader />}
            {taxRatesError && <Modal keyboard={false} backdrop="static" show={taxRatesError} onHide={() => {
                dispatch({
                    type: GET_TAX_RATES_FAILURE,
                    payload: null
                })
            }} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <MdErrorOutline style={{ fontWeight: "10px", fontSize: "60px", color: "#Cc0202", opacity: 0.8 }} />
                    <h5>Error!</h5>
                    <p className="mb-3">{taxRatesError}</p>
                </Modal.Body>
                <Modal.Footer className='footer'>
                    <Button variant="secondary" className="secondary-left" onClick={() => {
                        dispatch({
                            type: GET_TAX_RATES_FAILURE,
                            payload: null
                        })
                    }} >
                        Close
                    </Button>
                    <Button variant="secondary" className="secondary" onClick={() => {
                        dispatch(getTaxRates(selectedService, biilingPeriodFilter.getMonth() + 1, biilingPeriodFilter.getFullYear()))
                    }} >
                        Retry
                    </Button>
                </Modal.Footer>
            </Modal>}
            {updateTaxRatesSuccess && <Modal keyboard={false} backdrop="static" show={updateTaxRatesSuccess} onHide={() => {
                dispatch({
                    type: UPDATE_TAX_RATES_SUCCESS,
                    payload: null
                })
            }} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <img src="./images/icons/popper.svg" alt="Party Popper" />
                    <h5>Success!</h5>
                    <p className="mb-3">{updateTaxRatesSuccess}</p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center" >
                    <Button variant="secondary" className="secondary" onClick={() => {
                        dispatch({
                            type: UPDATE_TAX_RATES_SUCCESS,
                            payload: null
                        })
                    }} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>}
            {updateTaxRatesError && <Modal keyboard={false} backdrop="static" show={updateTaxRatesError} onHide={() => {
                dispatch({
                    type: UPDATE_TAX_RATES_FAILURE,
                    payload: null
                })
            }} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <MdErrorOutline style={{ fontWeight: "10px", fontSize: "60px", color: "#Cc0202", opacity: 0.8 }} />
                    <h5>Error!</h5>
                    <p className="mb-3">{updateTaxRatesError}</p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center" >
                    <Button variant="secondary" className="secondary" onClick={() => {
                        dispatch({
                            type: UPDATE_TAX_RATES_FAILURE,
                            payload: null
                        })
                        // window.location.reload();
                    }} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>}
        </div>
    )
}
