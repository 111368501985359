import React, { useEffect, useRef } from 'react';
import { PDFDocument } from 'pdf-lib';
import * as pdfjsLib from 'pdfjs-dist/webpack';

const PdfViewer = ({ base64Pdf }) => {
  const canvasRef = useRef(null);
  const renderTaskRef = useRef(null);

  useEffect(() => {
    const loadPdf = async () => {
      try {
        // Decode base64 to Uint8Array
        const pdfData = atob(base64Pdf);
        const uint8Array = new Uint8Array(pdfData.length);
        for (let i = 0; i < pdfData.length; i++) {
          uint8Array[i] = pdfData.charCodeAt(i);
        }

        // Load the PDF document
        const pdfDoc = await PDFDocument.load(uint8Array);
        const pdfBytes = await pdfDoc.save();
        const loadingTask = pdfjsLib.getDocument({ data: pdfBytes });

        loadingTask.promise.then((pdf) => {
          pdf.getPage(1).then((page) => {
            const viewport = page.getViewport({ scale: 1.5 });
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
              canvasContext: context,
              viewport: viewport,
            };

            // Cancel the previous render task if it exists
            if (renderTaskRef.current) {
              renderTaskRef.current.cancel();
            }

            // Start the new render task
            renderTaskRef.current = page.render(renderContext);
            renderTaskRef.current.promise.then(() => {
              renderTaskRef.current = null;
            }).catch(error => {
              if (error.name !== 'RenderingCancelledException') {
                console.error('Error rendering page:', error);
              }
            });
          });
        });
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    };

    loadPdf();

    // Cleanup function to cancel the render task on unmount
    return () => {
      if (renderTaskRef.current) {
        renderTaskRef.current.cancel();
      }
    };
  }, [base64Pdf]);

  return <canvas style={{width:'100%',marginTop:'20px'}} ref={canvasRef}></canvas>;
};

export default PdfViewer;
