import React from 'react'

export default function ToggleInput({ object }) {
  return (
    <div className='w-100 d-flex align-items-start justify-content-start' style={{ height: "100%" }}>
      <label style={{ width: "60%", fontSize: "12px", border: "1px solid #ccc", padding: "10px", height: "100%" }}>{object.content}</label>
      <div className="form-switch d-flex gap-2 justify-content-center align-items-center" style={{width : "10%",height: "100%", border: "1px solid #ccc" }}>
        <input className="form-check-input" style={{ width: "40px", height: "20px", padding: "8px" }} type="checkbox" title='status' />
      </div>
      <div style={{width:"30%",padding:"6px",border:"1px solid lightgray",height:"100%"}}>
        <input name={object.content} className='form-control' style={{ padding: "8px", flex: "1" }} placeholder='Remarks'/>
      </div>
    </div>
  )
}
