import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { MdErrorOutline, MdOutlineDelete } from "react-icons/md";
import { Alert, Button, Modal } from 'react-bootstrap';
import Element from './Element';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_PANEL, DELETE_PANEL_ERROR, FETCHING_ERROR, SHOW_MODAL, deletePanel, getAllPanels } from '../../Redux/Actions/Actions';
import FormLoader from '../Reusable_Components/FromLoader';
import { sorting_lists, timeElapsed } from '../Reusable_Components/ReusableFunctions';
import AlertPopUp from '../Reusable_Components/Alert';

export default function PanelsList() {

    const dispatch = useDispatch()
    const panelsList = useSelector((state) => state.panelsList)
    const loading = useSelector((state) => state.loading)
    const modal = useSelector((state) => state.showModal)
    const deletePanelSuccess = useSelector((state) => state.deletePanelSuccess)
    const fetchingError = useSelector((state) => state.fetchingError)
    const deletePanelError = useSelector((state) => state.deletePanelError)

    const [panels, setPanels] = useState([]);
    const [preview, setPreview] = useState(false);
    const [previewObject, setPreviewObject] = useState([])
    const [currentTime, setCurrentTime] = useState(new Date())
    const [deleteId, setDeleteId] = useState("")

    useEffect(() => {
        // let panels = JSON.parse(localStorage.getItem("Panels"))
        // panels = panels?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
        // setPanels(panels)

        dispatch(getAllPanels())
    }, [])

    useMemo(() => {
        return sorting_lists(panelsList)
    }, [panelsList]);

    return (
        <div>
            {preview ? <div className='d-flex flex-column p-3' style={{ width: "210mm", backgroundColor: "white" }}>
                {previewObject?.map((previewObj, index) => {
                    return <div key={index} id={previewObj.id + " " + index} className='d-flex'>
                        {previewObj.items.map((item) => {
                            return <Element object={item} preview={true} />
                        })}
                    </div>
                })}
                <div className='d-flex justify-content-end w-100'>
                    <button className='btn btn-secondary' onClick={() => {
                        setPreview(false)
                        setPreviewObject([])
                    }}>Back</button>
                </div>
            </div> : <>
                <div className='d-flex justify-content-between'>
                    <h3>Panels</h3>
                    <Link to={'/form/panels'}><button className='btn btn-secondary'>Create new panel</button></Link>
                </div>
                <table style={{ width: "100%" }} className='mt-4'>
                    <thead>
                        <tr className='fin_man_table_head text-center'>
                            <th>Panel Name</th>
                            <th>Description</th>
                            <th>Created by</th>
                            <th>Created</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody className='fin_man_table_body'>
                        {panelsList && panelsList?.length > 0 ? panelsList?.map((panel, index) => {
                            return <tr key={index}>
                                <td>{panel?.panel_name}</td>
                                <td>{panel?.description}</td>
                                <td>{panel?.created_by}</td>
                                <td>{timeElapsed(panel?.created_at)}</td>
                                <td className='text-center' role='button'>
                                    <img src="./images/icons/View.png" alt="search-icon" onClick={() => {
                                        setPreview(true)
                                        setPreviewObject(JSON.parse(panel?.json_data))
                                    }} />
                                    <MdOutlineDelete onClick={() => {
                                        dispatch({
                                            type: SHOW_MODAL,
                                            payload: true
                                        })
                                        setDeleteId(panel?.panel_id)
                                    }} />
                                </td>
                            </tr>
                        }) : <tr>
                            <td>No records found</td>
                        </tr>}
                    </tbody>
                </table>
            </>}
            {modal && <Modal keyboard={false} backdrop="static" show={modal} centered onHide={() => {
                dispatch({
                    type: SHOW_MODAL,
                    payload: false
                })
            }}>
                <Modal.Body>Are you sure you want to delete this Panel?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        dispatch({
                            type: SHOW_MODAL,
                            payload: false
                        })
                    }}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => dispatch(deletePanel(deleteId))}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>}
            {deletePanelSuccess && <AlertPopUp
                alertShow={deletePanelSuccess}
                alertType="success"
                alertMsg="Success"
                alertValue={deletePanelSuccess}
                alertButtons={[{ name: "Close", action: () => { dispatch({ type: DELETE_PANEL, payload: null }) } }]}
            />
            }
            {fetchingError && <AlertPopUp
                alertShow={fetchingError}
                alertType="error"
                alertMsg="Error"
                alertValue={fetchingError}
                alertButtons={[{ name: "Close", action: () => { dispatch({ type: FETCHING_ERROR, payload: null }) } }, {
                    name: "Retry", action: () => {
                        dispatch(getAllPanels())
                        dispatch({
                            type: FETCHING_ERROR,
                            payload: null
                        })
                    }
                }]}
            />}
            {deletePanelError && <AlertPopUp
                alertShow={deletePanelError}
                alertType="error"
                alertMsg="Error"
                alertValue={deletePanelError}
                alertButtons={[{
                    name: "Close", action: () => {
                        dispatch({
                            type: DELETE_PANEL_ERROR,
                            payload: null
                        })
                        dispatch({
                            type: SHOW_MODAL,
                            payload: false
                        })
                    }
                }, {
                    name: "Retry", action: () => {
                        dispatch(deletePanel(deleteId))
                        dispatch({
                            type: DELETE_PANEL_ERROR,
                            payload: null
                        })
                    }
                }]}
            />}
            {loading && <FormLoader />}
        </div>
    )
}
