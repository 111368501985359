import React, { useEffect, useRef } from 'react'
import { useDrag } from 'react-dnd';
import { TiWarningOutline } from "react-icons/ti";

export default function Task({ task, handleOpenTask }) {

    const taskRef = useRef(task);

    useEffect(() => {
        taskRef.current = task;
    }, [task]);

    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'TASK',
        item: () => {
            return { ...taskRef.current };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    const opacity = isDragging ? 0.4 : 1;

    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('-');
        return new Date(year, month - 1, day);
    };

    // Function to check if the date is past due
    const isPastDue = (date) => {
        const today = new Date();
        return date < today && !isToday(date);
    };

    // Function to check if the date is today
    const isToday = (date) => {
        const today = new Date();
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    };

    // Parse the scheduledDate string into a Date object
    const scheduledDate = parseDate(task?.task_start_time);

    return (
        <div className='task-item C-pointer' style={{ opacity }} ref={drag} onClick={() => handleOpenTask(task)}>
            <div style={{ borderBottom: "1px solid #CFCFCF", width: "100%" }} className='d-flex justify-content-between align-items-center'>
                <div>{task?.task_name}</div>
                {isPastDue(scheduledDate) && task?.status !== "completed" && <div>
                    <TiWarningOutline color={"red"} style={{ height: "20px", width: "20px", marginTop: "-5px" }} />
                </div>}
            </div>
            <div className='d-flex justify-content-between mt-2'>
                <div className='d-flex gap-2'>
                    <img src='./images/icons/schedule.svg' />
                    <span>{task?.task_start_time.split(" ")[0]}</span>
                </div>
                <span>{task?.scheduledTime}</span>
            </div>
            <div className='d-flex gap-2 align-items-center mt-2'>
                <img src="./images/icons/Person.svg" alt="person" />
                <span style={{ backgroundColor: "#F3F3F3", width: "fit-content" }} className='p-1'>
                    {task?.assignee ? task?.assignee : "Unassigned"}
                </span>
            </div>
            <div className='d-flex gap-3 align-items-center mt-2'>
                <img src="./images/icons/facility.svg" alt="location" height={18} width={18} />
                <span style={{ backgroundColor: task?.facilityColor, width: "fit-content" }} className='p-1'>{task?.facility_name ? task.facility_name : "-"}</span>
            </div>
        </div>
    )
}
