import React, { useState, useEffect } from 'react'
import NavBarTop from '../Reusable_Components/NavBarTop'
import LeftNavBar from '../Reusable_Components/LeftNavBar'
import FormLoader from '../Reusable_Components/FromLoader'
import { useDispatch, useSelector } from 'react-redux'
import { GET_PARTNER_COMPANY_DETAILS_FAILURE, GET_PARTNER_COMPANY_DETAILS_SUCCESS, SET_LOADER, UPDATE_PARTNER_COMPANY_DETAILS_FAILURE, UPDATE_PARTNER_COMPANY_DETAILS_SUCCESS, UPLOAD_DSC_FAILURE, UPLOAD_LOGO_FAILURE, getPartnerCompanyDetails, updatePartnerCompanyDetails, uploadDscFile, uploadLogo } from '../../Redux/Actions/Actions'
import { Button, Modal } from 'react-bootstrap'
import { MdErrorOutline } from 'react-icons/md'
import UploadComponent from '../Reusable_Components/UploadComponent'
import axios from 'axios'
import { base_url } from '../../utilities'
import { validEmailCheck, processAndValidateEmail } from "../Reusable_Components/ReusableFunctions";


export default function PartnerCompaniesEdit(props) {

    const [emailError, setEmailError] = useState('');

    const dispatch = useDispatch()

    const id = props?.companyId

    const partnerCompanyDetails = useSelector(state => state.partnerCompanyDetails)
    const partnerCompanyDetailsError = useSelector(state => state.partnerCompanyDetailsError)
    const loading = useSelector(state => state.loading)
    const updatePartnerCompanyDetailsSuccess = useSelector(state => state.updatePartnerCompanyDetailsSuccess)
    const updatePartnerCompanyDetailsError = useSelector(state => state.updatePartnerCompanyDetailsError)
    const uploadLogoError = useSelector(state => state.uploadLogoError)
    const uploadDscError = useSelector(state => state.uploadDscError)

    const [filesData, setFilesData] = useState([])
    const [dscFilesData, setDscFilesData] = useState([])
    const [signerNameError, setSignerNameError] = useState(null)

    const [edit, setEdit] = useState(false)

    useEffect(() => {
        dispatch(getPartnerCompanyDetails(id))
    }, [])

    const handleFileUpload = async (index) => {
        const data = {
            "files": [
                {
                    "file_content": filesData[index]["file_obj"],
                    "object_type": "our_company_logo",
                    "file_type": filesData[index]["file_type"].split("/")[1],
                    "file_name": filesData[index]["file_name"],
                    "flag": null,
                    "file_description": "",
                    "company_name": partnerCompanyDetails?.company_name,
                    "user_id": localStorage.getItem("username"),
                }
            ]
        }
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios
            .post(base_url + "documents", data)
            .then((response) => {
                dispatch({
                    type: GET_PARTNER_COMPANY_DETAILS_SUCCESS,
                    payload: { ...partnerCompanyDetails, logo: response?.data?.data[0].key }
                })
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            }
            )
            .catch((error) => {
                dispatch({
                    type: UPLOAD_LOGO_FAILURE,
                    payload: error.response.data.message,
                });
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            });
    }

    const handleEmailChange = (e) => {
        const input = e.target.value;
        const { processedInput, isValid } = processAndValidateEmail(input, partnerCompanyDetails.email);
        if (processedInput === '') {
            setEmailError('Email is required');
        } else if (!isValid) {
            setEmailError('Enter a valid email address');
        } else {
            setEmailError('');
        }
        dispatch({
            type: GET_PARTNER_COMPANY_DETAILS_SUCCESS,
            payload: { ...partnerCompanyDetails, email: processedInput }
        });
    };

    const updatePartnerDetails = () => {
        const updatedPartnerCompanyDetails = {
            ...partnerCompanyDetails,
            email: partnerCompanyDetails.email.trim(),
            short_name: partnerCompanyDetails.short_name.trim(),
            signer_name: partnerCompanyDetails.signer_name.trim()
        };
        dispatch(updatePartnerCompanyDetails(updatedPartnerCompanyDetails));
    }

    const handleSelectFile = (files) => {
        dispatch({
            type: GET_PARTNER_COMPANY_DETAILS_SUCCESS,
            payload: { ...partnerCompanyDetails, logo: null }
        })
        const processedFiles1 = [];
        files.forEach((file) => {
            const reader1 = new FileReader();
            reader1.onloadend = function () {
                const base64FileContent = reader1.result.split(",")[1];
                const processedFile1 = {
                    file_obj: base64FileContent,
                    file_name: file.file.name,
                    file_type: file.file.type,
                };
                processedFiles1.push(processedFile1);
                if (processedFiles1.length === files.length) {
                    setFilesData(processedFiles1);
                }
            };
            reader1.readAsDataURL(file.file);
        });
    }

    const selectDscFile = (files) => {
        dispatch({
            type: GET_PARTNER_COMPANY_DETAILS_SUCCESS,
            payload: { ...partnerCompanyDetails, dsc: null }
        })
        const processedFiles1 = [];
        files.forEach((file) => {
            const reader1 = new FileReader();
            reader1.onloadend = function () {
                const base64FileContent = reader1.result.split(",")[1];
                const processedFile1 = {
                    file_obj: base64FileContent,
                    file_name: file.file.name,
                    file_type: file.file.type,
                };
                processedFiles1.push(processedFile1);
                if (processedFiles1.length === files.length) {
                    setDscFilesData(processedFiles1);
                }
            };
            reader1.readAsDataURL(file.file);
        });
    }

    const uploadDsc = (index) => {
        const data = {
            "files": [
                {
                    "file_content": dscFilesData[index]["file_obj"],
                    "object_type": "our_company_dsc",
                    "file_type": "pfx",
                    "file_name": dscFilesData[index]["file_name"],
                    "flag": null,
                    "file_description": "",
                    "company_name": partnerCompanyDetails?.company_name,
                    "user_id": localStorage.getItem("username"),
                }
            ]
        }
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios
            .post(base_url + "documents", data)
            .then((response) => {
                dispatch({
                    type: GET_PARTNER_COMPANY_DETAILS_SUCCESS,
                    payload: { ...partnerCompanyDetails, dsc: response?.data?.data[0].key }
                })
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            }
            )
            .catch((error) => {
                dispatch({
                    type: UPLOAD_DSC_FAILURE,
                    payload: error.response.data.message,
                });
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            });
    }

    return (
        <>
            <>
                <NavBarTop />
                <LeftNavBar />
            </>
            <div className="addUserContainer">
                {loading && <FormLoader />}
                <div className="stepsContainer">
                    <div className="stepsTitle">
                        <h6 style={{ padding: "20px", lineHeight: "0px" }}>Edit Partner Company Details</h6>
                    </div>
                    <div className={'stepsCardActive'}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <span style={{ fontWeight: "bold", fontSize: "medium" }}>Company Information</span>
                        </div>
                        <span style={{ fontSize: "small", marginTop: "10px" }}>Partner Company Details</span>
                    </div>
                </div>
                <div className="infoContainer">
                    <div>
                        <div className="infoTitle">
                            <h6 style={{ padding: "20px", lineHeight: "0px" }}>Company Information</h6>
                        </div>
                        <div className="infoBody">
                            <div className="infoForm">
                                <div>
                                    <h4>{partnerCompanyDetails?.company_name}</h4>
                                </div>
                                <div className="inputsSection">
                                    <div>
                                        <label className='add-facility-modal-label'>GST Number  <span style={{ color: "red" }}>*</span></label>
                                        <input type='text' disabled={true} value={partnerCompanyDetails?.gst_number} className='form-control responsive-add-facility-input-fields' />
                                    </div>
                                    <div>
                                        <label className='add-facility-modal-label'>CIN  <span style={{ color: "red" }}>*</span></label>
                                        <input type='text' disabled={true} value={partnerCompanyDetails?.cin} className='form-control responsive-add-facility-input-fields' />
                                    </div>
                                </div>
                                <div className="inputsSection">
                                    <div>
                                        <label className='add-facility-modal-label'>Short Name  <span style={{ color: "red" }}>*</span></label>
                                        <input type='text' disabled={!edit} value={partnerCompanyDetails?.short_name} className='form-control responsive-add-facility-input-fields'
                                            onChange={(e) => {
                                                dispatch({
                                                    type: GET_PARTNER_COMPANY_DETAILS_SUCCESS,
                                                    payload: { ...partnerCompanyDetails, short_name: e.target.value }
                                                })
                                            }
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label className='add-facility-modal-label'>Email ID  <span style={{ color: "red" }}>*</span></label>
                                        <input type='text' disabled={!edit} value={partnerCompanyDetails?.email} className='form-control responsive-add-facility-input-fields'
                                            onChange={handleEmailChange}
                                        />
                                        <span className='text-danger'>{emailError}</span>
                                    </div>
                                </div>
                                <hr></hr>
                                <b className='d-flex my-4'>Gst Primary Address</b>
                                <div className="inputsSection">
                                    <div>
                                        <label className='add-facility-modal-label'>Door Number <span style={{ color: "red" }}>*</span></label>
                                        <input type='text' disabled={!edit} value={partnerCompanyDetails?.registered_address?.door_number}
                                            onChange={(e) => {
                                                let registered_address = { ...partnerCompanyDetails?.registered_address, door_number: e.target.value }
                                                dispatch({
                                                    type: GET_PARTNER_COMPANY_DETAILS_SUCCESS,
                                                    payload: { ...partnerCompanyDetails, registered_address: registered_address }
                                                })
                                            }}
                                            className='form-control responsive-add-facility-input-fields' />
                                    </div>
                                    <div>
                                        <label className='add-facility-modal-label'>Street Address <span style={{ color: "red" }}>*</span></label>
                                        <input type='text' disabled={!edit} value={partnerCompanyDetails?.registered_address?.street_address_1}
                                            onChange={(e) => {
                                                let registered_address = { ...partnerCompanyDetails?.registered_address, street_address_1: e.target.value }
                                                dispatch({
                                                    type: GET_PARTNER_COMPANY_DETAILS_SUCCESS,
                                                    payload: { ...partnerCompanyDetails, registered_address: registered_address }
                                                })
                                            }}
                                            className='form-control responsive-add-facility-input-fields' />
                                    </div>
                                    <div >
                                        <label className='add-facility-modal-label'>Street Address Lane 2 <span style={{ color: "red" }}>*</span></label>
                                        <input type='text' disabled={!edit} value={partnerCompanyDetails?.registered_address?.street_address_2}
                                            onChange={(e) => {
                                                let registered_address = { ...partnerCompanyDetails?.registered_address, street_address_2: e.target.value }
                                                dispatch({
                                                    type: GET_PARTNER_COMPANY_DETAILS_SUCCESS,
                                                    payload: { ...partnerCompanyDetails, registered_address: registered_address }
                                                })
                                            }}
                                            className='form-control responsive-add-facility-input-fields' />
                                    </div>
                                </div>
                                <div className="inputsSection">
                                    <div>
                                        <label className='add-facility-modal-label'>City <span style={{ color: "red" }}>*</span></label>
                                        <input type='text' disabled={!edit} value={partnerCompanyDetails?.registered_address?.city}
                                            onChange={(e) => {
                                                let registered_address = { ...partnerCompanyDetails?.registered_address, city: e.target.value }
                                                dispatch({
                                                    type: GET_PARTNER_COMPANY_DETAILS_SUCCESS,
                                                    payload: { ...partnerCompanyDetails, registered_address: registered_address }
                                                })
                                            }}
                                            className='form-control responsive-add-facility-input-fields' />
                                    </div>
                                    <div>
                                        <label className='add-facility-modal-label'>State <span style={{ color: "red" }}>*</span></label>
                                        <input type='text' disabled={!edit} value={partnerCompanyDetails?.registered_address?.state}
                                            onChange={(e) => {
                                                let registered_address = { ...partnerCompanyDetails?.registered_address, state: e.target.value }
                                                dispatch({
                                                    type: GET_PARTNER_COMPANY_DETAILS_SUCCESS,
                                                    payload: { ...partnerCompanyDetails, registered_address: registered_address }
                                                })
                                            }}
                                            className='form-control responsive-add-facility-input-fields' />
                                    </div>
                                    <div >
                                        <label className='add-facility-modal-label'>Pincode <span style={{ color: "red" }}>*</span></label>
                                        <input type='text' disabled={!edit} value={partnerCompanyDetails?.registered_address?.postal_code}
                                            onChange={(e) => {
                                                let registered_address = { ...partnerCompanyDetails?.registered_address, postal_code: e.target.value }
                                                dispatch({
                                                    type: GET_PARTNER_COMPANY_DETAILS_SUCCESS,
                                                    payload: { ...partnerCompanyDetails, registered_address: registered_address }
                                                })
                                            }}
                                            className='form-control responsive-add-facility-input-fields' />
                                    </div>
                                </div>
                                <div className='inputsSection'>
                                    <div className='w-100'>
                                        <label className='add-facility-modal-label'>Upload logo</label>
                                        <UploadComponent
                                            onFilesSelected={handleSelectFile}
                                            viewMode={!edit}
                                            uploadedFileData={partnerCompanyDetails?.logo ? [{ file_name: partnerCompanyDetails?.logo?.split("/")[2]?.split("-")[0], file_type: partnerCompanyDetails?.logo?.split("/")[2]?.split(".")[1], s3_key: partnerCompanyDetails?.logo }] : []}
                                            deleteSpecificFileMain={0}
                                            uploadActionClicked={handleFileUpload}
                                            idType={"our_company_edit"}
                                            acceptedFileTypes={".jpeg, .jpg, .png"}
                                        />
                                    </div>
                                </div>
                                <hr></hr>
                                <b className='d-flex my-4'>DSC Details</b>
                                <div className="inputsSection">
                                    <div>
                                        <label className='add-facility-modal-label'>Signer's Name <span style={{ color: "red" }}>*</span></label>
                                        <input type='text' disabled={!edit} value={partnerCompanyDetails?.signer_name} className='form-control responsive-add-facility-input-fields' onChange={(e) => {
                                            let value = e.target.value
                                            if (value.length < 3 || value.length > 200) {
                                                setSignerNameError("Must be between 3 to 200 characters")
                                                dispatch({
                                                    type: GET_PARTNER_COMPANY_DETAILS_SUCCESS,
                                                    payload: { ...partnerCompanyDetails, signer_name: value }
                                                })
                                            } else {
                                                setSignerNameError(null)
                                                dispatch({
                                                    type: GET_PARTNER_COMPANY_DETAILS_SUCCESS,
                                                    payload: { ...partnerCompanyDetails, signer_name: value }
                                                })
                                            }
                                        }} />
                                        <span className='text-danger'>{signerNameError}</span>
                                    </div>
                                </div>
                                <div className="inputsSection">
                                    <div className='w-100'>
                                        <label className='add-facility-modal-label'>Upload DSC (pfx file) <span style={{ color: "red" }}>*</span></label>
                                        <UploadComponent
                                            onFilesSelected={selectDscFile}
                                            viewMode={!edit}
                                            deleteSpecificFileMain={0}
                                            uploadActionClicked={uploadDsc}
                                            uploadedFileData={partnerCompanyDetails?.dsc && partnerCompanyDetails?.dsc ? [{ file_name: partnerCompanyDetails?.dsc?.split("/")[2]?.split("-")[0], file_type: partnerCompanyDetails?.dsc?.split("/")[2]?.split(".")[1], s3_key: partnerCompanyDetails?.dsc }] : []}
                                            idType={"our_company_dsc"}
                                            acceptedFileTypes={".pfx"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="infoFotter">
                        <div className="d-flex gap-3">
                            <button className="btn btn-secondary" onClick={() => props.setPage(1)}>Back</button>
                            {edit && <button className="btn btn-secondary" onClick={() => setEdit(false)}>Cancel</button>}
                            {edit ? <button className="btn btn-secondary" disabled={emailError
                                || !(partnerCompanyDetails.short_name) || !(partnerCompanyDetails.registered_address?.door_number) ||
                                !(partnerCompanyDetails.registered_address?.street_address_1) || !(partnerCompanyDetails.registered_address?.street_address_2) ||
                                !(partnerCompanyDetails.registered_address?.city) || !(partnerCompanyDetails.registered_address?.state) || !(partnerCompanyDetails.registered_address?.postal_code)
                                || !(partnerCompanyDetails.signer_name || signerNameError)
                            } onClick={updatePartnerDetails}>Update</button> : <button className="btn btn-secondary" onClick={() => setEdit(true)}>Edit</button>}
                        </div>
                    </div>
                </div>
                {updatePartnerCompanyDetailsSuccess && <Modal keyboard={false} backdrop="static" show={updatePartnerCompanyDetailsSuccess} onHide={() => {
                    dispatch({
                        type: UPDATE_PARTNER_COMPANY_DETAILS_SUCCESS,
                        payload: null
                    })
                }} centered className="popper-modal">
                    <Modal.Body className="text-center">
                        <img src="./images/icons/popper.svg" alt="Party Popper" />
                        <h5>Success!</h5>
                        <p className="mb-3"><b>{partnerCompanyDetails?.company_name}</b> {updatePartnerCompanyDetailsSuccess}</p>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center" >
                        <Button variant="secondary" className="secondary" onClick={() => {
                            dispatch({
                                type: UPDATE_PARTNER_COMPANY_DETAILS_SUCCESS,
                                payload: null
                            })
                            props?.setNavigate(!props?.navigat)
                            props?.setPage(1)
                        }} >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>}
                {updatePartnerCompanyDetailsError && <Modal keyboard={false} backdrop="static" show={updatePartnerCompanyDetailsError} onHide={() => {
                    dispatch({
                        type: UPDATE_PARTNER_COMPANY_DETAILS_FAILURE,
                        payload: null
                    })
                }} centered className="popper-modal">
                    <Modal.Body className="text-center">
                        <MdErrorOutline color='red' style={{ height: "60px", width: "60px", fontWeight: "10px" }} />
                        <h5>Error!</h5>
                        <p className="mb-3">{updatePartnerCompanyDetailsError}</p>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center" >
                        <Button variant="secondary" className="secondary" onClick={() => {
                            dispatch({
                                type: UPDATE_PARTNER_COMPANY_DETAILS_FAILURE,
                                payload: null
                            })
                        }} >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>}
                {partnerCompanyDetailsError && <Modal keyboard={false} backdrop="static" show={partnerCompanyDetailsError} onHide={() => {
                    dispatch({
                        type: GET_PARTNER_COMPANY_DETAILS_FAILURE,
                        payload: null
                    })
                }} centered className="popper-modal">
                    <Modal.Body className="text-center">
                        <MdErrorOutline color='red' style={{ height: "60px", width: "60px", fontWeight: "10px" }} />
                        <h5>Error!</h5>
                        <p className="mb-3">{partnerCompanyDetailsError}</p>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center" >
                        <Button variant="secondary" className="secondary" onClick={() => {
                            dispatch({
                                type: GET_PARTNER_COMPANY_DETAILS_FAILURE,
                                payload: null
                            })
                        }} >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>}
                {uploadLogoError && <Modal keyboard={false} backdrop="static" show={uploadLogoError} onHide={() => {
                    dispatch({
                        type: UPLOAD_LOGO_FAILURE,
                        payload: null
                    })
                }} centered className="popper-modal">
                    <Modal.Body className="text-center">
                        <MdErrorOutline color='red' style={{ height: "60px", width: "60px", fontWeight: "10px" }} />
                        <h5>Error!</h5>
                        <p className="mb-3">{uploadLogoError}</p>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center" >
                        <Button variant="secondary" className="secondary" onClick={() => {
                            dispatch({
                                type: UPLOAD_LOGO_FAILURE,
                                payload: null
                            })
                        }} >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>}
            </div >
        </>
    )
}
