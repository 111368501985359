import React, { useEffect, useState } from 'react'
import Task from './Task';
import { Modal } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDrop } from 'react-dnd';
import Dropdown from '../Dropdown';
import ViewForm from '../../FormBuilder/ViewForm';
import UploadComponent from '../UploadComponent';
import { timeElapsed } from '../ReusableFunctions';

let form_items = [
    {
        "id": "d9a29570-11b3-4d68-9e17-ef1572410455",
        "items": [
            {
                "type": "text",
                "label": "Dry Transformer",
                "id": "39081466-54eb-4a4f-a525-958080fcaf0c",
                "content": "Dry Transformer",
                "size": "h2"
            }
        ]
    },
    {
        "id": "d34afb27-3754-45a2-a24f-32b8327d2ee3",
        "items": [
            {
                "type": "input",
                "label": "Asset",
                "id": "a109b53f-095a-437d-81a2-9bf8a7421f1e",
                "content": "Asset"
            },
            {
                "type": "input",
                "label": "Work Group",
                "id": "4ddc1c84-0d84-430a-a2bf-e8a067220edf",
                "content": "Work Group"
            }
        ]
    },
    {
        "id": "bd742047-2d2d-44a9-ae90-a240f09cff47",
        "items": [
            {
                "type": "input",
                "label": "Rating/Make",
                "id": "cc1f6e12-a8d3-4cd8-b333-3655aafc66ed",
                "content": "Rating/Make"
            },
            {
                "type": "input",
                "label": "Frequency",
                "id": "72f08bc9-ff7c-4519-a396-6fb3089ae872",
                "content": "Frequency"
            }
        ]
    },
    {
        "id": "6ddf16da-8b25-4c5a-aa47-6ab12ca3ad82",
        "items": [
            {
                "type": "input",
                "label": "Location",
                "id": "c5811d4c-14b3-463c-ad55-7defbc110294",
                "content": "Location"
            },
            {
                "type": "date",
                "label": "Date",
                "id": "e6adba84-e15d-4c73-8e5b-696f16996e1f",
                "content": "Date"
            }
        ]
    },
    {
        "id": "f59d7035-c9c4-44bc-b9f8-2dca69a6e86d",
        "items": [
            {
                "type": "text",
                "label": "Fill up the checklist with TICK or CROSS  against each activity and write the test parameters",
                "id": "4cfe1945-3739-49df-80ee-b71e808fd0e0",
                "content": "Fill up the checklist with TICK or CROSS  against each activity and write the test parameters",
                "size": "h6"
            }
        ]
    },
    {
        "id": "7cf185f4-99cd-4196-8535-171946b4ccd9",
        "items": [
            {
                "type": "table",
                "label": "Table",
                "id": "73161e63-e88e-4a99-9378-37c2621d5f67",
                "content": "Table",
                "rows": 8,
                "columns": 3,
                "columnNames": [
                    "B. Performance checklist",
                    "Status",
                    "Remarks"
                ],
                "columnsType": [
                    "text",
                    "toggle",
                    "input"
                ],
                "tableData": [
                    [
                        {
                            "type": "text",
                            "content": "1.Facility Manager to ensure that the PPM does not affect site operations"
                        },
                        {
                            "type": "text",
                            "content": "text"
                        },
                        {
                            "type": "text",
                            "content": "text"
                        }
                    ],
                    [
                        {
                            "type": "text",
                            "content": "2.Isolate the incoming source and apply LOTO"
                        },
                        {
                            "type": "text",
                            "content": "text"
                        },
                        {
                            "type": "text",
                            "content": "text"
                        }
                    ],
                    [
                        {
                            "type": "text",
                            "content": "3.Make sure that supply isnot available at the i/p terminal of the equipment."
                        },
                        {
                            "type": "text",
                            "content": "text"
                        },
                        {
                            "type": "text",
                            "content": "text"
                        }
                    ],
                    [
                        {
                            "type": "text",
                            "content": "4.Use of insulated hand gloves while working."
                        },
                        {
                            "type": "toggle",
                            "content": ""
                        },
                        {
                            "type": "input",
                            "content": ""
                        }
                    ],
                    [
                        {
                            "type": "text",
                            "content": "5.Use of Hard hats, Safety shoes & safety goggles"
                        },
                        {
                            "type": "toggle",
                            "content": ""
                        },
                        {
                            "type": "input",
                            "content": ""
                        }
                    ],
                    [
                        {
                            "type": "text",
                            "content": "6.Use of Hard hats, Safety shoes & safety goggles"
                        },
                        {
                            "type": "toggle",
                            "content": ""
                        },
                        {
                            "type": "input",
                            "content": ""
                        }
                    ],
                    [
                        {
                            "type": "text",
                            "content": "7.Use of Hard hats, Safety shoes & safety goggles"
                        },
                        {
                            "type": "toggle",
                            "content": ""
                        },
                        {
                            "type": "input",
                            "content": ""
                        }
                    ],
                    [
                        {
                            "type": "text",
                            "content": "8.Other Special PPEs (Please specify if any)"
                        },
                        {
                            "type": "toggle",
                            "content": ""
                        },
                        {
                            "type": "input",
                            "content": ""
                        }
                    ]
                ]
            }
        ]
    },
    {
        "id": "71b98e09-88bb-4e0a-bfce-4012957a340b",
        "items": [
            {
                "type": "table",
                "label": "Inspection and test results",
                "id": "28a697dc-da73-4c86-8b2d-00ba8bb3985e",
                "content": "Inspection and test results",
                "rows": 2,
                "columns": 4,
                "columnNames": [
                    "Inspection and Test Results",
                    "RY",
                    "YB",
                    "BR"
                ],
                "columnsType": [
                    "text",
                    "input",
                    "input",
                    "input"
                ],
                "tableData": [
                    [
                        {
                            "type": "text",
                            "content": "Voltage"
                        },
                        {
                            "type": "text",
                            "content": "text"
                        },
                        {
                            "type": "text",
                            "content": "text"
                        },
                        {
                            "type": "text",
                            "content": "text"
                        }
                    ],
                    [
                        {
                            "type": "text",
                            "content": "Running AMP"
                        },
                        {
                            "type": "text",
                            "content": "text"
                        },
                        {
                            "type": "text",
                            "content": "text"
                        },
                        {
                            "type": "text",
                            "content": "text"
                        }
                    ]
                ]
            }
        ]
    }
]

export default function WorkLaw({ data, assignee_list, facilitiesList, columns, sections, handelSectionChange, height, handleChangeTaskStatus }) {

    const [showTask, setShowTask] = useState(false)
    const [selectedTask, setSelectedTask] = useState({})
    const [editAssignee, setEditAssignee] = useState(false)
    const [statuses, setStatuses] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [viewForm, setViewForm] = useState(false);
    const [formData, setFormData] = useState({});
    const [comments, setComments] = useState({
        name: "",
        comment: "",
        date: ""
    });


    useEffect(() => {
        setStatuses(columns);
        setTasks(data);
    }, [data]);

    const handleManageSection = (tab) => {
        handelSectionChange(tab);
    }

    const handleOpenTask = (task) => {
        setShowTask(true);
        setSelectedTask(task);
    };

    const formatDate = (dateString) => {
        // Parse the date string
        const [day, month, year] = dateString.split('-').reverse();
        const date = new Date(`${year}-${month}-${day}`);

        // Format the date
        const options = { weekday: 'long', month: 'long', day: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    const handleDrop = (item, status) => {
        handleChangeTaskStatus(item, status);
    }

    const handleFormData = (key, value) => {
        let newFormData = formData
        newFormData[key] = value
        setFormData({ ...newFormData })
    }

    const tableData = (key, row, tableName, value) => {
        let newFormData = { ...formData }
        let tableData = newFormData[tableName] ? newFormData[tableName] : []
        tableData[row] = { ...tableData[row], [key]: value }
        // tableData[row][key] = value
        newFormData[tableName] = tableData

        setFormData(newFormData)
    }

    const getInitials = (name) => {
        return name?.split(' ')
            .map(word => word[0].toUpperCase())
            .join('');
    };


    const colors = [
        "#15BDFA",
        "#369C2A",
        "#5E40A1",
        "#F7BC26",
        "#0F2D80",
        "#DF4451",
    ]

    const randomColor = (name) => {
        const initials = getInitials(name);
        const charCode = initials?.charCodeAt(0);
        const colorIndex = charCode % colors.length;
        return colors[colorIndex];
    }



    return (
        <>
            <div style={{ overflow: "auto", height: height }}>
                <DndProvider backend={HTML5Backend}>
                    <table style={{ width: "100%" }}>
                        <thead style={{ position: "sticky", top: 0 }}>
                            <tr>
                                {columns?.map((column, index) => {
                                    return (
                                        <th key={index} className='tasks-header-items-container'>
                                            <div className='tasks-header-items'>{column.charAt(0).toUpperCase() + column.slice(1)}</div>
                                        </th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {tasks && Object?.entries(tasks)?.map(([category, tasks], index) => (
                                <React.Fragment key={index}>
                                    <tr role='button' onClick={() => tasks?.length > 0 && handleManageSection(category)} style={{ position: "sticky", top: "40px" }}>
                                        <td style={{ backgroundColor: "white", minHeight: "40px", display: "flex", alignItems: "center", gap: "20px", padding: "0px 10px" }}>
                                            {sections[index]?.isOpen ? <img src='./images/icons/DownArrow.svg' alt="Down arrow" /> : <img src='./images/icons/RightArrow.svg' alt="Right Arrow" />}
                                            <div className='d-flex gap-2'>
                                                <span>{category}</span>
                                                <span>({tasks.length} tasks)</span>
                                            </div>
                                        </td>
                                        {statuses.map((status, index) => (
                                            <td key={index} style={{ backgroundColor: "white", minHeight: "40px" }}></td>
                                        )
                                        )}
                                    </tr>
                                    {tasks.length > 0 && sections[index]?.isOpen && (
                                        <tr>
                                            {statuses.map(status => (
                                                <TaskContainer task={tasks} key={status} status={status} tasks={tasks} handleOpenTask={handleOpenTask} facilitiesList={facilitiesList} changeStatus={handleDrop} />
                                            ))}
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </DndProvider>
            </div>
            {showTask && <Modal backdrop="static" show={showTask} onHide={() => setShowTask(false)} centered>
                <Modal.Body style={{ maxHeight: "90vh", overflow: "auto" }}>
                    <div className='d-flex flex-column gap-2'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <Dropdown options={
                                    statuses.map(status => {
                                        return { name: status.charAt(0).toUpperCase() + status.slice(1), backgroundColor: "white", value: status }
                                    })
                                } selectedOption={selectedTask?.status}
                                    handleChange={(e) => {
                                        handleChangeTaskStatus(selectedTask, e.target.value)
                                        setShowTask(false)
                                    }}
                                    className='dropdown-list'
                                />
                            </div>
                            <div className='d-flex gap-2'>
                                <img src='./images/icons/DeleteIcon.svg' alt='delete' />
                                <img src='./images/icons/WrongIcon.svg' alt='close' role='button' onClick={() => {
                                    setSelectedTask({})
                                    setShowTask(false)
                                }} />
                            </div>
                        </div>
                        <div>
                            <h4>{selectedTask?.task_name}</h4>
                        </div>
                        <div className='d-flex align-items-center gap-3'>
                            <img src='./images/icons/Person.svg' alt='person' />
                            {editAssignee ?
                                <div>
                                    <select className='form-control'>
                                        {assignee_list.map((assignee, index) => {
                                            return <option key={index} selected={selectedTask?.assignedTo === assignee.name} value={assignee.name}>{assignee.name}</option>
                                        })}
                                    </select>
                                </div>
                                : <span>{selectedTask?.assignee}</span>}
                            {editAssignee ? <div className='d-flex gap-2'><span style={{ color: "red" }}>✗</span></div> : <img src='./images/icons/EditIcon.svg' alt='edit' onClick={() => setEditAssignee(!editAssignee)} />}
                        </div>
                        <div className='d-flex align-items-start gap-3'>
                            <img src='./images/icons/schedule.svg' alt='schedule' className='mt-1' />
                            <div>
                                <div>
                                    {formatDate(selectedTask?.task_start_time.split(" ")[0])}
                                </div>
                            </div>
                        </div>
                        {selectedTask?.remainder && <div className='d-flex align-items-center gap-3'>
                            <img src='./images/icons/alarm.svg' alt='remainder' />
                            <span>{selectedTask?.remainder}</span>
                        </div>}
                        <div className='d-flex align-items-center'>
                            <span style={{ width: "30%" }}>Supervisor</span>
                            <span>{selectedTask?.supervisor}</span>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span style={{ width: "30%" }}>Facility</span>
                            <span>{selectedTask?.facility_name}</span>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span style={{ width: "30%" }}>SOP</span>
                            <span style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }} >{selectedTask?.sop}</span>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span style={{ width: "30%" }}>FORM</span>
                            <span style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }} onClick={() => {
                                setViewForm(true)
                            }}>{selectedTask?.form}</span>
                        </div>
                        <div>
                            <label>Attachments</label>
                            <UploadComponent
                                onFilesSelected={(files) => console.log(files)}
                                acceptedFileTypes={['image/*', 'application/pdf']}
                                idType={"task"}
                                maxNumberOfFiles={5}
                                multiple={true}
                                uploadedFileData={[]}
                            />
                        </div>
                        <div className='d-flex flex-column gap-2'>
                            <h5 style={{ marginBottom: "0px" }}>Comments</h5>
                            <div className='comments-section'>
                                {selectedTask?.comments?.map((comment, index) => (
                                    <div className='d-flex gap-2'>
                                        <div key={index} className={`worklog-assignee`}
                                            style={{ marginLeft: '0px', backgroundColor: randomColor(comment?.name) }}
                                        >
                                            <span>{getInitials(comment?.name)}</span>
                                        </div>
                                        <div key={index}>
                                            <b className='comment-author'>{comment?.name} </b> <span>{timeElapsed(comment?.time)}</span>
                                            <div
                                                className='comment-text'
                                                dangerouslySetInnerHTML={{ __html: comment?.comment.replace(/\n/g, '<br />') }}
                                            ></div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <textarea
                                className='form-control'
                                placeholder='Add a comment...'
                                rows='3'
                                value={comments?.comment}
                                onChange={(e) => {
                                    setComments({ name: localStorage.getItem("username"), comment: e.target.value, date: new Date().toLocaleDateString('en-GB') })
                                }}
                            />
                            <button
                                className='btn btn-secondary mt-2'
                                onClick={() => {
                                    setSelectedTask({
                                        ...selectedTask,
                                        comments: [...(selectedTask.comments || []), comments]
                                    });
                                    setComments({
                                        name: "",
                                        comment: "",
                                        date: ""
                                    })
                                }}
                                disabled={comments?.comment === ""}
                            >
                                Add Comment
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >}
            {
                viewForm &&
                <ViewForm closeForm={() => setViewForm(false)} form_items={form_items} handleFormData={handleFormData} tableData={tableData} />
            }
        </>
    )
}


const TaskContainer = ({ status, tasks, handleOpenTask, facilitiesList, changeStatus }) => {

    const handleDrop = (item) => {
        changeStatus(item, status); // Pass the status (column name) to the changeStatus function
    }

    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'TASK',
        drop: (item, monitor) => handleDrop(item, monitor),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));

    return (
        <td key={status} className='tasks-body-items' ref={drop}>
            {tasks.filter(task => task.task_status === status).map((task, i) => {
                const facilityColor = facilitiesList.find(facility => facility.name === task.facility)?.backgroundColor;
                return <Task key={i} task={{ ...task, facilityColor }} handleOpenTask={handleOpenTask} />
            })}
        </td>
    )
}