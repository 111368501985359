import React, { useEffect } from 'react'

export default function Textarea({ object, handleFormData, formUse, data }) {

    useEffect(() => {
        handleFormData && handleFormData(object.content, "")
    }, [])

    return (
        <div>
            <label>{object.content}</label>
            <textarea className='form-control'
                onChange={(e) => formUse && handleFormData && handleFormData(object.content, e.target.value)}
                value={data && data[object.content]}
                disabled={data && data} />
        </div>
    )
}
