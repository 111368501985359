import React, { useState, useRef, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import Element from './Element';
import { v4 as uuidv4 } from 'uuid';
import { RxCross1 } from 'react-icons/rx';
import { json, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_NEW_FIELD_ERROR, CREATE_NEW_TEMPLATE_ERROR, CREATE_PANEL_ERROR, CREATION_ERROR, createField } from '../../Redux/Actions/Actions';
import { Button, Modal } from 'react-bootstrap';
import { MdErrorOutline } from "react-icons/md";
import AlertPopUp from '../Reusable_Components/Alert';

const FormCanvas = (props) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const errorMessage = useSelector((state) => state.createFieldError)
  const modal = useSelector((state) => state.showModal)
  const createTemplateError = useSelector((state) => state.createTemplateError)
  const createPanelError = useSelector((state) => state.createPanelError)
  const creationError = useSelector((state) => state.creationError)

  const [droppedItems, setDroppedItems] = useState([])
  const [currentElement, setCurrentElement] = useState({})
  const [containers, setContainers] = useState([])
  const [position, setPosition] = useState()
  const [containerId, setContainerId] = useState()
  const [description, setDescription] = useState("")
  const [panelName, setPanelName] = useState("")
  const [templateName, setTemplateName] = useState("")
  const [formName, setFormName] = useState("")
  const [formType, setFormType] = useState("")
  const [formData, setFormData] = useState({})
  const [templateId, setTemplateId] = useState("")

  useEffect(() => {
    modal === false && setContainers([])
    modal === false && setCurrentElement({})
  }, [modal])

  useEffect(() => {
    props?.name === "templateClone" && props?.data && setContainers(props?.data)
  }, [])

  const determineDropPosition = (clientOffset, containerId) => {
    const containerRect = document.getElementById(containerId)?.getBoundingClientRect();
    const offsetX = clientOffset?.x - containerRect?.left;
    const offsetY = clientOffset?.y - containerRect?.top;

    if (offsetY < containerRect?.height / 4) {
      return "above";
    } else if (offsetY > (containerRect?.height / 4) * 3) {
      return "below";
    } else if (offsetX < containerRect?.width / 2) {
      return "left";
    } else {
      return "right";
    }
  };

  const handleDrop = (item, monitor) => {
    //  console.log(document.elementFromPoint(monitor.getClientOffset().x,monitor.getClientOffset().y)?.closest('.container')?.id)
    const containerId = document.elementFromPoint(monitor.getClientOffset().x, monitor.getClientOffset().y)?.closest('.container')?.id
    //  console.log(determineDropPosition(monitor.getClientOffset(),containerId)) 
    const droppedPosition = determineDropPosition(monitor.getClientOffset(), containerId)
    const indexPosition = (containerId?.split(" ")[1])
    const id = uuidv4()
    let newItem
    switch (item.type) {
      case "input":
        newItem = { ...item, id, content: "Input" }
        setContainers((containers) => [{ id: uuidv4(), items: [newItem] }])
        setCurrentElement({ ...item, id, content: "Input" })
        break;
      case "inputWithLabel":
        newItem = { ...item, id, content: "Input", width: 20 }
        setContainers((containers) => [{ id: uuidv4(), items: [newItem] }])
        setCurrentElement({ ...item, id, content: "Input" })
        break;
      case "paragraph":
        newItem = { ...item, id, content: "Paragraph", width: 100 }
        setContainers((containers) => [{ id: uuidv4(), items: [newItem] }])
        setCurrentElement(newItem)
        break;
      case "checkbox":
        newItem = { ...item, id, content: "Checkbox" }
        setContainers((containers) => [{ id: uuidv4(), items: [newItem] }])
        // setDroppedItems((droppedItems) => [...droppedItems, { ...item, id, content: "Checkbox" }])
        setCurrentElement({ ...item, id, content: "Checkbox" })
        break;
      case "textarea":
        newItem = { ...item, id, content: "Textarea" }
        setContainers((containers) => [{ id: uuidv4(), items: [newItem] }])
        // setDroppedItems((droppedItems) => [...droppedItems, { ...item, id, content: "Textarea" }])
        setCurrentElement({ ...item, id, content: "Textarea" })
        break;
      case "text":
        newItem = { ...item, id, content: "Text", size: "h1" }
        setContainers((containers) => [{ id: uuidv4(), items: [newItem] }])
        setCurrentElement({ ...item, id, content: "Text", size: "h1" })
        break;
      case "table":
        newItem = { ...item, id, content: "Table", rows: 3, columns: 3, columnNames: ["column1", "column2", "column3"], columnsType: ["text", "text", "text"], tableData: [[{ type: "text", content: "text" }, { type: "text", content: "text" }, { type: "text", content: "text" }], [{ type: "text", content: "text" }, { type: "text", content: "text" }, { type: "text", content: "text" }], [{ type: "text", content: "text" }, { type: "text", content: "text" }, { type: "text", content: "text" }]] }
        setContainers((containers) => [{ id: uuidv4(), items: [newItem] }])
        setCurrentElement({ ...newItem })
        break;
      case "radio":
        newItem = { ...item, id, content: "Radio", noOfOptions: 2, options: [{ label: "A" }, { label: "B" }] }
        setContainers((containers) => [{ id: uuidv4(), items: [newItem] }])
        setCurrentElement({ ...newItem })
        break;
      case "upload":
        newItem = { ...item, id, content: "Text", size: "h1" }
        setContainers((containers) => [{ id: uuidv4(), items: [newItem] }])
        setCurrentElement({ ...item, id, content: "Text", size: "h1" })
        break;
      case "toggle":
        newItem = { ...item, id, content: "Toggle" }
        setCurrentElement(newItem)
        setContainers((containers) => [{ id: uuidv4(), items: [newItem] }])
        break;
      case "dropdown":
        newItem = { ...item, id, content: "Dropdown", options: ["A", "B", "C"] }
        setCurrentElement(newItem)
        setContainers((containers) => [{ id: uuidv4(), items: [newItem] }])
        break;
      case "date":
        newItem = { ...item, id, content: "Date" }
        setCurrentElement(newItem)
        setContainers((containers) => [{ id: uuidv4(), items: [newItem] }])
        break;
      case "toggleInput":
        newItem = { ...item, id, content: "Toggle with input" }
        setCurrentElement(newItem)
        setContainers((containers) => [{ id: uuidv4(), items: [newItem] }])
        break;
      case "field":
        if (containerId) {
          let json_data_parsed = JSON.parse(item.json_data)
          switch (droppedPosition) {
            case "above":
              json_data_parsed?.type === 'table' && setCurrentElement(json_data_parsed)
              setContainers((containers) => {
                const newContainer = { id: uuidv4(), items: [json_data_parsed] };
                return [...containers.slice(0, indexPosition), newContainer, ...containers.slice(indexPosition)];
              });
              break;
            case "below":
              json_data_parsed?.type === 'table' && setCurrentElement(json_data_parsed)
              setContainers((containers) => {
                const newContainer = { id: uuidv4(), items: [json_data_parsed] };
                return [...containers.slice(0, indexPosition + 1), newContainer, ...containers.slice(indexPosition + 1)];
              });
              break;
            case "left":
              json_data_parsed?.type === 'table' && setCurrentElement(json_data_parsed)
              setContainers((containers) => {
                const updatedContainers = [...containers]
                const currentContainer = updatedContainers[indexPosition];
                currentContainer.items.unshift(json_data_parsed); // Add item/items at the start of the items array
                return updatedContainers;
              });
              break;
            case "right":
              json_data_parsed?.type === 'table' && setCurrentElement(json_data_parsed)
              setContainers((containers) => {
                const updatedContainers = [...containers]
                const currentContainer = updatedContainers[indexPosition];
                currentContainer.items.push(json_data_parsed); // Add item/items at the start of the items array
                return updatedContainers;
              });
              break;
            default:
              break;
          }
        } else {
          let json_data_parsed = JSON.parse(item.json_data)
          json_data_parsed?.type === 'table' && setCurrentElement(json_data_parsed)
          setContainers((containers) => [...containers, { id: uuidv4(), items: [json_data_parsed] }])
        }
        break;
      case "panel":
        let { json_data } = item
        json_data = JSON.parse(json_data)
        if (containerId) {
          switch (droppedPosition) {
            case "above":
              // item.items.type === 'table' && setCurrentElement(item.items)
              setContainers((containers) => {
                // const newContainer = { id: uuidv4(), items: [item.items] };
                return [...containers.slice(0, indexPosition), ...json_data, ...containers.slice(indexPosition)];
              });
              break;
            case "below":
              // item.items.type === 'table' && setCurrentElement(item.items)
              setContainers((containers) => {
                // const newContainer = { id: uuidv4(), items: [item.items] };
                return [...containers.slice(0, indexPosition + 1), ...json_data, ...containers.slice(indexPosition + 1)];
              });
              break;
            // case "left":
            //   // item.items.type === 'table' && setCurrentElement(item.items)
            //   setContainers((containers) => {
            //     const updatedContainers = [...containers]
            //     const currentContainer = updatedContainers[indexPosition];
            //     currentContainer.items.unshift(item.items); // Add item/items at the start of the items array
            //     return updatedContainers;
            //   });
            //   break;
            // case "right":
            // item.items.type === 'table' && setCurrentElement(item.items)
            // setContainers((containers) => {
            //   const updatedContainers = [...containers]
            //   const currentContainer = updatedContainers[indexPosition];
            //   currentContainer.items.push(item.items); // Add item/items at the start of the items array
            //   return updatedContainers;
            // });
            // break;
            default:
              setContainers((containers) => [...containers, ...json_data])
              break;
          }
        } else {
          setContainers((containers) => [...containers, ...json_data])
        }
        break;
      case "template":
        setTemplateId((templateId) => item.template_id)
        setContainers((containers) => [...(JSON.parse(item.json_data))])
        break
      default:
        break;
    }
    setPosition("")
    setFormData({})
  }

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'FORM_ELEMENT',
    drop: (item, monitor) => {
      // Handle dropped item
      handleDrop(item, monitor)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));


  const handleDeleteElement = (id) => {
    setDroppedItems(droppedItems.filter(item => item.id !== id))
  }

  const handleColumnNameChange = (i, id) => {
    setDroppedItems(droppedItems.map((item) => {
      if (item.id === id) {
        item.columnNames[i].editOption ? item.columnNames[i].editOption = false : item.columnNames[i].editOption = true
        return item
      } else {
        return item
      }
    }))
  }

  const handleChangeCurrentElement = (id) => {
    let filteredContainers = containers.filter(container =>
      container.items.some(item => item.id === id)
    );
    const currentObject = filteredContainers[0].items.filter((item) => item.id === id)[0]
    setCurrentElement(currentObject)
  }

  const changeInputContent = (e, id) => {
    setCurrentElement({ ...currentElement, content: e.target.value, label: e.target.value })
    containers.forEach(container => {
      container.items.forEach(item => {
        if (item.id === id) {
          item.content = e.target.value;
          item.label = e.target.value;
        }
      });
    });
    setContainers(containers);
  }

  const changeCheckboxContent = (e, id) => {
    setCurrentElement({ ...currentElement, content: e.target.value })
    containers.forEach(container => {
      container.items.forEach(item => {
        if (item.id === id) {
          item.content = e.target.value;
        }
      });
    });
    setContainers(containers);
  }
  const changeTextareaLabel = (e, id) => {
    setCurrentElement({ ...currentElement, content: e.target.value, label: e.target.value })
    containers.forEach(container => {
      container.items.forEach(item => {
        if (item.id === id) {
          item.content = e.target.value;
          item.label = e.target.value;
        }
      });
    });
    setContainers(containers);
  }

  const changeTextSize = (e, id) => {
    setCurrentElement({ ...currentElement, size: e.target.value })
    containers.forEach(container => {
      container.items.forEach(item => {
        if (item.id === id) {
          item.size = e.target.value;
        }
      });
    });
    setContainers(containers);
  }

  const changeTextContent = (e, id) => {
    setCurrentElement({ ...currentElement, content: e.target.value, label: e.target.value })
    containers.forEach(container => {
      container.items.forEach(item => {
        if (item.id === id) {
          item.content = e.target.value;
          item.label = e.target.value
        }
      });
    });
    setContainers(containers);
  }

  const handleSave = () => {
    if (props.name === "fields") {
      const item = {
        id: uuidv4(),
        type: "field",
        fieldType: containers[0]?.items[0]?.type,
        fieldName: containers[0]?.items[0]?.content,
        items: containers[0]?.items[0],
        description: description
      }
      const { handleSaveNewField } = props
      handleSaveNewField(item)
    } else if (props.name === "panels") {
      const item = {
        id: uuidv4(),
        type: "panel",
        description: description,
        panelName: panelName,
        items: containers,
        createdAt: new Date()
      }
      const { handleSaveNewPanel } = props
      handleSaveNewPanel(item)
      // setContainers([])
      // setCurrentElement({})
      // setDescription("")
      // setPanelName("")
    } else if (props.name === "templates") {
      const item = {
        id: uuidv4(),
        type: "template",
        description: description,
        templateName: templateName,
        items: containers,
        createdAt: new Date()
      }
      const { handleSaveNewTemplate } = props
      handleSaveNewTemplate(item)
      // setContainers([])
      // setCurrentElement({})
      // setDescription("")
      // setTemplateName("")
    } else if (props.name === "templateClone") {
      const item = {
        id: uuidv4(),
        type: "template",
        description: description,
        templateName: templateName,
        items: containers,
        createdAt: new Date()
      }
      const { handleSaveNewTemplate } = props
      handleSaveNewTemplate(item)
      // setContainers([])
      // setCurrentElement({})
      // setDescription("")
      // setTemplateName("")
    } else if (props.name === "forms") {
      const item = {
        id: uuidv4(),
        type: "form",
        description: description,
        formName: formName,
        formType: formType,
        items: containers,
        templateId: templateId,
        createdAt: new Date()
      }
      const { handleSaveNewForm } = props
      handleSaveNewForm(item)
      // setContainers([])
      // setCurrentElement({})
      // setDescription("")
      // setTemplateName("")
    }
  }

  const handleDecreaseRows = (id) => {
    let { tableData } = currentElement
    tableData = tableData.filter((data, index) => index !== currentElement.tableData.length - 1)
    setCurrentElement({ ...currentElement, rows: currentElement.rows <= 0 ? 0 : currentElement.rows - 1, tableData })
    containers.forEach(container => {
      container.items.forEach(item => {
        if (item.id === id) {
          let { tableData } = item
          tableData = tableData.filter((data, index) => index !== currentElement.tableData.length - 1)
          item.tableData = tableData
          if (item.rows <= 0) {
            item.rows = 0
          } else if (item.rows > 0) {
            item.rows -= 1
          }
        }
      });
    });
    setContainers(containers);
  }
  const handleIncreaseRows = (id) => {
    const { tableData, columnsType } = currentElement
    let newRow = [];
    columnsType.forEach(type => {

      // Create a new object based on the column type
      if (type === "text") {
        newRow.push({ type: "text", content: "text" });
      } else if (type === "toggle") {
        newRow.push({ type: "toggle", content: "" });
      } else if (type === "input") {
        newRow.push({ type: "input", content: "" });
      }
    });
    setCurrentElement({ ...currentElement, rows: currentElement.rows + 1, tableData: [...tableData, newRow] })
    containers.forEach(container => {
      container.items.forEach(item => {
        if (item.id === id) {
          item.rows += 1
          item.tableData = [...tableData, newRow]
        }
      });
    });
    setContainers(containers);
  }
  const handleDecreaseColumns = (id) => {
    const { columnNames } = currentElement
    const { tableData } = currentElement
    const { columnsType } = currentElement
    for (let i = 0; i < tableData.length; i++) {
      tableData[i].pop();
    }
    columnNames.pop()
    columnsType.pop()
    setCurrentElement({ ...currentElement, columns: currentElement.columns <= 0 ? 0 : currentElement.columns - 1, columnNames: columnNames, tableData, columnsType })
    containers.forEach(container => {
      container.items.forEach(item => {
        if (item.id === id) {
          item.tableData = tableData
          item.columnsType = columnsType
          item.columnNames = columnNames
          if (item.columns <= 0) {
            item.columns = 0
          } else if (item.columns > 0) {
            item.columns -= 1
          }
        }
      });
    });
    setContainers(containers);
  }
  const handleIncreaseColumns = (id) => {
    const { columnNames } = currentElement
    const { tableData } = currentElement
    const { columnsType } = currentElement
    for (let i = 0; i < tableData.length; i++) {
      tableData[i].push({ type: "text", content: "text" });
    }
    columnsType.push("text")
    setCurrentElement({ ...currentElement, columns: currentElement.columns + 1, columnNames: [...columnNames, `column${columnNames.length + 1}`], tableData: tableData })
    containers.forEach(container => {
      container.items.forEach(item => {
        const { columnNames } = item
        if (item.id === id) {
          item.columnNames = [...columnNames, `column${columnNames.length + 1}`]
          item.columns += 1
          item.tableData = tableData
          item.columnsType = columnsType
        }
      });
    });
    setContainers(containers);
  }

  const handleChangeColumnName = (e, index, id) => {
    let { columnNames } = currentElement
    columnNames[index] = e.target.value
    setCurrentElement({ ...currentElement, columnNames: columnNames })
    containers.forEach(container => {
      container.items.forEach(item => {
        if (item.id === id) {
          item.columnNames = columnNames
        }
      });
    });
    setContainers(containers);
  }

  const handleChangeColumnType = (e, id, index) => {
    let { columnsType } = currentElement
    columnsType[index] = e.target.value
    setCurrentElement({ ...currentElement, columnsType: columnsType })
    containers.forEach(container => {
      container.items.forEach(item => {
        const { columnNames } = item
        if (item.id === id) {
          item.columnsType = columnsType
        }
      });
    });
    setContainers(containers);
  }

  const handleTabelData = (id, tableData) => {
    tableData.forEach((data) => {
      data.forEach((item) => {
        delete item.edit
      })
    })
    setCurrentElement({ ...currentElement, tableData: tableData })
    containers.forEach(container => {
      container.items.forEach(item => {
        const { columnNames } = item
        if (item.id === id) {
          item.tableData = tableData
        }
      });
    });
    setContainers(containers);
  }

  const handleDeleteRadioOption = (id, index) => {
    setCurrentElement({ ...currentElement, options: currentElement.options.filter((option, i) => i != index) })
    containers.forEach(container => {
      container.items.forEach((item) => {
        if (item.id === id) {
          item.options = item.options.filter((option, i) => i != index)
        }
      });
    });
    setContainers(containers);
  }

  const handleAddNewOption = (id) => {
    const { options } = currentElement
    setCurrentElement({ ...currentElement, options: [...options, { label: "" }] })
    containers.forEach(container => {
      container.items.forEach((item) => {
        if (item.id === id) {
          const { options } = item
          item.options = [...options, { label: "" }]
        }
      });
    });
    setContainers(containers);
  }

  const handleRadioLabel = (e, id) => {
    setCurrentElement({ ...currentElement, content: e.target.value, label: e.target.value })
    containers.forEach(container => {
      container.items.forEach((item) => {
        if (item.id === id) {
          item.content = e.target.value
          item.label = e.target.value
        }
      });
    });
    setContainers(containers);
  }

  const handleEditRadioOption = (e, id, index) => {
    let { options } = currentElement
    options[index].label = e.target.value
    setCurrentElement({ ...currentElement, options: options })
    containers.forEach(container => {
      container.items.forEach((item) => {
        if (item.id === id) {
          const { options } = item
          options[index].label = e.target.value
        }
      });
    });
    setContainers(containers);
  }

  const handleEditdropdownOption = (e, id, index) => {
    let { options } = currentElement
    options[index] = e.target.value
    setCurrentElement({ ...currentElement, options: options })
    containers.forEach(container => {
      container.items.forEach((item) => {
        if (item.id === id) {
          const { options } = item
          options[index] = e.target.value
        }
      });
    });
    setContainers(containers);
  }
  const handleDeleteDropdownOption = (id, index) => {
    setCurrentElement({ ...currentElement, options: currentElement.options.filter((option, i) => i != index) })
    containers.forEach(container => {
      container.items.forEach((item) => {
        if (item.id === id) {
          item.options = item.options.filter((option, i) => i != index)
        }
      });
    });
    setContainers(containers);
  }
  const handleAddNewDropdownOption = (id) => {
    const { options } = currentElement
    setCurrentElement({ ...currentElement, options: [...options, ""] })
    containers.forEach(container => {
      container.items.forEach((item) => {
        if (item.id === id) {
          const { options } = item
          item.options = [...options, ""]
        }
      });
    });
    setContainers(containers);
  }

  const changeToggleContent = (e, id) => {
    setCurrentElement({ ...currentElement, content: e.target.value, label: e.target.value })
    containers.forEach(container => {
      container.items.forEach(item => {
        if (item.id === id) {
          item.content = e.target.value;
          item.label = e.target.value;
        }
      });
    });
    setContainers(containers);
  }

  const changeParaWidth = (e, id) => {
    setCurrentElement({ ...currentElement, width: (e.target.value === "" || e.target.value === "Na") ? "" : parseInt(e.target.value) })
    containers.forEach(container => {
      container.items.forEach((item) => {
        if (item.id === id) {
          item.width = (e.target.value === "" || e.target.value === "Na") ? "" : parseInt(e.target.value)
        }
      });
    });
    setContainers(containers);
  }

  const handleDeleteItem = (id) => {
    // let containerIndex = -1;
    // let itemIndex = -1;
    // containers.forEach((container, index) => {
    //   const foundItemIndex = container.items.findIndex(item => item.id === id);
    //   if (foundItemIndex !== -1) {
    //     containerIndex = index;
    //     itemIndex = foundItemIndex;
    //   }
    // });

    // If the item is found, remove it from the items array
    // if (containerIndex !== -1 && itemIndex !== -1) {
    //   const updatedItems = [...containers[containerIndex].items];
    //   updatedItems.splice(itemIndex, 1);
    //   const updatedContainerArray = [...containers];
    //   updatedContainerArray[containerIndex].items = updatedItems;

    //   // If the items array is empty, remove the container object
    //   if (updatedItems.length === 0) {
    //     updatedContainerArray.splice(containerIndex, 1);
    //   }

    //   // Update the state
    //   setContainers(updatedContainerArray);
    //   console.log(updatedContainerArray)
    // }

    const updatedData = containers.map(obj => ({
      ...obj,
      items: obj.items.filter(item => item.id !== id)
    })).filter(obj => obj.items.length > 0); // Filter out objects with non-empty items arrays

    setContainers(updatedData);
  }

  const handleFormData = (key, value) => {
    let newFormData = formData
    newFormData[key] = value
    setFormData({ ...newFormData })
  }

  const dragOverHandler = (e, id) => {
    e.preventDefault();
    setContainerId(id)
    const { clientX, clientY } = e;
    const containerRect = e.currentTarget.getBoundingClientRect();
    const offsetX = clientX - containerRect.left;
    const offsetY = clientY - containerRect.top;

    if (offsetY < containerRect.height / 4) {
      setPosition("above")
    } else if (offsetY > (containerRect.height / 4) * 3) {
      setPosition("below")
    } else if (offsetX < containerRect.width / 2) {
      setPosition("left")
    } else {
      setPosition("right")
    }
  };

  const isActive = canDrop && isOver;
  let backgroundColor = '#ffffff';
  if (isActive) {
    backgroundColor = '#d9edf7';
  } else if (canDrop) {
    backgroundColor = '#fcf8e3';
  }

  return (
    <>
      <div style={{ width: `${props.name === "panels" ? "210mm" : "70%"}` }}>
        {props.name === "panels" && <>
          <h5>Create a Panel</h5>
          <div style={{ height: "10%" }} className='d-flex gap-3 justify-content-start align-items-center'>
            <div className='d-flex align-items-center text-nowrap gap-2'>
              <label className=''>Panel Name </label><input type='text' className='form-control' placeholder='Enter panel name' value={panelName} onChange={(e) => setPanelName(e.target.value)} />
            </div>
            <div className='d-flex align-items-center gap-2 w-100'>
              <label className=''>Description </label><input type='text' className='form-control' placeholder='Enter description' value={description} onChange={(e) => setDescription(e.target.value)} />
            </div>
          </div>
        </>}
        {props.name === "templates" && <>
          <h5>Create a Template</h5>
          <div style={{ height: "10%" }} className='d-flex gap-3 justify-content-start align-items-center'>
            <div className='d-flex align-items-center text-nowrap gap-2'>
              <label className=''>Template Name </label><input type='text' className='form-control' placeholder='Enter Tempalte Name' value={templateName} onChange={(e) => setTemplateName(e.target.value)} />
            </div>
            <div className='d-flex align-items-center gap-2 w-100'>
              <label className=''>Description </label><input type='text' className='form-control' placeholder='Enter description' value={description} onChange={(e) => setDescription(e.target.value)} />
            </div>
          </div>
        </>}
        {props.name === "templateClone" && <>
          <h5>Create a Template Clone</h5>
          <div style={{ height: "10%" }} className='d-flex gap-3 justify-content-start align-items-center'>
            <div className='d-flex align-items-center text-nowrap gap-2'>
              <label className=''>Template Name </label><input type='text' className='form-control' placeholder='Enter Template Name' value={templateName} onChange={(e) => setTemplateName(e.target.value)} />
            </div>
            <div className='d-flex align-items-center gap-2 w-100'>
              <label className=''>Description </label><input type='text' className='form-control' placeholder='Enter description' value={description} onChange={(e) => setDescription(e.target.value)} />
            </div>
          </div>
        </>}
        {props.name === "forms" && <>
          <h5>Create a form</h5>
          <div style={{ height: "10%" }} className='d-flex gap-3 justify-content-start align-items-center'>
            <div className='d-flex flex-column align-items-start text-nowrap'>
              <label className=''>Form Name </label><input type='text' className='form-control' placeholder='Enter Form Name' value={formName} onChange={(e) => setFormName(e.target.value)} />
            </div>
            <div className='d-flex flex-column align-items-start text-nowrap'>
              <label className=''>Form Type </label>
              <select className='form-control' value={formType} onChange={(e) => setFormType(e.target.value)}>
                <option value="" hidden>Select Form Type</option>
                <option value="sop">SOP</option>
                <option value="checklist">Checklist</option>
              </select>
            </div>
            <div className='d-flex flex-column align-items-start text-nowrap'>
              <label className=''>Description </label><input type='text' className='form-control' placeholder='Enter description' value={description} onChange={(e) => setDescription(e.target.value)} />
            </div>
          </div>
        </>}
        {(props.name === "templates" || props.name === "panels" || props.name === "templateClone" || props.name === "forms") ? null : <h5>Create a Field</h5>}
        <div className='form_preview' style={{ height: `${(props.name === "panels" || props.name === "templates" || props.name === "templateClone" || props.name === "forms") ? "75%" : "85%"}` }}>
          <div ref={drop} style={{ overflow: "auto", paddingBottom: "20px", paddingRight: "20px", width: "100%" }} className='d-flex flex-column'>
            {containers.length > 0 ? containers.map((container, index) => {
              return <div style={{ backgroundColor: container.id === containerId ? backgroundColor : "white" }} key={index} id={container.id + " " + index} onDragOver={(e) => dragOverHandler(e, container.id)} onDragOverCapture={(e) => dragOverHandler(e, container.id)} className='d-flex flex-nowrap align-items-center gap-2 flex-start container'>
                {((container.id === containerId) && (position.length > 0)) && <span>{position}</span>}
                {container.items.length > 0 && container.items.map((item, i) => {
                  return <Element key={i} content={item?.content === "" && container?.items[0]?.content}
                    preview={(props.name === "templates" || props.name === "templateClone" || props.name === "forms") ? true : false}
                    delete={props.name === "templateClone" || props.name === "templates" || props.name === "panels" ? true : false}
                    object={item} activeId={currentElement?.id}
                    handleColumnNameChange={handleColumnNameChange}
                    handleDeleteElement={handleDeleteElement}
                    containerId={(containerId === container?.id) && containerId}
                    handleTabelData={handleTabelData}
                    handleDeleteItem={handleDeleteItem} />
                })}
              </div>
            }) : <div style={{ minHeight: "100px", backgroundColor }}>Drag and drop elements here from left side</div>}
          </div>
        </div>
        <div className='d-flex justify-content-end align-items-center' style={{ height: "10%" }}>
          <button className='btn btn-secondary mx-3' onClick={() => {
            switch (props.name) {
              case "forms":
                navigate('/formbuilder', { state: { name: "Forms" } })
                break;
              case "templates":
                navigate('/formbuilder', { state: { name: "Templates" } })
                break;
              case "templateClone":
                navigate('/formbuilder', { state: { name: "Templates" } })
                break;
              case "panels":
                navigate('/formbuilder', { state: { name: "Panels" } })
                break;
              case "fields":
                navigate('/formbuilder', { state: { name: "Fields" } })
                break;
              default:
                break;
            }
          }}>Back</button>
          <button className='btn btn-secondary' disabled={props?.name === "fields" ? containers?.length === 0 : props?.name === "panels" ? panelName?.length === 0 || description?.length === 0 || containers?.length === 0 : (props?.name === "templates" || props.name === "templateClone") ? description?.length === 0 || containers?.length === 0 || templateName?.length === 0 : props?.name === "forms" && formName?.length === 0 || description?.length === 0 || containers?.length === 0 || formType?.length === 0} onClick={() => handleSave()}>Save</button>
        </div>
      </div>
      {/* {props.name === "forms" && <div>
        {
          formData && <pre>{JSON.stringify(formData, null, 2)}</pre>
        }
        </div>} */}
      {(props.name === "fields" || (props.name === "panels" && containers.some(container => container.items.find(item => item.type === 'table')))) && <div className='element-settings'>
        <h5>Element properties</h5>
        {currentElement?.type === "input" && <div className='d-flex flex-column gap-4'>
          <div className='d-flex gap-4 align-items-center justify-content-between'>
            <label>Label</label>
            <input type='text' value={currentElement.content} className='form-control' style={{ width: "60%" }} onChange={(e) => changeInputContent(e, currentElement.id)} />
          </div>
          <div className='d-flex flex-column gap-2 align-items-start justify-content-between'>
            <label>Description</label>
            <textarea className='form-control' onChange={(e) => setDescription(e.target.value)} />
          </div>
        </div>}
        {currentElement?.type === "inputWithLabel" && <div className='d-flex flex-column gap-4'>
          <div className='d-flex gap-4 align-items-center justify-content-between'>
            <label>Label</label>
            <input type='text' value={currentElement.content} className='form-control' style={{ width: "60%" }} onChange={(e) => changeInputContent(e, currentElement.id)} />
          </div>
          <div className='d-flex gap-4 align-items-center justify-content-between'>
            <label>Width</label>
            <input type='text' value={currentElement.width} className='form-control' style={{ width: "60%" }} onChange={(e) => changeParaWidth(e, currentElement.id)} />
          </div>
          <div className='d-flex flex-column gap-2 align-items-start justify-content-between'>
            <label>Description</label>
            <textarea className='form-control' onChange={(e) => setDescription(e.target.value)} />
          </div>
        </div>}
        {currentElement?.type === "paragraph" && <div className='d-flex flex-column gap-4'>
          <div className='d-flex flex-column gap-2 align-items-start justify-content-between'>
            <label>Label</label>
            {/* <input type='text' value={currentElement.content} className='form-control' style={{ width: "60%" }} onChange={(e) => changeInputContent(e, currentElement.id)} /> */}
            <textarea value={currentElement.content} className='form-control' onChange={(e) => changeInputContent(e, currentElement.id)} />
          </div>
          <div className='d-flex gap-4 align-items-center justify-content-between'>
            <label>Width</label>
            <input type='text' value={currentElement.width} className='form-control' style={{ width: "60%" }} onChange={(e) => changeParaWidth(e, currentElement.id)} />
          </div>
          <div className='d-flex flex-column gap-2 align-items-start justify-content-between'>
            <label>Description</label>
            <textarea className='form-control' onChange={(e) => setDescription(e.target.value)} />
          </div>
        </div>}
        {currentElement?.type === "checkbox" && <div className='d-flex flex-column gap-3'>
          <div className='d-flex gap-4 align-items-center justify-content-between'>
            <label>label</label>
            <input type='text' value={currentElement.content} className='form-control' style={{ textOverflow: "ellipsis" }} onChange={(e) => changeCheckboxContent(e, currentElement.id)} />
          </div>
          <div className='d-flex flex-column gap-2 align-items-start justify-content-between'>
            <label>Description</label>
            <textarea className='form-control' onChange={(e) => setDescription(e.target.value)} />
          </div>
        </div>}
        {currentElement?.type === "textarea" && <div className='d-flex flex-column gap-3'>
          <div className='d-flex gap-4 align-items-center justify-content-between'>
            <label>label</label>
            <input type='text' value={currentElement.content} className='form-control' style={{ textOverflow: "ellipsis" }} onChange={(e) => changeTextareaLabel(e, currentElement.id)} />
          </div>
          <div className='d-flex flex-column gap-2 align-items-start justify-content-between'>
            <label>Description</label>
            <textarea className='form-control' onChange={(e) => setDescription(e.target.value)} />
          </div>
        </div>}
        {currentElement?.type === "table" && <div className='d-flex flex-column gap-3 align-items-center'>
          <div className='d-flex gap-4 align-items-center justify-content-between'>
            <label>Content</label>
            <input type='text' value={currentElement.content} className='form-control' onChange={(e) => handleRadioLabel(e, currentElement.id)} style={{ textOverflow: "ellipsis", width: "60%" }} />
          </div>
          <div className='d-flex gap-4 align-items-center justify-content-between w-100'>
            <label>Rows</label>
            <div className='d-flex gap-1 align-items-center' style={{ width: "60%" }} >
              <button className='btn btn-light' onClick={() => handleDecreaseRows(currentElement.id)}>-</button>
              <span>{currentElement.rows}</span>
              <button className='btn btn-light' onClick={() => handleIncreaseRows(currentElement.id)}>+</button>
            </div>
          </div>
          <div className='d-flex gap-4 align-items-center justify-content-between w-100'>
            <label>Columns</label>
            <div className='d-flex gap-1 align-items-center' style={{ width: "60%" }} >
              <button className='btn btn-light' onClick={() => handleDecreaseColumns(currentElement.id)}>-</button>
              <span>{currentElement.columns}</span>
              <button className='btn btn-light' onClick={() => handleIncreaseColumns(currentElement.id)}>+</button>
            </div>
          </div>
          <div className='d-flex gap-2'>
            <div className='d-flex w-50 flex-column gap-3'>
              <b>Column Names</b>
              <div className='d-flex flex-column gap-2'>
                {currentElement.columnNames.map((columnName, index) => {
                  return <input key={index} value={columnName} className='form-control' onChange={(e) => handleChangeColumnName(e, index, currentElement.id)} />
                })}
              </div>
            </div>
            <div className='d-flex w-50 flex-column gap-3'>
              <b>Column Type</b>
              <div className='d-flex flex-column gap-2'>
                {currentElement.columnNames.map((columnName, index) => {
                  return <select key={index} className='form-control' onChange={(e) => handleChangeColumnType(e, currentElement.id, index)}>
                    <option value={"text"}>text</option>
                    <option value={"toggle"}>toggle</option>
                    <option value={"input"}>input</option>
                  </select>
                })}
              </div>
            </div>
          </div>
        </div>}
        {currentElement?.type === "radio" && <div className='d-flex flex-column gap-4 align-items-center'>
          <div className='d-flex gap-4 align-items-center justify-content-between'>
            <label>Label</label>
            <input type='text' value={currentElement.content} className='form-control' onChange={(e) => handleRadioLabel(e, currentElement.id)} style={{ textOverflow: "ellipsis", width: "60%" }} />
          </div>
          <div className='d-flex w-100 flex-column gap-2 align-items-start justify-content-between'>
            <label>Description</label>
            <textarea className='form-control' onChange={(e) => setDescription(e.target.value)} />
          </div>
          <div className='d-flex gap-4 align-items-center justify-content-between w-100'>
            <label>Options</label>
          </div>
          {currentElement.options.map((option, index) => {
            return <div className='d-flex align-items-center gap-3'>
              {index + 1}. <input className='form-control' onChange={(e) => handleEditRadioOption(e, currentElement.id, index)} value={option.label} /> <RxCross1 title='delete' onClick={() => (handleDeleteRadioOption(currentElement.id, index))} />
            </div>
          })}
          <div className='d-flex w-100'>
            <button className='btn btn-light w-100' style={{ border: "1px solid lightgray" }} onClick={() => handleAddNewOption(currentElement.id)}>+</button>
          </div>
        </div>}
        {currentElement?.type === "text" && <div className='d-flex flex-column gap-4'>
          <div className='d-flex gap-4 align-items-center justify-content-between'>
            <label>Content</label>
            <input type='text' value={currentElement.content} className='form-control' style={{ textOverflow: "ellipsis", width: "60%" }} onChange={(e) => changeTextContent(e, currentElement.id)} />
          </div>
          <div className='d-flex gap-4 align-items-center justify-content-between' style={{ width: "100%" }} >
            <label>Size</label>
            <select value={currentElement.size} className='form-control' style={{ width: "60%" }} onChange={(e) => changeTextSize(e, currentElement.id)}>
              <option value={"h1"}>h1</option>
              <option value={"h2"}>h2</option>
              <option value={"h3"}>h3</option>
              <option value={"h4"}>h4</option>
              <option value={"h5"}>h5</option>
              <option value={"h6"}>h6</option>
            </select>
          </div>
          <div className='d-flex flex-column gap-2 align-items-start justify-content-between'>
            <label>Description</label>
            <textarea className='form-control' onChange={(e) => setDescription(e.target.value)} />
          </div>
        </div>}
        {currentElement?.type === "toggle" && <div className='d-flex flex-column gap-4 align-items-center'>
          <div className='d-flex gap-4 align-items-center justify-content-between'>
            <label>Label</label>
            <input type='text' value={currentElement.content} className='form-control' style={{ textOverflow: "ellipsis", width: "60%" }} onChange={(e) => changeToggleContent(e, currentElement.id)} />
          </div>
          <div className='d-flex w-100 flex-column gap-2 align-items-start justify-content-between'>
            <label>Description</label>
            <textarea className='form-control' onChange={(e) => setDescription(e.target.value)} />
          </div>
        </div>}
        {currentElement?.type === "date" && <div className='d-flex flex-column gap-4 align-items-center'>
          <div className='d-flex gap-4 align-items-center justify-content-between'>
            <label>Label</label>
            <input type='text' value={currentElement.content} className='form-control' style={{ textOverflow: "ellipsis", width: "60%" }} onChange={(e) => changeToggleContent(e, currentElement.id)} />
          </div>
          <div className='d-flex w-100 flex-column gap-2 align-items-start justify-content-between'>
            <label>Description</label>
            <textarea className='form-control' onChange={(e) => setDescription(e.target.value)} />
          </div>
        </div>}
        {currentElement?.type === "toggleInput" && <div className='d-flex flex-column gap-4 align-items-center'>
          <div className='d-flex gap-4 align-items-center justify-content-between'>
            <label>Label</label>
            <input type='text' value={currentElement.content} className='form-control' style={{ textOverflow: "ellipsis", width: "60%" }} onChange={(e) => changeToggleContent(e, currentElement.id)} />
          </div>
          <div className='d-flex w-100 flex-column gap-2 align-items-start justify-content-between'>
            <label>Description</label>
            <textarea className='form-control' onChange={(e) => setDescription(e.target.value)} />
          </div>
        </div>}
        {currentElement?.type === "dropdown" && <div className='d-flex flex-column gap-4 align-items-center'>
          <div className='d-flex gap-4 align-items-center justify-content-between'>
            <label>Label</label>
            <input type='text' value={currentElement.content} className='form-control' onChange={(e) => handleRadioLabel(e, currentElement.id)} style={{ textOverflow: "ellipsis", width: "60%" }} />
          </div>
          <div className='d-flex w-100 flex-column gap-2 align-items-start justify-content-between'>
            <label>Description</label>
            <textarea className='form-control' onChange={(e) => setDescription(e.target.value)} />
          </div>
          <div className='d-flex gap-4 align-items-center justify-content-between w-100'>
            <label>Options</label>
          </div>
          {currentElement.options.map((option, index) => {
            return <div key={index} className='d-flex align-items-center gap-3'>
              {index + 1}. <input className='form-control' onChange={(e) => handleEditdropdownOption(e, currentElement.id, index)} value={option} /> <RxCross1 title='delete' onClick={() => (handleDeleteDropdownOption(currentElement.id, index))} />
            </div>
          })}
          <div className='d-flex w-100'>
            <button className='btn btn-light w-100' style={{ border: "1px solid lightgray" }} onClick={() => handleAddNewDropdownOption(currentElement.id)}>+</button>
          </div>
        </div>}
      </div>}
      {/* {creationError && <Modal show={creationError} onHide={() => {
        dispatch({
          type: CREATION_ERROR,
          payload: null
        })
      }} centered>
        <Modal.Header><div className='d-flex align-items-center gap-2'><MdErrorOutline color='red' style={{ height: "25px", width: "25px" }} /><span className='fs-3'>Error</span></div></Modal.Header>
        <Modal.Body className="text-start"><span>{creationError}</span></Modal.Body>
        <Modal.Footer className="footer d-flex justify-content-end">
          <Button variant="secondary" onClick={() => {
            dispatch({
              type: CREATION_ERROR,
              payload: null
            })
          }}
          >Back</Button>
          <Button variant="secondary" onClick={() => {
            handleSave()
            // dispatch(createField())
            dispatch({
              type: CREATION_ERROR,
              payload: null
            })
            // navigate('/formbuilder', { state: { name: "Fields" } })
          }}
          >Retry</Button>
        </Modal.Footer>
      </Modal>} */}
      {creationError && <AlertPopUp
        alertShow={creationError}
        alertType="error"
        alertMsg="Error"
        alertValue={creationError}
        alertButtons={[{
          name: "Back", action: () => {
            dispatch({
              type: CREATION_ERROR,
              variant: "secondary",
              payload: null
            })
          }
        },
        {
          name: "Retry", action: () => {
            handleSave()
            dispatch({
              type: CREATION_ERROR,
              payload: null
            })
          }
        }
        ]}
      />}

    </>
  );
};

export default FormCanvas;