import React, { useEffect, useState } from 'react'
import { GET_HSN_FAILURE, GET_HSN_SUCCESS, UPDATE_HSN_FAILURE, UPDATE_HSN_SUCCESS, getHSNCode, updateHSNCode } from '../../Redux/Actions/Actions';
import { useDispatch, useSelector } from 'react-redux';
import FormLoader from '../Reusable_Components/FromLoader';
import { Button, Modal } from 'react-bootstrap';
import { MdErrorOutline } from 'react-icons/md';
import DatePicker from 'react-datepicker';

export default function HSNCode() {

    const dispatch = useDispatch();

    const hsnCode = useSelector(state => state.hsnCode);
    const hsnCodeError = useSelector(state => state.hsnCodeError);
    const updateHsnSuccess = useSelector(state => state.updateHsnSuccess);
    const updateHsnFailure = useSelector(state => state.updateHsnFailure);
    const loading = useSelector(state => state.loading);

    const [selectedService, setSelectedService] = useState('slf');
    const [biilingPeriodFilter, setBillingPeriodFilter] = useState(new Date())

    useEffect(() => {
        const month = biilingPeriodFilter.getMonth() + 1;
        const year = biilingPeriodFilter.getFullYear();
        dispatch(getHSNCode(selectedService, month, year))
    }, [selectedService, biilingPeriodFilter, updateHsnFailure])

    const updateHsn = () => {
        const month = biilingPeriodFilter.getMonth() + 1;
        const year = biilingPeriodFilter.getFullYear();
        dispatch(updateHSNCode({ selectedService, hsnCode, month, year }))
    }

    return (
        <div className='py-2' style={{ borderBottom: "1px solid lightgray" }}>
            <h4>HSN Code</h4>
            <div className='d-flex mb-2' style={{ width: "100%" }}>
                <div className=' d-flex flex-column gap-3' style={{ width: "100%" }}>
                    <span>Update the HSN Code for a service</span>
                    <div className='d-flex align-items-center justify-content-between gap-3 flex-wrap ' style={{ width: "100%" }}>
                        <div className='d-flex flex-column'>
                            <label style={{ width: "50%" }}>Service</label>
                            <div>
                                <select value={selectedService} onChange={(e) => setSelectedService(e.target.value)} className='form-select responsive-add-facility-input-fields' style={{ border: "1px solid lightgray", padding: "5px", borderRadius: "8px" }}>
                                    <option hidden>Select Service</option>
                                    <option value={"slf"}>SLF</option>
                                    <option value={"electricity"}>Electricity</option>
                                    <option value={"water"}>Water</option>
                                    <option value={"fms"}>FMS</option>
                                </select>
                            </div>
                        </div>
                        <div className="d-flex flex-column">
                            <label style={{ width: "50%", marginBottom: "5px" }}>Period :&nbsp;</label>
                            <DatePicker
                                className="admin-controls-date-picker form-control responsive-add-facility-input-fields"
                                placeholderText="Select Period"
                                selected={biilingPeriodFilter}
                                style={{ width: "1rem", height: "2.5rem !important" }}
                                onChange={(date) => setBillingPeriodFilter(date)}
                                showMonthYearPicker
                                dateFormat="MMM yyyy"
                            // customInput={<input value={biilingPeriodFilter ? changeDateFormat() : ''} />}
                            />

                        </div>
                        <div className='d-flex flex-column'>
                            <label style={{ width: "50%" }}>HSN Code</label>
                            <input type='number'
                            onWheel={(e) => e.target.blur()}
                                placeholder='Enter HSN Code'
                                value={hsnCode && hsnCode}
                                className='noSpinArrows form-control responsive-add-facility-input-fields'
                                onChange={(e) => {
                                    dispatch({ type: GET_HSN_SUCCESS, payload: e.target.value })
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-end' style={{ height: "100%" }}>
                <button className='invoice_btn' style={{ color: "white" }} onClick={updateHsn}>Update</button>
            </div>
            {loading && <FormLoader />}
            {hsnCodeError && <Modal keyboard={false} backdrop="static" show={hsnCodeError} onHide={() => {
                dispatch({
                    type: GET_HSN_FAILURE,
                    payload: null
                })
            }} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <MdErrorOutline style={{ fontWeight: "10px", fontSize: "60px", color: "#Cc0202", opacity: 0.8 }} />
                    <h5>Error!</h5>
                    <p className="mb-3">{hsnCodeError}</p>
                </Modal.Body>
                <Modal.Footer className='footer'>
                    <Button variant="secondary" className="secondary-left" onClick={() => {
                        dispatch({
                            type: GET_HSN_FAILURE,
                            payload: null
                        })
                    }} >
                        Close
                    </Button>
                    <Button variant="secondary" className="secondary" onClick={() => {
                        dispatch(getHSNCode(selectedService, biilingPeriodFilter.getMonth() + 1, biilingPeriodFilter.getFullYear()))
                    }} >
                        Retry
                    </Button>
                </Modal.Footer>
            </Modal>}
            {updateHsnSuccess && <Modal keyboard={false} backdrop="static" show={updateHsnSuccess} onHide={() => {
                dispatch({
                    type: UPDATE_HSN_SUCCESS,
                    payload: null
                })
            }} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <img src="./images/icons/popper.svg" alt="Party Popper" />
                    <h5>Success!</h5>
                    <p className="mb-3">{updateHsnSuccess}</p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center" >
                    <Button variant="secondary" className="secondary" onClick={() => {
                        dispatch({
                            type: UPDATE_HSN_SUCCESS,
                            payload: null
                        })
                    }} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>}
            {updateHsnFailure && <Modal keyboard={false} backdrop="static" show={updateHsnFailure} onHide={() => {
                dispatch({
                    type: UPDATE_HSN_FAILURE,
                    payload: null
                })
            }} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <MdErrorOutline style={{ fontWeight: "10px", fontSize: "60px", color: "#Cc0202", opacity: 0.8 }} />
                    <h5>Error!</h5>
                    <p className="mb-3">{updateHsnFailure}</p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center" >
                    <Button variant="secondary" className="secondary" onClick={() => {
                        dispatch({
                            type: UPDATE_HSN_FAILURE,
                            payload: null
                        })
                        // window.location.reload();
                    }} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>}
        </div>
    )
}
