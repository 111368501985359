import React, { useState } from 'react'
import Element from './Element'
import { RxCross2 } from 'react-icons/rx'

export default function ViewForm({closeForm,form_items}) {

    const [form,setForm] = useState(form_items)

  return (
    <div className='form_preview_container'>
    <div className='form_view'>
        <RxCross2 className='close-form' onClick={() => {
            // setPreview(false)
            closeForm()
        }} />
        {form?.map((previewObj, index) => {
            return <div key={previewObj.id} id={previewObj.id + " " + index} className='d-flex'>
                {previewObj.items.map((item, index) => {
                    return <Element preview={true} key={index} object={item} />
                })}
            </div>
        })}
        <div className='d-flex justify-content-between flex-wrap mt-3 p-2' style={{ fontSize: "10px", borderTop: "1px solid gray" }}>
            {/* <span><b>Created :</b> {footerValues?.createdAt?.split("T")[0] + "  " + new Date(footerValues?.createdAt).toLocaleTimeString()}</span> */}
            {/* <span><b>Last Updated at :</b> {footerValues?.updatedAt?.split("T")[0] + "  " + new Date(footerValues?.createdAt).toLocaleTimeString()}</span> */}
            {/* <span><b>Created by :</b> {footerValues?.createdBy}</span> */}
            {/* <span><b>Last updated by :</b> {footerValues?.updatedBy}</span> */}
        </div>
        <div className='d-flex justify-content-end gap-2'>
            <button className='btn btn-secondary' onClick={() => {
                // setPreview(false)
                closeForm()
            }}>Close</button>
            <button className='btn btn-secondary'>Submit</button>
        </div>
    </div>
</div>
  )
}
