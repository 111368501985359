import React from 'react'

export default function InputLabel({ object }) {
    return (
        <div class="form-floating mb-3 mt-3">
            <input type="text" className="form-control" id="email" placeholder="Enter email" name="email" />
            <label for="email">{object.content}</label>
        </div>
    )
}
