import React, { useEffect, useMemo, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { SiGoogleforms } from "react-icons/si";
import { FaRegHandshake } from "react-icons/fa6";
import { FiUsers } from "react-icons/fi";
import { RiUserSettingsLine } from "react-icons/ri";
import { ACLS } from "../../Config";
import { commercialHeadGetAllFacilitiesForNav } from '../../Redux/Actions/Actions'; // Adjust the import path as necessary
import { getGroupName } from "../Reusable_Components/ReusableFunctions"
import { useDispatch, useSelector } from 'react-redux';

let brandLogo = "./images/bellbell.svg";



export default function LeftNavBar({ children }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const [aclsValue, setAclsValue] = useState();
  const [activeImageListChead, setactiveImageListChead] = useState();

  useEffect(() => {
    setAclsValue(ACLS)

    const group = localStorage.getItem('group');
    if (group === "Commercial Head") {
      setactiveImageListChead(
        ACLS.IndoPortCommercialHeadGroup.map((obj) => ({
          url: obj.ROUTE,
          src: obj.IMGSRC,
          alt: obj.ALT
        })
        )
      )
    }
    if (group === "Technician") {
      setactiveImageListChead(
        ACLS.IndoPortTechnicianGroup.map((obj) => ({
          url: obj.ROUTE,
          src: obj.IMGSRC,
          alt: obj.ALT
        })
        )
      )
    }
    if (group === "Finance Manager") {
      setactiveImageListChead(
        ACLS.IndoPortFinanceManagerGroup.map((obj) => ({
          url: obj.ROUTE,
          src: obj.IMGSRC,
          alt: obj.ALT
        })
        )
      )
    }
    if (group === "CEO") {
      setactiveImageListChead(
        ACLS.IndoPortCEOGroup.map((obj) => ({
          url: obj.ROUTE,
          src: obj.IMGSRC,
          alt: obj.ALT
        })
        )
      )
    }
    if (group === "Facility Manager") {
      setactiveImageListChead(
        ACLS.IndoPortFacilityManagerGroup.map((obj) => ({
          url: obj.ROUTE,
          src: obj.IMGSRC,
          alt: obj.ALT
        })
        )
      )
    }
  }, [ACLS])



  return (
    <div className='left-navbar'>
      {activeImageListChead?.map((item, index) => (
        <NavLink to={item.url} key={index} 
        // onClick={() => window.location.reload()}
        
        onClick={() => {
          dispatch(commercialHeadGetAllFacilitiesForNav())
          
        }}
        className={location.pathname === item.url ? 'activeLeftNavItems' : 'leftNavItems'}>
          <img src={item.src} alt={item.alt} className={location.pathname === item.url ? 'active' : ''} />
          <span className='iconText'>{item.alt}</span>
        </NavLink>
      ))}
    </div>
  );
};
