import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, useNavigate, useParams } from 'react-router-dom';
import { SET_PASSWORD_FAILURE, SET_PASSWORD_SUCCESS, setUserPassword } from '../../Redux/Actions/Actions';
import FormLoader from '../Reusable_Components/FromLoader';
import { Button, Modal } from 'react-bootstrap';
import { MdErrorOutline } from 'react-icons/md';
import Footer from "../Login/Footer.jsx";
import { encrypt } from '../Reusable_Components/ReusableFunctions';

const SetPassword = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const setPasswordSuccess = useSelector(state => state.setPasswordSuccess);
    const setPasswordFailure = useSelector(state => state.setPasswordFailure);
    const loading = useSelector(state => state.loading);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [lengthError, setLengthError] = useState(true);
    const [uppercaseError, setUppercaseError] = useState(true);
    const [lowercaseError, setLowercaseError] = useState(true);
    const [numberError, setNumberError] = useState(true);
    const [specialCharacterError, setSpecialCharacterError] = useState(true);

    const params = useParams();
    const { id } = params;

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (e.target.value.length < 8) {
            setLengthError(true);
        } else {
            setLengthError(false);
        }

        if (!/[A-Z]/.test(e.target.value)) {
            setUppercaseError(true);
        } else {
            setUppercaseError(false);
        }

        if (!/[a-z]/.test(e.target.value)) {
            setLowercaseError(true);
        } else {
            setLowercaseError(false);
        }

        if (!/[0-9]/.test(e.target.value)) {
            setNumberError(true);
        } else {
            setNumberError(false);
        }

        if (!/[!@#$%^&*]/.test(e.target.value)) {
            setSpecialCharacterError(true);
        } else {
            setSpecialCharacterError(false);
        }

    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        if (password !== e.target.value) {
            setPasswordError('Passwords do not match');
        } else {
            setPasswordError('');
        }
    };

    const handleSubmit = () => {
        const data = {
            password: password,
            id: id
        };
        dispatch(setUserPassword(encrypt(data)));
    };

    return (
        <div className='set-password-container' style={{ backgroundImage: "url('../images/icons/SetPasswordBackground.avif')", backgroundSize: "cover" }}>
            <h5 style={{ textAlign: "center" }}>Congratulations! You Are Successfully Registered. Please Setup Your Password</h5>
            <h1 style={{ textAlign: "center" }} className='mt-5'>Indoport Logo</h1>
            <div className='d-flex justify-content-center align-items-start flex-wrap gap-5 mt-5' style={{ width: "70%" }}>
                <form style={{ width: "80%", maxWidth: "400px" }}>
                    <div className='d-flex flex-column gap-2'>
                        <label>
                            Password:
                        </label>
                        <input type="password" value={password} onChange={handlePasswordChange} className='form-control' />
                    </div>
                    <br />
                    <div className='d-flex flex-column gap-2'>
                        <label>
                            Confirm Password:
                        </label>
                        <input type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} className='form-control' />
                        <span style={{ color: "red" }}>{passwordError}</span>
                    </div>
                    <br />
                    <button type="button" className='btn btn-secondary' disabled={password === "" || confirmPassword === "" || passwordError || lengthError || uppercaseError
                        || lowercaseError || numberError || specialCharacterError
                    }
                        onClick={handleSubmit}
                    >Submit</button>
                </form>
                <div>
                    <div>
                        <h5>Password must contain:</h5>
                        <ul>
                            {lengthError ? <li style={{ color: "red" }}>At least 8 characters</li> : <li style={{ color: "green" }}>At least 8 characters</li>}
                            {uppercaseError ? <li style={{ color: "red" }}>At least 1 uppercase letter</li> : <li style={{ color: "green" }}>At least 1 uppercase letter</li>}
                            {lowercaseError ? <li style={{ color: "red" }}>At least 1 lowercase letter</li> : <li style={{ color: "green" }}>At least 1 lowercase letter</li>}
                            {numberError ? <li style={{ color: "red" }}>At least 1 number</li> : <li style={{ color: "green" }}>At least 1 number</li>}
                            {specialCharacterError ? <li style={{ color: "red" }}>At least 1 special character</li> : <li style={{ color: "green" }}>At least 1 special character</li>}
                        </ul>
                    </div>
                </div>
            </div>
            {loading && <FormLoader />}
            {setPasswordSuccess && <Modal keyboard={false} backdrop="static" show={setPasswordSuccess} onHide={() => {
                dispatch({
                    type: SET_PASSWORD_SUCCESS,
                    payload: null
                })
                navigate("/", { replace: true })
            }} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <img src="../images/icons/popper.svg" alt="Party Popper" />
                    <h5>Success!</h5>
                    <p className="mb-3">{setPasswordSuccess}</p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center" >
                    <Button variant="secondary" className="secondary" onClick={() => {
                        dispatch({
                            type: SET_PASSWORD_SUCCESS,
                            payload: null
                        })
                        navigate("/", { replace: true })
                    }} >
                        Proceed
                    </Button>
                </Modal.Footer>
            </Modal>}
            {setPasswordFailure && <Modal keyboard={false} backdrop="static" show={setPasswordFailure} onHide={() => {
                dispatch({
                    type: SET_PASSWORD_FAILURE,
                    payload: null
                })
            }} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <MdErrorOutline style={{ fontWeight: "10px", fontSize: "60px", color: "#Cc0202", opacity: 0.8 }} />
                    <h5>Error!</h5>
                    <p className="mb-3">{setPasswordFailure}</p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center" >
                    <Button variant="secondary" className="secondary" onClick={() => {
                        dispatch({
                            type: SET_PASSWORD_FAILURE,
                            payload: null
                        })
                        // window.location.reload();
                    }} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>}
            {/* <Footer /> */}
        </div>
    );
};

export default SetPassword;