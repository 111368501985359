import React, { useEffect, useState } from 'react'
import Input from './Elements/Input'
import Text from './Elements/Text'
import Textarea from './Elements/Textarea'
import Checkbox from './Elements/Checkbox'
import Radio from './Elements/Radio'
import Table from './Elements/Table'
import Upload from './Elements/Upload'
import Toggle from './Elements/Toggle'
import Dropdown from './Elements/Dropdown'
import ToggleInput from './Elements/ToggleInput'
import Paragraph from './Elements/Paragraph'
import Date from './Elements/Date'
import InputWithoutLabel from './Elements/InputWithoutLabel'
import InputLabel from './Elements/InputLabel'
import { RxCross2 } from 'react-icons/rx'

export default function Element(props) {

    const [table, setTableBody] = useState([])
    const [object, setObject] = useState(props.object)
    const [editOption, setEditOption] = useState(false)
    const [edit, setEdit] = useState(false)

    useEffect(() => {
        setObject(props.object)
    }, [])

    let componentToRender;
    switch (props.object.type) {
        case 'input':
            componentToRender = <Input object={props.object}
                data={props.data && props.data}
                handleFormData={props.handleFormData && props.handleFormData}
                rowIndex={props.rowIndex && props.rowIndex}
                colIndex={props.colIndex && props.colIndex}
                formUse={props.formUse && props.formUse}
            />;
            break;
        case 'inputWithoutLabel':
            componentToRender = <InputWithoutLabel object={props.object}
                rowIndex={props.rowIndex && props.rowIndex}
                colIndex={props.colIndex && props.colIndex}
            />;
            break;
        case 'inputWithLabel':
            componentToRender = <InputLabel object={props.object}
                rowIndex={props.rowIndex && props.rowIndex}
                colIndex={props.colIndex && props.colIndex}
            />;
            break;
        case 'text':
            componentToRender = <Text object={props.object}
                rowIndex={props.rowIndex && props.rowIndex}
                colIndex={props.colIndex && props.colIndex}
            />;
            break;
        case 'paragraph':
            componentToRender = <Paragraph object={props.object}
                rowIndex={props.rowIndex && props.rowIndex}
                colIndex={props.colIndex && props.colIndex}
            />;
            break;
        case 'textarea':
            componentToRender = <Textarea object={props.object}
                data={props.data && props.data}
                formUse={props.formUse && props.formUse}
                handleFormData={props.handleFormData && props.handleFormData}
                rowIndex={props.rowIndex && props.rowIndex}
                colIndex={props.colIndex && props.colIndex}
            />;
            break;
        case 'checkbox':
            componentToRender = <Checkbox object={props.object}
                data={props.data && props.data}
                formUse={props.formUse && props.formUse}
                handleFormData={props.handleFormData && props.handleFormData}
                rowIndex={props.rowIndex && props.rowIndex}
                colIndex={props.colIndex && props.colIndex} />;
            break;
        case 'radio':
            componentToRender = <Radio object={props.object}
                data={props.data && props.data}
                formUse={props.formUse && props.formUse}
                handleFormData={props.handleFormData && props.handleFormData}
                rowIndex={props.rowIndex && props.rowIndex}
                colIndex={props.colIndex && props.colIndex}
            />;
            break;
        case 'table':
            componentToRender = <Table object={props.object} preview={props.preview} handleTabelData={props.handleTabelData}
                rowIndex={props.rowIndex && props.rowIndex}
                colIndex={props.colIndex && props.colIndex}
                tableData={props.tableData && props.tableData}
                formUse={props.formUse && props.formUse}
                handleFormData={props.handleFormData && props.handleFormData}
                data={props.data && props.data}
            />;
            break;
        case 'toggle':
            componentToRender = <Toggle object={props.object}
                data={props.data && props.data}
                rowIndex={props.rowIndex && props.rowIndex}
                colIndex={props.colIndex && props.colIndex}
                formUse={props.formUse && props.formUse}
                handleFormData={props.handleFormData && props.handleFormData}
            />;
            break;
        case 'upload':
            componentToRender = <Upload object={props.object}
                rowIndex={props.rowIndex && props.rowIndex}
                colIndex={props.colIndex && props.colIndex}
            />;
            break;
        case 'dropdown':
            componentToRender = <Dropdown object={props.object}
                data={props.data && props.data}
                rowIndex={props.rowIndex && props.rowIndex}
                colIndex={props.colIndex && props.colIndex}
                formUse={props.formUse && props.formUse}
                handleFormData={props.handleFormData && props.handleFormData}
            />;
            break;
        case 'toggleInput':
            componentToRender = <ToggleInput object={props.object}
                rowIndex={props.rowIndex && props.rowIndex}
                colIndex={props.colIndex && props.colIndex}
            />;
            break;
        case 'date':
            componentToRender = <Date object={props.object}
                data={props.data && props.data}
                formUse={props.formUse && props.formUse}
                handleFormData={props.handleFormData && props.handleFormData}
                rowIndex={props.rowIndex && props.rowIndex}
                colIndex={props.colIndex && props.colIndex}
            />;
            break;
        default:
            componentToRender = null; // Render nothing if no matching case
            break;
    }

    return (<div title='edit'
        style={{ cursor: "pointer", borderRadius: "6px", width: `${object?.width ? `${object.width}%` : "100%"}`, marginTop: `${(props.object.type === "paragraph") ? "18px" : props.object.type === "text" ? "30px" : "0px"}` }}
        onMouseOver={() => setEditOption(true)}
        onMouseLeave={() => setEditOption(false)}
        className='element-conteiner'
    >
        {componentToRender}{props.delete && props.delete === true && editOption && <RxCross2 title='delete' className='delete-icon' onClick={() => props.handleDeleteItem(props.object.id)} />}
    </div>
    )
}
