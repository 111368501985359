import React, { useEffect } from 'react'

export default function Input({ object, handleFormData, data, formUse }) {

    useEffect(() => {
        formUse && handleFormData && handleFormData(object.content, "")
    }, [])

    return (
        <div className='d-flex gap-4 align-items-center justify-content-between' style={{ padding: "8px" }}>
            <label>{object.content}</label>
            {data ? <span style={{ width: "60%", fontSize: "12px", color: "gray" }}>{data && data[object?.content]}</span> : <input type='text' style={{ width: "60%" }} className='form-control' value={data && data} disabled={data && true} onChange={(e) => {
                handleFormData && handleFormData(object.content, e.target.value)
            }} />}
        </div>
    )
}