import React, { useEffect, useMemo, useState } from 'react'
import { Modal, Button, Table } from 'react-bootstrap';
import FormElement from './FormELement';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import FormCanvas from './FormCanvas';
import Fields from './Fields';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Panels from './Panels';
import Templates from './Templates';
import Forms from './Forms';
import { CREATE_NEW_PANEL, CREATE_NEW_TEMPLATE, CREATION_SUCCESS, FETCHING_ERROR, SHOW_MODAL, createField, createNewForm, createNewPanel, createNewTemplate, getAllFields, getAllPanels, getAllTemplates } from '../../Redux/Actions/Actions';
import { useDispatch, useSelector } from 'react-redux';
import FormLoader from '../Reusable_Components/FromLoader';
import { MdErrorOutline } from 'react-icons/md';
import { sorting_lists } from '../Reusable_Components/ReusableFunctions';
import AlertPopUp from '../Reusable_Components/Alert';

const elements = [
    {
        type: "text",
        label: "Header"
    },
    {
        type: "paragraph",
        label: "Paragraph"
    },
    {
        type: "input",
        label: "Input"
    },
    // {
    //     type: "inputWithLabel",
    //     label: "Input Label"
    // },
    {
        type: "checkbox",
        label: "Checkbox"
    },
    {
        type: "date",
        label: "Date"
    },
    // {
    //     type: "table",
    //     label: "Table"
    // },
    {
        type: "textarea",
        label: "Textarea"
    },
    {
        type: "radio",
        label: "Radio"
    },
    {
        type: "upload",
        label: "Upload"
    }, {
        type: "toggle",
        label: "Toggle"
    }, {
        type: "dropdown",
        label: "Dropdown"
    }
    // , {
    //     type: "toggleInput",
    //     label: "Toggle with Input"
    // }
]

export default function Form() {

    const { name } = useParams()
    const location = useLocation()
    const data = name === "templateClone" && location?.state?.data
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const newFieldCreated = useSelector((state) => state.newFieldCreated)
    const loader = useSelector((state) => state.loading)
    const modal = useSelector((state) => state.showModal)
    const fieldsList = useSelector((state) => state.fieldsList)
    const panelsList = useSelector((state) => state.panelsList)
    const templatesList = useSelector((state) => state.templatesList)
    const createPanel = useSelector((state) => state.createPanel)
    const createTemplate = useSelector((state) => state.createTemplateSuccess)
    const fetchingError = useSelector((state) => state.fetchingError)
    const creationSuccess = useSelector((state) => state.creationSuccess)

    const [elementsList, setELementsList] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    const [fields, setFields] = useState([])
    const [panels, setPanels] = useState([])
    const [templates, setTemplates] = useState([])
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        // setListofObjects(objects)
        setELementsList(elements)
        const Fields = localStorage.getItem("Fields")
        const Panels = localStorage.getItem("Panels")
        const Templates = localStorage.getItem("Templates")
        // if (Fields) {
        //     const fieldsList = JSON.parse(Fields)
        //     setFields([...fieldsList, {
        //         type: "field",
        //         fieldType: "Table",
        //         fieldName: "Table",
        //         items: { type: "table", label: "Table", id: uuidv4(), content: "Table", rows: 3, columns: 3, columnNames: ["column1", "column2", "column3"], columnsType: ["text", "text", "text"], tableData: [[{ type: "text", content: "text" }, { type: "text", content: "text" }, { type: "text", content: "text" }], [{ type: "text", content: "text" }, { type: "text", content: "text" }, { type: "text", content: "text" }], [{ type: "text", content: "text" }, { type: "text", content: "text" }, { type: "text", content: "text" }]] },
        //         description: "Table Field"
        //     }])
        // }
        if (Panels) {
            setPanels(JSON.parse(Panels))
        }
        if (Templates) {
            setTemplates(JSON.parse(Templates))
        }
        name === "panels" && dispatch(getAllFields())
        name === "templates" && dispatch(getAllPanels())
        name === "templateClone" && dispatch(getAllPanels())
        name === "forms" && dispatch(getAllTemplates())
    }, [])

    useMemo(() => {
        return sorting_lists(fieldsList)
    }, [fieldsList])

    useMemo(() => {
        return sorting_lists(panelsList)
    }, [panelsList])

    useMemo(() => {
        return sorting_lists(templatesList)
    }, [templatesList])

    const handleAddElement = () => {
        const defaultObject = {
            title: "New Title",
            titleSize: 20,
            showHeader: false,
            columnsWidth: [200, 200],
            rows: 2,
            columns: 2,
            columnNames: [],
            border: false,
            showTitle: true
        }
        let columnNames = []
        let columnsType = []
        let dataArray = []
        // let dataArray = [[<FixedText value="text"/>,<TextInput />],[<FixedText value="text"/>,<TextInput />]]
        for (let i = 0; i < defaultObject.columns; i++) {
            columnNames.push(`Column${i + 1}`)
            columnsType.push("text")
        }
        for (let j = 0; j < columnsType.length; j++) {
            dataArray.push(columnsType.map((type, index) => {
                if (type === "text") {
                    return <FixedText value="text" />
                } else if (type === "inputText") {
                    return <TextInput />
                } else {
                    return <Toggle />
                }
                // switch (type) {
                //     case "text":
                //         return <FixedText value="text" />
                //     case "inputText":
                //         return <TextInput />
                //     case "toggle":
                //         return <Toggle />
                //     default:
                //         break;
                // }
            }))

        }
        setELementsList([...elementsList, { ...defaultObject, id: elementsList.length, columnNames: columnNames, columnsType: columnsType, minimize: false, dataArray }])
    }

    const handleTitleChange = (e, id) => {
        // setELementsList(elementsList.map((object) => {
        //     if (object.id === id) {
        //         return {...object,title:e.target.value}
        //     }else{
        //         return object
        //     }
        // }))
        let updatedList = [...elementsList]
        updatedList[id].title = e.target.value
        setELementsList(updatedList)
    }

    const handleTitleSize = (e, id) => {
        setELementsList(elementsList.map((object) => {
            if (object.id === id) {
                return { ...object, titleSize: e.target.value }
            } else {
                return object
            }
        }))
    }

    const handleChangeRows = (e, id) => {
        let dataArray = [];
        for (let j = 0; j < e.target.value; j++) {
            dataArray.push(elementsList[id].columnsType.map((type, index) => {
                if (type === "text") {
                    return <FixedText value="text" />
                } else if (type === "inputText") {
                    return <TextInput />
                } else {
                    return <Toggle />
                }
            }))

        }
        setELementsList(elementsList.map((object) => {
            if (object.id === id) {
                return { ...object, rows: e.target.value, dataArray, dataArray }
            } else {
                return object
            }
        }))
    }

    const handleChangeColumns = (e, id) => {
        let columnNames = []
        let columnsWidth = []
        let columnsType = []
        let dataArray = []
        for (let i = 0; i < e.target.value; i++) {
            columnNames.push(`column${i + 1}`)
            columnsWidth.push(200)
            columnsType.push("text")
        }
        for (let j = 0; j < elementsList[id].rows; j++) {
            dataArray.push(columnsType.map((type, index) => {
                if (type === "text") {
                    return <FixedText value="text" />
                } else if (type === "inputText") {
                    return <TextInput />
                } else {
                    return <Toggle />
                }
            }))

        }
        // setELementsList(elementsList.map((object) => {
        //     if (object.id === id) {
        //         object.columnsType.push("text")
        //         return { ...object, columns: e.target.value, columnNames: columnNames,columnsType:columnsType, columnsWidth: columnsWidth,dataArray:dataArray }
        //     } else {
        //         return object
        //     }
        // }))
        const updatedList = [...elementsList]
        updatedList[id].columns = e.target.value
        updatedList[id].columnNames = columnNames
        updatedList[id].columnsType = columnsType
        updatedList[id].columnsWidth = columnsWidth
        updatedList[id].dataArray = dataArray
        setELementsList(updatedList)
    }

    const handleSelectHeader = (e, id) => {
        setELementsList(elementsList.map((object) => {
            if (object.id === id) {
                return e.target.value === "true" ? { ...object, showHeader: true } : { ...object, showHeader: false }
            } else {
                return object
            }
        }))
    }

    const handleSelectBorder = (e, id) => {
        setELementsList(elementsList.map((object) => {
            if (object.id === id) {
                return e.target.value === "true" ? { ...object, border: true } : { ...object, border: false }
            } else {
                return object
            }
        }))
    }

    const handleChangeColumnNames = (e, id, index) => {
        setELementsList(elementsList.map((element) => {
            if (element.id === id) {
                element.columnNames[index] = e.target.value
                return element
            } else {
                return element
            }
        }))
    }

    const handleChangeColumnWidth = (e, id, i) => {
        setELementsList(elementsList.map((element) => {
            if (element.id === id) {
                const columnsWidth = element.columnsWidth.map((width, index) => {
                    if (index === i) {
                        return width = e.target.value
                    } else {
                        return width
                    }
                })
                return { ...element, columnsWidth: columnsWidth }
            } else {
                return element
            }
        }))
    }

    const handleColumnTypeChange = (e, id, i) => {
        // setELementsList(elementsList.map((element) => {
        //     if (element.id === id) {
        //         const columnsType = element.columnsType.map((type,index) => {
        //             if (index === i) {
        //                 return type = e.target.value
        //             }else{
        //                 return type
        //             }
        //         })
        //         return {...element,columnsType:columnsType}
        //     }else{
        //         return element
        //     }
        // }))
        const updatedList = [...elementsList]
        updatedList[id].columnsType[i] = e.target.value
        updatedList[id].dataArray = updatedList[id].dataArray.map((each) => {
            return each.map((data, index) => {
                if (index === i) {
                    if (e.target.value === "text") {
                        return <FixedText value="text" />
                    } else if (e.target.value === "inputText") {
                        return <TextInput />
                    } else {
                        return <Toggle />
                    }
                } else {
                    return data
                }

            })
        })
        setELementsList(updatedList)
    }

    const handleMinimize = (id) => {
        setELementsList(elementsList.map((element) => {
            if (element.id === id) {
                return { ...element, minimize: !(element.minimize) }
            } else {
                return element
            }
        }))
    }

    const handleDeleteElement = () => {
        setELementsList(elementsList.filter((element) => element.id !== idToDelete))
        setShowModal(false)
    }

    const handleTextChange = (e, id, colIndex, rowIndex) => {
        const updatedList = [...elementsList]
        updatedList[id].dataArray[rowIndex][colIndex] = <FixedText value={e.target.value} />
        setELementsList(updatedList)
    }

    const handleShowTitle = (e, id) => {
        const updatedList = [...elementsList]
        updatedList[id].showTitle = e.target.checked
        updatedList[id].title = e.target.checked ? "New Title" : ""
        setELementsList(updatedList)
    }

    const handleOpenModal = (id) => {
        setIdToDelete(id);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    // const handleSaveNewPanel = async (newPanel) => {
    //     const data = JSON.stringify(newPanel, null, 2)



    //     // setListofObjects([...listOfObjects,newPanel])
    // }

    const handleSaveNewField = async (newField) => {
        // const fields = JSON.parse(localStorage.getItem("Fields"))
        // const newItems = fields ? [...fields, newField] : [newField]
        // localStorage.setItem('Fields', JSON.stringify(newItems));
        // navigate('/formbuilder', { state: { name: "Fields" } })
        dispatch(createField(newField))
        // console.log(newFieldCreated)
    };

    const handleSaveNewPanel = async (newPanel) => {
        // const panels = JSON.parse(localStorage.getItem("Panels"))
        // const newItems = panels ? [...panels, newPanel] : [newPanel]
        // localStorage.setItem('Panels', JSON.stringify(newItems));
        // navigate('/formbuilder', { state: { name: "Panels" } })
        dispatch(createNewPanel(newPanel))

    };
    const handleSaveNewTemplate = async (newTemplate) => {
        // const templates = JSON.parse(localStorage.getItem("Templates"))
        // const newItems = templates ? [...templates, newTemplate] : [newTemplate]
        // localStorage.setItem('Templates', JSON.stringify(newItems));
        // navigate('/formbuilder', { state: { name: "Templates" } })
        dispatch(createNewTemplate(newTemplate))
    };

    const handleSaveNewForm = async (newForm) => {
        // const forms = JSON.parse(localStorage.getItem("Forms"))
        // const newItems = forms ? [...forms, newForm] : [newForm]
        // localStorage.setItem('Forms', JSON.stringify(newItems));
        // navigate('/formbuilder', { state: { name: "Forms" } })
        dispatch(createNewForm(newForm))
    };


    const handleSearchFilter = (e) => {
        switch (name) {
            case "panels":
                if (e.target.value === "") {
                    setFields(JSON.parse(localStorage.getItem("Fields")))
                } else {
                    const fieldsList = JSON.parse(localStorage.getItem("Fields"))
                    const filteredFields = fieldsList.filter((field) => field.items.label.toLowerCase().includes(e.target.value.toLowerCase()))
                    setFields(filteredFields)
                }
                break;

            default:
                break;
        }
    }

    return (
        <div className='form_builder_container'>
            <DndProvider backend={HTML5Backend}>
                <div className='form_builder'>
                    <div className='d-flex flex-column gap-3' style={{ height: "95%", overflow: "auto" }}>
                        <h5>{name === "panels" ? "Fields" : (name === "templates" || name === "templateClone") ? "Panels" : name === "forms" ? "Templates" : "Elements"}</h5>
                        <input type='search' placeholder={name === "forms" ? "Search Templates" : name === "templates" || name === "templateClone" ? "Search panels" : name === "panels" ? "Search fields" : 'Search elements'} className='form-control' onChange={(e) => handleSearchFilter(e)} />
                        {name === "fields" && <div className='d-flex flex-column gap-2'>
                            {elements.map((element, index) => {
                                return <Fields key={index} element={element} />
                            })}
                        </div>}
                        {name === "panels" && <div className='d-flex flex-column gap-2'>
                            {fieldsList && fieldsList?.map((field, index) => {
                                return <Panels key={index} element={field} />
                            })}
                        </div>}
                        {name === "templates" && <div className='d-flex flex-column gap-2'>
                            {panelsList && panelsList.map((panel, index) => {
                                return <Templates key={index} element={panel} />
                            })}
                        </div>}
                        {name === "templateClone" && <div className='d-flex flex-column gap-2'>
                            {panelsList && panelsList.map((panel, index) => {
                                return <Templates key={index} element={panel} />
                            })}
                        </div>}
                        {name === "forms" && <div className='d-flex flex-column gap-2'>
                            {templatesList && templatesList?.map((template, index) => {
                                return <Forms key={index} element={template} />
                            })}
                        </div>}
                    </div>
                    <div style={{ height: "5%", fontSize: "12px", display: 'flex', gap: "15px" }}>
                        <b>*Note</b>  <span>Drag elements from here</span>
                    </div>
                </div>
                <div className='form_builder_rightside_container'>
                    <FormCanvas handleSaveNewField={handleSaveNewField}
                        handleSaveNewPanel={handleSaveNewPanel}
                        name={name} handleSaveNewTemplate={handleSaveNewTemplate}
                        handleSaveNewForm={handleSaveNewForm}
                        data={name === "templateClone" && data}
                    />
                    {/* {activeTab === "fields" && <FormCanvas />} */}
                </div>
            </DndProvider>
            {loader && <h1>
                <FormLoader />
            </h1>}
            {creationSuccess && <AlertPopUp
                alertShow={creationSuccess}
                alertType="success"
                alertMsg="Success"
                alertValue={creationSuccess}
                alertButtons={[{
                    name: "Proceed", action: () => {
                        dispatch({
                            type: CREATION_SUCCESS,
                            payload: null
                        })
                        switch (name) {
                            case "fields":
                                navigate('/formbuilder', { state: { name: "Fields" } })
                                dispatch({
                                    type: SHOW_MODAL,
                                    payload: false
                                })
                                break;
                            case "panels":
                                navigate('/formbuilder', { state: { name: "Panels" } })
                                break;
                            case "templates":
                                navigate('/formbuilder', { state: { name: "Templates" } })
                                break;
                            case "templateClone":
                                navigate('/formbuilder', { state: { name: "Templates" } })
                                break;
                            case "forms":
                                navigate('/formbuilder', { state: { name: "Forms" } })
                                break;

                            default:
                                break;
                        }
                    }
                }]}
            />}
            {fetchingError && <Modal keyboard={false} show={fetchingError} onHide={() => {
                dispatch({
                    type: FETCHING_ERROR,
                    payload: null
                })
            }} centered>
                <Modal.Header><div className='d-flex align-items-center gap-2'><MdErrorOutline color='red' style={{ height: "25px", width: "25px" }} /><span className='fs-3'>Error</span></div></Modal.Header>
                <Modal.Body className="text-start"><span>{fetchingError}</span></Modal.Body>
                <Modal.Footer className="footer d-flex justify-content-end">
                    <Button variant="secondary" onClick={() => {
                        dispatch({
                            type: FETCHING_ERROR,
                            payload: null
                        })
                    }}
                    >Close</Button>
                    <Button variant="secondary" onClick={() => {
                        switch (name) {
                            case "panels":
                                dispatch(getAllFields())
                                break;
                            case "templates":
                                dispatch(getAllPanels())
                                break;
                            case "templateClone":
                                dispatch(getAllPanels())
                                break;
                            case "forms":
                                dispatch(getAllTemplates())
                                break;
                            default:
                                break;
                        }
                        dispatch({
                            type: FETCHING_ERROR,
                            payload: null
                        })
                    }}
                    >Retry</Button>
                </Modal.Footer>
            </Modal>}
        </div>
    )
}


const FixedText = (props) => {
    return <>{props.value}{console.log(props.value)}</>
}

const TextInput = (props) => {
    return <input className='form-control' />
}

const Toggle = (props) => {
    return <div className="form-switch d-flex">
        <input className="form-check-input" style={{ width: "40px", height: "20px" }} type="checkbox" id="paymentTerms" />
    </div>
}