import React from "react";
import Calender from "../Reusable_Components/Calender";

const FinanceManagerCalender = () => {

    const tasksDummyData = {
        '2024-2-8' : [
            {'taskname' : 'Task1',
             'time' : '11:30 am'},
            {'taskname' : 'task2',
              'time' : '05:30 pm' },
        ],
        '2024-2-24' : [
            {'taskname' : 'Task1',
             'time' : '11:30 am'},
            {'taskname' : 'task2',
              'time' : '05:30 pm' },
        ],        
        '2024-1-29' : [
            {'taskname' : 'Task1',
             'time' : '11:30 am'},
        ],
        '2024-2-13' : [
              {'taskname' : 'task 1',
              'time' : '10:30 am' },
              {'taskname' : 'task 2',
              'time' : '11:30 am' },
              {'taskname' : 'task 3',
              'time' : '05:30 pm' },
              {'taskname' : 'task 4',
              'time' : '05:30 pm' },
              {'taskname' : 'task 5',
              'time' : '05:30 pm' },
              {'taskname' : 'task 6',
              'time' : '05:30 pm' },
        ],
        '2024-3-2' : [
            {'taskname' : 'Task1',
            'time' : '11:30 am'},
           {'taskname' : 'task2',
             'time' : '05:30 pm' },
        ],
        '2024-1-28' : [
            {'taskname' : 'Task1',
            'time' : '11:30 am'},
           {'taskname' : 'task2',
             'time' : '01:30 pm' },
             {'taskname' : 'Task1',
             'time' : '03:00 pm'},
            {'taskname' : 'task2',
              'time' : '05:30 pm' },
        ],
        '2024-3-24' : [
            {'taskname' : 'Task1',
            'time' : '11:30 am'},
        ],
        '2024-1-13' : [
            {'taskname' : 'Task1',
            'time' : '11:30 am'},
        ],
        '2024-2-29' : [
            {'taskname' : 'Task1',
             'time' : '11:30 am'},
            {'taskname' : 'task2',
              'time' : '01:00 pm' },
            {'taskname' : 'Task1',
              'time' : '05:30 pm'},
        ],
        '2024-2-1' : [
            {'taskname' : 'Generative ppm schedule',
            'time' : '11:30am'},
            {'taskname' : 'task2',
             'time' : '01:30 pm' },
            {'taskname' : 'Task3',
             'time' : '02:00 pm'},
            {'taskname' : 'task4',
              'time' : '05:30 pm' },
        ]
    }

    return (
        <div className="calender_container">
            <Calender tasksData={tasksDummyData}/>
        </div>
    )
}

export default FinanceManagerCalender