import React, {useEffect,useState} from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Label,
    Tooltip,
    Legend,
    RadialBarChart,
    RadialBar,
    BarChart,
    Bar,
    CartesianGrid,
    PieChart,
  Pie,
  Cell,
  } from "recharts";
  import NavBarTop from '../Reusable_Components/NavBarTop'
import LeftNavBar from '../Reusable_Components/LeftNavBar'
import Footer from "../Login/Footer";

const FinanceManagerDashboard = () => {

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text x={x} y={y} fill="white"  dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const getPath = (x, y, width, height) => {
        const borderRadius = 10; 
        const barWidth = width / 1; 
        const halfWidth = barWidth / 0.66;
        
        return `M${x + halfWidth},${y + height - borderRadius}
          L${x + halfWidth},${y + borderRadius}
          Q${x + halfWidth},${y},${x + halfWidth + borderRadius},${y}
          L${x + halfWidth + barWidth - borderRadius},${y}
          Q${x + halfWidth + barWidth},${y},${x + halfWidth + barWidth},${y + borderRadius}
          L${x + halfWidth + barWidth},${y + height - borderRadius}
          Q${x + halfWidth + barWidth},${y + height},${x + halfWidth + barWidth - borderRadius},${y + height}
          L${x + halfWidth + borderRadius},${y + height}
          Q${x + halfWidth},${y + height},${x + halfWidth},${y + height - borderRadius}
          Z`;
      };
    const RoundedBar = (props) => {
        const { fill, x, y, width, height } = props;
        const barWidth = width / 4; // Set the width to one-fourth of the available width
      
        return <path d={getPath(x, y, barWidth, height)} stroke="none" fill={fill} />;
      };

      const onMouseEnter = ()=>{
  
      }

      const invoiceChartData = []
    // const invoiceChartData = [
    //     {
    //         "name": "Approved Invoices",
    //         "value": 133,
    //         "color" : '#706F6F'

    //       },
    //       {
    //         "name": "On-hold Invoices",
    //         "value": 20,
    //         "color" : '#9D9D9C'
    //       },
    //       {
    //         "name": "Pending Invoices",
    //         "value": 20,
    //         "color" : '#D9D9D9'
    //       },
    // ]
    const barChartData = []
    // const barChartData = [
    //     {
    //       "name": "Jan",
    //       "Month Wise Payments": 4000,
          
    //     },
    //     {
    //       "name": "Feb",
    //       "Month Wise Payments": 3000,
          
    //     },
    //     {
    //       "name": "March",
    //       "Month Wise Payments": 2000,
          
    //     },
    //     {
    //       "name": "April",
    //       "Month Wise Payments": 2780,
          
    //     },
    //     {
    //       "name": "May",
    //       "Month Wise Payments": 1890,
          
    //     },
    //     {
    //       "name": "June",
    //       "Month Wise Payments": 2390,
          
    //     },
    //     {
    //       "name": "July",
    //       "Month Wise Payments": 3490,
          
    //     },
    //     {
    //         "name": "August",
    //         "Month Wise Payments": 3490,
            
    //       },
    //       {
    //         "name": "Sept",
    //         "Month Wise Payments": 3490,
            
    //       },
    //       {
    //         "name": "Oct",
    //         "Month Wise Payments": 3490,
            
    //       },
    //       {
    //         "name": "Nov",
    //         "Month Wise Payments": 3490,
            
    //       }
    //   ]
    const [invoiceBool,setInvoiceBool]=useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [isMEdiumScreen, setIsMediumScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
          setIsSmallScreen(window.innerWidth <= 768); // Adjust the threshold as needed
          setIsMediumScreen(window.innerWidth > 768 && window.innerWidth < 1070)
        };
    
        handleResize(); // Check initial screen size
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return (
      <>
        <>
          <NavBarTop />
          <LeftNavBar />
        </>
      
        <div className="fm-dashboard-container">
            <h1 className="fm-dashboard-heading">Dashboard (Work In Progress)</h1>
            <div className="fm-dashboard-card-container">
                <div className="fm-dashboard-card">
                    <div className="fm-dashboard-innercard">
                        <h1>00</h1>
                        <p>Total Invoices</p>
                    </div>
                    <div className="fm-dashboard-innercard-details">
                        <div>
                            <p><span>Occupied</span> 00</p>
                            <p><span>Vacant</span>   00</p>
                            <p><span>Vacant</span>   00</p>
                        </div>
                    </div>
                </div>
                <div className="fm-dashboard-card">
                    <div className="fm-dashboard-innercard">
                        <h1>00</h1>
                        <p>Total users</p>
                    </div>
                    <div className="fm-dashboard-innercard-details">
                        <div>
                            <p><span>Occupied</span> 00</p>
                            <p><span>Vacant</span>   00</p>
                            <p><span>Vacant</span>   00</p>
                        </div>
                    </div>
                </div>
                <div className="fm-dashboard-card">
                    <div className="fm-dashboard-innercard">
                        <h1>00</h1>
                        <p>No. of Contracts</p>
                    </div>
                    <div className="fm-dashboard-innercard-details">
                        <div>
                            <p><span>Occupied</span> 00</p>
                            <p><span>Vacant</span>   00</p>
                            <p><span>Vacant</span>   00</p>
                        </div>
                    </div>
                </div>

            </div>
            <div className="fm-dashboard-graph-container">
                <div className="fm-dashboard-graph-card">
                    <div>
                        <div className="fm-dashboard-invoice-heading">
                            <h1>Payments</h1>
                            <select name="" id="">
                                <option value="electricity">Electricity</option>
                                <option value="water">Water</option>
                            </select>
                        </div>
                    <div className="fm-dashboard-payments-graph">
                    {
                        invoiceChartData && invoiceChartData.length>0 ?
                        <BarChart width={window.screen.width >720 ?530 : 300} barGap={10} height={250} data={barChartData} margin={{ top: 5, right: 5, bottom: 5, left: 5 }} className='responsive-chart'>
                                          <CartesianGrid strokeDasharray="0" />
                                          <XAxis dataKey="name" tick={{ fontSize: 14 }}/>
                                          <YAxis tick={{ fontSize: 14 }}>
                                            <Label dy={-35} value="# of Payments" offset={10} position="insideBottomLeft" angle={-90} fontSize={14} />
                                          </YAxis>
                                          <Tooltip />
                                          <Legend align="center"/>
                                          <Bar dataKey="Month Wise Payments" fill="#878787" shape={<RoundedBar/>} onMouseEnter={onMouseEnter} />
                                        </BarChart>:
                        <div className='d-flex flex-row justify-content-center align-items-center' style={{paddingTop:"200px"}} >
                        <p></p>
                        </div>
                        }
                    </div>
                    </div>
                </div>
                <div className="fm-dashboard-graph-card">
                    <div>
                        <div className="fm-dashboard-invoice-heading">
                            <h1>Invoices</h1>
                            <select name="" id="">
                                <option value="electricity">Electricity</option>
                                <option value="water">Water</option>
                            </select>
                        </div>
                    <div className="fm-dashboard-invoive-graph">
                    {
                        invoiceChartData && invoiceChartData.length>0 ?
                        <PieChart width={isMEdiumScreen ? 160 : 300} height={300} >
                        <Pie
                            data={invoiceChartData}
                            dataKey="value"
                            nameKey="name"
                            cx={isMEdiumScreen ? 70 :isSmallScreen ? 70 : 120}
                            cy={isMEdiumScreen ? 130 : isSmallScreen ? 130 : 130}
                            innerRadius={ isMEdiumScreen ? 30 : isSmallScreen ? 30 : 40}
                            outerRadius={isMEdiumScreen ? 70 : isSmallScreen ? 70 : 100}
                            paddingAngle={0}
                            label={renderCustomizedLabel}
                            labelLine={0}
                        >
                            {invoiceChartData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                        </Pie>
                        <Tooltip
                            formatter={(value) =>
                            `${value}`
                            }
                        />
                        {isSmallScreen ? <Legend
                            align={isSmallScreen ? "center" : "right"}
                            verticalAlign={isSmallScreen ? "bottom" : "middle"} 
                            layout={isSmallScreen ? "centric" : "vertical"}
                            iconSize={10}
                            wrapperStyle={{ bottom : 15 }} // Adjust bottom margin as needed
                            formatter={(value, entry) => (
                            <span style={{ color: 'black' }}>
                                <span>{`${entry.payload.name}`}</span>
                                <span>{` ${entry.payload.value ? entry.payload.value : 0}`}</span>
                            </span>
                            )}
                        /> : <Legend
                        align={isSmallScreen ? "center" : "right"}
                        verticalAlign={isSmallScreen ? "bottom" : "middle"} 
                        layout={isSmallScreen ? "centric" : "vertical"}
                        iconSize={10}
                        wrapperStyle={{ right: invoiceBool ? 20 : -180 }} // Adjust bottom margin as needed
                        formatter={(value, entry) => (
                        <span style={{ color: 'black' }}>
                            <span>{`${entry.payload.name}`}</span>
                            <span>{` ${entry.payload.value ? entry.payload.value : 0}`}</span>
                        </span>
                        )}
                    />}
                    
                        </PieChart>:
                        <div className='d-flex flex-row justify-content-center align-items-center'  >
                        <p></p>
                        </div>
                        }
                    </div>
                    {
                        invoiceChartData && invoiceChartData.length>0 ?
                        <div style={{textAlign:"center"}}>
                        <p style={{fontSize:"16px",fontWeight:'500',marginTop:`${isSmallScreen ? "50px" : "0px"}`}}>Month Wise Invoices</p>
                        </div>:""
                    }

                    
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
      </>
    )
}

export default FinanceManagerDashboard