import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const FinanceManagerPayments = () => {

    let const2Data={
      1: {id:1,invoiceNo: "INV004", 
      invoiceType: "Invoice 4", 
      contractNumber: "C004", 
      userName: "User 4", 
      payableContacts: "delhiv15ince@mail.com", 
      issuedDate: "Oct 12 2023", 
      dueDate: "Oct 20 2023", 
      amountDue: 1500000, 
      totalAmount: 1500000, 
      status: "Not Paid",
      contractName:"amazon lease",
         amountDepositedinbank:null,
        
         tds:null
 }
,     2:   { id:2,invoiceNo: "INV003", 
     invoiceType: "Invoice 3", 
     contractNumber: "C003", 
     userName: "User 3", 
     payableContacts: "fedexfinance@mail.com", 
     issuedDate: "Oct 12 2023", 
     dueDate: "Oct 20 2023", 
     amountDue: 155500, 
     totalAmount: 155500, 
     status: "Not Paid",
     contractName:"amazon lease",
        amountDepositedinbank:null,
                tds:null
     
  },
 3: { id:3,invoiceNo: "INV001", 
        invoiceType: "Invoice 1", 
        contractNumber: "C001", 
        userName: "User 1", 
        payableContacts: "user1amazon@mail.com", 
        issuedDate: "Oct 12 2023", 
        dueDate: "Oct 20 2023",  
        amountDue: 165000, 
        totalAmount: 165000, 
        status: "Not Paid", 
        contractName:"amazon lease",
        amountDepositedinbank:null,
        tds: null
         },
   4:     {id:4,
     invoiceNo: "INV002", 
        invoiceType: "Invoice 2", 
        contractNumber: "C002", 
        userName: "User 2", 
        payableContacts:"user2mail@mail.com" , 
        issuedDate: "Oct 12 2023", 
        dueDate: "Oct 20 2023", 
        amountDue: 152300, 
        totalAmount: 152300, 
        status: "Not Paid",
        contractName:"amazon lease",
        amountDepositedinbank:null,
        tds:null
        
     },
     5: {id:5,invoiceNo: "INV004", 
      invoiceType: "Invoice 4", 
      contractNumber: "C004", 
      userName: "User 4", 
      payableContacts: "delhivine@mail.com", 
      issuedDate: "Oct 12 2023", 
      dueDate: "Oct 20 2023", 
      amountDue: 1500000, 
      totalAmount: 1500000, 
      status: "Not Paid",
      contractName:"amazon lease",
         amountDepositedinbank:null,
        
         tds:null
 }
,     6:   { id:6,invoiceNo: "INV003", 
     invoiceType: "Invoice 3", 
     contractNumber: "C003", 
     userName: "User 3", 
     payableContacts: "fedexfinance@mail.com", 
     issuedDate: "Oct 12 2023", 
     dueDate: "Oct 20 2023", 
     amountDue: 155500, 
     totalAmount: 155500, 
     status: "Not Paid",
     contractName:"amazon lease",
        amountDepositedinbank:null,
                tds:null
     
  },
 7: { id:7,invoiceNo: "INV001", 
        invoiceType: "Invoice 1", 
        contractNumber: "C001", 
        userName: "User 1", 
        payableContacts: "user1amazon@mail.com", 
        issuedDate: "Oct 12 2023", 
        dueDate: "Oct 20 2023", 
        amountDue: 165000, 
        totalAmount: 165000, 
        status: "Not Paid", 
        contractName:"amazon lease",
        amountDepositedinbank:null,
        tds: null
         },
   8:     {id:8,
     invoiceNo: "INV002", 
        invoiceType: "Invoice 2", 
        contractNumber: "C002", 
        userName: "User 2", 
        payableContacts:"user2mail@mail.com" , 
        issuedDate: "Oct 12 2023", 
        dueDate: "Oct 20 2023", 
        amountDue: 152300, 
        totalAmount: 152300, 
        status: "Not Paid",
        contractName:"amazon lease",
        amountDepositedinbank:null,
        tds:null
        
     },
     9: {id:9,invoiceNo: "INV004", 
      invoiceType: "Invoice 4", 
      contractNumber: "C004", 
      userName: "User 4", 
      payableContacts: "delhiv167ie@mail.com", 
      issuedDate: "Oct 12 2023", 
      dueDate: "Oct 20 2023", 
      amountDue: 1500000, 
      totalAmount: 1500000, 
      status: "Not Paid",
      contractName:"amazon lease",
         amountDepositedinbank:null,
        
         tds:null
 }
,     10:   { id:10,invoiceNo: "INV003", 
     invoiceType: "Invoice 3", 
     contractNumber: "C003", 
     userName: "User 3", 
     payableContacts: "fedexfinance@mail.com", 
     issuedDate: "Oct 12 2023", 
     dueDate: "Oct 20 2023", 
     amountDue: 155500, 
     totalAmount: 155500, 
     status: "Not Paid",
     contractName:"amazon lease",
        amountDepositedinbank:null,
                tds:null
     
  },
  11:   { id:11,invoiceNo: "INV003", 
     invoiceType: "Invoice 3", 
     contractNumber: "C003", 
     userName: "User 3", 
     payableContacts: "fedexfinance@mail.com", 
     issuedDate: "Oct 12 2023", 
     dueDate: "Oct 20 2023", 
     amountDue: 155500, 
     totalAmount: 155500, 
     status: "Not Paid",
     contractName:"amazon lease",
        amountDepositedinbank:null,
                tds:null
     
  },
  12:   { id:12,invoiceNo: "INV003", 
     invoiceType: "Invoice 3", 
     contractNumber: "C003", 
     userName: "User 3", 
     payableContacts: "fedexfinance@mail.com", 
     issuedDate: "Oct 12 2023", 
     dueDate: "Oct 20 2023", 
     amountDue: 155500, 
     totalAmount: 155500, 
     status: "Not Paid",
     contractName:"amazon lease",
        amountDepositedinbank:null,
                tds:null
     
  },

    } 

    const [tableData, setTableData] = useState([]);//store fetched data
    // const [currentPage, setCurrentPage] = useState(1)
    const [modalData , setModalData] = useState()
   
    const [modalShow, setModalShow] = React.useState(false);
    
    const [amountpaid, setAmountPaid] = React.useState();
    const [tds, setTds] = useState(); // Initialize tds state
    const [amountDue, setAmountDue] = useState();

    useEffect( ()=>{
    calculateAmountDue()
    },[amountpaid,tds])
    const handleAmountPaidChange = (event) => {
      setAmountPaid(event.target.value);
      // calculateAmountDue(); // Recalculate amountDue on change
    };
  
    const handleTdsChange = (event) => {
      setTds(event.target.value);
      // calculateAmountDue(); // Recalculate amountDue on change
    };
    const calculateAmountDue = () => {
      const total=modalData?.totalAmount- amountpaid
      const due=total-tds  //amount due fun
        setAmountDue(due);
      
    };

    const saveData= ()=>{
   
     let data = modalData
     data.tds=tds
    data.amountDepositedinbank=amountpaid
    data.amountDue=amountDue
    if(data.amountDue===0){data.status="Paid"}
    else{if(data.amountDue==data.totalAmount){data.status="Not Paid"}
    else{data.status="Part Paid"}
  }
  setModalShow(false) //to colse aftr save
    const2Data[modalData?.id]=data}
    useEffect(() => {
        const fetchData = async() => {
            setTableData(Object.values(const2Data));
        }
        
        fetchData();
    }, [] //dependency array
    );//useE
    const handleSendNotification = (data) => {
      console.log('Send notification for:', data);
      
    };

    return (
      <div className='payments_container'> 
      {/* paymentHeadingcontainer */}
      <div className='d-flex justify-content-between align-items-center flex-wrap'>
          <div className='d-flex align-items-center gap-3 mb-3'>
          <div className="d-flex align-items-center" >
        <h2 style={{margin: 0, paddingTop: 30}}>Payments</h2>
        </div>
     </div>
     </div> 
     {/* tablecontainer */}
     <div className='ptable_container'>
                    <table>
                        <thead>
                            <tr className='fin_man_table_head'>
                                <th>Invoice No</th>
                                <th>Invoice Type</th>
                                <th>User Name</th>
                                <th>Payable Contacts</th>
                                <th>Issued Date</th>
                                <th>Due Date</th>
                                <th style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                 Total Amount
                                  <img src="./images/icons/rupee.svg" style={{ alignSelf: 'center' }} /></th>
                                <th style ={{ alignItems: 'center'}}>Amount Due <img  src="./images/icons/rupee.svg"style={{ alignSelf: 'center' }} /></th>

                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody className='fin_man_table_body'>
                            {tableData.map((each) => {
                                return <tr key={each.id} className='table_row'>
                                    <td className='text-center'>{each.invoiceNo}</td>
                                    <td>{each.invoiceType}</td>
                                    <td className='table_text_left'>{each.userName}</td>
                                    {/* Split payableContacts into separate lines */}
                                    <td style ={{width:"50px"}}className='table_text_left'>{each.payableContacts}
                                    </td>
                                    {/* <td className='table_text_right'>{each.payableContacts.split(',').map((contact, index) => (
                                        <div key={index}>{contact.trim()}</div>
                                    ))}
                                    </td> */}
                                    <td className='table_text_right'>{each.issuedDate}</td>
                                    <td className='table_text_right'>{each.dueDate}</td>
                                    {/* <td className='table_text_right'>{each.daysOverDue} Days</td> */}
                                    <td className='table_text_right'>{each.totalAmount}</td>
                                    <td className='table_text_right'>{each.amountDue}</td>
                                    <td className={`table_text_left `}>
                                    {each.status === "Not Paid" ? <strong>{each.status}</strong> : each.status}</td>
                                    <td className='text-center' role='button' onClick={() => handleSendNotification(each)}>
                                    <img src ="./images/icons/Vectoredit.svg" onClick={() => {
                                      const popupX= each?.totalAmount - (each?.amountDepositedinbank + each?.tds)
                                      setAmountDue(popupX)
                                      setAmountPaid(each.amountDepositedinbank)
                                      setTds(each.tds)
                                        setModalShow(true);
                                        setModalData(each)
                                    }} className=" me-1" />
                                    {each.status === "Not Paid" ? 
                                      <img  src="./images/icons/sendnotification.svg" alt="sendnotification"
                                       className="send-notification-icon"/>
                                       :
                                       <img src="./images/icons/greySN.png" onClick={()=>{console.log("click on button")}} alt="sendnotification"
                                       className="send-notification-icon"/>
                                    }
                                    {each.action === "Not Paid" ? <strong>{each.action}</strong> : each.action}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                {modalShow && 
                <div>
                 <Modal keyboard={false} backdrop="static" show={modalShow}
                  size='lg'
                                centered onHide={() => setModalShow(false)}>
                                                    <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                View Payments
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal_popup'>
                <div className='modal_popupcard ' >
                  <div className='popupdetails_right'>
                    <p><span>Contract Number:</span> {modalData && modalData.contractNumber}</p>
                    <p><span>Contract Name: </span>{modalData && modalData.contractName}</p>
                    <p><span>User Name: </span>{modalData && modalData.userName}</p>
                    <p><span>Total Amount: </span>{modalData && modalData.totalAmount}</p>
                    {/* <p><span>GST:</span><div className='Rupee'><input className= 'noSpinArrows' type="number" /><img  src="./images/icons/rupee.svg" alt='ruppee symbol' className='ruppee'/></div></p> */}
                    <p><span>TDS Paid(if any):</span><div className='Rupee'><input className='noSpinArrows' value={tds} onChange={e=>{handleTdsChange(e)}} 
                    onWheel={(e) => e.target.blur()}
                    type="number"/><img  src="./images/icons/rupee.svg" alt='ruppee symbol' className='ruppee'/></div></p>
                  </div>
                  
                  <div className='popupdetails_right'>
                    <p><span>Invoice Number:</span> {modalData && modalData.invoiceNo}</p>
                    <p><span>Invoice Type: </span>{modalData && modalData.invoiceType}</p>
                    <p><span>Payable Contacts: </span>{modalData && modalData.payableContacts}</p>
                    <p><span>Amount Paid: </span><div className='Rupee'><input className='noSpinArrows'value={amountpaid} 
                    onWheel={(e) => e.target.blur()}
                    type="number" onChange={e=>{handleAmountPaidChange(e)}} /><img  src="./images/icons/rupee.svg" alt='ruppee symbol' className='ruppee'/></div></p>
                    <p><span>Amount Due:</span><div className='Rupee'><input disabled className='noSpinArrows'  value= {amountDue} 
                    onWheel={(e) => e.target.blur()}
                    type="number"/><img  src="./images/icons/rupee.svg" alt='ruppee symbol' className='ruppee'/></div></p>
                  </div>
                  
                </div>
         
              
            </Modal.Body>
            <Modal.Footer>
                <Button className='btn btn-secondary' onClick={()=>{saveData()}}> Save</Button> 
            </Modal.Footer>
                                    </Modal>
                </div>}
                </div> //1stcon   
    )
}//fun
export default FinanceManagerPayments;
