import React from 'react'

export default function Text({object}) {
    return (
        <div>
            {object.size === "h1" ? <h1 title='click to edit'>{object.content}</h1>
            : object.size === "h2" ? <h2 title='click to edit'>{object.content}</h2> : 
            object.size === "h3" ? <h3 title='click to edit'>{object.content}</h3>
            : object.size === "h4" ? <h4 title='click to edit'>{object.content}</h4>
            : object.size === "h5" ? <h5 title='click to edit'>{object.content}</h5>
            : <h6 title='click to edit'>{object.content}</h6>}
        </div>
    )
}
