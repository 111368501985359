import React from 'react'

export default function Paragraph({ object }) {
  return (
    <div style={{ padding: "8px", width: `100%` }}>
      {object.content.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </div>
  )
}
