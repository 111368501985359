import React, { useEffect } from 'react'

export default function Checkbox({ object, formUse, handleFormData, data }) {

    useEffect(() => {
        formUse && handleFormData && handleFormData(object.content, false)
    }, [])

    return (
        <div className='d-flex gap-3 align-items-center' style={{ padding: "8px" }}>
            <input type='checkbox' id={object.content}
                checked={data && data[object.content]}
                disabled={data && data}
                onChange={(e) => { formUse && handleFormData && handleFormData(object.content, e.target.checked) }} />
            <label htmlFor={object.content}>{object.content}</label>
        </div>
    )
}
