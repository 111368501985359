import React, { useEffect, useState } from 'react'
import WorkLog from '../Reusable_Components/Work_Log/WorkLog.jsx';
import { IoAddOutline } from 'react-icons/io5';
import NavBarTop from '../Reusable_Components/NavBarTop.jsx';
import LeftNavBar from '../Reusable_Components/LeftNavBar.jsx';
import Search from '../Reusable_Components/Search.jsx';
import Dropdown from '../Reusable_Components/Dropdown.jsx';
import axios from "../../axios-interceptor.js";
import { base_url } from '../../utilities.jsx';
import Loader from '../Reusable_Components/loader.jsx';
import AlertPopUp from '../Reusable_Components/Alert.jsx';

const facilitiesList = [
    {
        name: "ACL",
        id: 1,
        backgroundColor: "rgba(198, 237, 201)",
        color: "red"
    },
    {
        name: "ACLC",
        id: 2,
        backgroundColor: "rgba(226, 181, 227)",
        color: "green"
    },
    {
        name: "ACLC-2A",
        id: 3,
        backgroundColor: "rgba(96, 211, 230, 0.7)",
        color: "blue"
    },
    {
        name: "ACLC-2B",
        id: 4,
        backgroundColor: "rgba(228, 175, 118,0.7)",
        color: "yellow"
    },
]

let data = {
    "PPM": [
        {
            id: 1,
            eventName: "Perform PPM on DG-1",
            scheduledDate: "05-11-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "10:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "todo",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Raj"
        },
        {
            id: 2,
            eventName: "Perform PPM on DG-2",
            scheduledDate: "01-11-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "12:00 PM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "todo",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Rajesh"
        },
        {
            id: 3,
            eventName: "Perform PPM on DG-3",
            scheduledDate: "01-11-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "02:00 PM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC",
            status: "todo",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Dinesh Karri"
        },
        {
            id: 4,
            eventName: "Perform Fire Drill at ACLC-2A",
            scheduledDate: "01-11-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC-2A",
            status: "inprogress",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Dinesh Dunna"
        },
        {
            id: 5,
            eventName: "Perform PPM on Dry transformer-1",
            scheduledDate: "01-11-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "inprogress",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Akhil Yerramilli"
        },
        {
            id: 6,
            eventName: "Perform PPM on Dry transformer-5",
            scheduledDate: "05-11-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC-2B",
            status: "approval",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Bharat Ram"
        },
        {
            id: 7,
            eventName: "Perform Meter Readings",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "completed",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Yashwanth palika"
        }
    ],
    "SOP": [
        {
            id: 8,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "10:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "todo",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Raj"
        },
        {
            id: 9,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "12:00 PM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC",
            status: "todo",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Rajesh"
        },
        {
            id: 10,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "02:00 PM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC-2A",
            status: "todo",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Dinesh Karri"
        },
        {
            id: 11,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC-2B",
            status: "inprogress",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Dinesh Dunna"
        },
        {
            id: 12,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "approval",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Akhil Yerramilli"
        },
        {
            id: 13,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC-2A",
            status: "approval",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Akhil Yerramilli"
        },
        {
            id: 14,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "approval",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Akhil Yerramilli"
        },
        {
            id: 15,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "approval",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Akhil Yerramilli"
        },
        {
            id: 16,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "completed",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Bharat Ram"
        },
        {
            id: 17,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "completed",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Yashwanth palika"
        }
    ]
}

const assignee_list = [
    {
        name: "Raj"
    },
    {
        name: "Rajesh"
    },
    {
        name: "Dinesh Karri"
    },
    {
        name: "Dinesh Dunna"
    },
    {
        name: "Akhil Yerramilli"
    },
    {
        name: "Bharat Ram"
    },
    {
        name: "Yashwanth palika"
    },
    {
        name: "Pavani Sreemukhi"
    },
    {
        name: "Yashwanth kumar"
    }
]

const colors = [
    "#15BDFA",
    "#369C2A",
    "#5E40A1",
    "#F7BC26",
    "#0F2D80",
    "#DF4451",
]

export default function FacilityManagerWorkLog() {

    const [columnNames, setColumnNames] = useState([])
    const [tasks, setTasks] = useState([])
    const [filteredTasks, setFilteredTasks] = useState([])
    const [assignee, setAssignee] = useState([])
    const [remainingAssignee, setRemainingAssignee] = useState([])
    const [selectedAssignee, setSelectedAssignee] = useState([])
    const [selectedRemainingAssignee, setSelectedRemainingAssignee] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [fromDate, setFromDate] = useState(() => {
        const date = new Date();
        date.setDate(date.getDate() - 7);
        return date.toISOString().split('T')[0];
    });
    const [toDate, setToDate] = useState(() => {
        const date = new Date();
        date.setDate(date.getDate() - 7);
        const fromDate = date.toISOString().split('T')[0];
        date.setMonth(date.getMonth() + 1);
        const toDate = date.toISOString().split('T')[0];
        return toDate;
    });
    const [selectedFacility, setSelectedFacility] = useState('all')
    const [sections, setSections] = useState([])
    const [loader, setLoader] = useState(false)
    const [facilitiesList, setFacilitiesList] = useState([])
    const [getTasksError, setGetTasksError] = useState(null)

    useEffect(() => {
        setColumnNames(['scheduled', 'inprogress', 'approval', 'completed']);
    }, [fromDate, toDate]);

    useEffect(() => {
        setLoader(true)
        axios.get(base_url + `events/tasks?filter_condition=%7B%22task_start_time%22%3A%22${fromDate}%22%2C%22task_end_time%22%3A%22${toDate}%22%7D`)
            .then((response) => {
                setSections(Object.keys(response?.data?.data[0])?.map((each) => {
                    return {
                        tab: each,
                        isOpen: false
                    }
                }))
                setFilteredTasks(response?.data?.data[0]);
                let assigneeList = [];
                let facilitiesList = new Set();
                Object.values(response?.data?.data[0]).forEach((tasks) => {
                    tasks.forEach((task) => {
                        task.assignee && assigneeList.push({ name: task.assignee });
                        task.facility_name = facilitiesList.add(task.facility_name);
                        const facilitiesArray = Array.from(facilitiesList);
                        setFacilitiesList(facilitiesArray);
                    });
                });
                const uniqueAssigneeList = Array.from(new Set(assigneeList.map(a => a.name))).map(name => {
                    return assigneeList.find(a => a.name === name);
                });
                uniqueAssigneeList.length > 5 ? setAssignee(uniqueAssigneeList.slice(0, 5)) : setAssignee(uniqueAssigneeList);
                uniqueAssigneeList.length > 5 && setRemainingAssignee(uniqueAssigneeList.slice(5));

                setLoader(false)
            })
            .catch((error) => {
                console.log(error);
                setGetTasksError(error.response?.data?.message);
                setLoader(false)
            });

    }, [fromDate, toDate]);

    const getInitials = (name) => {
        return name?.split(' ')
            .map(word => word[0].toUpperCase())
            .join('');
    };

    const randomColor = (name) => {
        const initials = getInitials(name);
        const charCode = initials?.charCodeAt(0);
        const colorIndex = charCode % colors.length;
        return colors[colorIndex];
    }

    const filterAssignee = (assignee) => {
        let updatedSelectedAssignee;
        if (selectedAssignee.includes(assignee?.name)) {
            updatedSelectedAssignee = selectedAssignee.filter(selected => selected !== assignee?.name);
        } else {
            updatedSelectedAssignee = [...selectedAssignee, assignee?.name];
        }

        setSelectedAssignee(updatedSelectedAssignee);
    };

    const handleSearchTasks = (e) => {
        const searchText = e.target.value;
        setSearchText(searchText);
    };

    const handleChangeFromDate = (e) => {
        setFromDate(e.target.value);
    };

    const handleChangeToDate = (e) => {
        setToDate(e.target.value);
    };

    const handleFacilityChange = (e) => {
        const facility = e.target.value;
        setSelectedFacility(facility);
    };

    const handelSectionChange = (tab) => {
        setSections(sections.map((section) => {
            if (section.tab === tab) {
                return { ...section, isOpen: !(section.isOpen) }
            } else {
                return section
            }
        }))
    }

    const handleUpdateStatus = (task, status) => {
        setFilteredTasks((prevTasks) => {
            const updatedTasks = { ...prevTasks };
            Object.keys(updatedTasks).forEach(categoryKey => {
                updatedTasks[categoryKey] = updatedTasks[categoryKey].map(t => {
                    if (t.event_task_id === task.event_task_id) {
                        return { ...t, task_status: status };
                    }
                    return t;
                });
            });
            return updatedTasks;
        });
    }

    return (
        <>
            <NavBarTop />
            <LeftNavBar />
            <div className='tasks-screen-container'>
                <div className='d-flex justify-content-between align-items-center flex-wrap mb-2'>
                    <div className='d-flex align-items-center gap-3'>
                        <h3 className="invoice-heading" >Work Log</h3>
                    </div>
                    <div className='scheduler-dropdown'>
                        <IoAddOutline style={{ height: "20px", width: "20px" }} />
                        <select className='worklog-dropdown'>
                            <option value="" hidden>Schedule Event</option>
                            <option value="monthly">Monthly</option>
                            <option value="quarterly">Quarterly</option>
                            <option value="yearly">Yearly</option>
                        </select>
                    </div>
                </div>
                <div className='d-flex mb-2 justify-content-between align-items-end gap-2 flex-wrap'>
                    <Search value={searchText} handleSearch={handleSearchTasks} />

                    {facilitiesList && <Dropdown
                        selectedOption={selectedFacility}
                        handleChange={handleFacilityChange}
                        options={facilitiesList}
                        name='Facility'
                        className='dropdown-list'
                        handleChangeTaskStatus={handleUpdateStatus}
                        defaultOption={'All'}
                    />}

                    <div className='d-flex gap-2'>
                        <div className='d-flex flex-column'>
                            <label>From date</label>
                            <input type='date' max={toDate} value={fromDate} onChange={handleChangeFromDate} className='fin_man_date_range' />
                        </div>
                        <div className='d-flex flex-column'>
                            <label>To date</label>
                            <input type='date' min={fromDate} value={toDate} onChange={handleChangeToDate} className='fin_man_date_range' />
                        </div>
                    </div>
                    {assignee.length > 0 && <div className='d-flex'>
                        {assignee.map((assignee, index) => {
                            return (
                                <div key={index} className={`worklog-assignee ${selectedAssignee?.some(selected => selected === assignee?.name) ? 'selected-worklog-assignee' : ''}`}
                                    style={{ marginLeft: index !== 0 ? '-8px' : '0', backgroundColor: randomColor(assignee?.name) }}
                                    onClick={() => filterAssignee(assignee)}
                                    title={assignee?.name}
                                >
                                    <span>{getInitials(assignee?.name)}</span>
                                </div>
                            )
                        })}
                        {remainingAssignee.length > 0 && <div className='d-flex flex-column'>
                            <div className={`worklog-assignee ${selectedRemainingAssignee ? 'selected-worklog-assignee' : ''}`} style={{ marginLeft: '-8px', backgroundColor: "#E1E1E1", color: "black" }}
                                onClick={() => setSelectedRemainingAssignee(!selectedRemainingAssignee)}
                            >
                                <span>+{remainingAssignee.length}</span>
                            </div>
                            <div className='remaining-assignee' onMouseLeave={() => setSelectedRemainingAssignee(false)}>
                                {selectedRemainingAssignee && <div className='remaining-assignee-list'  >
                                    {selectedRemainingAssignee && remainingAssignee.map((assignee, index) => {
                                        return (
                                            <div className='d-flex align-items-center gap-2 C-pointer'
                                                onClick={() => filterAssignee(assignee)}
                                            >
                                                <div>
                                                    <input type='checkbox' checked={selectedAssignee?.some(selected => selected === assignee?.name)} />
                                                </div>
                                                <div key={index} className='worklog-assignee' style={{ width: "30px", height: "30px", backgroundColor: randomColor(assignee.name) }}
                                                >
                                                    <span>{getInitials(assignee?.name)}</span>
                                                </div>
                                                <div style={{ fontSize: "12px" }}>
                                                    <span>{assignee.name}</span>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>}
                            </div>
                        </div>}
                    </div>}
                </div>
                <WorkLog
                    height={window.innerHeight - 250}
                    columns={columnNames}
                    assignee_list={assignee_list}
                    data={filteredTasks}
                    facilitiesList={facilitiesList}
                    sections={sections}
                    handelSectionChange={handelSectionChange}
                    handleChangeTaskStatus={handleUpdateStatus}
                />
            </div>
            {loader && <Loader />}
            {getTasksError && <AlertPopUp
                alertShow={getTasksError}
                alertType="error"
                alertMsg="Error"
                alertValue={getTasksError}
                alertButtons={[{
                    name: "Close", action: () => setGetTasksError(null)
                }]} />}
        </>
    )
}
